/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	Dispatch,
	SetStateAction,
	useState,
	createContext,
} from "react";
import SignUpImg from "../../../assets/image/dashboard/credit_applications/stepOne.png";
import ApplyCreditImg from "../../../assets/image/dashboard/credit_applications/stepTwo.png";
import AcceptCreditOffer from "../../../assets/image/dashboard/credit_applications/stepThree.png";
import {
	RegisterUserForm1,
	RegisterUserForm2,
	RetailerDetailsForm,
	RetailerStoreUpdateForm,
} from "./Forms";
import { useNavigate } from "react-router";
import {
	TopBar,
	SuccessModal,
	ErrorModal,
	StepProcessStage,
} from "../../../components";
import { HiOutlineChevronDoubleRight } from "react-icons/hi2";

type Processprops = {
	page?: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};
export type FormContextProps = {
	formData: any;
	setFormData: Dispatch<SetStateAction<any>>;
};

export type RegisterRetailerForm = {
	phoneNumber: string;
	bankName: string;
	bankAccountNumber: string;
	businessName: string;
	firstName: string;
	lastName: string;
	storeAddress: string;
};

export const registerRetailerProcessForm = {
	phoneNumber: "",
	bankName: "",
	bankAccountNumber: "",
	businessName: "",
	firstName: "",
	lastName: "",
	storeAddress: "",
};
export const CreditApplicationFormContext = createContext<FormContextProps>({
	formData: registerRetailerProcessForm,
	setFormData: () => {
		registerRetailerProcessForm;
	},
});

const CreditApplications: React.FC = () => {
	const processes = [
		"Register User",
		"Apply For Credit",
		"Retailers Details",
	];
	const navigate = useNavigate();
	const [formData, setFormData] = useState<any>({
		phoneNumber: "",
		bankName: "",
		bankAccountNumber: "",
		businessName: "",
		firstName: "",
		lastName: "",
		storeAddress: "",
	});
	const [page, setPage] = useState<number>(0);
	const [showSuccess, setSuccessShow] = useState<boolean>(false);
	const [showError, setErrorShow] = useState<boolean>(false);
	const completeProcess = () => {
		navigate("/dashboard/credit-application/apply", {
			state: { formData },
		});
	};
	const showApplyForCredit = () => {
		setSuccessShow(true);
		// setErrorShow(!showError);
	};
	const submitError = () => {
		setErrorShow(true);
	};

	return (
		<CreditApplicationFormContext.Provider
			value={{ formData, setFormData }}
		>
			{/* <div className="h-full"> */}
			<TopBar pageName="Credit Applications" />
			<div className="mt-4 flex flex-wrap justify-center gap-5 px-8 md:mt-0">
				{page !== 0 &&
					processes.map((process, index) => (
						<div
							key={index}
							className={`flex items-center gap-3 bg-opacity-5 duration-200 ${
								!(page - 1 >= index) ? "opacity-30" : ""
							} ${page === 0 ? "opacity-30" : ""}`}
						>
							<StepProcessStage
								title={process}
								page={page}
								index={index}
								setPage={setPage}
							/>

							<HiOutlineChevronDoubleRight
								className={`hidden flex-shrink-0 text-[#061F6A] md:block ${
									processes.length - 1 === index && "hidden"
								}`}
							/>
						</div>
					))}
			</div>
			{page == 0 && <HomePage setPage={setPage} />}
			{page == 1 && (
				<RegisterUserForm1
					setPage={setPage}
					submitError={submitError}
				/>
			)}
			{page == 2 && <RegisterUserForm2 setPage={setPage} />}
			{page == 3 && (
				<RetailerDetailsForm
					setPage={setPage}
					submit={showApplyForCredit}
				/>
			)}
			{page == 4 && <RetailerStoreUpdateForm setPage={setPage} />}

			<SuccessModal
				content="You have successfully registered the retailer!"
				buttonContent="Apply For Credit"
				buttonAction={completeProcess}
				show={showSuccess}
				setShow={setSuccessShow}
			/>
			<ErrorModal
				content="This account has pending transactions with us!"
				show={showError}
				setShow={setErrorShow}
			/>
			{/* </div> */}
		</CreditApplicationFormContext.Provider>
	);
};

export const HomePage: React.FC<Processprops> = ({ setPage }) => {
	// const startApplication = () => {
	// 	if (isVerifiedUser)
	// 	setShowNotVerified(true);
	// 	// Toast.info("Hi 🙂 Your Account is Ongoing Verification");
	// };

	const registrationSteps = [
		{
			img: SignUpImg,
			title: "Sign Up Retailer",
			description: "Sign Up the Retailer to apply for Credit",
		},
		{
			img: ApplyCreditImg,
			title: "Apply for credit",
			description: "Begin to Apply for Credit",
		},
		{
			img: AcceptCreditOffer,
			title: "Accept Credit Offer",
			description: "You can then accept your credit offer",
		},
	];
	return (
		<>
			<div className="flex flex-col gap-8 px-8 py-12 md:pt-32">
				<div className="mb-4 flex flex-col items-center">
					<h1 className="text-2xl font-bold text-[#002060] ">
						Apply For Credit
					</h1>
					<p className="626D88">
						Follow 3 step to get credit for your distributor
					</p>
				</div>
				<div className="flex flex-col justify-center gap-8 md:flex-row">
					{registrationSteps.map((card, index) => (
						<div
							key={index}
							className="flex min-w-[200px] flex-col items-center gap-2 rounded-lg bg-gray-100 px-4 py-8 backdrop:blur-sm lg:min-w-[264px]"
						>
							<div className="flex h-[112px] w-[121px] items-center justify-center">
								<img
									src={card.img}
									alt={card.title}
									className=" object-contain"
								/>
							</div>
							<h1 className="font-semibold text-[#002060]">
								{card.title}
							</h1>
							<p className="text-[12px] text-[#626D88]">
								{" "}
								{card.description}{" "}
							</p>
						</div>
					))}
				</div>
				<div className="flex w-full justify-center">
					<button
						className="w-full rounded-lg bg-[#072154] py-2 text-white md:w-[40%]"
						onClick={() => setPage(1)}
					>
						Start
					</button>
				</div>
			</div>
		</>
	);
};

export default CreditApplications;
