import React, { useState } from "react";
import { IoPlay } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";

type SubmenuItem = {
	id: number;
	title: string;
	path: string;
};

type Props = {
	icon: React.ReactNode;
	activeIcon?: React.ReactNode;
	title: string;
	path?: string;
	submenus?: SubmenuItem[];
	colorClass?: string;
};

const SidebarItem: React.FC<Props> = ({
	icon,
	activeIcon,
	title,
	path,
	submenus,
	colorClass,
}) => {
	const [isSubmenuVisible, setSubmenuVisible] = useState(false);
	const location = useLocation();

	const handleSubmenuToggle = () => {
		setSubmenuVisible(!isSubmenuVisible);
	};

	const renderMenuItem = () => {
		if (path) {
			return (
				<NavLink
					to={path}
					end
					className={({ isActive }) =>
						[
							isActive
								? "flex cursor-pointer items-center gap-3 rounded-md bg-blue-500 px-4 py-3 text-base font-medium text-white md:text-[14px]"
								: "flex cursor-pointer items-center gap-3 px-4 py-3 text-base font-medium text-white md:text-[14px]",
							// isSubmenuVisible &&
							submenus?.some(
								(submenu) =>
									location.pathname === `${submenu.path}` ||
									location.pathname === `${path}`
							)
								? "rounded-md bg-blue-500"
								: "",
						].join(" ")
					}
					onClick={() => {
						submenus &&
							submenus.length > 0 &&
							handleSubmenuToggle();
					}}
				>
					<div className="flex items-center gap-3">
						<span className="text-xl">
							{location.pathname === path ? activeIcon : icon}
						</span>
						<span>{title}</span>
					</div>
					{submenus && submenus.length > 0 && (
						<span className="-mr-1 ml-auto">
							<IoPlay
								className={`duration-380 transition-transform ease-in-out ${
									isSubmenuVisible
										? "rotate-90 transform"
										: ""
								}`}
							/>
						</span>
					)}
				</NavLink>
			);
		} else if (submenus && submenus.length > 0) {
			return (
				<div
					className={`flex cursor-pointer items-center gap-3 ${
						submenus?.some(
							(submenu) => location.pathname === `${submenu.path}`
						)
							? "py-3"
							: `${isSubmenuVisible ? "pb-0 pt-3" : "py-3"}`
					} px-4 text-base font-medium text-white md:text-[14px] ${
						// isSubmenuVisible &&
						submenus?.some(
							(submenu) => location.pathname === `${submenu.path}`
						)
							? "rounded-md bg-blue-500"
							: ""
					}`}
					onClick={() => {
						submenus &&
							submenus.length > 0 &&
							handleSubmenuToggle();
					}}
				>
					<div className="flex items-center gap-3">
						<span className="text-xl">
							{submenus?.some(
								(submenu) =>
									location.pathname === `/${submenu.path}`
							)
								? activeIcon
								: icon}
						</span>
						<span>{title}</span>
					</div>
					{submenus && submenus.length > 0 && (
						<span className="-mr-0 ml-auto">
							<IoPlay
								className={`duration-380 transition-transform ease-in-out ${
									isSubmenuVisible
										? "rotate-90 transform"
										: ""
								}`}
							/>
						</span>
					)}
				</div>
			);
		} else {
			return (
				<div
					className={`flex cursor-pointer items-center gap-3 px-4 py-3 text-base font-medium text-white md:text-[14px] ${
						colorClass ? colorClass : ""
					}`}
				>
					<div className="flex items-center gap-3">
						<span
							className={`text-xl ${
								colorClass ? colorClass : ""
							}`}
						>
							{location.pathname === path ? activeIcon : icon}
						</span>
						<span className={`${colorClass ? colorClass : ""}`}>
							{title}
						</span>
					</div>
				</div>
			);
		}
	};

	return (
		<div className="relative">
			{renderMenuItem()}
			{isSubmenuVisible && submenus && submenus.length > 0 && (
				<div className="relative mb-[26px] ml-[25px] flex flex-col border-l-[1px] border-white  p-0 text-base font-medium text-white md:text-[14px]">
					{submenus.map((submenu, index) =>
						submenu.path ? (
							<div
								key={index}
								className="border-b-[1px] border-white"
							>
								<NavLink
									key={index}
									end
									to={submenu.path}
									className="subNav ml-[18px] block translate-y-2/4 bg-primary-900 px-2 py-1 text-gray-500"
								>
									{submenu.title}
								</NavLink>
							</div>
						) : (
							<div
								key={index}
								className="subNav cursor-pointer p-2 text-gray-500"
							>
								{submenu.title}
							</div>
						)
					)}
				</div>
			)}
		</div>
	);
};

export default SidebarItem;
