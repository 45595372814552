/* eslint-disable react/prop-types */
import { NotificationType } from "types/components/dashboard/dashboard";
import RecordItem from "./RecordItem";

const RecordDetails: React.FC<{ notification: NotificationType }> = ({
	notification,
}) => {
	return (
		<div className="w-full pl-6 pt-6 mmd:hidden">
			<div className="mt-3">
				{!notification ? (
					<p>No credit selected</p>
				) : (
					<>
						<h1 className="text-2xl font-bold ">
							{notification.heading}
						</h1>
						<RecordItem
							title={"Date"}
							date={new Date(notification.created)}
							message={notification.message}
						/>
					</>
				)}

				{/* <RecordItem title={"Status"} desc={notification.heading} /> */}
			</div>
		</div>
	);
};

export default RecordDetails;
