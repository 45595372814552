import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { DeliveryOTPInput, Loader2 } from "../../../components";
import { Toast } from "../../../utils/toast";

type DeliveryOtpProps = {
	handleDeliveryOtp: (data: string) => void;
	setDeliveryOtp: Dispatch<SetStateAction<string>>;
	loading: boolean;
};

function DeliveryOtp({
	setDeliveryOtp,
	handleDeliveryOtp,
	loading,
}: DeliveryOtpProps) {
	const [otpCode, setOtpCode] = useState("");
	// const [isLoading, setIsLoading] = useState(false);
	// const [isSuccess, setIsSuccess] = useState(false);

	const otpRef = useRef<HTMLInputElement>(null);

	const handleVerifyOtp = () => {
		// handleDeliveryOtp(otpCode);
		setDeliveryOtp(otpCode);
		if (otpCode) {
			handleDeliveryOtp(otpCode);
		} else {
			Toast.error("otp is not present");
		}
		// console.log(otpCode);
		// Add your OTP verification logic here
	};

	return (
		<>
			<section className="flex flex-col items-center justify-center gap-5">
				<form
					className="flex flex-col items-center justify-center gap-2"
					onSubmit={(e) => {
						e.preventDefault();
						handleVerifyOtp();
					}}
				>
					<DeliveryOTPInput
						otpRef={otpRef}
						onChange={(e) => {
							setOtpCode(e.target.value);
						}}
						otpCode={otpCode}
					/>
					<button
						className="mt-2 flex h-10 w-full items-center justify-center rounded-lg bg-[#002060] py-3 text-sm text-white disabled:opacity-40"
						type="button"
						onClick={handleVerifyOtp}
						disabled={loading || otpCode.length < 4}
					>
						{loading ? <Loader2 /> : "Confirm"}
					</button>
				</form>
			</section>
		</>
	);
}

export default DeliveryOtp;
