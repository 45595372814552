import { DateRange } from "react-day-picker";
import Wrapper from "../../../components/container/Wrapper";
import { StatusType } from "../../../types/utils";
import { DataTable } from "../../../components/Table/DataTable";
import { RecentCreditTableColumn } from "../DashboardHome/RecentCreditTableColumn";

type RecentCreditHistoryProps = {
	creditData: any[];
	dataFetching: boolean;
	isError: any;
	isSuccess: any;
	filteredData: any[];
	uniqueStatuses: any;
	handleClearFilter: () => void;
	emptyState?: () => void;
	handleStatusSelect: (selectedStatus: StatusType) => void;
	handleRowClick: (id: string) => void;
	datePicker: Date | undefined;
	setDatePicker: React.Dispatch<React.SetStateAction<Date | undefined>>;
	dateRange: DateRange | undefined;
	setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
	tableTitle?: string | undefined;
};

const RecentCreditHistory = ({
	creditData,
	dataFetching,
	isSuccess,
	filteredData,
	isError,
	uniqueStatuses,
	handleClearFilter,
	emptyState,
	handleStatusSelect,
	handleRowClick,
	dateRange,
	setDatePicker,
	datePicker,
	setDateRange,
	tableTitle,
}: RecentCreditHistoryProps) => {
	return (
		<Wrapper className="w-full" fetching={dataFetching} working={false}>
			{isSuccess && (
				<DataTable
					columns={RecentCreditTableColumn}
					data={
						filteredData?.length > 0
							? filteredData
							: creditData || []
					}
					filterColumnName="name"
					// exportFileName="allDistributor_credits_data"
					statusOptions={uniqueStatuses}
					onStatusSelect={handleStatusSelect}
					dateRange={dateRange}
					setDateRange={setDateRange}
					setDatePicker={setDatePicker}
					datePicker={datePicker}
					onRowClick={(id) => handleRowClick(id)}
					onClearFilter={handleClearFilter}
					dateFieldName="created"
					tableTitle={tableTitle}
					emptyState={emptyState}
				/>
			)}
			{isError && (
				<div className="mt-8 flex w-full justify-center text-xl font-semibold">
					<p>An Error occurred while loading this page</p>
				</div>
			)}
		</Wrapper>
	);
};

export default RecentCreditHistory;
