import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { HiPlus } from "react-icons/hi2";

export const SubusersData: React.FC = () => {
	const users = [
		{
			id: 1,
			userId: "James Don",
			permissions: "User Level 1",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 2,
			userId: "James Don",
			permissions: "User Level 1",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 3,
			userId: "James Don",
			permissions: "User Level 2",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 4,
			userId: "James Don",
			permissions: "User Level 1",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 5,
			userId: "James Don",
			permissions: "User Level 1",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 6,
			userId: "James Don",
			permissions: "User Level 1",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
		{
			id: 7,
			userId: "James Don",
			permissions: "User Level 2",
			status: "Active",
			lastLogin: "NGN 50,000",
		},
	];
	const selection = ["Date", "In/out", "Method", "Paid"];

	return (
		<section className="px-8 py-4">
			<div className="mt-3 flex items-center justify-between ">
				<div className="flex gap-4">
					<p className="text-[14px] font-[500] text-[#1E293B]">
						Filters
					</p>
					<div className=" flex gap-4 text-[12px]">
						{selection.map((data, index) => (
							<button
								className="flex items-center gap-2  rounded border border-gray-400 px-2 py-[0.5px] text-gray-400"
								key={index}
							>
								{data}
								<BsChevronDown />
							</button>
						))}
					</div>
				</div>
				<button className="inline-flex h-[34px] w-[96px] items-center justify-center gap-2 rounded bg-blue-950 text-[14px] text-slate-50">
					<HiPlus className="h-[24px] w-[24px] font-bold text-slate-50" />
					Add
				</button>
			</div>
			<div className="mt-[32px]">
				<table className="w-full text-[16px] font-[500]">
					<thead className="bg-[#334155]">
						<tr className="w-full rounded font-[500] text-gray-50">
							<th className="px-3 py-2 text-left">User Id</th>
							<th className="px-3 py-2 text-left">Permission</th>
							<th className="px-3 py-2 text-left">Status</th>
							<th className="px-3 py-2 text-left">Last Login</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user.id}>
								<td className="w-[285px] p-1.5">
									<div className="flex w-[206px] gap-[28px]">
										<div className="w-fit rounded-full bg-[#002060] px-1 py-1 text-[12px] font-semibold text-white">
											JD
										</div>
										{user.userId}
									</div>
								</td>
								<td className="w-[179px] p-1.5">
									{user.permissions}
								</td>

								<td className="w-[154px] p-1.5">
									<div className="mt-1 flex items-center">
										<div className="flex items-center gap-2 rounded-[5px] bg-[#27AE60] bg-opacity-25 px-4 py-1.5">
											<span className="h-[12px] w-[12px] rounded-full bg-[#27AE60]"></span>
											<span> {user.status}</span>
										</div>
									</div>
								</td>
								<td className="w-[182px] p-1.5">
									{user.lastLogin}
								</td>
								<td className="flex items-center gap-2">
									Others{" "}
									<span>
										<FiChevronDown />
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
};
