import React, { Dispatch, SetStateAction, useRef, ReactNode } from "react";
import { Overlay } from "../../modals";
import Logo from "../../../assets/image/hadiPngLogo.png";
import { formatDate, getStatusColor } from "../../../utils/utilities";
import NumberFormat from "../../../utils/NumberFormat";
import { StatusType } from "../../../types/utils";
// import { Button } from "../../Button";
// import {
// 	Document,
// 	Page,
// 	StyleSheet,
// 	View,
// 	Text,
// 	Image,
// 	Font,
// 	pdf,
// } from "@react-pdf/renderer";
// import { saveAs } from "file-saver";

import { LazyDownloadPDFButton } from "./PdfReceiptDownload";

interface TransactionDataInterface {
	date: string;
	amount: number;
	transaction_id: string;
	status: StatusType;
	recipient_name: string | null;
	recipient_bank: string | null;
	retailer_name: string | null;
	transaction_type: string;
	narration: string;
}

interface BankTransferProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	transactionDetailsData: TransactionDataInterface;
}

interface DataPairProps {
	label: string;
	value: string | number | ReactNode;
	status?: StatusType;
	color?: string;
}

const TransactionDetails: React.FC<BankTransferProps> = ({
	show,
	setShow,
	transactionDetailsData,
}) => {
	const modalRef = useRef(null);

	const onClose = () => {
		setShow(false);
	};

	const wrapValue = (value: string): string | ReactNode => {
		const maxLength = 20; // Define the maximum length before wrapping
		if (value.length > maxLength) {
			return (
				<span className="whitespace-pre-wrap">
					{value.substring(0, maxLength)}
					<br />
					{value.substring(maxLength)}
				</span>
			);
		}
		return value ?? "N/A";
	};

	const DataPair: React.FC<DataPairProps> = ({ label, value, status }) => {
		return (
			<div className="flex flex-row">
				<div className="w-1/2">
					<p className="text-sm font-medium text-gray-800">{label}</p>
				</div>
				<div className="w-1/2">
					{label === "Status:" ? (
						<p
							className={`text-sm font-medium capitalize ${getStatusColor(
								status ?? "",
								"text"
							)}`}
						>
							{value ?? "N/A"}
						</p>
					) : (
						<p className="text-sm font-medium capitalize text-gray-800">
							{value ?? "N/A"}
						</p>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			<Overlay
				show={show}
				setShow={setShow}
				modalRef={modalRef}
				onClose={onClose}
			>
				{show && (
					<div
						className="printable-content relative mx-4 flex max-h-[90vh] w-full max-w-[536px] flex-col items-center overflow-y-auto bg-white px-6 py-0"
						ref={modalRef}
					>
						<div className="flex items-center justify-between py-5">
							<img src={Logo} alt="" className="h-8 w-24" />
						</div>
						<div className="mt-3 flex w-full flex-col items-center px-3">
							<h1 className="text-center text-[19px] font-semibold text-blue-950">
								Transaction Receipt
							</h1>
							<div className="mt-8 w-full">
								<h1 className="mb-3 divide-y-2 border-b border-gray-300 pb-3 text-left text-[12px] font-semibold text-blue-950">
									Transaction Summary
								</h1>

								<div className="mb-4 flex flex-col gap-5 border-b border-gray-300 pb-8">
									<DataPair
										label="Date:"
										value={formatDate(
											transactionDetailsData?.date
										)}
									/>
									<DataPair
										label="Transaction ID:"
										value={wrapValue(
											String(
												transactionDetailsData?.transaction_id ??
													"N/A"
											)
										)}
									/>
									<DataPair
										label="Retailer’s Name:"
										value={wrapValue(
											String(
												transactionDetailsData?.retailer_name ??
													"N/A"
											)
										)}
									/>
									<DataPair
										label="Amount:"
										value={
											<NumberFormat
												value={
													transactionDetailsData?.amount
												}
											/>
										}
									/>
									<DataPair
										label="Recipient Name:"
										value={wrapValue(
											String(
												transactionDetailsData?.recipient_name ??
													"N/A"
											)
										)}
									/>
									<DataPair
										label="Recipient Bank:"
										value={wrapValue(
											String(
												transactionDetailsData?.recipient_bank ??
													"N/A"
											)
										)}
									/>
									<DataPair
										label="Transaction Type:"
										value={wrapValue(
											String(
												transactionDetailsData?.transaction_type ??
													"N/A"
											)
										)}
									/>
									<DataPair
										label="Status:"
										value={wrapValue(
											String(
												transactionDetailsData?.status ??
													"N/A"
											)
										)}
										status={transactionDetailsData?.status}
									/>
									<DataPair
										label="Narration:"
										value={
											transactionDetailsData?.narration ??
											"N/A"
										}
									/>
								</div>
								<div>
									<h1 className="mb-3 text-left text-[12px] font-semibold text-blue-950">
										Read Me!
									</h1>
									<div className="mb-[8px] rounded-[7px] bg-gray-100 px-[4px] py-[8px]">
										<p className="text-[10px] font-medium text-gray-500">
											Click the download button to
											download this receipt as PDF
										</p>
									</div>
								</div>
							</div>
							<LazyDownloadPDFButton
								data={transactionDetailsData}
							/>
						</div>
					</div>
				)}
			</Overlay>
		</>
	);
};

export default TransactionDetails;
