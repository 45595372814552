import React, { useState } from "react";
import { SubUserModal, TopBar } from "../../../components";
import NoDataUser from "./NoData";
import { SubusersData } from "./SubuserData";

const Subusers: React.FC = () => {
	const [show, setShow] = useState(false);
	const [step, setStep] = useState<number>(1);

	const handleChange = (e: any) => {
		e.preventDefault();
	};

	const showNext = (e: any) => {
		e.preventDefault();
		setShow(true);
	};

	const handleFormSubmit = () => {
		setStep(2);
	};

	return (
		<>
			<TopBar pageName="Sub-Users" />
			<main className="h-full px-4 py-4">
				{step === 1 && <NoDataUser stage1={showNext} />}
				<SubUserModal
					show={show}
					setShow={setShow}
					buttonAction={handleFormSubmit}
				/>
				{step === 2 && <SubusersData />}
			</main>
		</>
	);
};
export default Subusers;
