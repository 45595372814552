import React from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
	return (
		<div className="flex h-screen flex-col">
			<Outlet />
		</div>
	);
};

export default Layout;
