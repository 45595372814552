import {
	ReactNode,
	useEffect,
	useState,
	createContext,
	useContext,
	Dispatch,
	SetStateAction,
} from "react";
import { AuthContext, useAuth } from "../hooks/auth";
import { request } from "../utils/axios-utils";

type Props = {
	children: ReactNode;
};

interface OnboardingContextIProp {
	showOnboardingModal: boolean;
	showNotVerified: boolean;
	showIncompleteOnboarding: boolean;
	setShowOnboardingModal: Dispatch<SetStateAction<boolean>>;
	setShowNotVerified: Dispatch<SetStateAction<boolean>>;
	setShowIncompleteOnboarding: Dispatch<SetStateAction<boolean>>;
	toggleOnboardingModal: () => void;
}

// Create context
export const OnboardingContext = createContext<OnboardingContextIProp>({
	showOnboardingModal: false,
	showNotVerified: false,
	showIncompleteOnboarding: false,
	setShowOnboardingModal: () =>
		console.error("Onboarding modal context not yet configured."),
	setShowNotVerified: () =>
		console.error("Onboarding modal context not yet configured."),
	setShowIncompleteOnboarding: () =>
		console.error("Onboarding modal context not yet configured."),
	toggleOnboardingModal: () => {
		console.error("Onboarding modal context not yet configured.");
	},
});

export const OnboardingProvider = ({ children }: Props) => {
	const { user } = useAuth();
	const [showOnboardingModal, setShowOnboardingModal] =
		useState<boolean>(false);
	const [showNotVerified, setShowNotVerified] = useState<boolean>(false);
	const [showIncompleteOnboarding, setShowIncompleteOnboarding] =
		useState<boolean>(false);
	const OnboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;

	useEffect(() => {
		if (!OnboardedUser) {
			setShowOnboardingModal(true);
			setShowNotVerified(false);
			setShowIncompleteOnboarding(false);
		} else if (isVerifiedUser === false) {
			setShowIncompleteOnboarding(true);
			setShowNotVerified(false);
			setShowOnboardingModal(false);
		} else if (isVerifiedUser === null) {
			setShowNotVerified(true);
			setShowOnboardingModal(false);
			setShowIncompleteOnboarding(false);
		} else {
			setShowIncompleteOnboarding(false);
			setShowOnboardingModal(false);
			setShowNotVerified(false);
		}
	}, [OnboardedUser, isVerifiedUser]);

	// Function to toggle modal visibility
	const toggleOnboardingModal = () => {
		if (!OnboardedUser) {
			setShowOnboardingModal(true);
			setShowIncompleteOnboarding(false);
			setShowNotVerified(false);
			// console.log("working 1");
		} else if (isVerifiedUser === false) {
			setShowIncompleteOnboarding(true);
			setShowNotVerified(false);
			setShowOnboardingModal(false);
			// console.log("working 2");
		} else if (isVerifiedUser === null) {
			setShowNotVerified(true);
			setShowOnboardingModal(false);
			setShowIncompleteOnboarding(false);
			// console.log("working 3");
		}
	};

	const contextValue = {
		showOnboardingModal,
		showNotVerified,
		showIncompleteOnboarding,
		setShowOnboardingModal,
		setShowNotVerified,
		setShowIncompleteOnboarding,
		toggleOnboardingModal,
	};

	return (
		<OnboardingContext.Provider value={contextValue}>
			{children}
		</OnboardingContext.Provider>
	);
};

export const useOnboarding = () => {
	return useContext(OnboardingContext);
};
