import { useQueryParams } from "utils/utilities";
import { useState, useEffect } from "react";
import RetailerGuarantorAccept from "./Retailer";
import GuarantorAccept from "../GuarantorAccept";

const GuarantorAcceptForm = () => {
	const [type, setType] = useState("retailer_guarantor");
	// const [codeParam, setCodeParam] = useState(null);
	// const navigate = useNavigate();
	const query = useQueryParams();

	useEffect(() => {
		// const codeParam = query.get("auth");
		const userType = query.get("distributor");
		if (!userType) {
			setType("retailer_guarantor");
		} else {
			setType("distributor_guarantor");
		}
	}, [query]);
	return (
		<>
			{type === "retailer_guarantor" ? <RetailerGuarantorAccept /> : null}
			{type === "distributor_guarantor" ? <GuarantorAccept /> : null}
		</>
	);
};

export default GuarantorAcceptForm;
