import React, { useContext } from "react";
import { Button, FormInput, UploadedFile } from "../../components";
import { AnimatePresence, motion } from "framer-motion";
import {
	ProcessTransitionAnimation,
	uploadedFileContainerVariants,
} from "../../services/Animations";
import { Toast } from "../../utils/toast";
import { validateTextNumber } from "../../utils/utilities";
import { OnboardingFormContext } from ".";
import { OnboardingForm } from ".";
import { ProcessUploadFile } from "../../components";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

export const VerifyOrganization = () => {
	const { onboardingFormData, setOnboardingFormData, page, setPage } =
		useContext(OnboardingFormContext);
	const validateOrganizationDetails = (formData: OnboardingForm) => {
		if (
			!formData.TINNumber ||
			formData.TINNumber.length > 15 ||
			formData.TINNumber.length < 7
		) {
			Toast.info("Enter a Valid Tin Number");
			return false;
		} else if (!formData.CACNumber || formData.CACNumber.length < 7) {
			Toast.info("Enter a Valid CAC Number");
			return false;
		} else if (!formData.cacDocumentFile) {
			Toast.info("Kindly upload a valid CAC document");
			return false;
		}
		return true;
	};
	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex w-full max-w-[510px] flex-col items-center gap-6 rounded-lg bg-white p-8 shadow"
			>
				<div className="flex w-full flex-col gap-6">
					<div className="flex w-full flex-col gap-4">
						<div className="flex flex-col gap-2 text-left">
							<h2 className="text-xl font-bold text-[#002060]">
								Tax Identification Number
							</h2>
							<p className="font-medium text-[#626D88]">
								Type in your correct Business Tax Identification
								Number
							</p>
						</div>
						<FormInput
							label=""
							name="tin number"
							id="tin number"
							type="text"
							placeholder="Type in your TIN Number"
							minLength={7}
							maxLength={15}
							required={false}
							autoComplete="off"
							additionalClasses="inputChanger focus-none h-full w-full bg-transparent px-4 py-2 border-[1.5px] border-[#002060] rounded-lg text-sm py-3.5 outline-none"
							onChange={(e) => {
								setOnboardingFormData({
									...onboardingFormData,
									TINNumber: validateTextNumber(
										e.target.value
									),
								});
							}}
							value={onboardingFormData.TINNumber ?? ""}
						/>
					</div>
					<div className="flex w-full flex-col gap-4">
						<div className="flex flex-col gap-2 text-left">
							<h2 className="text-xl font-bold text-[#002060]">
								CAC Number
							</h2>
							<p className="text-[#626D88]">
								Type in your correct Corporate Affairs
								Commission Number
							</p>
						</div>
						<div className="relative">
							<FormInput
								label=""
								name="cac number"
								id="cac number"
								type="text"
								placeholder="Type in your correct Corporate Affairs
									Commission Number"
								minLength={7}
								maxLength={7}
								required={false}
								autoComplete="off"
								additionalClasses="inputChanger focus-none h-full w-full bg-transparent !pl-10 pr-4 border-[1.5px] border-[#002060] rounded-lg text-sm py-3.5 outline-none"
								onChange={(e) => {
									setOnboardingFormData({
										...onboardingFormData,
										CACNumber: validateTextNumber(
											e.target.value
										),
									});
								}}
								value={onboardingFormData.CACNumber ?? ""}
							/>
							<span className="translate-y-{-50%} bg-transparenttext-sm absolute left-[0.5rem] top-0 inline-flex h-full w-8 items-center justify-center rounded-bl-lg rounded-tl-lg pt-[6px] font-semibold">
								RC-
							</span>
						</div>
					</div>
				</div>
				<div className="flex w-full flex-col gap-3">
					<div className="flex flex-col text-left">
						<h2 className="text-xl font-semibold text-[#002060]">
							Upload CAC Document
						</h2>
					</div>
					<ProcessUploadFile
						formats={[
							"jpeg",
							"jpg",
							"png",
							"pdf",
							"ai",
							"doc",
							"docx",
							"ppt",
							"pptx",
						]}
						maxSize={2 * 1024 * 1024}
						setFile={setOnboardingFormData}
						localStorageFileName={"cacDocumentFile"}
					/>
				</div>
				<AnimatePresence>
					{onboardingFormData.cacDocumentFile && (
						<motion.div
							className="flex w-full flex-col gap-4"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<div className="flex flex-col gap-3">
								<UploadedFile
									title={
										onboardingFormData.cacDocumentFileName
									}
									deleteFile={() => {
										setOnboardingFormData((prevData) => ({
											...prevData,
											cacDocumentFile: null,
										}));
									}}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				<div className="mx-auto flex w-full flex-col items-center gap-6">
					<Button
						disabled={false}
						onClick={() => {
							if (
								!validateOrganizationDetails(onboardingFormData)
							) {
								return;
							}
							setPage(page + 1);
						}}
						additionalClasses="bg-primary-700 max-w-[445px] text-white"
					>
						Next
					</Button>
					<SaveContinueProcess />
					{/* <Button
						disabled={false}
						additionalClasses="w-content h-auto bg-transparent text-xl font-medium  text-error-600 underline !w-auto"
						onClick={() => navigate("/dashboard")}
					>
						Save and Continue later
					</Button> */}
				</div>
			</motion.section>
		</AnimatePresence>
	);
};

export default VerifyOrganization;
