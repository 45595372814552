/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { Overlay } from "./Overlay";
import { ChangePasswordModalProps } from "types/components/modals";
import SuccessImg from "../../assets/image/icons/success.svg";
import ErrorImg from "../../assets/image/icons/error.png";
import { MdClose } from "react-icons/md";
import { slideDownAnimation } from "../../services/Animations";
import { Button } from "../Button";
import { FormInput, Loader2 } from "..";
import { Patterns } from "../../services/Constants";
import { Toast } from "../../utils/toast";
import { changePasswordData } from "types/hooks";
import { useChangePassword } from "../../hooks/useAuthData";

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
	show,
	setShow,
}) => {
	const [status, setStatus] = useState<"none" | "success" | "error">("none");

	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-[80%] max-w-[675px] flex-col items-center rounded-[10px] bg-white px-4 py-4 md:w-fit md:px-8"
				>
					{status === "none" && (
						<PasswordChange
							show={show}
							setShow={setShow}
							setStatus={setStatus}
						/>
					)}

					{status === "success" && <PasswordChangeSuccess />}
					{status === "error" && <PasswordChangeError />}
				</motion.section>
			)}
		</Overlay>
	);
};

const PasswordChange = ({
	show,
	setShow,
	setStatus,
}: ChangePasswordModalProps) => {
	const [formData, setFormData] = useState<changePasswordData>({
		old_password: "",
		new_password1: "",
		new_password2: "",
	});

	const passwordResetOnSuccess = (data: any) => {
		// console.log(data);
		if (setStatus !== undefined) {
			setStatus("success");
		}
	};

	const passwordResetOnError = (error: any) => {
		// console.log(error);
		if (setStatus) {
			setStatus("error");
		}
		setTimeout(() => {
			if (setStatus) setStatus("none");
		}, 4000);
	};

	const { changePassword, isLoading } = useChangePassword(
		passwordResetOnSuccess,
		passwordResetOnError
	);

	const handleChangePassword: () => void = () => {
		if (
			!Patterns.password.test(formData.new_password1) ||
			formData.new_password1.length < 8
		) {
			Toast.error(
				"You password should contain special characters and not be less than 8 characters"
			);
			return;
		}

		if (
			!Patterns.password.test(formData.new_password2) ||
			formData.new_password2.length < 8
		) {
			Toast.error("Your two passwords do not match");
			return;
		}
		changePassword(formData);
	};

	return (
		<div className="flex w-full flex-1 flex-col items-center justify-center  py-2 pb-6">
			<div className="flex w-full justify-end">
				<button className="text-[1.5em]" onClick={() => setShow(!show)}>
					<MdClose />
				</button>
			</div>
			<h2 className="mt-4 text-2xl font-bold text-[#001746]">
				Change Password
			</h2>
			<p className="mb-0 mt-2 w-full text-center text-[#626D88] md:w-[417px]">
				Your new password must be different from the previous one
			</p>

			<form action="" className="mt-4 flex w-full flex-col gap-6">
				<FormInput
					label="New Password"
					name="new_password1"
					id="new_password1"
					type="password"
					placeholder="Enter new Password"
					additionalClasses="focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
					autoComplete="off"
					required={true}
					onChange={(e) => {
						setFormData((prevData) => ({
							...prevData,
							new_password1: e.target.value,
						}));
					}}
				/>

				<FormInput
					label="Confirm Password"
					name="new_password2"
					id="new_password2"
					type="password"
					placeholder="Confirm Password"
					additionalClasses="focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
					autoComplete="off"
					required={true}
					onChange={(e) => {
						setFormData((prevData) => ({
							...prevData,
							new_password2: e.target.value,
						}));
					}}
				/>

				<Button
					disabled={isLoading ? true : false}
					additionalClasses=" text-white font-[600] cusor-pointer"
					onClick={handleChangePassword}
					// onClick={passwordResetOnError}
				>
					{isLoading ? <Loader2 /> : "Reset Password"}{" "}
				</Button>
			</form>
		</div>
	);
};
const PasswordChangeSuccess = () => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<motion.section
			variants={slideDownAnimation}
			initial="hidden"
			animate="visible"
			exit="exit"
			ref={modalRef}
			className="m-6 flex h-fit w-fit max-w-[675px] flex-col items-center rounded-[10px] bg-white px-6 py-4"
		>
			<div className="flex flex-1 flex-col items-center justify-center gap-8  py-2">
				<img src={SuccessImg} alt="success" />
				<h4 className="text-xl">Password was Successfully changed!</h4>
			</div>
		</motion.section>
	);
};
const PasswordChangeError = () => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<motion.section
			variants={slideDownAnimation}
			initial="hidden"
			animate="visible"
			exit="exit"
			ref={modalRef}
			className="m-4 flex h-fit w-fit max-w-[675px] flex-col items-center rounded-[10px] bg-white px-4 py-4"
		>
			<div className="flex flex-1 flex-col items-center justify-center gap-8 ">
				<img src={ErrorImg} alt="error img" />
				<h4 className="text-center text-xl">
					Password change was not successful
					<br />
					try again !!
				</h4>
			</div>
		</motion.section>
	);
};
