import React, { SetStateAction } from "react";
import { Button, FormInput, ProcessUploadFile, UploadedFile } from "components";
import { RetailerCreditAuthorizationFormProps } from "@/types/views";
import { AnimatePresence, motion } from "framer-motion";
import { uploadedFileContainerVariants } from "services/Animations";
import {
	Nationalities,
	RetailerCreditLoanPeriod,
	BankNames,
	OccupationType,
} from "services/Constants";
import { RetailerCreditAuthorizationPageProps } from "types/views";
import { isValidAddress, isValidBVN } from "utils/validation";
import { Toast } from "utils/toast";

interface RetailerDetailsForm2Props
	extends RetailerCreditAuthorizationPageProps {
	formData: RetailerCreditAuthorizationFormProps;
	setFormData: React.Dispatch<
		SetStateAction<RetailerCreditAuthorizationFormProps>
	>;
	setPreviousGuarantor: React.Dispatch<SetStateAction<boolean>>;
	validateGuarantorForm: (
		formData: RetailerCreditAuthorizationFormProps
	) => boolean;
	isAdminRequest: boolean;
}

const RetailerDetailsForm3 = ({
	formData,
	setFormData,
	setPage,
	isAdminRequest,
	validateGuarantorForm,
	setPreviousGuarantor,
}: RetailerDetailsForm2Props) => {
	const validateRetailerForm2 = (
		formData: RetailerCreditAuthorizationFormProps
	) => {
		if (!formData.occupation) {
			Toast.info("Enter your occupation");
			return false;
		} else if (isAdminRequest && !formData.purchase_amount) {
			Toast.info("Enter purchase amount");
			return false;
		} else if (isAdminRequest && !formData.credit_amount) {
			Toast.info("Enter loan amount");
			return false;
		} else if (isAdminRequest && !formData.account_number) {
			Toast.info("Enter your account number");
			return false;
		} else if (
			isAdminRequest &&
			parseInt(formData.credit_amount.replace(/,/g, "")) >
				parseInt(formData.purchase_amount.replace(/,/g, ""))
		) {
			Toast.info("Purchase amount must be greater than credit amount");
			return false;
		} else if (isAdminRequest && !formData.bank_name) {
			Toast.info("Select your bank");
			return false;
		} else if (!formData.tenure) {
			Toast.info("Select your loan tenure");
			return false;
		} else if (!formData.invoice.invoice) {
			Toast.info("Upload a valid invoice");
			return false;
		} else if (!formData.signature) {
			Toast.info("Upload a valid signature");
			return false;
		}
		return true;
	};

	const handleNextPage = () => {
		if (!validateRetailerForm2(formData)) {
			return;
		}
		if (!validateGuarantorForm(formData)) {
			setPreviousGuarantor(false);
		}
		setPage(4);
	};

	return (
		<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
			<div className="bg-[#FFDAD6] p-2 ">
				<p className="text-[12px] md:text-[15px]">
					Please enter correct personal details before authorising the
					credit application. You must be a working adult above the
					age of 18 yrs.{" "}
				</p>
			</div>
			<div className="space-y-4 py-4">
				<FormInput
					label="What is your profession?"
					name="occupation"
					id="occupation"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={OccupationType}
					value={formData.occupation}
					placeholder="Enter your Occupation"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							occupation: selectedOption.value,
						})
					}
				/>
				{isAdminRequest && (
					<React.Fragment>
						<div className="relative w-full">
							<FormInput
								label="How much is needed for you to buy goods?"
								name="purchase_amount"
								id="purchase_amount"
								value={formData.purchase_amount}
								placeholder="Input specific amount"
								type="text"
								required={true}
								minLength={4}
								autoComplete="off"
								additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent !px-6 py-2 text-[15px] placeholder-black placeholder-opacity-50 outline-none"
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) => {
									const value = e.target.value.replace(
										/\D/g,
										""
									); // Remove non-digit characters
									const formattedValue = value.replace(
										/\B(?=(\d{3})+(?!\d))/g,
										","
									);

									if (formattedValue.startsWith("0")) {
										return;
									}

									setFormData({
										...formData,
										purchase_amount: formattedValue,
									});
								}}
							/>
							<span className="absolute bottom-2 left-2">₦</span>
						</div>
						<div className="relative w-full">
							<FormInput
								label="How much loan do you want?"
								name="credit_amount"
								id="credit_amount"
								value={formData.credit_amount}
								placeholder="Input specific amount"
								type="text"
								required={true}
								minLength={4}
								autoComplete="off"
								additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent !px-6 py-2 text-[15px] placeholder-black placeholder-opacity-50 outline-none"
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) => {
									const value = e.target.value.replace(
										/\D/g,
										""
									); // Remove non-digit characters
									const formattedValue = value.replace(
										/\B(?=(\d{3})+(?!\d))/g,
										","
									);

									if (formattedValue.startsWith("0")) {
										return;
									}

									const purchaseAmount = parseInt(
										formData.purchase_amount.replace(
											/,/g,
											""
										),
										10
									);
									const creditAmount = parseInt(
										e.target.value.replace(/,/g, ""),
										10
									);

									if (creditAmount > 250000) {
										return Toast.info(
											"Credit Amount cannot be greater than NGN250,000"
										);
									}
									if (creditAmount > purchaseAmount) {
										return Toast.info(
											"Purchase amount must be greater than credit amount"
										);
									}

									setFormData({
										...formData,
										credit_amount: formattedValue,
									});
								}}
							/>
							<span className="absolute bottom-2 left-2">₦</span>
						</div>

						<FormInput
							label="Account Number"
							name="account_number"
							id="account_number"
							labelStyles="text-[16px] font-medium"
							value={formData.account_number}
							placeholder="Hadi Credit-Book"
							additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
							min={0}
							max={9999999999}
							type="number"
							required={true}
							autoComplete="false"
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => {
								const inputValue = e.target.value;
								// Check if the input value exceeds 10 characters
								if (inputValue.length <= 10) {
									// Update the form data if the input length is within the limit
									setFormData({
										...formData,
										account_number: inputValue,
									});
								}
							}}
						/>
						<FormInput
							label="Bank Name"
							name="bank_name"
							select={true}
							id="bank_name"
							labelStyles="text-[16px] font-medium"
							value={formData.bank_name}
							options={BankNames}
							placeholder="Hadi Credit-Book"
							additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
							minLength={2}
							type="text"
							required={true}
							autoComplete="false"
							onChange={(selectedOption) => {
								setFormData({
									...formData,
									bank_name: selectedOption.value,
								});
							}}
						/>
					</React.Fragment>
				)}
				<FormInput
					label="Loan Tenure"
					name="tenure"
					id="tenure"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={RetailerCreditLoanPeriod}
					value={formData.tenure}
					placeholder="Select Loan Tenure"
					additionalClasses="rounded-md font-light  outline-none"
					type="text"
					required={true}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							tenure: selectedOption.value,
						})
					}
				/>
				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className="relative text-[16px] font-[500] text-blue-950"
					>
						Invoice Upload
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</label>
					<p className="text-sm text-slate-600">
						Upload your invoice here
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5000Kb
					setFile={setFormData}
					localStorageFileName={"invoice.invoice"}
				/>
				<AnimatePresence>
					{formData.invoice.invoice && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData.invoice.invoiceName}
								deleteFile={() => {
									setFormData({
										...formData,
										invoice: {
											...formData.invoice,
											invoice: null,
										},
									});
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>

				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className="relative text-[16px] font-[500] text-blue-950"
					>
						Upload Signature
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</label>
					<p className="text-sm text-slate-600">
						Upload an image of your signature
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5000Kb
					setFile={setFormData}
					localStorageFileName={"signature"}
				/>
				<AnimatePresence>
					{formData.signature && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData.signatureName}
								deleteFile={() => {
									setFormData({
										...formData,
										signature: null,
									});
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
				<Button
					additionalClasses=" w-fit text-white"
					disabled={false}
					onClick={handleNextPage}
				>
					Next
				</Button>
				<button
					className="text-sm font-semibold text-blue-900"
					onClick={() => setPage(1)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};

export default RetailerDetailsForm3;
