import React, { useContext, useState } from "react";
import { FormInput, customSelectStyles } from "../../../components/FormInput";
import { HiInformationCircle } from "react-icons/hi2";
import { BankNames } from "../../../services/Constants";
import { CreditApplicationFormContext, RegisterRetailerForm } from ".";
import {
	useRegisterRetailer,
	useSearchRetailerDetailGetQuery,
	useUpdateStoreDetails,
} from "../../../hooks/useCustomerData";
import { useNavigate } from "react-router";
import { Toast } from "../../../utils/toast";
import { validateTextNumber } from "./../../../utils/utilities";
import { Loader2 } from "../../../components";
// import { request } from "../../../utils/axios-utils";
import AsyncCreatableSelect from "react-select/async-creatable";
import { request } from "../../../utils/axios-utils";
import { Option } from "../../../types/components";
import { isEmpty } from "lodash";
import { SingleValue } from "react-select";
import { checkCrmAvailability } from "../../../api/onboarding";
import { AxiosError } from "axios";
// import { LinkCrmModal } from "../../../components/modals/LinkCrmModal";
// type Processprops = {
// 	page?: number;
// 	setPage: React.Dispatch<React.SetStateAction<number>>;
// 	submitError?: () => void;
// };
type Process1Props = {
	page?: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	submitError: () => void;
};
type Processprops = {
	page?: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
};

type ProcessSubmitProps = {
	page?: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	submit: () => void;
	// submitError: () => void;
};
export const RegisterUserForm1 = ({ setPage, submitError }: Process1Props) => {
	const navigate = useNavigate();
	const { formData, setFormData } = useContext(CreditApplicationFormContext);
	const [loading, setLoading] = useState(false);

	const onSuccess = (data: any) => {
		if (data.status === 200 || Boolean(data.phone_number)) setPage(2);
		setLoading(false);
	};

	const onError = (error: any) => {
		if (
			error.response.data.message ===
			"User with phone number already exists"
		) {
			Toast.info("User with phone number already exists");
			setTimeout(() => {
				navigate("/dashboard/credit-application/apply", {
					state: { formData },
				});
				setLoading(false);
			}, 2000);
		} else if (
			error.response.data.message ===
			"User exists, but no store name for the distributor making the search"
		) {
			Toast.info(
				"User exists, but no store name for the distributor making the search"
			);
			setTimeout(() => {
				setPage(4);
				setLoading(false);
			}, 2000);
		} else if (error) {
			Toast.error(error.response?.data?.message ?? error.message);
			setLoading(false);
		}
	};

	const { mutateSearchRetailerDetailGet } = useSearchRetailerDetailGetQuery(
		onSuccess,
		onError
	);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		mutateSearchRetailerDetailGet(formData.phoneNumber);
	};

	return (
		<div className="mx-auto px-8 pt-20 md:pt-32">
			<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-4 shadow-sm md:w-[500px] md:p-8">
				<form
					className=" flex flex-col items-center"
					onSubmit={(e) => handleSubmit(e)}
				>
					<h1 className="pb-4 text-2xl font-semibold text-[#002060]">
						Register User
					</h1>
					<FormInput
						label="Customers Phone Number"
						name="retailer_phone_number"
						id="retailer_phone_number"
						value={formData.phoneNumber}
						placeholder="08012378456"
						minLength={11}
						maxLength={11}
						type="text"
						required={true}
						autoComplete="off"
						onChange={(e) => {
							setFormData({
								...formData,
								phoneNumber: validateTextNumber(e.target.value),
							});
						}}
					/>
					<div className="my-4 flex items-center gap-1">
						<span className="text-red-700">
							<HiInformationCircle />
						</span>
						<p className="text-[12px] font-medium text-red-700">
							If your Customer has not been registered please
							register the retailer for them to access CREDIT
						</p>
					</div>
					<div className="mx-auto  flex w-[90%] flex-col items-center gap-4">
						<button
							type="submit"
							className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] text-center text-white disabled:bg-gray-400"
							disabled={loading}
						>
							{loading ? <Loader2 /> : "Next"}
						</button>
						<button
							className=" text-sm font-semibold text-blue-900"
							onClick={() => setPage(0)}
						>
							Go Back
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
export const RegisterUserForm2 = ({ setPage }: Processprops) => {
	const { formData, setFormData } = useContext(CreditApplicationFormContext);
	const [isLoading, setIsLoading] = useState<boolean>();
	const handleSubmit = async (e: React.FormEvent) => {
		setIsLoading(true);
		e.preventDefault();
		// const data = await checkCrmAvailability();
		setIsLoading(false);
		setPage(3);
		// if (data?.status === 200) {
		// 	setPage(3);
		// } else if (data?.status === 404) {
		// 	Toast.info(
		// 		"Please relink your CRM tool in the settings page to be able to apply for a credit"
		// 	);
		// } else {
		// 	Toast.error("An error occurred");
		// }
	};
	return (
		<>
			<div className="mx-auto px-8 pt-20 md:pt-32">
				<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-4 shadow-sm md:w-[500px] md:p-8">
					<form
						className=" flex flex-col items-center"
						onSubmit={(e) => handleSubmit(e)}
					>
						<h1 className="pb-4  text-2xl font-semibold text-[#002060]">
							User Details
						</h1>
						<div className="flex w-full flex-col gap-2">
							<FormInput
								label="Retailers Phone Number"
								name="retailer_phone_number"
								id="retailer_phone_number"
								placeholder="Select your Bank"
								value={formData.phoneNumber}
								required={true}
								disabled={true}
								type="text"
								autoComplete="false"
								additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent px-4 py-2 text-[15px] placeholder-black  outline-none "
								onChange={(selectedOption) => {
									setFormData({
										...formData,
										bankName: selectedOption,
									});
								}}
							/>
							<FormInput
								label="Retailers Bank Name"
								name="retailer_bank_name"
								select={true}
								id="retailer_bank_name"
								placeholder="Select your Bank"
								value={formData.bankName.label}
								options={BankNames}
								required={true}
								type="text"
								autoComplete="false"
								additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent px-4 py-2 text-[15px] placeholder-black  outline-none "
								onChange={(selectedOption) => {
									setFormData({
										...formData,
										bankName: selectedOption,
									});
								}}
							/>
							<FormInput
								label="Retailers Account Number"
								name="retailer_account_number"
								id="retailer_account_number"
								value={formData.bankAccountNumber}
								placeholder="0870123784"
								minLength={10}
								maxLength={10}
								type="text"
								required={true}
								autoComplete="false"
								onChange={(e) =>
									setFormData({
										...formData,
										bankAccountNumber: validateTextNumber(
											e.target.value
										),
									})
								}
							/>
						</div>
						<div className="my-4 flex items-center gap-1">
							<span className="text-red-700">
								<HiInformationCircle />
							</span>
							<p className="font-medium] text-[12px] text-red-700">
								If your Customer has not been registered please
								register the retailer for them to access CREDIT
							</p>
						</div>
						<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
							<button
								type="submit"
								className="flex h-10 w-full  items-center justify-center rounded-md bg-[#072154] py-2 text-white"
							>
								{isLoading ? <Loader2 /> : "Next"}
							</button>
							<button
								className="text-sm font-semibold text-blue-900"
								onClick={() => setPage(1)}
							>
								Go Back
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* <LinkCrmModal show={showCrmModal} setShow={setShowCrmModal} /> */}
		</>
	);
};

export const RetailerDetailsForm = ({
	setPage,
	submit,
}: ProcessSubmitProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [quickbooksRetailers, setQuickbookRetailers] = useState([]);

	const fetchRetailers = async (name: string) => {
		if (isEmpty(name)) return [];
		const response = await request({
			url: `/accounting-crm/crm/customers?name=${name}`,
		});

		const modifiedBankData = response.data.data?.map((retailer: any) => ({
			value: retailer.Id,
			label: retailer.DisplayName,
		}));
		setQuickbookRetailers(modifiedBankData);
		return modifiedBankData;
	};

	const { formData, setFormData } = useContext(CreditApplicationFormContext);
	const onSuccess = (data: any) => {
		setLoading(false);
		if (data.status === 200 || data.status === 201) {
			submit();
		}
	};
	const onError = (data: any) => {
		setLoading(false);
		if (data.response.data.message) {
			return Toast.error(data.response.data.message);
		}
		Toast.error("There was an error😬");
	};

	const { mutateRegisterRetailer } = useRegisterRetailer(onSuccess, onError);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateForm(formData)) return;
		setLoading(true);
		const form = {
			name: formData.firstName + " " + formData.lastName,
			address: formData.storeAddress,
			phone_number: formData.phoneNumber,
			user_bank: {
				customer_reference: "1233949949554",
				bank_code: formData.bankName.label,
				bank_name: formData.bankName.label,
				account_number: formData.bankAccountNumber,
			},
			retailer: {
				name: formData.businessName,
			},
		};
		mutateRegisterRetailer(form);
	};
	return (
		<div className="mx-auto px-8 pt-20 md:pt-32">
			<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-8 py-12 shadow-sm md:w-[500px]">
				<form
					className=" flex w-full flex-col items-center gap-3"
					onSubmit={(e) => handleSubmit(e)}
				>
					<h1 className="text-2xl font-semibold text-[#002060]">
						Retailers Details
					</h1>
					<div className="flex w-full flex-col gap-2">
						<label className="font-semibold capitalize text-blue-950">
							Retailers Business Name
						</label>
						<AsyncCreatableSelect
							cacheOptions
							defaultOptions
							getOptionValue={(option) => option.value}
							getOptionLabel={(option) => option.label}
							loadOptions={fetchRetailers}
							styles={customSelectStyles(undefined, {
								color: "#CBD5E1",
							})}
							value={quickbooksRetailers?.find(
								(option: Option) =>
									option.value === formData.businessName
							)}
							placeholder="Select Business Name"
							onChange={(option: SingleValue<Option>) => {
								setFormData({
									...formData,
									businessName: option?.label,
								});
							}}
						/>
					</div>
					<FormInput
						label="First Name"
						name="first_name"
						id="first_name"
						value={formData.firstName}
						placeholder="Enter Retailer's First Name"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e) =>
							setFormData({
								...formData,
								firstName: e.target.value,
							})
						}
					/>
					<FormInput
						label="Last Name"
						name="last_name"
						id="last_name"
						value={formData.lastName}
						placeholder="Enter Retailer's Last Name"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e) =>
							setFormData({
								...formData,
								lastName: e.target.value,
							})
						}
					/>
					<FormInput
						label="Store Address"
						name="store_address"
						id="store_address"
						value={formData.storeAddress}
						placeholder="Enter Retailer's Store Address"
						type="text"
						minLength={10}
						required={true}
						autoComplete="false"
						onChange={(e) =>
							setFormData({
								...formData,
								storeAddress: e.target.value,
							})
						}
					/>
					<div className="mx-auto flex w-[95%] flex-col items-center gap-2 pt-2">
						<button
							type="submit"
							disabled={loading}
							className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white"
						>
							{loading ? <Loader2 /> : "Sign Up Retailer"}
						</button>

						<button
							className="self-center text-sm font-semibold text-blue-900"
							onClick={() => setPage(2)}
						>
							Go Back
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export const RetailerStoreUpdateForm = ({ setPage }: Processprops) => {
	const navigate = useNavigate();
	const { formData } = useContext(CreditApplicationFormContext);

	const [quickbooksRetailers, setQuickbookRetailers] = useState([]);
	const [storeData, setStoreData] = useState<Record<string, any>>({
		retailer: formData.phoneNumber,
		store_name: "",
		store_address: "",
	});
	const [loading, setLoading] = useState<boolean>(false);

	const fetchRetailers = async (name: string) => {
		if (isEmpty(name)) return [];
		const response = await request({
			url: `/accounting-crm/quickbooks/customers?name=${name}`,
		});

		const modifiedBankData = response.data.data?.map((retailer: any) => ({
			value: retailer.Id,
			label: retailer.DisplayName,
		}));
		setQuickbookRetailers(modifiedBankData);
		return modifiedBankData;
	};

	const onSuccess = () => {
		Toast.success("Store details updated succesfully");
		setTimeout(() => {
			navigate("/dashboard/credit-application/apply", {
				state: formData,
			});
		}, 2000);
	};
	const onError = () => {
		Toast.error(
			"Unable to update store details 🤕, kindly try again later."
		);
	};

	const { isLoading, mutateUpdateStoreDetails } = useUpdateStoreDetails(
		onSuccess,
		onError
	);
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (storeData.retailer === "") {
			Toast.error("Please enter your retailer's number again");
			return setPage(0);
		}
		if (storeData.store_name === "" || storeData.store_address === "") {
			return Toast.error("Please fill in store data fields");
		}
		setLoading(true);
		const form = {
			retailer_phone_number: storeData.retailer,
			store_name: storeData.store_name,
			store_address: storeData.store_address,
		};
		mutateUpdateStoreDetails(form);
	};

	return (
		<div className="mx-auto px-8 pt-20 md:pt-32">
			<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-8 py-12 shadow-sm md:w-[500px]">
				<form
					className=" flex w-full flex-col items-center gap-3"
					onSubmit={(e) => handleSubmit(e)}
				>
					<h1 className="text-2xl font-semibold text-[#002060]">
						Store Details
					</h1>
					<div className="flex w-full flex-col gap-2">
						<label className="font-semibold capitalize text-blue-950">
							Retailers Business Name
						</label>
						<AsyncCreatableSelect
							cacheOptions
							defaultOptions
							getOptionValue={(option) => option.value}
							getOptionLabel={(option) => option.label}
							loadOptions={fetchRetailers}
							styles={customSelectStyles(undefined, {
								color: "#CBD5E1",
							})}
							value={quickbooksRetailers?.find(
								(option: Option) =>
									option.value === storeData.store_name
							)}
							placeholder="Select Business Name"
							onChange={(option: SingleValue<Option>) => {
								setStoreData({
									...storeData,
									store_name: option?.label,
								});
							}}
						/>
					</div>
					<FormInput
						label="Retailers Store Address"
						name="store_address"
						id="store_address"
						value={storeData.store_address}
						placeholder="Enter Retailer's Store Address"
						type="text"
						minLength={10}
						required={true}
						autoComplete="false"
						onChange={(e) =>
							setStoreData({
								...storeData,
								store_address: e.target.value,
							})
						}
					/>
					<div className="mx-auto flex w-[95%] flex-col items-center gap-2 pt-2">
						<button
							type="submit"
							disabled={isLoading}
							className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white"
						>
							{isLoading ? <Loader2 /> : "Update Retailer Form"}
						</button>

						<button
							className="self-center text-sm font-semibold text-blue-900"
							onClick={() => setPage(1)}
						>
							Go Back
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

const validateForm = (formData: RegisterRetailerForm) => {
	switch (true) {
		case !formData.firstName || !formData.lastName:
			Toast.error("Kindly enter your enter your name");
			return false;
		case !formData.phoneNumber:
			Toast.error("Kindly enter your phone number");
			return false;
		case !formData.storeAddress:
			Toast.error("Kindly enter your store address");
			return false;
		case !formData.bankAccountNumber:
			Toast.error("Kindly enter an account number");
			return true;
		case !formData.bankName:
			Toast.error("Kindly select a bank");
			return false;
		case !formData.businessName:
			Toast.error("Kindly select a business");
			return false;

		default:
			return true;
	}
};
