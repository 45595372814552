import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ProcessTransitionAnimation } from "../../services/Animations";
import {
	useLinkToQuickbooksCRM,
	useLinkToZohoCRM,
} from "../../hooks/useAuthData";
import { AxiosResponse } from "axios";
import { checkCrmAvailability } from "../../api/onboarding";
import { Toast } from "../../utils/toast";
import { OnboardingFormContext } from ".";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { ERPData } from "../../services/Constants";
import { FormInput } from "../../components";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

type ERPProps = {
	showCrmSuccess: boolean;
	setShowCrmSuccess: Dispatch<SetStateAction<boolean>>;
	showCrmError: boolean;
	setShowCrmError: Dispatch<SetStateAction<boolean>>;
	isLoading: boolean;
	setCrmLinkSuccess: Dispatch<SetStateAction<boolean>>;
};
export const LinkERP: React.FC<ERPProps> = ({
	showCrmError,
	setShowCrmError,
	showCrmSuccess,
	setShowCrmSuccess,
	setCrmLinkSuccess,
}) => {
	const { setPage } = useContext(OnboardingFormContext);
	const [crmChoice, setCrmChoice] = useState("");
	const [crmCheckedDataLoading, setCrmCheckedDataLoading] =
		useState<boolean>(false);
	const onSuccess = (response: AxiosResponse) => {
		const CRMLink = response?.data.data.auth_url;
		window.open(CRMLink, "_blank");
	};
	const onError = () => {
		Toast.error("An Error Occured");
	};

	// const [linkSuccess, setLinkSuccess] = useState("");

	useEffect(() => {
		const handleLocalStorageChange = () => {
			const successValue = localStorage.getItem("crmSuccess") || "";
			const errorValue = localStorage.getItem("crmError");
			// setLinkSuccess(newValue);
			if (successValue === "true") {
				setShowCrmSuccess(!showCrmSuccess);
				setCrmLinkSuccess(true);
			}
			if (errorValue === "true") {
				setShowCrmError(!showCrmError);
				setCrmLinkSuccess(false);
			}
		};
		window.addEventListener("storage", handleLocalStorageChange);

		// Clean up
		return () => {
			window.removeEventListener("storage", handleLocalStorageChange);
		};
	}, [
		setCrmLinkSuccess,
		setShowCrmError,
		setShowCrmSuccess,
		showCrmError,
		showCrmSuccess,
	]);

	const { refetch: LinkToQuickbooksCrm } = useLinkToQuickbooksCRM(
		onSuccess,
		onError
	);
	const { refetch: LinkToZohoCrm } = useLinkToZohoCRM(onSuccess, onError);

	const processCrmChoice = async () => {
		if (crmChoice === "quick_book") {
			return await LinkToQuickbooksCrm();
		} else if (crmChoice === "zoho") {
			return await LinkToZohoCrm();
		} else {
			return Toast.error("Please select your CRM tool");
		}
	};

	const LinktoCRM = async () => {
		setCrmCheckedDataLoading(true);
		if (crmChoice === "") {
			setCrmCheckedDataLoading(false);
			return Toast.error("Please select your CRM tool");
		}
		try {
			const crmCheckedData = await checkCrmAvailability();
			if (crmCheckedData) {
				const { status } = crmCheckedData;
				if (status === 200) {
					setCrmLinkSuccess(true);
					setCrmCheckedDataLoading(false);
					setShowCrmSuccess(true);
					return;
				} else if (status === 404) {
					setCrmLinkSuccess(false);
					setCrmCheckedDataLoading(false);
					processCrmChoice();
					return;
				} else {
					setCrmCheckedDataLoading(false);
					return Toast.error("An Error Occured");
				}
			}
			setCrmCheckedDataLoading(false);
		} catch (error) {
			setCrmCheckedDataLoading(false);
			return Toast.error("An Error Occurred in Linking CRM");
		}
	};

	return (
		<>
			<AnimatePresence>
				{/* {state === 5 && ( */}
				<motion.section
					variants={ProcessTransitionAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					className="flex w-full max-w-[510px] flex-col items-center gap-4 rounded-lg bg-white p-8 shadow"
				>
					<div className="flex flex-col gap-2 text-center">
						<h1 className="text-xl font-bold text-blue-950 ">
							Link to ERP
						</h1>
						<p className="font-medium text-secondary-300">
							Add your ERP to access all Customers Details
						</p>
					</div>
					<div className="mt-4 flex w-full flex-col gap-6">
						<FormInput
							label=""
							labelStyles="hidden"
							name="erp_link"
							id="erp_link"
							value={crmChoice}
							placeholder="Select an ERP"
							placeholderStyleOptions={{
								opacity: 0.5,
								fontWeight: 300,
								paddingLeft: "4px",
								fontSize: "14px",
							}}
							type="text"
							onChange={(selectedOption) => {
								setCrmChoice(selectedOption.value);
							}}
							options={ERPData}
							autoComplete="false"
							required={false}
							select={true}
							selectStyleOptions={{
								border: "1.4px solid #CBD5E1",
								fontWeight: 300,
								paddingLeft: "4px",
								fontSize: "14px",
								textAlign: "left",
								backgroundColor: "white",
								height: "3.5rem",
							}}
						/>
						<button
							type="button"
							className="w-full rounded-md bg-primary-700 px-4 py-4 text-white"
							onClick={LinktoCRM}
						>
							{crmCheckedDataLoading
								? "Loading ...."
								: "Link to ERP"}
						</button>
					</div>
					<div className="mt-4 flex items-center gap-1">
						<span className="text-[#1E293B]">
							<HiOutlineInformationCircle className="h-5 w-5" />
						</span>
						<p className="text-[16px] font-medium">
							Dont have an erp ?{" "}
							<span
								className="cursor-pointer text-[#003AFF]"
								onClick={() => setPage(7)}
							>
								click me
							</span>{" "}
							to skip
						</p>
					</div>
					<div className="relative my-4 flex w-full items-center justify-center gap-1">
						<hr className="w-[60%] bg-[#94A3B8]" />
						<span className="absolute -top-3 z-50 transform bg-white px-2 text-[#94A3B8]">
							or
						</span>
					</div>
					<div className="pt-4">
						<SaveContinueProcess />
					</div>
				</motion.section>
				{/* )} */}
			</AnimatePresence>
		</>
	);
};

export default LinkERP;
