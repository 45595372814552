import { useContext, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, FormInput } from "../../components";
import { ProcessTransitionAnimation } from "../../services/Animations";
import { OnboardingFormContext } from ".";
import { Toast } from "../../utils/toast";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

export const ManufacturerProcess = () => {
	const {
		onboardingFormData,
		setOnboardingFormData,
		setPage,
		manufacturersList,
	} = useContext(OnboardingFormContext);
	const [manufacturersOptions, setManufacturersOptions] = useState<any>([]);

	useEffect(() => {
		if (manufacturersList) {
			const options = manufacturersList.map((manufacturer) => {
				return {
					value: manufacturer.name,
					label: manufacturer.name,
				};
			});
			setManufacturersOptions(options);
		}
	}, [manufacturersList]);

	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex w-full max-w-[510px] flex-col items-center gap-8 rounded-lg bg-white px-8 py-8 shadow"
			>
				<div className="flex w-full flex-col gap-4">
					<div className="flex flex-col gap-4">
						{/* Government Id  */}
						<div className="flex flex-col text-center">
							<h2 className="text-xl font-semibold text-[#002060]">
								Manufacturers
							</h2>
							<p className="text-[14px] font-medium text-[#626D88]">
								Select Manufacturers you work with
							</p>
						</div>
						<FormInput
							select={true}
							options={manufacturersOptions}
							label=""
							name="manufacturers"
							id="manufacturers"
							type="text"
							multiSelect
							value={onboardingFormData.manufacturers}
							placeholder="Select from the drop-down below"
							autoComplete="off"
							required={false}
							onChange={(selectedOption) => {
								// localStorage.setItem(
								// 	"governmentIdType",
								// 	selectedOption
								// );
								setOnboardingFormData({
									...onboardingFormData,
									manufacturers: selectedOption,
								});
							}}
						/>
					</div>
				</div>

				<div className="mx-auto flex w-full flex-col items-center gap-6">
					<Button
						disabled={false}
						onClick={() => {
							if (onboardingFormData.manufacturers) {
								return setPage(6);
							}
							return Toast.error("Please fill all fields");
						}}
						additionalClasses="max-w-[445px]  bg-primary-700  text-white"
					>
						Next
					</Button>
					<SaveContinueProcess />
					{/* <Button
						disabled={false}
						additionalClasses="w-content h-auto bg-transparent text-xl font-medium  underline-offset-4 text-error-600 underline !w-auto"
						onClick={() => navigate("/dashboard")}
					>
						Save and Continue later
					</Button> */}
				</div>
			</motion.section>
		</AnimatePresence>
	);
};

export default ManufacturerProcess;
