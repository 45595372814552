import { motion } from "framer-motion";
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from "react";
import { Button, Loader2, Overlay } from "..";
import { useNavigate } from "react-router";
import { slideDownAnimation } from "../../services/Animations";
import OverviewCard from "../../views/Onboarding/OverviewCard";
import { request } from "../../utils/axios-utils";
import { Toast } from "../../utils/toast";
import { useLogoutUser } from "../../hooks/useAuthData";

interface OnboardingReviewModalProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}

export const OnboardingReviewModal: React.FC<OnboardingReviewModalProps> = ({
	show,
	setShow,
}) => {
	const [onboardedData, setOnboardedData] = useState<any>();
	const { isLoading, mutate: logoutUser } = useLogoutUser();
	useEffect(() => {
		const fetchVerificationDetails = async () => {
			const response = await request({
				url: "/distributors/verification/check/",
				method: "GET",
			});
			if (response?.status === 200) {
				const reviewData = response.data.data;
				setOnboardedData(reviewData.verification_status);
			} else {
				Toast.error("Error fetching details");
			}
		};
		if (show) {
			fetchVerificationDetails();
		}
	}, [show, setShow]);

	const modalRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	return (
		<Overlay
			isPersistent={true}
			show={show}
			setShow={setShow}
			modalRef={modalRef}
		>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex w-full max-w-[536px] flex-col items-center rounded-[10px] bg-white p-9"
				>
					<div className="flex flex-col gap-y-4">
						<h1 className=" text-[24px] font-bold">
							Re-Confirm Your Details
						</h1>

						<p className="text-[14px]">
							Your account verification couldn&apos;t be completed
							due to discrepancies in the information provided.
							Please review and correct the details submitted. If
							you you need assistance, contact our{" "}
							<b>support team</b>.
						</p>
						{!onboardedData && (
							<div className="mt-3 flex w-full justify-center">
								<div className="mx-auto h-full scale-[2]">
									<Loader2 color="#061F6A" />
								</div>
							</div>
						)}
						{onboardedData && (
							<div className="flex flex-col items-center gap-y-2">
								{!onboardedData.is_government_id_verified && (
									<OverviewCard
										title={"Government ID"}
										subtitle={
											"Upload a standard government ID"
										}
										isComplete={false}
									/>
								)}
								{!onboardedData.is_address_verified && (
									<OverviewCard
										title={"Business Address"}
										subtitle={
											"Type  in your Correct Business Address"
										}
										isComplete={false}
									/>
								)}
								{!onboardedData.is_tin_verified && (
									<OverviewCard
										title={"TIN Number"}
										subtitle={
											"Type  in your Correct Business Tax Identification Number"
										}
										isComplete={false}
									/>
								)}
								{!onboardedData.is_cac_verified && (
									<OverviewCard
										title={"CAC Number"}
										subtitle={
											"Type in your correct Business CAC Number"
										}
										isComplete={false}
									/>
								)}
								{!onboardedData.is_bvn_verified && (
									<OverviewCard
										title={"BVN"}
										subtitle={
											"Type in your correct Bank Verification Number"
										}
										isComplete={false}
									/>
								)}
							</div>
						)}
						<div className="mx-auto flex w-full gap-4 md:w-[60%]">
							<Button
								onClick={() => {
									logoutUser();
								}}
								disabled={isLoading}
								additionalClasses="flex justify-center h-10 w-fit border border-[#D2D5D8] bg-transparent text-[#8C9196] rounded-md px-4 py-2"
							>
								{isLoading ? (
									<Loader2 color="#061F6A" />
								) : (
									"Logout"
								)}
							</Button>
							<Button
								onClick={() => {
									navigate("/onboarding-review", {
										state: onboardedData,
										replace: true,
									});
									document.body.style.overflow = "auto";
								}}
								disabled={false}
								additionalClasses="flex w-fit bg-[#061F6A] px-4 py-2 text-white"
							>
								Proceed
							</Button>
						</div>
					</div>
				</motion.section>
			)}
		</Overlay>
	);
};
