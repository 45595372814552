import { QueryFunctionContext } from "react-query";
import { request } from "../utils/axios-utils";

// const fetchCustomer = ({ queryKey }: any) => {
// 	return request({
// 		headers: {
// 			Authorization: "Bearer " + queryKey[1],
// 		},
// 		url: `/users/user/`,
// 	});
// };

export const onBoardDistributor = (data: FormData) => {
	return request({
		method: "POST",
		url: `/distributors/onboarding/`,
		data,
	});
};
export const reonBoardDistributor = (data: FormData) => {
	return request({
		method: "PATCH",
		url: `/distributors/onboarding/`,
		data,
	});
};

export const searchRetailerDetailsGet = (queryKey: any) => {
	// const phone_number = queryKey[1];
	return request({
		method: "GET",
		url: `/auth/retailer/search/?phone_number=${queryKey}`,
	});
};

export const searchRetailerDetails = (data: { phone_number: string }) => {
	return request({
		method: "POST",
		url: `/auth/retailer/search/`,
		data,
	});
};

export const registerRetailer = (data: any) => {
	return request({
		method: "POST",
		url: `/auth/retailer/register/`,
		data,
	});
};

export const getDashboardData = (data: any) => {
	return request({
		method: "GET",
		url: `/dashboard/distributor/`,
		data,
	});
};

export const getBusinessSectors = () => {
	return request({
		method: "GET",
		url: `/distributors/business-sectors/`,
	});
};

export const getManufacturers = () => {
	return request({
		method: "GET",
		url: `/distributors/manufacturers/`,
	});
};

export const getAllNotifications = () => {
	return request({
		url: `/notifications/all/`,
	});
};

export const getAllReadNotifications = () => {
	return request({
		url: `/notifications/seen/`,
	});
};
export const getAllUnreadNotifications = () => {
	return request({
		url: `/notifications/unseen/`,
	});
};

export const readNotification = (ids: string[]) => {
	return request({
		method: "POST",
		url: `/notifications/read/`,
		data: { data: ids },
	});
};

export const getRetailerLoanDetails = ({ queryKey }: QueryFunctionContext) => {
	const credit_id = queryKey[1];
	return request({
		method: "GET",
		url: `/credit/${credit_id}/details`,
	});
};

// export const confirmBankDetail = (data: any) => {
// 	return request({
// 		method: "GET",
// 		url: `/auth/retailer/search`,
// 		data,
// 	});
// };
