import { motion } from "framer-motion";
import React, { useRef } from "react";
import { MdClose } from "react-icons/md";
import { Overlay } from "./Overlay";
import { SuccessModalProps } from "types/components/modals";
import ErrorImg from "../../assets/image/icons/error.png";
import { slideDownAnimation } from "../../services/Animations";

export const ErrorModal: React.FC<SuccessModalProps> = ({
	show,
	setShow,
	content,
	buttonContent,
	buttonAction,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-[80%] max-w-[675px] flex-col items-center rounded-[10px] bg-white p-4  md:w-fit md:p-8"
				>
					<div className="flex w-full justify-end">
						<button
							className="text-[1.5em]"
							onClick={() => setShow(!show)}
						>
							<MdClose />
						</button>
					</div>
					<div className="flex flex-col items-center">
						<img src={ErrorImg} alt="error" />
					</div>
					<div className="max-w-[350px]">
						<p className="text-center text-lg text-red-600 ">
							{content}
						</p>
					</div>
					{buttonContent && (
						<div className="flex py-4">
							<button
								className="w-full rounded-md bg-[#002060]  py-2 text-sm text-white"
								onClick={buttonAction}
							>
								{buttonContent}
							</button>
						</div>
					)}
				</motion.section>
			)}
		</Overlay>
	);
};
