import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	ColumnFiltersState,
	useReactTable,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	SortingState,
} from "@tanstack/react-table";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./table";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./select";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	DoubleArrowLeftIcon,
	DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Button } from "./button";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { AiOutlineExport } from "react-icons/ai";
import { exportDataAsCSV } from "../../utils/utilities";
import { StatusType } from "../../types/utils";
import Dropdown from "../StatusDropdown";
import { DatePickerWithRange } from "../DateRange";
import { DateRange } from "react-day-picker";
import { parse, isValid } from "date-fns";
import { DatePicker } from "./DatePicker";

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	tableTitle: string;
	filterColumnName: string;
	exportFileName?: string;
	statusOptions: StatusType[];
	onStatusSelect: (status: StatusType) => void;
	onRowClick?: (distributor: any) => void;
	onClearFilter: () => void;
	setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
	dateRange: DateRange | undefined;
	dateFieldName: string;
	datePicker: Date | undefined;
	setDatePicker: React.Dispatch<React.SetStateAction<Date | undefined>>;
	filterField?: boolean;
	pagination?: boolean;
	emptyState: () => void;
}

export function DataTable<TData, TValue>({
	columns,
	data,
	filterColumnName,
	exportFileName,
	statusOptions,
	onStatusSelect,
	onRowClick,
	onClearFilter,
	setDateRange,
	dateRange,
	dateFieldName,
	setDatePicker,
	datePicker,
	filterField,
	tableTitle,
	pagination,
	emptyState,
}: DataTableProps<TData, TValue>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [rowSelection, setRowSelection] = useState({});
	const [filteredData, setFilteredData] = useState<TData[]>([]);

	const parseDate = (dateString: string): Date | null => {
		// Regular expression to match dates in the format '08th Mar 2024'
		const regex = /(\d{1,2}(?:st|nd|rd|th)?)\s+([A-Za-z]{3})\s+(\d{4})/;
		const match = dateString.match(regex);

		if (match) {
			return parse(dateString, "do MMM yyyy", new Date());
		} else {
			const date = new Date(dateString);
			return isValid(date) ? date : null;
		}
	};

	useEffect(() => {
		if (dateRange && dateRange.from && dateRange.to) {
			const filtered = data?.filter((item: any) => {
				const itemDate = parseDate(item[dateFieldName]);
				if (!itemDate) {
					return false;
				}

				let isValid = true;

				if (dateRange.from) {
					isValid = itemDate >= dateRange.from;
				}

				if (isValid && dateRange.to) {
					isValid = itemDate <= dateRange.to;
				}

				return isValid;
			});
			setFilteredData(filtered || []);
		} else {
			setFilteredData(data);
		}
	}, [data, dateRange, dateFieldName]);

	useEffect(() => {
		if (datePicker) {
			const filtered = data.filter((item: any) => {
				const itemDate = parseDate(item[dateFieldName]);
				return (
					itemDate &&
					itemDate.toDateString() === datePicker.toDateString()
				);
			});
			setFilteredData(filtered);
		} else {
			setFilteredData(data);
		}
	}, [data, datePicker, dateFieldName]);

	const table = useReactTable({
		data: filteredData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			rowSelection,
		},
	});
	const handleExport = async () => {
		await exportDataAsCSV(data, exportFileName);
	};
	return (
		<div className="space-y-6">
			{/* Header */}

			{/* input */}
			{filterField && (
				<div className="flex items-center justify-between gap-6">
					<div className="ml-[10px] flex w-full items-center justify-between rounded border px-[10px]  py-3 text-[#939393]">
						<input
							placeholder={`Search by ${filterColumnName}...`}
							type="text"
							name="search"
							value={
								(table
									.getColumn(filterColumnName)
									?.getFilterValue() as string) ?? ""
							}
							onChange={(event) =>
								table
									.getColumn(filterColumnName)
									?.setFilterValue(event.target.value)
							}
							className="focus-none h-full w-full bg-transparent bg-none text-[12px] text-[#000] outline-none"
						/>

						<BiSearch />
					</div>

					<div className="flex items-center justify-between gap-3 px-[10px] py-0.5">
						<p className="text-[14px] font-[500] text-gray-800">
							Filters
						</p>
						<DatePickerWithRange
							setDateRange={setDateRange}
							dateRange={dateRange}
							data={data}
							dateFieldName={dateFieldName}
						/>
						<DatePicker
							datePicker={datePicker}
							data={data}
							dateFieldName={dateFieldName}
							setDatePicker={setDatePicker}
						/>

						<Dropdown
							options={statusOptions}
							onSelect={onStatusSelect}
							onClear={onClearFilter}
						/>
						{exportFileName && (
							<button
								onClick={() => handleExport()}
								className="inline-flex items-center gap-2 rounded border bg-blue-950 px-3 py-1.5 text-[14px] text-[#fff]"
							>
								<AiOutlineExport />
								<span className="text-[14px]">Export</span>
							</button>
						)}
					</div>
				</div>
			)}
			{/* table */}
			{/* <div className="rounded-md border border-[#F3F5F7]"> */}
			<Table className="overflow-x-auto">
				<TableHeader className="whitespace-nowrap">
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<TableHead
										key={header.id}
										className=" text-white "
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef
														.header,
													header.getContext()
											  )}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								data-state={row.getIsSelected() && "selected"}
								onClick={() =>
									onRowClick && onRowClick(row.original)
								}
								className="cursor-pointer"
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell
										key={cell.id}
										className="whitespace-nowrap"
									>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext()
										)}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columns.length}
								className="h-24 text-center"
							>
								<div className="mb-[20px] mt-[37px] flex w-full flex-col items-center justify-center">
									<div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-primary-100 p-2">
										<div className="flex h-full w-full items-center justify-center rounded-full bg-[#DBF2FF] p-2">
											<span className="">
												<svg
													width="21"
													height="20"
													viewBox="0 0 21 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M19.5 19L15.15 14.65M17.5 9C17.5 13.4183 13.9183 17 9.5 17C5.08172 17 1.5 13.4183 1.5 9C1.5 4.58172 5.08172 1 9.5 1C13.9183 1 17.5 4.58172 17.5 9Z"
														stroke="#2A60B7"
														strokeWidth="1.66667"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										</div>
									</div>
									<h1 className="mt-4 text-base font-medium">
										No history found
									</h1>
									<h2 className="mb-6 mt-1 text-sm font-normal text-[#667085]">
										Create your first credit to get started
									</h2>
									<Button
										variant="secondary"
										className="flex w-[50%] items-center"
										onClick={emptyState}
									>
										<span className="mr-3">
											<svg
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M6.99935 1.16602V12.8327M1.16602 6.99935H12.8327"
													stroke="white"
													strokeWidth="1.67"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</span>
										Apply for credit
									</Button>
								</div>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{/* </div> */}

			{/* paginations */}
			{pagination && (
				<div className="flex items-center justify-end px-2">
					<div className="flex items-center space-x-6 lg:space-x-8">
						<div className="flex items-center space-x-2">
							<p className="text-sm font-medium">Rows per page</p>
							<Select
								value={`${
									table.getState().pagination.pageSize
								}`}
								onValueChange={(value) => {
									table.setPageSize(Number(value));
								}}
							>
								<SelectTrigger className="h-8 w-[70px]">
									<SelectValue
										placeholder={
											table.getState().pagination.pageSize
										}
									/>
								</SelectTrigger>
								<SelectContent side="top">
									{[10, 20, 30, 40, 50].map((pageSize) => (
										<SelectItem
											key={pageSize}
											value={`${pageSize}`}
										>
											{pageSize}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						<div className="flex w-[100px] items-center justify-center text-sm font-medium">
							Page {table.getState().pagination.pageIndex + 1} of{" "}
							{table.getPageCount()}
						</div>
						<div className="flex items-center space-x-2">
							<Button
								variant="outline"
								className="hidden h-8 w-8 p-0 lg:flex"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
							>
								<span className="sr-only">
									Go to first page
								</span>
								<DoubleArrowLeftIcon className="h-4 w-4" />
							</Button>
							<Button
								variant="outline"
								className="h-8 w-8 p-0"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							>
								<span className="sr-only">
									Go to previous page
								</span>
								<ChevronLeftIcon className="h-4 w-4" />
							</Button>
							<Button
								variant="outline"
								className="h-8 w-8 p-0"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							>
								<span className="sr-only">Go to next page</span>
								<ChevronRightIcon className="h-4 w-4" />
							</Button>
							<Button
								variant="outline"
								className="hidden h-8 w-8 p-0 lg:flex"
								onClick={() =>
									table.setPageIndex(table.getPageCount() - 1)
								}
								disabled={!table.getCanNextPage()}
							>
								<span className="sr-only">Go to last page</span>
								<DoubleArrowRightIcon className="h-4 w-4" />
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
