import React from "react";
import { SetStateAction } from "react";
// import { FormInput } from "components/FormInput";
import {
	RetailerCreditAuthorizationFormProps,
	RetailerCreditAuthorizationPageProps,
} from "types/views";
// import { Button } from "../../../Button";
import { Patterns } from "../../../../services/Constants";
import { Toast } from "utils/toast";
import { isValidName } from "utils/validation";
// import { Loader2 } from "components";
// import { MdOutlineClose } from "react-icons/md";
import SuccessImg from "../../../../assets/image/icons/success.svg";
import { MonoConnectComponent } from "components/MonoConnectComponent";
import NumberFormat from "utils/NumberFormat";

interface LinkMonoFormProps extends RetailerCreditAuthorizationPageProps {
	formData: RetailerCreditAuthorizationFormProps;
	setFormData: React.Dispatch<
		SetStateAction<RetailerCreditAuthorizationFormProps>
	>;
	previousGuarantor: boolean;
	submit: () => void;
	isLoading: boolean;
	creditData: any;
	auth?: string;
	isAdminRequest?: boolean;
	validateGuarantorForm?: (formData: any) => boolean;
	previousPage: 0 | 1 | 2 | 3 | 4 | 5;
	retailer_name: string;
	purchase_amount: string;
	credit_amount: string;
	creditDataAmount: string;
	formCredit_amount: string;
}

const LinkMonoForm = ({
	formData,
	// setFormData,
	// previousGuarantor,
	submit,
	isLoading,
	// creditData,
	auth,
	isAdminRequest,
	setPage,
	validateGuarantorForm,
	previousPage,
	retailer_name,
	purchase_amount,
	credit_amount,
	creditDataAmount,
	formCredit_amount,
}: LinkMonoFormProps) => {
	const onMonoSuccess = () => {
		submit();
	};

	return (
		<div className="relative m-5 mt-[18px] p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
			<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
				<div className="flex flex-col gap-2">
					<p>
						Credit Applicant:{" "}
						{retailer_name
							? retailer_name
							: formData.first_name +
							  " " +
							  formData.last_name}{" "}
					</p>
					{/* This can change to content_object.total_amount later  */}
					<p>
						Purchase Amount:{" "}
						{isAdminRequest ? (
							<NumberFormat value={purchase_amount ?? 0} />
						) : creditDataAmount ? (
							<NumberFormat value={purchase_amount} />
						) : null}{" "}
					</p>
					<p>
						Credit Amount:{" "}
						{isAdminRequest ? (
							<NumberFormat value={formCredit_amount ?? 0} />
						) : credit_amount ? (
							<NumberFormat value={credit_amount ?? 0} />
						) : null}{" "}
					</p>
				</div>
			</div>
			<div className="space-y-4 py-4">
				<div className="mb-8 flex flex-col items-center py-6">
					<img src={SuccessImg} alt="success" />
					<h1 className="text-[1.7em]">Grant Access?</h1>
					<div className="max-w-[350px]">
						<p className=" text-center text-[#626D88] text-opacity-40">
							by clicking continue, you are giving HADI access to
							your bank statement on MONO
						</p>
					</div>
				</div>
				<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
					<MonoConnectComponent
						onSuccess={onMonoSuccess}
						viewMode={"inline"}
						buttonContent={"Submit"}
						buttonAction={validateGuarantorForm(formData)}
						auth={auth}
						isLoading={isLoading}
					/>
					<button
						className="text-sm font-semibold text-blue-900"
						onClick={() => setPage(previousPage)}
					>
						Go Back
					</button>
				</div>
			</div>
			{/* {} */}
		</div>
	);
};

export default LinkMonoForm;
