import React, { useState } from "react";
import { SetStateAction } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { uploadedFileContainerVariants } from "services/Animations";
import { FormInput, ProcessUploadFile, UploadedFile, Button } from "components";
import {
	RetailerCreditAuthorizationFormProps,
	RetailerCreditAuthorizationPageProps,
} from "types/views";
// import { Button } from "../../../Button";
import { Patterns, Relationship } from "../../../../services/Constants";
import { Toast } from "utils/toast";
import { isValidName } from "utils/validation";
// import { Loader2 } from "components";
import { OccupationType } from "services/Constants";

interface GuarantorFormProps extends RetailerCreditAuthorizationPageProps {
	formData: RetailerCreditAuthorizationFormProps;
	setFormData: React.Dispatch<
		SetStateAction<RetailerCreditAuthorizationFormProps>
	>;
	setPreviousGuarantor: React.Dispatch<SetStateAction<boolean>>;
	previousGuarantor: boolean;
	// submit: () => void;
	isLoading: boolean;
	validateGuarantorForm: (
		formData: RetailerCreditAuthorizationFormProps
	) => boolean;
}

const GuarantorForm = ({
	formData,
	setFormData,
	previousGuarantor,
	setPage,
	isLoading,
	setPreviousGuarantor,
	validateGuarantorForm,
}: GuarantorFormProps) => {
	const [selected, setSelected] = useState<boolean>(false);

	return (
		<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
			<div className="space-y-2 bg-[#FFDAD6] p-2">
				<p className="text-[12px] md:text-[15px]">
					Please enter correct guarantor details before authorising
					the credit application. Your guarantor cannot be a spouse
					and must be a working adult above the age of 18 yrs.
				</p>
			</div>
			{previousGuarantor ? (
				<div className={`mt-4 space-y-3`}>
					<div
						className={`${
							previousGuarantor && !selected
								? "visible"
								: "hidden"
						} space-y-2`}
					>
						<p>
							Do you want to edit your current guarantor&apos;s
							details below ?
						</p>
						<div className={"flex justify-between gap-x-8"}>
							<Button
								additionalClasses="bg-red-500 text-white w-fit "
								disabled={false}
								onClick={() => {
									setPreviousGuarantor(true);
									setSelected(!selected);
								}}
							>
								No, i dont
							</Button>
							<Button
								additionalClasses="bg-transparent border border-[#BABFC3] w-fit"
								disabled={false}
								onClick={() => {
									setPreviousGuarantor(false);
									setSelected(!selected);
								}}
							>
								Yes, i do
							</Button>
						</div>
					</div>
				</div>
			) : null}
			<div className="space-y-4 py-4">
				<div className="space-y-4 py-4">
					<FormInput
						label="Guarantor’s First Name"
						name="guarantor_first_name"
						id="guarantor_first_name"
						labelStyles="text-[16px] font-medium"
						value={formData.guarantor.first_name}
						disabled={previousGuarantor}
						placeholder="Enter First Name"
						additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									first_name: e.target.value,
								},
							})
						}
					/>
					<FormInput
						label="Guarantor’s Last Name"
						name="guarantor_last_name"
						id="guarantor_last_name"
						labelStyles="text-[16px] font-medium"
						value={formData.guarantor.last_name}
						disabled={previousGuarantor}
						placeholder="Enter Last Name"
						additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									last_name: e.target.value,
								},
							})
						}
					/>
					<FormInput
						label="Guarantor’s Phone Number"
						name="guarantor_phone"
						id="guarantor_phone"
						labelStyles="text-[16px] font-medium"
						value={formData.guarantor.phone}
						disabled={previousGuarantor}
						placeholder="Enter Phone Number"
						additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
						maxLength={11}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/\D/g, "");
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									phone: value,
								},
							});
						}}
					/>
					<FormInput
						label="Guarantor’s Address"
						name="guarantor_address"
						id="guarantor_address"
						labelStyles="text-[16px] font-medium"
						value={formData.guarantor.address}
						disabled={previousGuarantor}
						placeholder="Enter Valid Address "
						additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									address: e.target.value,
								},
							})
						}
					/>
					<FormInput
						label="Guarantor’s Occupation"
						name="occupation_type"
						id="occupation_type"
						labelStyles="text-[16px] font-medium"
						select={true}
						options={OccupationType}
						value={formData.guarantor.occupation_type}
						disabled={previousGuarantor}
						placeholder="Enter your Occupation"
						additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(selectedOption) =>
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									occupation_type: selectedOption.value,
								},
								// occupation_type: selectedOption.value,
							})
						}
					/>
					<FormInput
						label="Relationship with Guarantor"
						name="relationship"
						id="relationship"
						labelStyles="text-[16px] font-medium"
						select={true}
						options={Relationship}
						value={formData.guarantor.relationship}
						disabled={previousGuarantor}
						placeholder="Specify your relationship"
						additionalClasses="rounded-md font-light  outline-none"
						minLength={2}
						type="text"
						required={true}
						autoComplete="false"
						onChange={(selectedOption) =>
							setFormData({
								...formData,
								guarantor: {
									...formData.guarantor,
									relationship: selectedOption.value,
								},
							})
						}
					/>
					{formData.guarantor.relationship === "other" && (
						<>
							<p className="text-sm text-slate-600">
								If you select “Other” kindly specify the
								relationship below
							</p>
							<FormInput
								label=""
								name="other_relationship"
								id="other_relationship"
								type="text"
								placeholder="Specify Relationship"
								autoComplete={"true"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none -mt-1"
								required={false}
								value={formData.guarantor.other_relationship}
								onChange={(e) => {
									setFormData({
										...formData,
										guarantor: {
											...formData.guarantor,
											other_relationship: e.target.value,
										},
									});
								}}
							/>
						</>
					)}
					<div className="flex flex-col gap-2">
						<label
							htmlFor="digital_signature"
							className=" text-[16px] font-[500] text-blue-950"
						>
							Upload Signature
						</label>
						<p className="text-sm text-slate-600">
							Upload an image of your signature
						</p>
					</div>
					<ProcessUploadFile
						formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
						maxSize={5 * 1024 * 1024} // 5000Kb
						setFile={setFormData}
						localStorageFileName={"guarantor.signature"}
					/>
					<AnimatePresence>
						{formData.guarantor.signature && (
							<motion.div
								className="mt-3 flex w-full flex-col gap-2"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<UploadedFile
									title={formData.guarantor.signatureName}
									deleteFile={() => {
										setFormData((prevData) => ({
											...prevData,
											guarantor: {
												...formData.guarantor,
												signature: null,
											},
										}));
									}}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
					<Button
						additionalClasses=" w-fit text-white"
						disabled={isLoading}
						onClick={() => {
							if (!validateGuarantorForm(formData)) {
								return;
							}
							setPage(5);
						}}
					>
						Next
					</Button>
					<button
						className="text-sm font-semibold text-blue-900"
						onClick={() => setPage(3)}
					>
						Go Back
					</button>
				</div>
			</div>
			{}
		</div>
	);
};

export default GuarantorForm;
