import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loader2, ProcessCompleted } from "../../components";
import { AnimatePresence, motion } from "framer-motion";
import { ProcessTransitionAnimation } from "../../services/Animations";
import { OnboardingFormContext } from ".";
import { OverviewCard } from "./OverviewCard";

type FinalOverviewProps = {
	isLoading: boolean;
	crmLinkSuccess: boolean;
	// filePassportPhoto: null | File;
	handleSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const FinalOverview: React.FC<FinalOverviewProps> = ({
	crmLinkSuccess,
	isLoading,
	handleSubmit,
}) => {
	const [show, setShow] = useState<boolean>(false);
	const { setPage, onboardingFormData } = useContext(OnboardingFormContext);
	const navigate = useNavigate();
	return (
		<>
			<AnimatePresence>
				{/* {state === 6 && ( */}
				<motion.section
					variants={ProcessTransitionAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					className="flex w-full max-w-[500px] flex-col items-center gap-6 rounded-xl p-6 drop-shadow-md"
				>
					<div className="flex flex-col gap-2 text-center">
						<h2 className="flex items-center justify-center text-[28px] font-bold text-primary-900 ">
							Click to edit your submission
						</h2>
						<p className="text-base font-light text-secondary-400">
							Follow the process and provide all information
							required to finish onboarding your business and
							start extending credit to your customers
						</p>
					</div>
					<div className="flex flex-col items-center gap-4">
						<OverviewCard
							title={"Government ID"}
							subtitle={"Upload a standard government ID"}
							isComplete={
								onboardingFormData.governmentIdDetails && true
							}
							onClick={() => {
								setPage(1);
							}}
						/>
						{/* <OverviewCard
							title={"Business Address"}
							subtitle={"Type  in your Correct Business Address"}
							isComplete={
								onboardingFormData.businessAddress && true
							}
							onClick={() => {
								setPage(2);
							}}
						/> */}
						<OverviewCard
							title={"Proof of Address"}
							subtitle={
								"Scan or Upload PDF, JPEG or PNG Copy of your Proof of Address which could be either Bank Statement, utility Bill or Tenancy or Proof of Ownership"
							}
							isComplete={
								onboardingFormData.businessAddressFile && true
							}
							onClick={() => {
								setPage(2);
							}}
						/>
						<OverviewCard
							title={"Take a Selfie and Verify ID"}
							subtitle={
								"Take a selfie of yourself for identification "
							}
							isComplete={onboardingFormData.selfiePhoto && true}
							onClick={() => {
								setPage(3);
							}}
						/>
						<OverviewCard
							title={"TIN & CAC Number"}
							subtitle={
								"Type  in your Correct Business Tax Identification Number"
							}
							isComplete={
								onboardingFormData.TINNumber &&
								true &&
								onboardingFormData.CACNumber &&
								true
							}
							onClick={() => {
								setPage(4);
							}}
						/>
						<OverviewCard
							title={"Manufacturers"}
							subtitle={"Select manufacturers you work with"}
							isComplete={
								onboardingFormData.TINNumber &&
								true &&
								onboardingFormData.CACNumber &&
								true
							}
							onClick={() => {
								setPage(5);
							}}
						/>
						<OverviewCard
							title={"Link to ERP"}
							subtitle={
								"Add your ERP to access retailers details"
							}
							isComplete={crmLinkSuccess && true}
							onClick={() => {
								setPage(6);
							}}
						/>
					</div>
					<div className="flex w-full flex-col items-center gap-y-8">
						<Button
							disabled={false}
							onClick={(e) => handleSubmit(e)}
							additionalClasses="max-w-[418px] text-lg text-white"
						>
							{isLoading ? <Loader2 /> : "Submit"}
						</Button>
						<Button
							disabled={false}
							additionalClasses="w-content h-auto bg-transparent text-xl font-semibold text-primary-800 underline !w-auto"
							onClick={() => navigate("/dashboard")}
						>
							Back to dashboard
						</Button>
					</div>
				</motion.section>
				{/* )} */}
			</AnimatePresence>
			<ProcessCompleted show={show} setShow={setShow} />
		</>
	);
};

export default FinalOverview;
