import React, { useEffect, useRef, useState } from "react";
import { CreditOTP } from "../OtpForms/CreditOTP";
import { OTPCode } from "../../types/components";
import { CreditVerifyOTPProps } from "../../types/views";

const CreditVerify: React.FC<CreditVerifyOTPProps> = ({
	fullOtpCode,
	setFullOtpCode,
	setIsOTPComplete,
}) => {
	const [time, setTime] = useState<number>(5 * 60);
	const [otpCode, setSetOtpCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});
	const [hasResent, setHasResent] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const minutes = Math.floor(time / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (time % 60).toString().padStart(2, "0");

	// const fullCode = otpCode.one + otpCode.two + otpCode.three + otpCode.four;

	const otpRef1 = useRef<HTMLInputElement>(null);
	const otpRef2 = useRef<HTMLInputElement>(null);
	const otpRef3 = useRef<HTMLInputElement>(null);
	const otpRef4 = useRef<HTMLInputElement>(null);

	const onSuccess = () => {
		setHasResent(true);
		setTime(300);
		setIsSuccess(true);
	};

	const onError = () => {
		setIsLoading(false);
	};

	const handleVerifyOtp = () => {
		setIsLoading(true);
	};

	useEffect(() => {
		setFullOtpCode(
			otpCode.one + otpCode.two + otpCode.three + otpCode.four
		);
		if (
			otpCode.one.length > 0 &&
			otpCode.two.length > 0 &&
			otpCode.three.length > 0 &&
			otpCode.four.length > 0
		) {
			if (setIsOTPComplete) setIsOTPComplete(true);
		} else {
			if (setIsOTPComplete) setIsOTPComplete(false);
		}
	}, [otpCode, setFullOtpCode, setIsOTPComplete]);

	return (
		<section className="w-full max-w-[320px] justify-between">
			<form
				method="post"
				onSubmit={(e) => {
					e.preventDefault();
					handleVerifyOtp();
				}}
			>
				<div className="flex justify-between gap-4">
					<CreditOTP
						otpRef={otpRef1}
						onChange={(e) => {
							setSetOtpCode((prevData) => ({
								...prevData,
								one: e.target.value,
							}));
							if (
								e.target.value.length === 1 &&
								otpRef2.current
							) {
								(otpRef2.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.one}
					/>
					<CreditOTP
						otpRef={otpRef2}
						onChange={(e) => {
							setSetOtpCode((prevData) => ({
								...prevData,
								two: e.target.value,
							}));
							if (
								e.target.value.length === 1 &&
								otpRef2.current
							) {
								(otpRef3.current as HTMLInputElement)?.focus();
							}
							if (
								e.target.value.length === 0 &&
								otpRef2.current
							) {
								(otpRef1.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.two}
					/>
					<CreditOTP
						otpRef={otpRef3}
						onChange={(e) => {
							setSetOtpCode((prevData) => ({
								...prevData,
								three: e.target.value,
							}));
							if (
								e.target.value.length === 1 &&
								otpRef4.current
							) {
								(otpRef4.current as HTMLInputElement)?.focus();
							}
							if (
								e.target.value.length === 0 &&
								otpRef3.current
							) {
								(otpRef2.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.three}
					/>

					<CreditOTP
						otpRef={otpRef4}
						onChange={(e) => {
							setSetOtpCode((prevData) => ({
								...prevData,
								four: e.target.value,
							}));
							if (
								e.target.value.length === 0 &&
								otpRef4.current
							) {
								(otpRef3.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.four}
					/>
				</div>
			</form>
		</section>
	);
};

export default CreditVerify;
