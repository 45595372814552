import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Button } from "../../../components/Button";
import CreditVerify from "../../../components/CreditAuth/CreditVerify";
import { CreditAcceptProcessProps } from "../../../types/views";
import {
	useFinalAcceptCredit,
	useResendVerifyOTP,
} from "../../../hooks/useCreditData";
import { Loader2 } from "../../../components";
import { Toast } from "../../../utils/toast";

const Verify: React.FC<CreditAcceptProcessProps> = ({
	creditData,
	isOTPComplete,
	pinID,
	stage,
	code,
	setCreditData,
	setStage,
	setIsOTPComplete,
	setPinID,
	goBack,
}) => {
	const [hasResent, setHasResent] = useState<boolean>(false);
	const [time, setTime] = useState<number>(5 * 60);
	const [fullOtpCode, setFullOtpCode] = useState("");

	const minutes = Math.floor(time / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (time % 60).toString().padStart(2, "0");

	const [otpData, setOtpData] = useState<{
		pin: string;
		pin_id: string;
	}>({
		pin: fullOtpCode,
		pin_id: pinID,
	});

	const sendOTPOnSuccess = (data: AxiosResponse) => {
		if (setCreditData) setCreditData(data);
		setStage(3);
	};

	const sendOTPOnError = () => {
		//
	};

	const reSendVerifyOTPOnSuccess = (data: AxiosResponse) => {
		setTime(300);
		setHasResent(true);
		// console.log(data.data.data.pinId)
		setPinID(data.data.data.pinId);
		// setOtpData({ ...otpData, pin_id: data.data.data.pinId });
	};

	const reSendVerifyOTPOnError = () => {
		//
	};

	const { mutateFinalAcceptCredit, isLoading: finalAcceptCreditIsLoading } =
		useFinalAcceptCredit(sendOTPOnSuccess, sendOTPOnError, code);

	const { mutateResendSendOTP, isLoading: resendVerifyOTPLoading } =
		useResendVerifyOTP(reSendVerifyOTPOnSuccess, reSendVerifyOTPOnError);

	const handleVerifyCredit = () => {
		mutateFinalAcceptCredit(otpData);
	};

	useEffect(() => {
		if (hasResent) {
			const interval = setInterval(() => {
				setTime((prevTime) => {
					if (!(prevTime === 0)) {
						return prevTime - 1;
					} else {
						setHasResent(false);
						return prevTime;
					}
				});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [hasResent]);

	useEffect(() => {
		setOtpData({ pin: fullOtpCode, pin_id: pinID });
	}, [fullOtpCode, pinID]);
	return (
		<>
			{stage === 2 && (
				<section className="w-full max-w-[640px]">
					<div className="relative p-3 text-center text-[24px] font-semibold text-blue-950">
						<div
							className="absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer"
							onClick={goBack}
						>
							<RiArrowLeftSLine className="m-5 h-[32px] w-[32px]" />
						</div>
						<h3 className="px-14">Credit Offer</h3>
					</div>
					<div className="m-5 mt-[18px] flex flex-col gap-2 rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
						<p>
							Credit Processed by:{" "}
							{"₦" +
								(+creditData?.data?.data
									?.credit_offer).toLocaleString()}
						</p>
						<p>Credit Offer: {creditData?.data?.data?.customer}</p>
						<p>Load ID: {creditData?.data?.data?.loadn_id}</p>
					</div>
					<div className="m-5 flex flex-col items-center justify-center space-y-6 text-[14px] text-slate-400">
						<p>
							Enter 4 digit OTP you got in the SMS to authorize
							Credit Application
						</p>
						<CreditVerify
							isOTPComplete={isOTPComplete}
							setIsOTPComplete={setIsOTPComplete}
							fullOtpCode={fullOtpCode}
							setFullOtpCode={setFullOtpCode}
						/>
						<Button
							additionalClasses="max-w-[420px] text-lg text-white"
							disabled={finalAcceptCreditIsLoading}
							onClick={() => {
								if (!isOTPComplete) {
									Toast.error("Kindly enter in the OTP code");
								} else if (code) handleVerifyCredit();
							}}
						>
							{finalAcceptCreditIsLoading ? (
								<Loader2 />
							) : (
								"Verify"
							)}
						</Button>
						<div className="flex flex-col gap-2">
							<h4
								className="cursor-pointer text-[14px] text-[#AAB2C3]"
								onClick={() => {
									if (!resendVerifyOTPLoading && code)
										mutateResendSendOTP(code);
								}}
							>
								Didn&apos;t get the code?{" "}
								<span className="font-bold text-[#002060]">
									{resendVerifyOTPLoading
										? "Sending..."
										: "Resend"}
								</span>
							</h4>
							{hasResent && (
								<h5>
									Time before resending:{" "}
									{minutes + ":" + seconds}
								</h5>
							)}
						</div>
					</div>
				</section>
			)}
		</>
	);
};
export default Verify;
