import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BsMailbox, BsPhone } from "react-icons/bs";
import { ProcessUploadFile, TopBar } from "../../../components";
import SendFundsLogo from "../../../assets/image/icons/sendfunds-logo.svg";
import { IoMdAddCircle } from "react-icons/io";
import { useAuth } from "../../../hooks/auth";
import {
	useGetAccountInfo,
	useGetPoolerWalletInfo,
} from "../../../hooks/useWalletData";
import { Toast } from "../../../utils/toast";
import { letter_profile_icon } from "../../../utils/utilities";

type PersonalProfileType = {
	setAddressUploadFile: Dispatch<SetStateAction<File | null>>;
};
type CompanyProfileType = {
	setBusinessAddressUploadFile: Dispatch<SetStateAction<File | null>>;
};

const ProfilePage: React.FC = () => {
	const { user } = useAuth();
	const nameInitials = user?.first_name.split("")[0];

	const [addressUploadFile, setAddressUploadFile] = useState<any>(null);
	const [businessAddressUploadFile, setBusinessAddressUploadFile] =
		useState<any>(null);
	const [tab, setTab] = useState(0);
	useEffect(() => {
		setAddressUploadFile(localStorage.getItem("filePersonalAddress"));
	}, []);
	const handleChangeTab = (tabName: string) => {
		switch (tabName) {
			case "personal":
				setTab(0);
				break;
			case "business":
				setTab(1);
				break;
			case "bankAccount":
				setTab(2);
				break;
			default:
				setTab(0);
		}
	};

	return (
		<>
			<TopBar pageName="Profile Screen" />
			<div className="flex gap-4 px-8 md:px-12">
				<div className="mt-2 flex cursor-pointer gap-4">
					<button
						className={`p-2 ${
							tab === 0 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("personal")}
					>
						Admin
					</button>

					<button
						className={`p-2 ${
							tab === 1 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("business")}
					>
						Business
					</button>
					<button
						className={` p-2 ${
							tab === 2 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("bankAccount")}
					>
						Payment
					</button>
				</div>
			</div>
			<div className="flex h-[144px] items-start justify-center bg-gray-100 bg-profile bg-right  bg-no-repeat pt-6 md:items-center md:pt-0">
				<h1 className="text-2xl font-semibold">
					{tab === 0 && "Personal Profile"}
					{tab === 1 && "Company Profile"}
					{tab === 2 && "Account Details"}
				</h1>
			</div>
			<div className="relative min-h-[60px] px-8 md:px-12">
				<div className="absolute -top-full flex h-[120px] w-[120px] items-center justify-center rounded-full bg-gray-400">
					<p className="select-none text-7xl text-black">
						{nameInitials}
					</p>
					<span className="absolute bottom-0 right-[10%] z-10 cursor-pointer text-2xl text-blue-950 ">
						<IoMdAddCircle />
					</span>
				</div>
			</div>
			<div className="relative flex flex-col divide-y-2 px-8 md:px-12">
				<div className="flex flex-col gap-2 py-4">
					<h1 className="text-2xl font-semibold capitalize text-blue-950">
						{/* {user} */}
						{user?.first_name + " " + user?.last_name ?? "N/A"}
						{/* Samuel Ashikalaye */}
					</h1>
					<div className="flex flex-col gap-2 text-gray-400 md:flex-row">
						<div className="flex items-center gap-1">
							<BsPhone />
							{user?.username ?? "N/A"}
						</div>
						<div className="flex items-center gap-1">
							<BsMailbox />
							{user?.email ?? "N/A"}
						</div>
					</div>
				</div>
				{tab === 0 && (
					<PersonalDetails
						setAddressUploadFile={setAddressUploadFile}
					/>
				)}
				{tab === 1 && (
					<CompanyDetails
						setBusinessAddressUploadFile={
							setBusinessAddressUploadFile
						}
					/>
				)}
				{tab === 2 && <BankAccountDetails />}
			</div>
		</>
	);
};

export default ProfilePage;

const CompanyDetails = ({
	setBusinessAddressUploadFile,
}: CompanyProfileType) => {
	const { user } = useAuth();
	return (
		<>
			<div className="flex flex-col py-4 md:flex-row">
				<div className="flex-1">
					<h1 className="font-semibold text-gray-800">
						Company Details
					</h1>
					{/* <p className="text-[14px] text-gray-400">
						This is where the company&apos;s information such as
						name would be edited
					</p> */}
				</div>
				<div className="flex flex-1 flex-col gap-y-2">
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="business_name"
								className="font-semibold text-[#001746]"
							>
								Business Name
							</label>
							<input
								type="text"
								className="w-full rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="business_name"
								id="business_name"
								value={user?.distributor?.name}
								disabled
							/>
						</div>
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="phone_number"
								className="font-semibold text-[#001746]"
							>
								Phone Number
							</label>
							<input
								type="text"
								className="w-full rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="phone_number"
								id="phone_number"
								value={
									user?.distributor?.distributor_profile
										?.phone_number
								}
								disabled
							/>
						</div>
					</div>
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="tin_number"
								className="font-semibold text-[#001746]"
							>
								TIN Number
							</label>
							<input
								type="text"
								className="w-full rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="tin_number"
								id="tin_number"
								value={
									user?.distributor?.distributor_onboarding
										?.tin_number
								}
								disabled
							/>
						</div>
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="cac_number"
								className="font-semibold text-[#001746]"
							>
								CAC Number
							</label>
							<input
								type="text"
								className="w-full rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="cac_number"
								id="cac_number"
								value={
									user?.distributor?.distributor_onboarding
										?.cac_number
								}
								disabled
							/>
						</div>
					</div>
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								className="font-semibold text-[#001746]"
								htmlFor="address"
							>
								Business Address
							</label>
							<input
								type="text"
								className="w-full rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="address"
								id="address"
								value={
									user?.distributor?.distributor_profile
										?.address
								}
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="flex pb-4">
				<div className="mt-6 flex-1">
					<h1 className="font-semibold text-gray-800">
						Proof of Business Address
					</h1>
					<p className="text-[14px] text-gray-400">
						Upload the company&apos;s proof of address as typed
						above
					</p>
				</div>
				<div className="flex-1">
					<div>
						<ProcessUploadFile
							formats={[
								"jpeg",
								"jpg",
								"png",
								"pdf",
								"ai",
								"doc",
								"docx",
								"ppt",
								"pptx",
							]}
							maxSize={2000000}
							setFile={setBusinessAddressUploadFile}
							localStorageFileName={"fileBusinessAddress"}
						/>
					</div>
				</div>
			</div> */}
			{/* <div className="flex justify-end py-12">
				<div className="flex gap-4">
					<button className="rounded-md border border-blue-950 bg-transparent px-12 py-[10px] text-blue-950">
						Cancel
					</button>
					<button className="rounded-md bg-blue-950 px-[20px] py-[10px] text-white">
						Save Changes
					</button>
				</div>
			</div> */}
		</>
	);
};

const BankAccountDetails = () => {
	const { user } = useAuth();
	const onboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;
	user?.distributor?.user_profile?.is_verified;
	const getAccountError = () => {
		Toast.error("Error fetching account details 🙂");
	};
	// const getAccountInfoQuery = useGetAccountInfo(undefined, getAccountError);
	const getPoolerWalletQuery = useGetPoolerWalletInfo(
		undefined,
		getAccountError,
		Boolean(onboardedUser && isVerifiedUser)
	);

	return (
		<>
			<div className="flex flex-col gap-y-4 py-4 md:flex-row">
				<div className="flex-1">
					<h1 className="font-semibold text-gray-800">Hadi Wallet</h1>
					<p className="max-w-[284px] text-[14px] text-gray-400">
						This is the account number to which all transactions
						would be made
					</p>
				</div>
				<div className="flex flex-1 flex-col gap-y-2">
					<div className="flex justify-between rounded-md border border-[#000] border-opacity-20 px-5 py-4">
						<div>
							<div className="flex items-center gap-2">
								<figure className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-[#002060] text-[12px] font-semibold text-white">
									{getPoolerWalletQuery.isFetched &&
									getPoolerWalletQuery.data?.data
										?.bank_name !== undefined
										? letter_profile_icon(
												getPoolerWalletQuery.data?.data
													?.bank_name
										  )
										: "H"}
								</figure>
								<h4 className="font-semibold text-blue-950">
									{getPoolerWalletQuery.isFetched &&
									getPoolerWalletQuery?.data?.data
										?.bank_name !== undefined
										? getPoolerWalletQuery?.data?.data
												?.bank_name
										: "N/A"}
								</h4>
							</div>
							<div className="px-8">
								<p className="text-gray-400">
									{" "}
									{getPoolerWalletQuery.isFetched &&
									getPoolerWalletQuery?.data?.data
										?.account_no !== undefined
										? getPoolerWalletQuery.data?.data
												?.account_no
										: "N/A"}
								</p>
								<h4 className="text-blue-950">
									{getPoolerWalletQuery.isFetched &&
									getPoolerWalletQuery?.data?.data
										?.display_name !== undefined
										? getPoolerWalletQuery?.data?.data
												?.display_name
										: "N/A"}
								</h4>
							</div>
						</div>
						{/* <div className="flex flex-col items-center justify-end gap-y-2">
							<button
								onClick={() => {
									setChecked(!checked);
								}}
							>
								<div
									className={`h-5 w-5 rounded-full border-2 border-gray-300 ${
										checked ? "bg-blue-950" : "bg-white"
									}`}
								></div>
							</button>
							<button className="text-[14px] text-blue-950">
								Edit
							</button>
						</div> */}
					</div>
				</div>
			</div>
			{/* <div className="flex min-h-full justify-end py-12">
				<div className="mt-24 flex gap-4">
					<button className="rounded-md border border-blue-950 bg-transparent px-12 py-[10px] text-blue-950">
						Cancel
					</button>
					<button className="rounded-md bg-blue-950 px-[20px] py-[10px] text-white">
						Save Changes
					</button>
				</div>
			</div> */}
		</>
	);
};

const PersonalDetails = ({ setAddressUploadFile }: PersonalProfileType) => {
	const { user } = useAuth();
	return (
		<>
			<div className="flex flex-col py-4 md:flex-row">
				<div className="flex-1">
					<h1 className="font-semibold text-gray-800">
						Personal Details
					</h1>
					{/* <p className="text-[14px] text-gray-400">
						This is where personal info such as name would be edited
					</p> */}
				</div>
				<div className="flex flex-1 flex-col gap-y-2">
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="full_name"
								className="font-semibold text-[#001746]"
							>
								Full Name
							</label>
							<input
								type="text"
								className="w-full cursor-default rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="full_name"
								id="full_name"
								value={
									user?.first_name + " " + user?.last_name ??
									"N/A"
								}
								disabled
							/>
						</div>
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="phone_number"
								className="font-semibold text-[#001746]"
							>
								Phone Number
							</label>
							<input
								type="text"
								className="w-full cursor-default rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="phone_number"
								id="phone_number"
								value={user?.username ?? "N/A"}
								disabled
							/>
						</div>
					</div>
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="email"
								className="font-semibold text-[#001746]"
							>
								Email Address
							</label>
							<input
								type="text"
								className="w-full cursor-default rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="email"
								id="email"
								value={user?.email ?? "N/A"}
								disabled
							/>
						</div>
						<div className="flex w-full flex-col gap-2">
							<label
								htmlFor="gender"
								className="font-semibold text-[#001746]"
							>
								Gender
							</label>
							<input
								type="text"
								className="w-full cursor-default rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="gender"
								id="gender"
								value={
									user?.user_profile.gender === "M"
										? "Male"
										: "Female"
								}
								disabled
							/>
						</div>
					</div>
					<div className="flex w-full flex-col gap-2 md:flex-row">
						<div className="flex w-full flex-col gap-2">
							<label
								className="font-semibold text-[#001746]"
								htmlFor="address"
							>
								Personal Address
							</label>
							<input
								type="text"
								className="w-full cursor-default rounded-md border border-gray-300 bg-[#fff] bg-opacity-50 p-[10px] text-[14px] font-semibold text-gray-400"
								name="address"
								id="address"
								value={user?.user_profile.address ?? "N/A"}
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="flex pb-4">
				<div className="mt-6 flex-1">
					<h1 className="font-semibold text-gray-800">
						Proof of Personal Address
					</h1>
					<p className="text-[14px] text-gray-400">
						Upload your personal proof of address as typed above.
					</p>
				</div>
				<div className="flex-1">
					<div>
						<ProcessUploadFile
							formats={[
								"jpeg",
								"jpg",
								"png",
								"pdf",
								"ai",
								"doc",
								"docx",
								"ppt",
								"pptx",
							]}
							maxSize={2000000}
							setFile={setAddressUploadFile}
							localStorageFileName={"filePersonalAddress"}
						/>
					</div>
				</div>
			</div> */}
			{/* <div className="flex justify-end py-12">
				<div className="flex gap-4">
					<button className="rounded-md border border-blue-950 bg-transparent px-12 py-[10px] text-blue-950">
						Cancel
					</button>
					<button className="rounded-md bg-blue-950 px-[20px] py-[10px] text-white">
						Save Changes
					</button>
				</div>
			</div> */}
		</>
	);
};
