import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ArrowLeft2, ArrowRight2, ElementPlus } from "iconsax-react";
import _ from "lodash";
import { RiWalletFill } from "react-icons/ri";
import { useNavigate } from "react-router";
import { DateRange } from "react-day-picker";
import { useAuth } from "../../hooks/auth";
import RequestIsLoading from "../../components/RequestIsLoading";
import { GetTrendIcon, getInitials } from "../../utils/GetTrendIcon";
import { BsChevronRight } from "react-icons/bs";
import NumberFormat from "../../utils/NumberFormat";
import { useOnboarding } from "../../provider/OnboardingProvider";
import NotificationContainer from "../../components/Dashboard/Notification";
import { useNotificationWebSocketContext } from "../../provider/NotificationProvider";
import { DashboardChart } from "../../components/Dashboard/DashboardHome/DashboardChart";
import RecentCreditHistory from "../../components/Dashboard/DashboardHome/RecentCreditTable";
import { useGetPoolerWalletInfo } from "../../hooks/useWalletData";
import {
	useGetDistributorMetrics,
	useGetDistributorDashboardChartData,
} from "../../hooks/useDashboardData";
import { Button } from "components";
import { StatusType } from "../../types/utils";
import { getAllDistributorCredit } from "../../api/credit";

const DashboardHome: React.FC = () => {
	const navigate = useNavigate();
	const { user, setUser } = useAuth();
	const { toggleOnboardingModal } = useOnboarding();
	const onboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;
	user?.distributor?.user_profile?.is_verified;
	const Username = user?.distributor && user?.distributor?.name;
	const [dashboardData, setDashboardData] = useState<any>({});
	const [dashboardChartData, setDashboardChartData] = useState<any>([]);
	const [transactionHitory, setTransactionHistory] = useState<any>([]);
	const [chartFilterYear, setChartFilterYear] = useState<any>(
		new Date().getFullYear()
	);

	const [creditData, setCreditData] = useState<any>([]);
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [dateRange, setDateRange] = useState<DateRange | undefined>({
		from: undefined,
		to: undefined,
	});
	const [datePicker, setDatePicker] = useState<Date>();

	const { notifications } = useNotificationWebSocketContext();

	const onError = () => {
		setUser(null);
		localStorage.removeItem("hadi_user");
	};

	//The useGetDashboarData is the one causing the problem
	const { data, isLoading, isSuccess, isError } = useGetDistributorMetrics(
		undefined,
		onError
	);

	const {
		data: transactionsData,
		isLoading: transactionsLoading,
		isError: transactionsError,
		isSuccess: transactionsSuccess,
	} = useQuery("distributor-credit", getAllDistributorCredit, {
		retry: 3,
		refetchInterval: false,
	});

	const prevChartYear = () => {
		setChartFilterYear(chartFilterYear - 1);
	};

	const nextChartYear = () => {
		setChartFilterYear(
			new Date().getFullYear() === chartFilterYear
				? chartFilterYear
				: chartFilterYear + 1
		);
	};

	const {
		data: chartData,
		isLoading: chartLoading,
		isSuccess: chartSuccess,
		isError: chartError,
	} = useGetDistributorDashboardChartData(
		chartFilterYear.toString(),
		undefined,
		onError
	);

	const getPoolerWalletQuery = useGetPoolerWalletInfo(
		undefined,
		undefined,
		Boolean(onboardedUser && isVerifiedUser)
	);

	const handleOnboardAuth = (callback: () => void) => {
		if (!onboardedUser || !isVerifiedUser) {
			toggleOnboardingModal();
			return;
		} else {
			callback();
		}
	};

	const handleRowClick = (id: any) => {
		null;
	};

	const uniqueStatuses = Array.from(
		new Set(creditData?.map((item: any) => item.status))
	) as StatusType[];

	const handleStatusSelect = (selectedStatus: StatusType) => {
		const filtered = creditData?.filter(
			(item: any) => item.status === selectedStatus
		);
		setFilteredData(filtered || []);
	};

	const handleClearFilter = () => {
		setFilteredData([]);
	};

	useEffect(() => {
		if (isSuccess && data) {
			setDashboardData(data?.data?.data);
			setCreditData(data?.data?.data?.recent_transactions ?? []);
		}
	}, [isSuccess, data]);

	useEffect(() => {
		if (chartSuccess && chartData) {
			setDashboardChartData(chartData?.data?.data);
		}
	}, [chartSuccess, chartData]);

	useEffect(() => {
		if (transactionsSuccess && transactionsData) {
			setTransactionHistory(transactionsData?.data?.data.slice(0, 6));
		}
	}, [transactionsSuccess, transactionsData]);

	return (
		<>
			<div className="h-full bg-[#F6F7F9]">
				{/* second section  */}
				<div className="flex flex-col gap-7 pb-5 lg:flex-row mlg:flex-col-reverse">
					{/* Graph section  */}
					<div className="flex-3 flex w-full flex-col gap-y-6">
						{/* Revenue card  */}
						<div className="flex flex-col gap-y-4">
							<article className="flex flex-1 flex-col divide-x divide-[#F0F0F0] rounded-[10px] bg-white py-6 drop-shadow-sm md:flex-row mmd:flex-1">
								<CreditInsight
									title={"Credit Issued"}
									amount={
										dashboardData.total_amount_credit_issued ??
										"N/A"
									}
									iconImage={GetTrendIcon(
										dashboardData?.percentage_increase ?? 0,
										"icon"
									)}
									footNote={GetTrendIcon(
										dashboardData?.percentage_increase ?? 0,
										"text"
									)}
									isLoading={isLoading}
									isFetched={isSuccess}
								/>
								<CreditInsight
									title="Credit Due"
									amount={
										dashboardData.credits_due_for_payment ??
										"N/A"
									}
									iconImage={GetTrendIcon(
										dashboardData?.percentage_due_increase ??
											0,
										"icon"
									)}
									footNote={GetTrendIcon(
										dashboardData?.percentage_due_increase ??
											0,
										"text"
									)}
									isLoading={isLoading}
									isFetched={isSuccess}
								/>
							</article>
						</div>
						{/* Chart section  */}
						<div className="flex flex-col gap-y-12 rounded-md bg-white p-6">
							<div className="flex items-center justify-between font-semibold text-[#002060]">
								<h1 className="text-base font-semibold text-[#1A202C]">
									Retailers Statistics
								</h1>
								<div className="flex items-center gap-6">
									<button
										className="rounded-md bg-[#F6F7F9] p-1.5 text-base font-bold transition-shadow hover:underline"
										onClick={prevChartYear}
									>
										<ArrowLeft2 size="12" color="#1A202C" />
									</button>
									<h1 className="text-base font-semibold text-[#1A202C]">
										{chartFilterYear}
									</h1>
									<button
										className="rounded-md bg-[#F6F7F9] p-1.5 text-base font-bold transition-shadow hover:underline"
										onClick={nextChartYear}
									>
										<ArrowRight2
											size="12"
											color="#1A202C"
										/>
									</button>
								</div>
							</div>
							<DashboardChart
								chartData={dashboardChartData}
								isLoading={chartLoading}
								chartFilterYear={chartFilterYear}
							/>
						</div>
						{/* Transaction card  */}
						<div className="flex flex-col gap-6 rounded-md bg-white py-5">
							{/* {dashboardData?.recent_transactions?.length > 0 ? ( */}
							<div className="flex  items-center justify-between px-6 font-semibold text-[#002060]">
								<h1 className="text-base font-semibold text-[#1A202C]">
									Transaction History
								</h1>
								{transactionHitory?.length > 0 && (
									<button
										className="text-base font-semibold transition-shadow hover:underline"
										onClick={() =>
											navigate("/dashboard/credit-book")
										}
									>
										View More
									</button>
								)}
							</div>
							<RecentCreditHistory
								uniqueStatuses={uniqueStatuses}
								handleClearFilter={handleClearFilter}
								handleStatusSelect={handleStatusSelect}
								handleRowClick={handleRowClick}
								filteredData={filteredData}
								creditData={transactionHitory}
								dataFetching={isLoading}
								isSuccess={isSuccess}
								isError={isError}
								dateRange={dateRange}
								setDateRange={setDateRange}
								setDatePicker={setDatePicker}
								datePicker={datePicker}
								tableTitle={"Transaction History"}
								emptyState={() =>
									handleOnboardAuth(() => {
										navigate(
											"/dashboard/credit-application"
										);
									})
								}
							/>
						</div>
					</div>
					{/* ===== Card section ===== */}
					<div className="flex-2">
						<div className="sticky top-[116px] flex w-full flex-col justify-between gap-x-8 gap-y-6 md:flex-row lg:w-[340px] lg:flex-col">
							{!onboardedUser && (
								<div className="relative flex h-full w-full flex-col gap-y-6 overflow-hidden rounded-md bg-primary-600 p-6 text-left text-white">
									<div className="bg-hadiLogo absolute -right-[160px] -top-[61px] z-10 h-20 w-full -rotate-45 bg-contain bg-right-top bg-no-repeat bg-blend-color"></div>
									<div className="relative z-20 flex flex-col justify-evenly gap-x-6 gap-y-2  md:justify-center">
										<h2 className="flex items-center justify-start text-sm font-bold">
											{`Hello ${
												user?.distributor?.name ?? "N/A"
											}`}
											<span className="ml-2">
												<svg
													width="13"
													height="14"
													viewBox="0 0 13 14"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M3.51946 3.94772L1.98754 2.96616C1.85537 2.88491 1.8337 2.70182 1.94312 2.59239L2.5184 2.01711C2.62891 1.90661 2.81525 1.93044 2.89434 2.06587L3.85206 3.6227C3.97773 3.83721 3.7318 4.07772 3.51946 3.94772Z"
														fill="#FFC107"
													/>
													<path
														d="M2.17925 8.66999L0.400326 8.70574C0.245401 8.70899 0.134895 8.85633 0.173897 9.00584L0.380825 9.79238C0.420911 9.94406 0.59317 10.0166 0.729677 9.94081L2.30168 9.11851C2.51836 8.99609 2.42843 8.66457 2.17925 8.66999Z"
														fill="#FFC107"
													/>
													<path
														d="M6.31069 11.6916L5.92067 13.4239C5.88492 13.5745 5.99868 13.7197 6.1536 13.7197H6.96723C7.12432 13.7197 7.23808 13.5713 7.19907 13.4196L6.77547 11.6873C6.71263 11.4457 6.3692 11.4489 6.31069 11.6916Z"
														fill="#FFC107"
													/>
													<path
														d="M9.48591 3.94772L11.0178 2.96616C11.15 2.88491 11.1717 2.70182 11.0622 2.59239L10.487 2.01711C10.3765 1.90661 10.1901 1.93044 10.111 2.06587L9.15331 3.6227C9.02764 3.83721 9.27357 4.07772 9.48591 3.94772Z"
														fill="#FFC107"
													/>
													<path
														d="M10.8229 8.66999L12.6018 8.70574C12.7568 8.70899 12.8673 8.85633 12.8283 9.00584L12.6213 9.79238C12.5812 9.94406 12.409 10.0166 12.2725 9.94081L10.7005 9.11851C10.4838 8.99609 10.5737 8.66457 10.8229 8.66999Z"
														fill="#FFC107"
													/>
													<path
														d="M6.93894 0.74277L8.39719 4.06878C8.50878 4.32338 8.75037 4.4978 9.02772 4.52272L12.5802 4.84124C12.9821 4.89974 13.1424 5.39268 12.851 5.67653L10.175 7.92457C9.95835 8.10658 9.85977 8.39259 9.91935 8.66886L10.6972 12.3101C10.7655 12.7099 10.3462 13.0154 9.98652 12.8258L6.88586 11.0101C6.64751 10.8703 6.35283 10.8703 6.11448 11.0101L3.01382 12.8247C2.65522 13.0133 2.23486 12.7088 2.30311 12.3091L3.08099 8.66777C3.13949 8.39151 3.04199 8.1055 2.82531 7.92349L0.14825 5.67762C-0.142099 5.39485 0.0182426 4.90082 0.419097 4.84232L3.97154 4.5238C4.24889 4.49889 4.49048 4.32446 4.60207 4.06986L6.06031 0.743853C6.24124 0.379834 6.7591 0.379834 6.93894 0.74277Z"
														fill="#FDD835"
													/>
													<path
														d="M6.83184 4.26842L6.58483 1.81779C6.57508 1.68128 6.54691 1.44727 6.76575 1.44727C6.9391 1.44727 7.03335 1.80803 7.03335 1.80803L7.77439 3.77547C8.05391 4.5241 7.93907 4.78086 7.6693 4.93253C7.35945 5.10588 6.90226 4.97045 6.83184 4.26842Z"
														fill="#FFFF8D"
													/>
													<path
														d="M9.88979 7.70509L12.0154 6.04642C12.1205 5.95867 12.3101 5.81891 12.1584 5.65965C12.0382 5.53398 11.7131 5.71491 11.7131 5.71491L9.85296 6.44186C9.29826 6.63362 8.92991 6.91747 8.89741 7.27499C8.85515 7.75168 9.28309 8.11895 9.88979 7.70509Z"
														fill="#F4B400"
													/>
												</svg>
											</span>
										</h2>
										<p className="w-[80%] text-[9px] font-normal text-primary-100">
											Kindly complete your onboarding to
											access credit for you and your
											retailers
										</p>
									</div>
									<Button
										disabled={false}
										additionalClasses="w-content h-auto bg-transparent text-[10px] font-semibold text-white underline !w-auto !justify-start"
										onClick={() => toggleOnboardingModal()}
									>
										Complete Onboarding
										<span className="ml-2">
											<BsChevronRight />
										</span>
									</Button>
								</div>
							)}

							<div className="relative flex h-full w-full flex-col rounded-md bg-white p-6 text-left text-[#1A202C]">
								<div className="mb-[21px] flex flex-col gap-y-2">
									<h2 className="font-semibold ">
										Number of Credit Issued
									</h2>
									<h3 className="text-3xl font-bold">
										{dashboardData?.total_number_of_credit_issued ??
											"N/A"}
									</h3>
								</div>
								<div className="flex w-full items-center justify-center">
									<hr className="w-[90%] text-[#F0F0F0]" />
								</div>
								<div className="mt-[21px] flex flex-col gap-y-2">
									<h2 className="font-semibold ">
										Retailers with Active Credit
									</h2>
									<h3 className="text-3xl font-bold">
										{dashboardData?.total_active_credits ??
											"N/A"}
									</h3>
								</div>
							</div>

							<div className="flex h-full w-full flex-col gap-y-7 rounded-md bg-white px-8  py-6 text-center">
								<div className="flex flex-row justify-evenly gap-x-6 gap-y-2 md:flex-col md:justify-center ">
									<div className="mt-0 flex flex-col gap-2">
										<div className="mx-auto flex h-[60px] w-[60px] items-center justify-center rounded-[100%] bg-[#002060] text-xl font-semibold text-white">
											<span>{getInitials(Username)}</span>
										</div>
										<div>
											<p className="text-base font-semibold">
												{Username ?? "N/A"}
											</p>
										</div>
									</div>
								</div>
								<div className="flex justify-between gap-y-4 md:text-left">
									<div className="flex flex-1 flex-col text-left">
										<p className="text-[12px] text-[#667085]">
											Account Name
										</p>
										<p className="text-[14px] font-semibold text-[#002060]">
											{(user?.distributor &&
												user?.distributor?.name) ??
												"N/A"}
										</p>
									</div>
									{/* <div className="flex flex-col">
										<p className="text-[12px] text-[#667085]">
											Bank Name
										</p>
										<p className="pl-2 text-[15px] font-semibold text-[#002060]">
											{getPoolerWalletQuery.data?.data
												?.bank_name ?? "N/A"}
										</p>
									</div> */}
									<div className="flex flex-1 flex-col text-right">
										<p className="text-[12px] text-[#667085]">
											Account Number
										</p>
										<p className="text-[14px] font-semibold text-[#002060]">
											{getPoolerWalletQuery?.data?.data
												?.account_no ?? "N/A"}
										</p>
									</div>
								</div>
								<div className="mt-auto flex items-center justify-center">
									{/* <button
									className="flex w-full items-center justify-center gap-2 rounded-md bg-[#25E2AF] px-4 py-1 text-white"
									onClick={() =>
										handleOnboardAuth(() => {
											navigate(
												"/dashboard/credit-application"
											);
										})
									}
								>
									<MdDashboardCustomize />
									Apply for Credit
								</button> */}

									<Button
										disabled={false}
										additionalClasses="flex px-4 py-3 !w-auto items-center justify-center h-auto !bg-primary-600 text-[12px] font-semibold text-white"
										onClick={() =>
											handleOnboardAuth(() => {
												navigate(
													"/dashboard/credit-application"
												);
											})
										}
									>
										<ElementPlus
											size="18"
											className="mr-3"
										/>
										Apply for Credit
									</Button>
								</div>
							</div>
							{/* Second Section  */}
							<NotificationContainer data={notifications} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardHome;

interface HadiPayInsightProps {
	title: string;
	amount: string;
	isLoading: boolean;
	isFetched: boolean;
	iconImage?: React.ReactNode;
	footNote?: React.ReactNode;
}

const CreditInsight: React.FC<HadiPayInsightProps> = ({
	title,
	amount,
	isLoading,
	isFetched,
	iconImage,
	footNote,
}) => {
	return (
		<div className="relative flex min-h-[120px] flex-1 flex-col bg-white px-6">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between">
					<h4 className="text-base font-semibold text-[#1A202C]">
						{title}
					</h4>
					<figure className="self-end rounded-[8px] bg-[#F6F7F9] p-2.5">
						{iconImage ? iconImage : <RiWalletFill />}
					</figure>
				</div>
				<p
					className={`text-[32px] font-bold text-blue-950 msm:flex msm:flex-col ${
						isLoading && "py-3.5"
					}`}
				>
					{isFetched && amount !== undefined ? (
						<>
							<NumberFormat
								className="text-[#1A202C]"
								value={amount}
							/>
							<span className="ml-[12px] text-[13px] text-[#90A3BF] msm:self-end">
								(NGN)
							</span>
						</>
					) : (
						"N/A"
					)}
				</p>
				{footNote ? footNote : ""}
			</div>
			<RequestIsLoading isLoading={isLoading} />
		</div>
	);
};
