import { OTPInputProps } from "types/components/";
import React from "react";

export const CreditOTP: React.FC<OTPInputProps> = ({
	otpCode,
	otpRef,
	onChange,
}) => {
	return (
		<input
			className="text-darkblue-300 flex h-[56px] min-h-[24px] min-w-[24px] max-w-[55px] items-center rounded-2xl border border-[#AAB2C3] text-center text-3xl text-[#002060] caret-white selection:bg-transparent"
			value={otpCode}
			maxLength={1}
			onChange={onChange}
			ref={otpRef}
			onFocus={(e) => {
				e.target.select();
			}}
			onClick={() => {
				if (otpRef && otpRef.current) {
					otpRef.current.select();
				}
			}}
			inputMode="numeric"
			onKeyDown={handleKeyPress}
		/>
	);
};

const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const keyValue = e.key;
	// Only allow numeric input (0-9)
	if (parseInt(keyValue, 10) || keyValue !== "Backspace") {
		if (isNaN(Number(keyValue)) && keyValue !== "Backspace") {
			e.preventDefault();
		} else {
			return true;
		}
	}
};
