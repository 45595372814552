import { useMutation, useQuery, useQueryClient } from "react-query";
import { logDev } from "../utils/utilities";
import {
	getAllNotifications,
	getAllReadNotifications,
	getAllUnreadNotifications,
	getBusinessSectors,
	getManufacturers,
	getDashboardData,
	getRetailerLoanDetails,
	onBoardDistributor,
	readNotification,
	registerRetailer,
	reonBoardDistributor,
	searchRetailerDetails,
	searchRetailerDetailsGet,
} from "../api/customer";
import { AxiosError, AxiosResponse } from "axios";
import { useAuth } from "./auth";
import { updateStoreDetails } from "../api/credit";
import {
	NotificationWebSocketContextProps,
	useNotificationWebSocketContext,
} from "../provider/NotificationWebSocketProvider";
import { Dispatch, SetStateAction } from "react";

// export const useGetCustomer = (onSuccess: (data: any) => void) => {
// 	const { acToken, rfToken } = useContext(UserContext);

// 	return useQuery<any, Error>(["customer", acToken], fetchCustomer, {
// 		onSuccess: (data: any) => {
// 			onSuccess(data);
// 		},
// 		onError: (error: any) => {
// 			logDev(error);
// 		},
// 		enabled: !!rfToken,
// 		// keepPreviousData: true,
// 		// staleTime: 5000,
// 	});
// };

export const useGetDashboardData = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void
) => {
	return useQuery("dashboardData", getDashboardData, {
		onSuccess,
		onError,
		retry: 4,
		refetchInterval: 300000,
	});
};

export const useOnBoardDistributor = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (error: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(onBoardDistributor, {
		onSuccess: async (data: AxiosResponse) => {
			logDev(data);
			onSuccess(data);
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			logDev(error);
		},
	});

	return { mutateOnBoardDistributor: mutate, ...rest };
};
export const useReOnBoardDistributor = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (error: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(reonBoardDistributor, {
		onSuccess: async (data: AxiosResponse) => {
			logDev(data);
			onSuccess(data);
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			logDev(error);
		},
	});

	return { mutateReOnBoardDistributor: mutate, ...rest };
};

export const useSearchRetailerDetailQuery = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(
		// "search_retailer_detail",
		searchRetailerDetails,
		{
			onSuccess,
			onError,
		}
	);
	return { mutateSearchRetailerDetail: mutate, ...rest };
};

export const useSearchRetailerDetailGetQuery = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(searchRetailerDetailsGet, {
		onSuccess,
		onError,
	});
	return { mutateSearchRetailerDetailGet: mutate, ...rest };
};

// export const useSearchRetailerDetailGetQuery = (
// 	phone_number: string,
// 	onSuccess: (data: AxiosResponse) => void,
// 	onError: (data: AxiosResponse) => void
// ) => {
// 	return useQuery("retailer_search", searchRetailerDetailsGet(phone_number), {
// 		refetchInterval: Infinity,
// 		onSuccess,
// 		onError,
// 	});
// };

// export const useSearchRetailerDetailGetQuery = (
// 	phone_number: string,
// 	onSuccess: (data: any) => void,
// 	onError: (error: Error) => void
// ) => {
// 	return useQuery(
// 		"retailer_search",
// 		() => searchRetailerDetailsGet(phone_number),
// 		{
// 			refetchInterval: Infinity,
// 			onSuccess,
// 			onError,
// 		}
// 	);
// };

// export const useSearchRetailerDetailGetQuery = (
// 	phone_number: string,
// 	onSuccess: (data: AxiosResponse) => void,
// 	onError: (data: AxiosError) => void
// ) => {
// 	return useQuery(
// 		["guarantor-retailer-verify", phone_number],
// 		searchRetailerDetailsGet,
// 		{
// 			onSuccess: (data) => onSuccess(data),
// 			onError: (data: AxiosError) => onError(data),
// 			enabled: false,
// 			retry: 2,
// 		}
// 	);
// };

export const useRegisterRetailer = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(registerRetailer, {
		onSuccess,
		onError,
	});
	return { mutateRegisterRetailer: mutate, ...rest };
};

export const useUpdateStoreDetails = (
	onSuccess: () => void,
	onError: () => void
) => {
	const { mutate, ...rest } = useMutation(updateStoreDetails, {
		onSuccess,
		onError,
	});
	return { mutateUpdateStoreDetails: mutate, ...rest };
};

export const useGetBusinessSectors = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	return useQuery("business_sectors", getBusinessSectors, {
		onSuccess,
		onError,
	});
};

export const useGetManufacturers = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	return useQuery("manufacturers", getManufacturers, {
		onSuccess,
		onError,
	});
};

export const useGetAllNotifications = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void
) => {
	return useQuery("all_notifications", getAllNotifications, {
		onSuccess,
		onError,
	});
};

export const useGetReadNotifications = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void
) => {
	return useQuery("seen_notifications", getAllReadNotifications, {
		onSuccess,
		onError,
	});
};

export const useGetUnreadNotifications = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void,
	setUnreadNotificationCount?: Dispatch<SetStateAction<string>>,
	enabled = true
) => {
	return useQuery("unseen_notifications", getAllUnreadNotifications, {
		onSuccess: (data: any) => {
			localStorage.setItem(
				"unread_notifications_count",
				JSON.stringify({
					// ...user,
					unread_notifications_count: data?.data?.data?.length,
				})
			);

			if (setUnreadNotificationCount)
				setUnreadNotificationCount(data?.data?.data?.length);

			onSuccess(data);
		},
		onError,
		enabled,
	});
};

export const useReadNotificaion = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void
) => {
	// const queryClient = useQueryClient();
	const mutation = useMutation(readNotification, {
		onSuccess,
		onError,
	});
	return mutation;
};

export const useGetRetailerLoanDetails = (data: string | undefined) => {
	return useQuery(
		["retailer_loan_accept_details", data],
		getRetailerLoanDetails,
		{
			retry: 3,
		}
	);
};

// export const useLinkToCRM = (onSuccess: any, onError: any) => {
// 	return useQuery("link_quickbooks", linkQuickbooks, {
// 		enabled: false,
// 		onSuccess: (response: AxiosResponse) => {
// 			onSuccess(response);
// 		},
// 		onError: (response: AxiosResponse) => {
// 			onError(response);
// 		},
// 	});
// };
