import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { Button, Loader2 } from "../../../components";
import { SuccessModal } from "../../../components/modals/SuccessModal";
import { UpdateTransactionModal } from "../../../components/modals/UpdateTransactionModal";
import CancelRetailerCreditModal from "../../../components/modals/CancelRetailerCreditModal";
// import { useGetRetailerLoanDetails } from "../../../hooks/useCustomerData";
import { formatDate } from "../../../utils/utilities";
import { useDistributorConfirmCredit } from "../../../hooks/useCreditData";
import { ErrorModal } from "../../../components/modals/ErrorModal";
import { getRetailerLoanDetails } from "../../../api/customer";
import { useQuery } from "react-query";

const Transaction = () => {
	const [newData, setnewData] = useState<any>();
	const [showApprovalSuccessModal, setShowApprovalSuccessModal] =
		useState<boolean>(false);
	const [showApprovalErrorModal, setShowApprovalErrorModal] =
		useState<boolean>(false);
	const [showloanCancellationModal, setShowLoanCancellationModal] =
		useState<boolean>(false);
	const [showEditLoanModal, setShowEditLoanModal] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();
	const credit_id = location?.pathname.split("/").pop();

	const goBack = () => {
		navigate("/dashboard/credit-book/transactions");
	};

	const handleLoanApprovalSuccess = () => {
		setShowApprovalSuccessModal(true);
		setShowEditLoanModal(false);
		setTimeout(() => {
			navigate("/dashboard/credit-book/transactions");
		}, 4000);
	};
	const handleLoanApprovalError = () => {
		setShowApprovalErrorModal(true);
		setShowEditLoanModal(false);
	};
	const { mutateDistributorConfirmCredit, isLoading: approvalLoading } =
		useDistributorConfirmCredit(
			handleLoanApprovalSuccess,
			handleLoanApprovalError
		);

	const handleLoanApproval = () => {
		const data = {
			credit_id: credit_id,
			command: "APPROVE",
		};
		mutateDistributorConfirmCredit(data);
	};

	const handleLoanUpdateAndApproval = (updatedAmount: number) => {
		const data = {
			credit_id: credit_id,
			command: "APPROVE",
			amount: updatedAmount,
		};
		mutateDistributorConfirmCredit(data);
	};

	const handleLoanCancel = () => {
		setShowLoanCancellationModal(true);
	};

	const handleLoanEdit = () => {
		setShowEditLoanModal(true);
	};

	const {
		data: loanData,
		isLoading,
		isError,
		isSuccess,
	} = useQuery(
		["retailers_loan_approve_details", credit_id],
		getRetailerLoanDetails,
		{
			retry: 3,
			refetchInterval: 300000,
		}
	);

	// const {
	// 	data: loanData,
	// 	isLoading,
	// 	isSuccess,
	// 	isError,
	// } = useGetRetailerLoanDetails(credit_id);

	useEffect(() => {
		if (typeof credit_id === "undefined") {
			navigate("/dashboard");
		}
	}, [credit_id, navigate]);

	useEffect(() => {
		if (isError) {
			setTimeout(() => {
				navigate("/dashboard/credit-book/transactions");
			}, 3500);
		}
	}, [isError, navigate]);

	// console.log(loanData);

	useEffect(() => {
		if (loanData && isSuccess) {
			setnewData(loanData?.data.data);
		}
	}, [loanData, isSuccess]);
	return (
		<>
			<button className="flex items-center gap-2" onClick={goBack}>
				<BiArrowBack />
				<p>Back To Transactions</p>
			</button>
			<section className="mb-10 mt-6">
				{loanData && (
					<div className="flex flex-col gap-4 rounded-md border p-6">
						<div className="flex justify-between">
							<h1 className="text-2xl font-semibold">
								{newData?.retailer_name}
							</h1>
							<div>
								<Button
									disabled={false}
									onClick={handleLoanEdit}
									additionalClasses="bg-transparent text-[#002EA9] font-[400] w-auto-important"
								>
									Edit
								</Button>
							</div>
						</div>
						<div className="flex justify-between">
							<div className="flex flex-col gap-4 ">
								<p>
									Amount: NGN{" "}
									{newData?.amount.toLocaleString() ?? "N/A"}
								</p>
								<p>
									Loan offer: NGN{" "}
									{newData?.credit_offer ?? "N/A"}
								</p>
								<p>Status: {newData?.status}</p>
							</div>
							<div className="flex flex-col gap-4">
								<p>
									Date Issued:{" "}
									{newData?.created
										? formatDate(newData?.created)
										: "N/A"}
								</p>
								<p>
									Repayment Amount:{" "}
									{newData?.repayment_amount ?? "N/A"}
								</p>
							</div>
						</div>
						<div className="ml-auto flex w-[40%] gap-4">
							<Button
								disabled={approvalLoading}
								onClick={handleLoanApproval}
								additionalClasses="text-white w-fit flex items-center justify-center"
							>
								{approvalLoading ? <Loader2 /> : "Approve"}
							</Button>
							<Button
								disabled={false}
								onClick={handleLoanCancel}
								additionalClasses="bg-transparent border rounded-md w-fit "
							>
								Cancel
							</Button>
						</div>
					</div>
				)}

				{isLoading && (
					<div className={`flex h-full`}>
						<div className="mx-auto mt-12 h-full scale-[2]">
							<Loader2 color="#061F6A" />
						</div>
					</div>
				)}

				{isError && (
					<>
						<div>
							<h1>An error occurred while viewing the page</h1>
						</div>
					</>
				)}
			</section>
			<CancelRetailerCreditModal
				show={showloanCancellationModal}
				setShow={setShowLoanCancellationModal}
				data={newData}
			/>
			<SuccessModal
				show={showApprovalSuccessModal}
				setShow={setShowApprovalSuccessModal}
				content={"The Loan was successfully approved"}
			/>
			<ErrorModal
				show={showApprovalErrorModal}
				setShow={setShowApprovalErrorModal}
				content={"This loan update request was not successful"}
			/>
			<UpdateTransactionModal
				show={showEditLoanModal}
				setShow={setShowEditLoanModal}
				loanData={newData}
				handleLoanUpdateAndApproval={handleLoanUpdateAndApproval}
				isLoading={approvalLoading}
			/>
		</>
	);
};

export default Transaction;
