import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from "react";
import { FormInput } from "../FormInput";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { Overlay } from "./Overlay";
import { slideDownAnimation } from "../../services/Animations";
import { useInstallmentPayment } from "../../hooks/useRepaymentData";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../utils/toast";
import { Loader2 } from "../Loaders/index";

interface Paymentmodalprops {
	show: boolean;
	isPaymentSuccessful: boolean;
	repaymentID: string;
	setShow: Dispatch<SetStateAction<boolean>>;
	setIsPaymentSuccessful: Dispatch<SetStateAction<boolean>>;
	walletBalance: number;
}

export const PaymentModal: React.FC<Paymentmodalprops> = ({
	show,
	repaymentID,
	setIsPaymentSuccessful,
	setShow,
	walletBalance,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [formData, setFormData] = useState<{
		amount: number;
	}>({
		amount: null,
	});
	const [rawInput, setRawInput] = useState("");

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (formDataValidation()) {
			mutatePayInstallment(formData);
		}
	};

	const formDataValidation = () => {
		if (!formData.amount) {
			Toast.error("Kindly enter your amount");
			return false;
		}
		return true;
	};

	const onSuccess = () => {
		setShow(false);
		setTimeout(() => {
			setIsPaymentSuccessful(true);
		}, 1000);
	};

	const onError = (error: any) => {
		Toast.error(error?.response?.data?.message || "Something went wrong");
	};

	const { isLoading, mutatePayInstallment } = useInstallmentPayment(
		onSuccess,
		onError,
		repaymentID
	);

	useEffect(() => {
		setFormData({ amount: null });
		setRawInput("");
	}, [show]);

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-4 flex h-fit w-full max-w-[536px] flex-col items-center rounded-[10px] bg-white p-4 sm:m-10 sm:px-8 sm:py-6"
				>
					<div className="flex w-full justify-end">
						<button
							className="rounded-full p-2 text-[1.5em] duration-200 hover:bg-black hover:bg-opacity-20"
							onClick={() => {
								setShow(!show);
							}}
						>
							<MdClose />
						</button>
					</div>

					<section className="flex w-full flex-col items-center">
						<div className="flex w-full flex-col items-center justify-center">
							<h3 className="text-[28px] font-semibold text-blue-950">
								Instalment
							</h3>
							<p className="text-center text-[16px] font-[500] text-slate-400">
								Confirm your instalment payment details
							</p>
						</div>
						<form
							className="mt-[16px] w-full space-y-4"
							onSubmit={handleSubmit}
						>
							<FormInput
								label="Amount"
								name="amount"
								id="amount"
								type="text"
								placeholder="Enter amount"
								autoComplete={"false"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none"
								required={true}
								// value={formData.amount}
								value={rawInput
									?.toString()
									.replace(/\D/g, "")
									.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								onChange={(e) => {
									const value = e.target.value.replace(
										/\D/g,
										""
									);
									// const formattedValue = value.replace(
									// 	/\B(?=(\d{3})+(?!\d))/g,
									// 	","
									// );

									// setFormData({
									// 	amount: formattedValue,
									// });
									if (
										value.length > 1 &&
										value.startsWith("0")
									) {
										setRawInput(
											e.target.value.substring(1)
										);
										return;
									}

									if (value === "") {
										setFormData({
											...formData,
											amount: 0,
										});
									} else {
										const formattedValue = value.replace(
											/\B(?=(\d{3})+(?!\d))/g,
											","
										);
										const amount = parseInt(value, 10);

										if (amount > walletBalance) {
											return Toast.info(
												"Insufficient funds to complete transaction"
											);
										}

										setFormData({
											...formData,
											amount: amount,
										});

										setRawInput(e.target.value);
									}
								}}
								// }}
							/>
						</form>

						<Button
							additionalClasses="flex max-w-[418px] items-center justify-center rounded-md bg-[#002060] py-2 my-3 sm:my-4 text-sm text-white"
							onClick={handleSubmit}
							disabled={isLoading}
						>
							{isLoading ? <Loader2 /> : "Confirm"}
						</Button>
					</section>
				</motion.section>
			)}
		</Overlay>
	);
};
