import { Button, FormInput, ProcessUploadFile, UploadedFile } from "components";
import {
	RetailerCreditAuthorizationPageProps,
	RetailerCreditAuthorizationFormProps,
} from "@/types/views";
import { SetStateAction } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { uploadedFileContainerVariants } from "services/Animations";
import { Toast } from "utils/toast";
import { IDType } from "services/Constants";

interface RetailerUploadProps extends RetailerCreditAuthorizationPageProps {
	formData: RetailerCreditAuthorizationFormProps;
	setFormData: React.Dispatch<
		SetStateAction<RetailerCreditAuthorizationFormProps>
	>;
}

const RetailerDetailsForm2 = ({
	formData,
	setFormData,
	setPage,
}: RetailerUploadProps) => {
	const validateRetailerUploadForm = (
		formData: RetailerCreditAuthorizationFormProps
	) => {
		if (!formData.id_image) {
			Toast.info("Upload a valid id");
			return false;
		} else if (!formData.id_number) {
			Toast.info("Enter the id no contained in the uploaded document");
			return false;
		} else if (!formData.id_type) {
			Toast.info("Select your type of id");
			return false;
		} else if (!formData.address_document) {
			Toast.info("Upload a utility bill document");
			return false;
		}
		return true;
	};
	const handleNextPage = () => {
		if (!validateRetailerUploadForm(formData)) {
			return;
		}
		setPage(3);
	};
	return (
		<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
			<div className="rounded-md bg-[#FFDAD6] p-2">
				<ul className="list-disc px-5 py-2">
					<li className="text-[12px] md:text-[15px]">
						Please enter correct personal details before authorising
						the credit application.
					</li>
					<li className="text-[12px] md:text-[15px]">
						{" "}
						You must be a working adult above the age of 18 yrs.
					</li>
				</ul>
			</div>
			<div className="space-y-5 py-4">
				<FormInput
					label="Identification Type"
					name="id_type"
					id="id_type"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={IDType}
					value={formData.id_type}
					placeholder="Select from drop-down"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							id_type: selectedOption.value,
						})
					}
				/>
				<FormInput
					label="Identification Number"
					name="id_no"
					id="id_no"
					labelStyles="text-[16px] font-medium"
					value={formData.id_number}
					placeholder="Enter Corresponding Id No of uploaded document"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					maxLength={19}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const inputValue = e.target.value;
						// Check if the input value exceeds 20 characters
						if (inputValue.length <= 19) {
							// Update the form data if the input length is within the limit
							setFormData({
								...formData,
								id_number: inputValue,
							});
						}
					}}
				/>
				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className="relative text-[16px] font-[500] text-blue-950"
					>
						Valid Means of Identification
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</label>
					<p className="text-sm text-slate-600">
						Upload a valid means of Identification
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5000Kb
					setFile={setFormData}
					localStorageFileName={"id_image"}
				/>
				<AnimatePresence>
					{formData.id_image && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData.id_imageName}
								deleteFile={() => {
									setFormData((prevData) => ({
										...prevData,
										id_image: null,
									}));
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>

				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className="relative text-[16px] font-[500] text-blue-950"
					>
						Store Utility Bill
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</label>
					<p className="text-sm text-slate-600">
						Upload an image or document of your utility bill
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5000Kb
					setFile={setFormData}
					localStorageFileName={"address_document"}
				/>
				<AnimatePresence>
					{formData.address_document && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData.address_documentName}
								deleteFile={() => {
									setFormData((prevData) => ({
										...prevData,
										address_document: null,
									}));
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
				<Button
					additionalClasses=" w-fit text-white"
					disabled={false}
					onClick={handleNextPage}
				>
					Next
				</Button>
				<button
					className="text-sm font-semibold text-blue-900"
					onClick={() => setPage(3)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};

export default RetailerDetailsForm2;
