export const ProcessEnterAnimation = {
	hidden: {
		opacity: 0,
		x: "100vw",
	},
	visible: {
		opacity: 1,
		x: 0,
		transition: { type: "spring", delay: 0.5 },
	},
	exit: {
		x: "-100vh",
		transition: { ease: "easeInOut" },
	},
};
export const ProcessTransitionAnimation = {
	// hidden: { opacity: 0, y: -20 },
	// visible: { opacity: 1, y: 0 },
	// exit: { opacity: 0, y: -20 },
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { type: "spring", delay: 0.2 } },
};

export const uploadedFileContainerVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: -20 },
};

export const opacityAnimation = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: { delay: 0.5 },
	},
	exit: { opacity: 0 },
};

export const slideDownAnimation = {
	hidden: {
		opacity: 0,
		y: "-30px",
	},
	visible: {
		opacity: 1,
		y: "0",
	},
	exit: {
		opacity: 0,
		y: "-30px",
	},
	transition: { duration: 0.3, type: "tween", ease: "easeInOut" },
};
