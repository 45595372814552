import { cn } from "../../utils/utilities";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { format } from "date-fns";

import { Calendar as CalendarIcon } from "iconsax-react";

interface DatePickerProps<TData> {
	data: TData[];
	datePicker: Date | undefined;
	setDatePicker: React.Dispatch<React.SetStateAction<Date | undefined>>;
	dateFieldName: string;
}
export function DatePicker<TData>({
	datePicker,
	setDatePicker,
	data,
	dateFieldName,
}: DatePickerProps<TData>) {
	const earliestYear = Math.min(
		...data.map((item: any) =>
			new Date(
				item?.[dateFieldName]?.replace(/(\d+)(st|nd|rd|th)/, "$1")
			).getFullYear()
		)
	);
	const oldestYear = Math.max(
		...data.map((item: any) =>
			new Date(
				item?.[dateFieldName]?.replace(/(\d+)(st|nd|rd|th)/, "$1")
			).getFullYear()
		)
	);
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"w-[140px] justify-start text-left font-normal",
						!datePicker && "text-[#1A202C]"
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{datePicker ? (
						format(datePicker, "MMM d, yyyy")
					) : (
						<span>Pick a date</span>
					)}{" "}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<Calendar
					mode="single"
					selected={datePicker}
					onSelect={setDatePicker}
					initialFocus
					fromYear={earliestYear}
					toYear={oldestYear}
				/>
			</PopoverContent>
		</Popover>
	);
}
