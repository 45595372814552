import { useState } from "react";
import { TopBar } from "../../../components";
import { ChangePasswordModal } from "../../../components/modals/ChangePasswordModal";
import Account from "./Account";
import ERPLink from "./ERPLink";

const Settings = () => {
	const [openChangePasswordModal, setOpenChangePasswordModal] =
		useState<boolean>(false);

	const [tab, setTab] = useState(0);
	const handleChangeTab = (tabName: string) => {
		switch (tabName) {
			case "erp":
				setTab(0);
				break;
			case "account":
				setTab(1);
				break;
			default:
				setTab(0);
		}
	};

	return (
		<>
			<TopBar
				pageName="Settings"
				pageLead="Need to speak with us? we are here to support you!"
			/>
			<div className="ml-4 flex gap-4 border-b md:ml-8 ">
				<div className="mt-2 flex cursor-pointer gap-4 ">
					<button
						className={`p-2 ${
							tab === 0 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("erp")}
					>
						ERP
					</button>

					<button
						className={`p-2 ${
							tab === 1 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("account")}
					>
						Account
					</button>
				</div>
			</div>
			{tab === 0 && <ERPLink />}
			{tab === 1 && (
				<Account
					setOpenChangePasswordModal={setOpenChangePasswordModal}
					openChangePasswordModal={openChangePasswordModal}
				/>
			)}
			<ChangePasswordModal
				show={openChangePasswordModal}
				setShow={setOpenChangePasswordModal}
			/>
		</>
	);
};

export default Settings;
