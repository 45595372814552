import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { motion } from "framer-motion";
import Overlay from "./Overlay";
import { slideDownAnimation } from "../../services/Animations";
import { Button } from "../Button";
import { Loader2 } from "../Loaders";
import SuccessImg from "../../assets/image/icons/success.svg";
import ErrorImg from "../../assets/image/icons/error.png";
import CancelImg from "../../assets/image/icons/cancel.png";
import { MdClose } from "react-icons/md";
import { useDistributorConfirmCredit } from "../../hooks/useCreditData";

type CancelCreditModalProps = {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	data: Record<string, unknown>;
};

export const CancelRetailerCreditModal: React.FC<CancelCreditModalProps> = ({
	show,
	setShow,
	data,
}) => {
	// const [loading, setLoading] = useState<boolean>(false);
	const [distributorCancelError, setDistributorCancelError] =
		useState<boolean>(false);
	const [distributorCancelSuccess, setDistributorCancelSuccess] =
		useState<boolean>(false);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleCancelCreditError = () => {
		setDistributorCancelError(true);
		setTimeout(() => {
			setDistributorCancelError(false);
			setShow(false);
		}, 5000);
	};

	const handleCancelCreditSuccess = () => {
		setDistributorCancelSuccess(true);
		setTimeout(() => {
			setDistributorCancelSuccess(false);
			setShow(false);
		}, 3500);
	};

	const handleCancelCredit = (data: Record<string, any>) => {
		const { id } = data;
		const formData = {
			credit_id: id,
			command: "REJECT",
		};
		mutateDistributorConfirmCredit(formData);
	};

	const { mutateDistributorConfirmCredit, isLoading: loading } =
		useDistributorConfirmCredit(
			handleCancelCreditSuccess,
			handleCancelCreditError
		);

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-6 flex w-full max-w-[532px] flex-col items-center rounded-[10px] bg-white p-4  md:m-6 md:p-6"
				>
					{distributorCancelSuccess && (
						<>
							<div className="flex flex-col items-center gap-y-3">
								<img src={SuccessImg} alt="success" />
								<p>Credit was successfully cancelled</p>
							</div>
						</>
					)}

					{distributorCancelError && (
						<>
							<div>
								<div className="flex flex-col items-center gap-y-3">
									<img src={ErrorImg} alt="success" />
									<p>The Credit couldn&apos;t be cancelled</p>
								</div>
							</div>
						</>
					)}
					{!distributorCancelSuccess && !distributorCancelError && (
						<div className="mt-2 flex w-full flex-col items-center gap-5">
							<div className="flex w-full justify-end">
								<button
									className="text-[2em] "
									onClick={() => setShow(!show)}
								>
									<MdClose />
								</button>
							</div>
							<img src={CancelImg} alt="error" className="" />
							<p className="text-center text-[18px]">
								Are you sure you want to cancel the credit for{" "}
								<b>{data.retailer_name as string}</b> with phone
								number {""}
								<b>{data.customer as string}</b>
							</p>
							<div className="flex gap-4">
								{/* <Button
									disabled={loading}
									onClick={() => {
										setShow(false);
									}}
									additionalClasses="rounded-[4px] bg-[#D52500] px-12 py-2 font-normal text-white max-w-[136px] text-sm"
								>
									Cancel
								</Button> */}
								<Button
									disabled={loading}
									onClick={() => {
										handleCancelCredit(data);
									}}
									additionalClasses="rounded-[4px] border border-[#BABFC3] bg-[#D52500] px-12 py-2 text-[14px] text-[#202223] max-w-[200px] text-white"
								>
									{loading ? (
										<Loader2 color="#002060" />
									) : (
										"Cancel Credit"
									)}
								</Button>
							</div>
						</div>
					)}
				</motion.section>
			)}
		</Overlay>
	);
};

export default CancelRetailerCreditModal;
