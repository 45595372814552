export const distributorDefaultCreditFormData = {
	credit_amount: "",
	tenure: "",
	invoice_file: null,
	invoice_fileName: "",
	bank_statement_file: null,
	bank_statement_fileName: "",
	bank_code: "",
	guarantor1: {
		name: "",
		address: "",
		phone: "",
	},
	guarantor2: {
		name: "",
		address: "",
		phone: "",
	},
	next_of_kin: {
		name: "",
		address: "",
		phone: "",
		relationship: "",
	},
};
