import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { OverlayProps } from "../../types/components/modals";

export const Overlay = React.memo(function Overlay({
	children,
	show,
	setShow,
	onClose,
	modalRef,
	isPersistent,
	...props
}: OverlayProps) {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = "hidden";
		} else {
			if (onClose) {
				onClose();
				document.body.style.overflow = "auto";
			}
			document.body.style.overflow = "auto";
		}

		if (!isPersistent) {
			const handleClickOutside = (event: MouseEvent) => {
				if (
					modalRef.current &&
					!modalRef.current.contains(event.target as Node)
				) {
					setShow(false);
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
		return () => {
			// setShow(false);
			document.body.style.overflow = "auto";
		};
	}, [isPersistent, modalRef, setShow, show]);

	// Listen for beforeunload event
	window.addEventListener("beforeunload", function () {
		// Remove overflow: hidden from the body element
		document.body.style.overflow = "";
	});

	return (
		<AnimatePresence {...props}>
			{show && (
				<motion.section
					className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{
						duration: 0.3,
						ease: "easeInOut",
					}}
				>
					{children}
				</motion.section>
			)}
		</AnimatePresence>
	);
});

export default Overlay;
