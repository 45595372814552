import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Overlay } from "./Overlay";
import { SuccessModalProps } from "types/components/modals";
import ErrorImg from "../../assets/image/icons/error.png";
import { slideDownAnimation } from "../../services/Animations";

export const ErrorCrmModal: React.FC<SuccessModalProps> = ({
	show,
	setShow,
	content,
	buttonContent,
	buttonAction,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-full max-w-[400px] flex-col items-center rounded-[10px] bg-white p-8 "
				>
					<div className="flex flex-col items-center">
						<img src={ErrorImg} alt="error" />
					</div>
					<div className="flex max-w-[350px] flex-col gap-2">
						<h1 className="text-center text-2xl text-red-600 ">
							Failed !
						</h1>
						<p className="text-center text-lg font-semibold text-red-600 ">
							{content}
						</p>
					</div>
					{buttonContent && (
						<div className="flex py-4">
							<button
								className="w-full rounded-md bg-[#002060] px-4 py-2 text-sm text-white"
								onClick={buttonAction}
							>
								{buttonContent}
							</button>
						</div>
					)}
				</motion.section>
			)}
		</Overlay>
	);
};
