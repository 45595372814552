import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router";
import Layout from "./layout/Layout";
import AuthLayout from "./layout/AuthLayout";
import Login from "./views/Login";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import Verify from "./views/Verify";
import Dashboard from "./views/Dashboard";
import DashboardHome from "./views/Dashboard/DashboardHome";
// import Settings from "./views/Dashboard/Settings/";
import LandingPage from "./views/LandingPage";
import ResetPassword from "./views/ResetPassword";
import CreditApplications from "./views/Dashboard/CreditApplications";
import ApplyForCredit from "./views/Dashboard/CreditApplications/ApplyForCredit";
// import CreditAuth from "./views/CreditAuthorization";
import Subusers from "./views/Dashboard/Subusers";
import ProfilePage from "./views/Dashboard/Profile/";
import { useAuth } from "./hooks/auth";
import Onboarding from "./views/Onboarding";
import NoMatch from "./views/NoMatch";
import CreditBooks from "./views/Dashboard/CreditBook";
import CreditAccept from "./views/CreditAuthorization/Accept";
import VerifyEmail from "./views/VerifyEmail";
import ResendEmail from "./views/ResendEmail";
// import GuarantorAccept from "./views/CreditAuthorization/GuarantorAccept";
import Settings from "./views/Dashboard/Settings";
// import Customer from "./views/Dashboard/Customers/Customer""
import OnboardingReview from "./views/Onboarding/OnboardingReview";
// import OnboardingReview from "./views/Onboarding/OnboardingReview";
import HadiPay from "./views/Dashboard/HadiPay/HadiPay";
import ZohoCallback from "./views/Redirect/ZohoCallback";
import QuickbookCallback from "./views/Redirect/QuickbookCallback";
import DistributorCreditApplication from "./views/Dashboard/CreditApplications/Distributor";
import ComingSoon from "./components/ComingSoon";
import RetailerCreditAuthorization from "./views/CreditAuthorization/Retailer";
import GuarantorAcceptForm from "./views/CreditAuthorization/Guarantor";
import TransactionHistory from "./views/Dashboard/HadiPay/TransactionHistory";
// import RetailerOnboarding from "./views/RetailerOnboarding";
// import RetailerCreditAuth from "./views/RetailerOnboarding/RetailerCreditAuth";
import { useOnboarding } from "./provider/OnboardingProvider";
import { useNavigate } from "react-router-dom";
import AccountOfficer from "./views/AccountOfficer";
import Notifications from "./views/Dashboard/Profile/Notifications";
import HelpCenter from "./views/Dashboard/HelpCenter";
import FieldAgent from "./views/Agent";

const RequireAuth = () => {
	const location = useLocation();
	const { user } = useAuth();

	if (!user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Outlet />;
};

const CheckOnboardingStatus = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const { toggleOnboardingModal } = useOnboarding();

	const OnboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;

	useEffect(() => {
		if (!OnboardedUser || !isVerifiedUser) {
			toggleOnboardingModal();
			navigate("/dashboard", {
				state: { from: location },
				replace: true,
			});
		}
	}, [
		OnboardedUser,
		isVerifiedUser,
		toggleOnboardingModal,
		navigate,
		location,
	]);

	if (!OnboardedUser || !isVerifiedUser) {
		return null;
	}

	return <Outlet />;
};

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route
					index
					element={
						<AuthLayout>
							<LandingPage />
						</AuthLayout>
					}
				/>

				<Route element={<RequireAuth />}>
					<Route element={<Dashboard />}>
						<Route path="dashboard" element={<DashboardHome />} />
						<Route element={<CheckOnboardingStatus />}>
							<Route
								path="dashboard/credit-book/*"
								element={<CreditBooks />}
							/>

							{/* <Route
							path="dashboard/credit-repayment"
							element={<CreditRepaymentID />}
						/> */}
							{/* <Route
							path="dashboard/customers"
							element={<Customer />}
						/> */}
							<Route
								path="dashboard/subusers"
								element={<Subusers />}
							/>

							<Route
								path="dashboard/credit-application"
								element={<CreditApplications />}
							/>
							<Route
								path="dashboard/credit-application/distributor"
								element={<DistributorCreditApplication />}
							/>
							<Route
								path="dashboard/credit-application/apply"
								element={<ApplyForCredit />}
							/>
							{/* <Route path="/dashboard/hadi-pay">
								<Route index element={<HadiPay />} />
								<Route
									path="/dashboard/hadi-pay/history"
									element={<TransactionHistory />}
								/>
							</Route> */}
							<Route
								path="dashboard/settings"
								element={<Settings />}
							/>
							<Route
								path="dashboard/sales/analytics"
								element={<ComingSoon />}
							/>
							<Route
								path="dashboard/sales/inventory"
								element={<ComingSoon />}
							/>
							<Route
								path="dashboard/sales/order"
								element={<ComingSoon />}
							/>
							<Route
								path="dashboard/customers"
								element={<ComingSoon />}
							/>
							<Route path="dashboard/hadi-pay">
								<Route index element={<HadiPay />} />
								<Route
									path="/dashboard/hadi-pay/history"
									element={<TransactionHistory />}
								/>
							</Route>
							<Route path="dashboard/help-center">
								<Route index element={<HelpCenter />} />
							</Route>
						</Route>
						<Route path="/dashboard/profile">
							<Route index element={<ProfilePage />} />
							<Route
								path="/dashboard/profile/notifications"
								element={<Notifications />}
							/>
						</Route>
					</Route>
				</Route>
			</Route>
			<Route
				path="login"
				element={
					<AuthLayout>
						<Login />
					</AuthLayout>
				}
			/>
			<Route path="onboarding/:pages" element={<Onboarding />} />
			<Route path="onboarding" element={<Onboarding />} />
			<Route path="onboarding-review" element={<OnboardingReview />} />

			<Route
				path="onboarding/quickbooks"
				element={<QuickbookCallback />}
			/>
			<Route path="/onboarding/zoho/*" element={<ZohoCallback />} />
			{/* <Route path="onboarding-review" element={<OnboardingReview />} /> */}
			<Route path="/credit">
				<Route index element={<RetailerCreditAuthorization />} />
				<Route path="/credit/accept" element={<CreditAccept />} />
				<Route
					path="/credit/guarantor/"
					element={<GuarantorAcceptForm />}
				/>
			</Route>
			{/* <Route path="/retailer">
				<Route index element={<RetailerOnboarding />} />
				<Route
					path="/retailer/credit"
					element={<RetailerCreditAuth />}
				/>
			</Route> */}
			<Route path="/account-officer" element={<AccountOfficer />} />
			<Route path="/agent" element={<FieldAgent />} />

			{/* <Route
				path="/credit-auth"
				element={<RetailerOnboardingCreditAuthorization />}
			/> */}

			<Route
				path="register"
				element={
					<AuthLayout>
						<Register />
					</AuthLayout>
				}
			/>
			<Route path="verify-email/*" element={<VerifyEmail />} />
			<Route
				path="forgot-password"
				element={
					<AuthLayout>
						<ForgotPassword />
					</AuthLayout>
				}
			/>
			<Route
				path="/reset-password/*"
				element={
					<AuthLayout>
						<ResetPassword />
					</AuthLayout>
				}
			/>
			<Route
				path="/resend-email/*"
				element={
					<AuthLayout>
						<ResendEmail />
					</AuthLayout>
				}
			/>
			<Route
				path="verify"
				element={
					<AuthLayout>
						<Verify />
					</AuthLayout>
				}
			/>

			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
};

export default AppRoutes;
