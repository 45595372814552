/* eslint-disable @typescript-eslint/no-explicit-any */
import "@smile_identity/smart-camera-web";
import React, { useEffect } from "react";

type SmartCameraProps = {
	onHandleImageChanged: (e: any) => void;
	capture_id?: boolean;
	document_modes?: string;
};

export const SmartCamera = ({
	onHandleImageChanged,
	capture_id,
	document_modes,
}: SmartCameraProps) => {
	const ref = React.useRef();

	useEffect(() => {
		const smartCamera: any = ref.current;

		if (smartCamera) {
			const handleImagesComputed = (e: any) =>
				onHandleImageChanged(e.detail);
			smartCamera.addEventListener(
				"imagesComputed",
				handleImagesComputed
			);

			return () => {
				smartCamera.removeEventListener(
					"imagesComputed",
					handleImagesComputed
				);
			};
		}
	}, [ref, onHandleImageChanged]);
	return (
		<>
			<smart-camera-web
				ref={ref}
				capture-id={capture_id ? true : undefined}
				document-capture-modes={document_modes}
			/>
		</>
	);
};
