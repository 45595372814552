import React from "react";
import { TopBar } from "../../../components";
import Repayments from "./Repayments";
import Transactions from "./Transactions";
import Transaction from "./Transaction";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Repayment from "./Repayment";

const CreditBooks: React.FC = () => {
	const location = useLocation();
	const path = location.pathname.split("/").pop();
	// const regex = /^\/dashboard\/credit-book\/repayments\/.*$/;
	const getActive = (name: string) => {
		return path === name ||
			(path === "credit-book" && name === "transactions")
			? "border-b  border-b-blue-950 text-blue-950"
			: "";
	};
	return (
		<>
			<>
				<TopBar pageName="Credit Book" />
				{(path === "transactions" ||
					path === "credit-book" ||
					path === "repayments") && (
					<div className="flex gap-4 px-4 md:px-8">
						<div className="mt-2 flex cursor-pointer gap-4">
							<ul className="flex">
								<li>
									<Link
										className={`p-2 ${getActive(
											"transactions"
										)}`}
										to="/dashboard/credit-book/transactions"
									>
										Transactions
									</Link>
								</li>
								<li>
									<Link
										className={`p-2 ${getActive(
											"repayments"
										)}`}
										to="/dashboard/credit-book/repayments"
									>
										Repayments
									</Link>
								</li>
							</ul>
						</div>
					</div>
				)}
			</>

			<div className="px-4 py-4 md:px-8">
				<Routes>
					<Route index element={<Transactions />} />
					<Route path="/transactions" element={<Transactions />} />
					<Route path="/transactions/*" element={<Transaction />} />
					<Route path="/repayments" element={<Repayments />} />
					<Route path="/repayments/:id" element={<Repayment />} />
				</Routes>
			</div>
		</>
	);
};

export default CreditBooks;
