import RequestIsLoading from "components/RequestIsLoading";
import RecordDetails from "./RecordDetails";
import NotificationTile from "./NotificationTile";
import { NotificationType } from "@/types/components/dashboard/dashboard";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useNotificationWebSocketContext } from "provider/NotificationProvider";

interface NotificationTabProps {
	notificationData?: any[];
	notificationLoading?: boolean;
	nullMessage: string;
	tab: number;
	newSeenNotifications?: NotificationType[] | [];
	setNewSeenNotifications?: Dispatch<SetStateAction<NotificationType[] | []>>;
}

export const NotificationsContent: React.FC<NotificationTabProps> = ({
	notificationData,
	notificationLoading,
	nullMessage,
	tab,
	setNewSeenNotifications,
}) => {
	const [currentRecordDetails, setCurrentRecordDetails] = useState<
		NotificationType | undefined
	>();
	const { markNotificationAsSeen } = useNotificationWebSocketContext();

	const updateSeen = (notification: any) => {
		if (
			(!notification?.is_seen || !notification?.isSeen) &&
			setNewSeenNotifications
		) {
			setNewSeenNotifications((prevNotifications) =>
				prevNotifications.map((data: any) =>
					data.id === notification.id
						? { ...data, isSeen: true, is_seen: true }
						: data
				)
			);
			markNotificationAsSeen(notification.id);
		}
		setCurrentRecordDetails(notification);
	};

	return (
		<>
			{!notificationLoading ? (
				notificationData && notificationData.length ? (
					<section className="flex w-full">
						<div className="flex  h-full w-full flex-col overflow-y-auto md:max-w-[669px] md:border-r ">
							<div>
								<div className="flex items-center gap-2">
									<h1 className="text-lg font-semibold text-[#101828]">
										Notifications
									</h1>
									<span className="w-fit bg-primary-100 px-2 py-1 text-xs text-primary-600">
										{notificationData.length ?? "0"} new
										notifications
									</span>
								</div>
								<p className="text-sm font-normal text-[#667085]">
									Receive alerts, messages and notifications
									here
								</p>
							</div>
							<div className="flex flex-col gap-y-2 pr-4 pt-6">
								{notificationData.map(
									(
										notification: NotificationType,
										index: number
									) => (
										<NotificationTile
											key={index}
											tab={tab}
											notification={notification}
											onClick={() =>
												updateSeen(notification)
											}
										/>
									)
								)}
							</div>
						</div>
						<div className="w-full bg-white ">
							{currentRecordDetails ? (
								<RecordDetails
									notification={currentRecordDetails}
								/>
							) : (
								<div className="my-auto mt-8 flex items-center justify-center">
									<p className="text-xl font-semibold">
										No Message content available
									</p>
								</div>
							)}
						</div>
					</section>
				) : (
					""
				)
			) : (
				""
			)}
			{!notificationLoading && !notificationData?.length && (
				<>
					<div className="flex items-center space-x-3 pl-9 pt-6 text-2xl">
						<p>{nullMessage}</p>
						<span>🎉</span>
					</div>
				</>
			)}

			{notificationLoading && (
				<RequestIsLoading isLoading={notificationLoading} />
			)}
		</>
	);
};

export default NotificationsContent;
