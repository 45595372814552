import { useState } from "react";
import { changePasswordData } from "types/hooks";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useChangePassword } from "../../../hooks/useAuthData";
import { Button, FormikInput, Loader2 } from "../../../components";
import { Toast } from "../../../utils/toast";
import ChangeTransactionPIN from "../../../components/Dashboard/ChangePin";
import { getChangeTransactionPinOTP } from "../../../api/wallet";

type AccountType = {
	setOpenChangePasswordModal: (value: boolean) => void;
	openChangePasswordModal: boolean;
};

const Account = ({
	setOpenChangePasswordModal,
	openChangePasswordModal,
}: AccountType) => {
	const [showChangePINModal, setShowChangePINModal] = useState(false);
	const [changePinToken, setChangePinToken] = useState("");
	const [changePinStage, setChangePinStage] = useState(0);
	const [getChangeOTPTokenLoading, setGetChangeOTPTokenLoading] =
		useState<boolean>(false);

	const [formData] = useState<changePasswordData>({
		old_password: "",
		new_password1: "",
		new_password2: "",
	});
	const validationSchema = Yup.object({
		old_password: Yup.string().required("Old password is required"),
		new_password1: Yup.string()
			.required("Password is required")
			.min(8, "Password must be at least 8 characters"),
		new_password2: Yup.string()
			.oneOf([Yup.ref("new_password1"), null], "Passwords must match")
			.required("Confirm password is required"),
	});

	const passwordResetOnSuccess = (data: any) => {
		// console.log(data);
		// if (setStatus !== undefined) {
		// 	setStatus("success");
		// }
		Toast.success(data?.detail || "Password was Successfully changed!");
	};

	const passwordResetOnError = (error: any) => {
		Toast.error(error?.detail || "Password change was not successful");
	};

	const { changePassword, isLoading } = useChangePassword(
		passwordResetOnSuccess,
		passwordResetOnError
	);

	const ChangeTransactionPin = async () => {
		setGetChangeOTPTokenLoading(true);
		try {
			const changePinData = await getChangeTransactionPinOTP();
			if (changePinData) {
				const { status, message, pin_id } = changePinData;
				if (status) {
					setChangePinToken(pin_id);
					setShowChangePINModal(true);
					setGetChangeOTPTokenLoading(false);
					return;
				} else if (!status) {
					setGetChangeOTPTokenLoading(false);
					Toast.error(message || "An Error Occured");
					return;
				} else {
					setGetChangeOTPTokenLoading(false);
					return Toast.error(message || "An Error Occured");
				}
			}
			setGetChangeOTPTokenLoading(false);
		} catch (error) {
			return Toast.error("An Error Occured");
		}
	};

	const handleSubmit = async (payload: changePasswordData, actions: any) => {
		actions.setTouched({});
		actions.setSubmitting(false);
		changePassword(payload);
		actions.resetForm();
	};

	return (
		<div className="mb-12 mt-4 flex w-full flex-col justify-start px-8 md:mt-9">
			<h1 className="text-xl font-semibold text-secondary-500">
				Change Password
			</h1>
			<Formik
				initialValues={formData}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ values }) => {
					return (
						<Form className="msm-max-w-full mt-10 flex w-full max-w-[734px] flex-col gap-y-9 msm:mt-5">
							<div className="grid grid-cols-1 items-start gap-x-4 gap-y-9 md:grid-cols-1">
								<div className="flex flex-1 items-center justify-between whitespace-nowrap">
									<h1 className="mr-4 text-base font-semibold text-secondary-500">
										Old Password
									</h1>
									<div className="w-[418px]">
										<FormikInput
											label=""
											name="old_password"
											id="old_password"
											floatingLabel={false}
											type="password"
											placeholder="Enter new password"
											additionalClasses="!px-4 border-[#D0D0D0] bg-transparent placeholder-[#263238]"
											autoComplete="off"
											required={false}
										/>
									</div>
								</div>
								<div className="flex flex-1 items-center justify-between whitespace-nowrap">
									<h1 className="mr-4 text-base font-semibold text-secondary-500">
										New Password
									</h1>
									<div className="w-[418px]">
										<FormikInput
											label=""
											name="new_password1"
											id="new_password1"
											floatingLabel={false}
											type="password"
											placeholder="Enter new password"
											additionalClasses="!px-4 border-[#D0D0D0] bg-transparent placeholder-[#263238]"
											autoComplete="off"
											required={false}
										/>
									</div>
								</div>
								<div className="flex flex-1 items-center justify-between whitespace-nowrap">
									<h1 className="mr-4 text-base font-semibold text-secondary-500">
										Confirm Password
									</h1>
									<div className="w-[418px]">
										<FormikInput
											label=""
											name="new_password2"
											id="new_password2"
											floatingLabel={false}
											type="password"
											placeholder="Confirm new password"
											additionalClasses="!px-4 border-[#D0D0D0] bg-transparent placeholder-[#263238]"
											autoComplete="off"
											required={false}
										/>
									</div>
								</div>
								<div className="flex flex-1 items-center justify-between whitespace-nowrap">
									<div className=" mr-4 text-base font-semibold text-secondary-500">
										<Button
											disabled={false}
											type="submit"
											additionalClasses="!h-10 text-sm text-white font-medium !bg-primary-600 py-2.5 px-4"
										>
											{isLoading ? <Loader2 /> : "Update"}
										</Button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
			<h1 className="mt-14 text-xl font-semibold text-secondary-500">
				Change transfer OTP
			</h1>
			<div className="flex flex-1 items-center justify-between whitespace-nowrap">
				<div className="mr-4 text-base font-semibold text-secondary-500">
					<Button
						disabled={false}
						onClick={ChangeTransactionPin}
						type="submit"
						additionalClasses="text-sm mt-10 text-white font-medium !bg-error-600 py-2.5 px-4 !h-10"
					>
						{getChangeOTPTokenLoading ? <Loader2 /> : "Change Pin"}
					</Button>
				</div>
			</div>
			<ChangeTransactionPIN
				show={showChangePINModal}
				setShow={setShowChangePINModal}
				changePinToken={changePinToken}
				setChangePinStage={setChangePinStage}
				changePinStage={changePinStage}
			/>
		</div>
	);
};

export default Account;
