import { UploadedFileProps } from "types/components/cards";
import { motion } from "framer-motion";
import React from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";

const itemVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

export const UploadedFile: React.FC<UploadedFileProps> = ({
	title,
	deleteFile,
}) => {
	return (
		<motion.div
			variants={itemVariants}
			initial="hidden"
			animate="visible"
			onClick={deleteFile}
			className="flex w-full justify-between rounded-[4px] border border-[#11AF22] bg-white px-2 py-2.5"
		>
			<p className="flex-1 text-sm">{title}</p>
			<RiDeleteBin2Fill className="h-5 w-5 cursor-pointer text-[#D52500]" />
		</motion.div>
	);
};

export default UploadedFile;
