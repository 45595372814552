import React, { useState } from "react";
import Select from "react-select";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { FormInputProps } from "types/components";
import { cn } from "../utils/utilities";

export const FormInput = ({
	label,
	floatingLabel,
	labelStyles,
	value,
	name,
	id,
	type,
	additionalClasses,
	placeholder,
	placeholderStyleOptions,
	onChange,
	additionalAttributes,
	select,
	multiSelect,
	selectStyleOptions,
	textarea,
	password,
	pattern,
	rows,
	cols,
	disabled,
	required,
	minLength,
	maxLength,
	autoComplete,
	options,
	inputMode,
	min,
	max,
}: FormInputProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	return (
		<div className="relative flex w-full flex-col justify-center gap-y-1">
			{!floatingLabel && (
				<label
					htmlFor={name}
					className={`relative text-left font-medium capitalize text-primary-700 md:font-semibold ${
						labelStyles ?? ""
					}`}
				>
					{label}

					{required ? (
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					) : (
						""
					)}
				</label>
			)}
			{!select && !textarea && (
				<div className="relative flex items-center">
					<input
						name={name}
						id={id}
						value={value}
						className={`${
							additionalClasses
								? additionalClasses +
								  " peer h-10 w-full rounded-md border border-secondary-300 py-2 pl-2.5 pr-4 text-[#333333] autofill:bg-white"
								: " peer h-10 w-full rounded-md border border-secondary-300 bg-transparent px-4 py-2 text-[15px] font-light text-[#333333] outline-none autofill:bg-white md:placeholder-white md:placeholder-opacity-50"
						}`}
						type={!visible ? type : "text"}
						required={required}
						minLength={minLength}
						maxLength={maxLength} // Add the maxLength attribute here
						autoComplete={autoComplete}
						pattern={pattern}
						placeholder={placeholder}
						disabled={disabled}
						onChange={onChange}
						min={min}
						max={max}
						inputMode={inputMode ? inputMode : "text"}
						{...additionalAttributes}
					/>
					{/* <label
						htmlFor={name}
						className={`absolute -top-3 left-4  bg-white px-3 text-left font-medium capitalize text-primary-700 md:font-semibold ${labelStyles}`}
					>
						{label}

						{required ? (
							<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
								*
							</span>
						) : (
							""
						)}
					</label> */}
					{password && (
						<i className="absolute right-4 top-1/2 flex -translate-y-1/2 items-center text-secondary-300">
							<button
								type="button"
								onClick={() => setVisible(!visible)}
								aria-label={
									visible ? "Hide password" : "Show password"
								}
							>
								<span className="sr-only">
									{visible
										? "Hide password"
										: "Show password"}
								</span>
								{visible ? <BsEyeSlashFill /> : <BsEyeFill />}
							</button>
						</i>
					)}
				</div>
			)}
			{select && (
				<Select
					name={name}
					id={id}
					options={options}
					placeholder={placeholder}
					className={"font-light"}
					isMulti={multiSelect}
					styles={customSelectStyles(
						selectStyleOptions,
						placeholderStyleOptions
					)}
					defaultValue={
						multiSelect
							? value
							: value
							? options?.find((option) => option.value === value)
							: placeholder
					}
					value={
						multiSelect
							? value
							: value
							? options?.find((option) => option.value === value)
							: placeholder
					}
					onChange={onChange}
					isDisabled={disabled}
				/>
			)}
			{textarea && (
				<textarea
					className={cn(
						"w-full rounded-md border-[0.5px] border-[#04172a] bg-white px-4 py-2 outline-none",
						additionalClasses
					)}
					rows={rows}
					cols={cols}
					value={value}
					required={required}
					disabled={disabled}
					placeholder={placeholder}
					maxLength={maxLength}
					onChange={onChange}
				></textarea>
			)}
			{floatingLabel && (
				<label
					htmlFor={name}
					className={`absolute -top-3 left-4  bg-white px-3 text-left font-medium capitalize text-primary-700 md:font-semibold ${labelStyles}`}
				>
					{label}

					{required ? (
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					) : (
						""
					)}
				</label>
			)}
		</div>
	);
};

export const customSelectStyles = (
	selectStyleOptions?: any,
	placeholderStyleOptions?: any
) => ({
	control: (defaultStyles: any) => ({
		...defaultStyles,
		"&:hover": { borderColor: "#04172a" },
		minHeight: "2.5rem",
		borderRadius: "0.5em",
		border: "1.4px solid #90A3BF",
		duration: "200",
		color: "white",
		backgroundColor: "transparent",
		boxShadow: `0 0 0 1px 'orange'`,
		outline: "none !important",
		fontSize: "1rem",
		"@media (max-width: 500px)": {
			minHeight: "3rem",
			fontSize: "14px",
		},
		...selectStyleOptions,
	}),
	placeholder: (base: any) => ({
		...base,
		fontWeight: 400,
		fontSize: "12px",
		color: "#90A3BF",
		"@media (max-width: 500px)": {
			minHeight: "3rem",
			fontSize: "12px",
		},
		...placeholderStyleOptions,
	}),
	option: (provided: any, { isSelected, isFocused }) => ({
		...provided,
		color: isSelected ? "#fff" : "#333333", // Set selected option text color
		backgroundColor: isSelected
			? "#002060"
			: isFocused
			? "#F1F5F9"
			: undefined, // Set selected option background color
		cursor: "pointer",
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: "#333333",
	}),
});
