/**
 * Checks if the provided address is valid.
 * @param address The address to validate.
 * @returns True if the address is valid (length is greater than or equal to 5 characters), false otherwise.
 */
export const isValidAddress = (address: string): boolean => {
	if (address.length < 5) return false;
	return true;
};

/**
 * Checks if the names provided  are unique.
 * @param names The array of names to compare.
 * @returns True if all names are unique, false otherwise.
 */
export const isSimilarName = (...names: Array<string>): boolean => {
	const uniqueNames = new Set(names);
	if (uniqueNames.size == names.length) return true;
	return false;
};

/**
 * Checks if the phone numbers provided are unique.
 * @param numbers The array of phone numbers to compare.
 * @returns True if all phone numbers are unique, false otherwise.
 */
export const isSimilarPhoneNumber = (...numbers: Array<string>): boolean => {
	const uniqueNumbers = new Set(numbers);
	if (uniqueNumbers.size == numbers.length) return true;
	return false;
};

/**
 * Checks if the name provided is valid.
 * @param name The name to validate.
 * @returns True if the name is valid (length is greater than or equal to 5 characters), false otherwise.
 */
export const isValidName = (name: string) => {
	if (name.length < 3) return false;
	return true;
};
/**
 * Checks if the name provided is valid.
 * @param name The name to validate.
 * @param minLength The minimum length the name must be.
 * @returns True if the name is valid (length is greater than or equal to 5 characters), false otherwise.
 */
export const isValidNameInput = (name: string, minLength: number) => {
	if (name.length < minLength) return false;
	return true;
};

export const isValidBVN = (bvn: string) => {
	if (bvn.length < 11) return false;
	return true;
};
