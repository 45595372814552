import {
	ArrowDownIcon,
	ArrowUpIcon,
	CaretSortIcon,
} from "@radix-ui/react-icons";
import { type Column } from "@tanstack/react-table";

import { cn } from "../../utils/utilities";
import { Button } from "./button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "./dropdown-menu";

interface DataTableColumnHeaderProps<TData, TValue>
	extends React.HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	title: string;
}

export function DataTableColumnHeader<TData, TValue>({
	column,
	title,
	className,
}: DataTableColumnHeaderProps<TData, TValue>) {
	if (!column.getCanSort() && !column.getCanHide()) {
		return <div className={cn(className)}>{title}</div>;
	}

	return (
		<div className={cn("flex items-center space-x-2", className)}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<button
						aria-label={
							column.getIsSorted() === "desc"
								? "Sorted descending. Click to sort ascending."
								: column.getIsSorted() === "asc"
								? "Sorted ascending. Click to sort descending."
								: "Not sorted. Click to sort ascending."
						}
						className="flex items-center whitespace-nowrap border-none px-2 py-2 text-sm font-medium"
					>
						<span>{title}</span>
						{column.getCanSort() &&
						column.getIsSorted() === "desc" ? (
							<ArrowDownIcon
								className="size-4 ml-2"
								aria-hidden="true"
							/>
						) : column.getIsSorted() === "asc" ? (
							<ArrowUpIcon
								className="size-4 ml-2"
								aria-hidden="true"
							/>
						) : (
							<CaretSortIcon
								className="size-4 ml-2"
								aria-hidden="true"
							/>
						)}
					</button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					{column.getCanSort() && (
						<>
							<DropdownMenuItem
								aria-label="Sort ascending"
								onClick={() => column.toggleSorting(false)}
							>
								<ArrowUpIcon
									className="size-3.5 text-muted-foreground/70 mr-2"
									aria-hidden="true"
								/>
								Asc
							</DropdownMenuItem>
							<DropdownMenuItem
								aria-label="Sort descending"
								onClick={() => column.toggleSorting(true)}
							>
								<ArrowDownIcon
									className="size-3.5 text-muted-foreground/70 mr-2"
									aria-hidden="true"
								/>
								Desc
							</DropdownMenuItem>
						</>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
