import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Overlay } from "./Overlay";
import { SuccessModalProps } from "types/components/modals";
import SuccessImg from "../../assets/image/icons/success.svg";
import { MdClose, MdOutlineClose } from "react-icons/md";
import { slideDownAnimation } from "../../services/Animations";

export const SuccessModal: React.FC<SuccessModalProps> = ({
	title,
	show,
	setShow,
	content,
	buttonAction,
	buttonContent,
	children,
	onClose,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay
			show={show}
			setShow={setShow}
			modalRef={modalRef}
			onClose={onClose}
		>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-[80%] max-w-[675px] flex-col items-center rounded-[10px] bg-white p-4  md:w-fit md:p-8"
				>
					<figure
						className="cursor-pointer self-end rounded-full p-2.5 duration-300 ease-in-out hover:bg-slate-300"
						onClick={() => setShow(!show)}
					>
						<MdOutlineClose size={24} />
					</figure>
					<div className="flex flex-col items-center">
						<img src={SuccessImg} alt="success" />
						<h1 className="text-[1.7em]">
							{title ? title : "Successful!"}
						</h1>
					</div>
					<div className="max-w-[350px]">
						<p className=" text-center text-[#626D88] text-opacity-40">
							{content}
						</p>
					</div>
					{buttonContent && (
						<div className="flex py-4">
							<button
								className="w-[404px] rounded-md bg-[#002060]  py-2 text-sm text-white"
								// onClick={() => setShow(!show)}
								onClick={buttonAction}
							>
								{buttonContent}
							</button>
						</div>
					)}
					{children}
				</motion.section>
			)}
		</Overlay>
	);
};
