import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, Loader2, Overlay } from "..";
import { MdOutlineClose } from "react-icons/md";
import { OTPCode } from "../../types/components";
import OTPGroup, { validatePINCode } from "../OtpForms/OTPGroup";
import { useUpdatePIN } from "../../hooks/useWalletData";
import { Toast } from "../../utils/toast";
import { isEqual } from "lodash";
import { useAuth } from "../../hooks/auth";
import { encryptPin } from "../../utils/utilities";

interface SetNewPINProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	setShowPaymentModal?: Dispatch<SetStateAction<boolean>>;
	changePinToken: string;
	changePinStage: number;
	setChangePinStage: Dispatch<SetStateAction<number>>;
}

const ChangeTransactionPIN: React.FC<SetNewPINProps> = ({
	show,
	setShow,
	setShowPaymentModal,
	changePinToken,
	changePinStage,
	setChangePinStage,
}) => {
	const modalRef = useRef(null);
	const { user, setUser } = useAuth();
	const [otpPINCode, setOtpPINCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});
	const [firstPINCode, setFirstPINCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});
	const [confirmPINCode, setConfirmPINCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});

	const fullOTPCode =
		otpPINCode.one + otpPINCode.two + otpPINCode.three + otpPINCode.four;

	const fullCode =
		confirmPINCode.one +
		confirmPINCode.two +
		confirmPINCode.three +
		confirmPINCode.four;

	const onSuccess = () => {
		setUser({
			...user,
			transfer_pin: true,
		});
		localStorage.setItem(
			"hadi_user",
			JSON.stringify({ ...user, transfer_pin: true })
		);
		Toast.success("PIN has been changed successfully 🎉");
		setShow(false);
		setShowPaymentModal(true);
		setChangePinStage(0);
		setOtpPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
		setFirstPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
		setConfirmPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
	};

	const onError = () => {
		Toast.error("An error occured. Kindly try again later 🤕");
	};

	const handleClose = () => {
		setShow(false);
		setChangePinStage(0);
		setOtpPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
		setFirstPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
		setConfirmPINCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
	};

	const updateOTPPINMutation = useUpdatePIN(onSuccess, onError);

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef} isPersistent>
			{show && (
				<section
					className="relative flex w-full max-w-[536px] flex-col items-center rounded-[10px] bg-white px-8 py-7"
					ref={modalRef}
				>
					<figure
						className="cursor-pointer self-end rounded-full p-2.5 duration-300 ease-in-out hover:bg-slate-300"
						onClick={handleClose}
					>
						<MdOutlineClose size={24} />
					</figure>
					<div className="flex max-w-[324px] flex-col space-y-1.5 text-center">
						<h2 className="text-2xl font-medium text-blue-950">
							{changePinStage === 0
								? "Enter your OTP"
								: changePinStage === 1
								? "Set-Up Pin"
								: " Confirm Pin"}
						</h2>
						<p className="font-medium text-slate-600">
							{changePinStage === 0
								? `In order to change your pin, an OTP has been sent to ${
										user?.username
											? user?.username
													.toString()
													.replace(
														/(?<=.{3}).(?=.{4})/g,
														"x"
													)
											: "your registered phone number"
								  }.`
								: "In order to make transactions set up your pin to continue this process."}
						</p>
					</div>
					<div className="mt-4 flex w-full max-w-[376px] justify-between">
						{changePinStage === 0 && (
							<OTPGroup
								otpCode={otpPINCode}
								setOtpCode={setOtpPINCode}
								size={4}
								inputSize={70}
								additionalClasses="rounded-lg"
							/>
						)}
						{changePinStage === 1 && (
							<OTPGroup
								otpCode={firstPINCode}
								setOtpCode={setFirstPINCode}
								size={4}
								inputSize={70}
								additionalClasses="rounded-lg"
							/>
						)}
						{changePinStage === 2 && (
							<OTPGroup
								otpCode={confirmPINCode}
								setOtpCode={setConfirmPINCode}
								size={4}
								inputSize={70}
								additionalClasses="rounded-lg"
							/>
						)}
					</div>

					<Button
						disabled={updateOTPPINMutation.isLoading}
						onClick={() => {
							if (
								changePinStage === 0 &&
								validatePINCode(otpPINCode)
							) {
								setChangePinStage(1);
							} else if (
								changePinStage === 0 &&
								!validatePINCode(otpPINCode)
							) {
								Toast.info("Kindly input the correct otp code");
							}

							if (
								changePinStage === 1 &&
								validatePINCode(firstPINCode)
							) {
								setChangePinStage(2);
							} else if (
								changePinStage === 1 &&
								!validatePINCode(firstPINCode)
							) {
								Toast.info(
									"Kindly input in your preferred PIN code"
								);
							}
							if (
								changePinStage === 2 &&
								!isEqual(firstPINCode, confirmPINCode)
							) {
								Toast.info("PIN codes do not match");
								return;
							}
							if (
								changePinStage === 2 &&
								validatePINCode(confirmPINCode) &&
								isEqual(firstPINCode, confirmPINCode)
							) {
								updateOTPPINMutation.mutateUpdatePIN({
									new_pin: encryptPin(fullCode),
									otp_pin_id: changePinToken,
									otp_pin: encryptPin(fullOTPCode),
								});
							} else if (
								changePinStage === 2 &&
								!validatePINCode(confirmPINCode)
							) {
								Toast.info("PIN codes do not match");
							}
						}}
						additionalClasses="text-white max-w-[418px] mt-4 h-[53px]"
					>
						{changePinStage === 0 || changePinStage === 1
							? "Next"
							: changePinStage === 2 &&
							  !updateOTPPINMutation.isLoading
							? "Confirm"
							: changePinStage === 2 &&
							  updateOTPPINMutation.isLoading && <Loader2 />}
					</Button>
				</section>
			)}
		</Overlay>
	);
};

export default ChangeTransactionPIN;
