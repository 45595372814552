import React, { useContext } from "react";
import { useAuth } from "../../hooks/auth";
import { Button } from "../../components";
import { OverviewCard } from "./OverviewCard";
import { AnimatePresence, motion } from "framer-motion";
import { ProcessTransitionAnimation } from "../../services/Animations";
import { OnboardingFormContext } from ".";
import { useNavigate } from "react-router-dom";

export const Overview = () => {
	const { page, setPage } = useContext(OnboardingFormContext);
	const { user } = useAuth();
	const navigate = useNavigate();

	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex h-fit w-full max-w-[580px] flex-col items-center gap-6 rounded-2xl p-6"
			>
				<div className="flex flex-col gap-2 text-center">
					<h2 className="flex items-center justify-center text-[28px] font-bold text-primary-900 ">
						{`Welcome ${user?.distributor?.name}!`}
						<span className="ml-4">
							<svg
								width="23"
								height="24"
								viewBox="0 0 23 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.22148 6.15156L3.51116 4.41497C3.27732 4.27121 3.23898 3.94728 3.43258 3.75368L4.45038 2.73588C4.64589 2.54037 4.97558 2.58254 5.1155 2.82213L6.80992 5.57653C7.03227 5.95605 6.59716 6.38157 6.22148 6.15156Z"
									fill="#FFC107"
								/>
								<path
									d="M3.8529 14.505L0.705565 14.5683C0.431467 14.574 0.235957 14.8347 0.30496 15.0992L0.671063 16.4908C0.741984 16.7591 1.04675 16.8875 1.28826 16.7534L4.06949 15.2985C4.45285 15.0819 4.29376 14.4954 3.8529 14.505Z"
									fill="#FFC107"
								/>
								<path
									d="M11.1627 19.8505L10.4726 22.9155C10.4094 23.1819 10.6106 23.4387 10.8847 23.4387H12.3242C12.6022 23.4387 12.8034 23.1761 12.7344 22.9078L11.985 19.8429C11.8738 19.4154 11.2662 19.4212 11.1627 19.8505Z"
									fill="#FFC107"
								/>
								<path
									d="M16.7798 6.15156L19.4901 4.41497C19.7239 4.27121 19.7623 3.94728 19.5687 3.75368L18.5509 2.73588C18.3553 2.54037 18.0257 2.58254 17.8857 2.82213L16.1913 5.57653C15.969 5.95605 16.4041 6.38157 16.7798 6.15156Z"
									fill="#FFC107"
								/>
								<path
									d="M19.1479 14.505L22.2952 14.5683C22.5693 14.574 22.7649 14.8347 22.6958 15.0992L22.3297 16.4908C22.2588 16.7591 21.9541 16.8875 21.7125 16.7534L18.9313 15.2985C18.548 15.0819 18.7071 14.4954 19.1479 14.505Z"
									fill="#FFC107"
								/>
								<path
									d="M12.2766 0.481349L14.8566 6.36583C15.054 6.81627 15.4814 7.12487 15.9721 7.16895L22.2572 7.73248C22.9683 7.83599 23.252 8.70812 22.7364 9.21031L18.002 13.1876C17.6186 13.5096 17.4442 14.0157 17.5496 14.5044L18.9259 20.9467C19.0466 21.654 18.3048 22.1945 17.6685 21.8591L12.1827 18.6466C11.761 18.3993 11.2396 18.3993 10.8179 18.6466L5.33214 21.8572C4.69769 22.1907 3.95398 21.6521 4.07474 20.9448L5.45098 14.5025C5.55449 14.0137 5.38198 13.5077 4.99862 13.1857L0.262288 9.21223C-0.251406 8.71195 0.0322754 7.83791 0.74148 7.7344L7.02657 7.17087C7.51726 7.12679 7.9447 6.81819 8.14213 6.36774L10.7221 0.483266C11.0422 -0.160769 11.9584 -0.160769 12.2766 0.481349Z"
									fill="#FDD835"
								/>
								<path
									d="M12.0895 6.7188L11.6525 2.38307C11.6352 2.14156 11.5854 1.72754 11.9726 1.72754C12.2793 1.72754 12.446 2.36582 12.446 2.36582L13.7571 5.84668C14.2516 7.17116 14.0484 7.62544 13.5712 7.89378C13.023 8.20047 12.2141 7.96087 12.0895 6.7188Z"
									fill="#FFFF8D"
								/>
								<path
									d="M17.4952 12.8019L21.2559 9.86736C21.4418 9.71211 21.7773 9.46484 21.5089 9.18308C21.2962 8.96073 20.7211 9.28083 20.7211 9.28083L17.4301 10.567C16.4487 10.9063 15.797 11.4084 15.7395 12.041C15.6647 12.8844 16.4218 13.5341 17.4952 12.8019Z"
									fill="#F4B400"
								/>
							</svg>
						</span>
					</h2>
					<p className="text-base font-light text-secondary-400">
						Follow the process and provide all information required
						to finish onboarding your business and start extending
						credit to your customers
					</p>
				</div>
				<div className="flex flex-col items-center gap-4">
					<OverviewCard
						title={"Government ID"}
						subtitle={"Upload a standard government issue ID"}
					/>
					<OverviewCard
						title={"Proof of Address"}
						subtitle={
							"Scan or Upload PDF, JPEG or PNG Copy of your Proof of Address which could be either Bank Statement, utility Bill or Tenancy or Proof of Ownership"
						}
					/>
					{/* <OverviewCard
						title={"Proof of Address"}
						subtitle={
							"Scan or Upload PDF, JPEG or PNG Copy of your Proof of Address which could be either Bank Statement, utility Bill or Tenancy or Proof of Ownership"
						}
					/> */}
					<OverviewCard
						title={"Take a Selfie and Verify ID"}
						subtitle={"Take a picture to identify yourself"}
					/>
					<OverviewCard
						title={"TIN & CAC Number"}
						subtitle={"Type in your Correct Business TIN & CAC"}
					/>
					<OverviewCard
						title={"Manufacturers"}
						subtitle={"Select manufacturers you work with"}
					/>
					<OverviewCard
						title={"Link to ERP"}
						subtitle={"Add your ERP to access retailers details"}
					/>
				</div>
				<div className="flex w-full flex-col items-center gap-y-8">
					<Button
						disabled={false}
						onClick={() => setPage(page + 1)}
						additionalClasses="max-w-[418px] p-4 text-lg text-white"
					>
						Start
					</Button>
					<Button
						disabled={false}
						additionalClasses="w-content h-auto bg-transparent text-xl font-semibold text-primary-800 underline !w-auto"
						onClick={() => navigate("/dashboard")}
					>
						Back to dashboard
					</Button>
				</div>
			</motion.section>
		</AnimatePresence>
	);
};

export default Overview;
