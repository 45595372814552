import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useRef,
	Dispatch,
	SetStateAction,
} from "react";
import { API_URI } from "../services/Constants";
import { getCookie } from "../utils/cookies";
import { useAuth } from "../hooks/auth";
import { useNavigate } from "react-router-dom";
import { Toast } from "../utils/toast";
import { useQuery, useQueryClient } from "react-query";
import { NotificationType } from "../types/components/dashboard/dashboard";
import { getAllUnreadNotifications } from "../api/customer";
import { useReadNotificaion } from "../hooks/useCustomerData";

interface Notification {
	content_object: any;
	created: string;
	heading: string;
	id: string;
	is_seen: boolean;
	message: string;
	status: string;
}

interface NotificationContextProps {
	notifications: any;
	setNotifications: (notifications: Notification[]) => void;
	unreadNotificationCount: string;
	setUnreadNotificationCount: Dispatch<SetStateAction<string>>;
	markNotificationAsSeen: any;
}

const NotificationContext = createContext<NotificationContextProps>({
	notifications: [],
	setNotifications: () => null,
	unreadNotificationCount: "0",
	setUnreadNotificationCount: () => null,
	markNotificationAsSeen: () => null,
});

const NotificationProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [socket, setSocket] = useState<WebSocket | null>(null);
	const queryClient = useQueryClient();
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [token] = useState(getCookie("ac-token"));
	const { user } = useAuth();
	const navigate = useNavigate();
	const [unreadNotificationCount, setUnreadNotificationCount] = useState("0");

	const { data } = useQuery(
		"unseen_notifications",
		getAllUnreadNotifications,
		{
			enabled: Boolean(user),
		}
	);

	const readNotificationMutation = useReadNotificaion(
		() => {
			queryClient.invalidateQueries("unseen_notifications");
		},
		() => {
			//
		}
	);

	const markNotificationAsSeen = async (notificationId: string) => {
		try {
			setNotifications((prevNotifications) =>
				prevNotifications.map((notification) =>
					notification.id === notificationId
						? { ...notification, isSeen: true }
						: notification
				)
			);
			readNotificationMutation.mutate([notificationId]);
		} catch (error) {
			console.error("Error marking notification as seen:", error);
		}
	};

	useEffect(() => {
		if (user) {
			const newNotifications = data?.data?.data || [];
			setNotifications(newNotifications);
			setUnreadNotificationCount(newNotifications.length);
		}
	}, [data, token, user]);

	const updateNotificationList = (newData: NotificationType) => {
		queryClient.setQueryData<NotificationType[]>(
			["unseen_notifications"],
			(oldData: any) => {
				if (oldData?.data?.data) {
					const updatedData = [...oldData.data.data, newData];
					return {
						...oldData,
						data: {
							...oldData.data,
							data: updatedData,
						},
					};
				} else {
					return [newData];
				}
			}
		);
	};
	const connect = async (accessToken: string | null) => {
		if (!accessToken) {
			console.error("Missing access token for WebSocket connection.");
			return;
		}

		const domain = API_URI.split("https://")[1];
		const url = `wss://${domain}/websocket/notifications/`;

		const newSocket = new WebSocket(`${url}?auth=${token}`);
		newSocket.onopen = () => {
			console.log("WebSocket connection established");
			newSocket.send(
				JSON.stringify({ type: "AUTH", token: accessToken })
			);
		};
		newSocket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			setNotifications((prevNotifications) => [
				...prevNotifications,
				data,
			]);
			updateNotificationList(data);
			Toast.info("You just got a new notification, click here", {
				onClick: () => {
					navigate("dashboard/profile/notifications");
				},
			});
		};
		newSocket.onerror = (error) => {
			console.error("WebSocket error:", error);
		};
		newSocket.onclose = () => {
			console.log("WebSocket connection closed");
		};
		setSocket(newSocket);
	};

	const hasConnectedRef = useRef(false);
	useEffect(() => {
		if (user && token && !hasConnectedRef.current) {
			connect(token);
			hasConnectedRef.current = true;
		}

		return () => {
			clearTimeout(timeoutRef.current);
			socket?.close();
		};
	}, [token, user]);

	return (
		<NotificationContext.Provider
			value={{
				notifications,
				setNotifications,
				unreadNotificationCount,
				setUnreadNotificationCount,
				markNotificationAsSeen,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotificationWebSocketContext = () =>
	useContext(NotificationContext);

export default NotificationProvider;
