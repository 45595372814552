import SignUpImg from "../../../assets/image/dashboard/credit_applications/stepOne.png";
import ApplyCreditImg from "../../../assets/image/dashboard/credit_applications/stepTwo.png";
import AcceptCreditOffer from "../../../assets/image/dashboard/credit_applications/stepThree.png";
import { distributorCreditPageNumbers } from "types/components/dashboard/distributor";
const HomeScreen = ({
	setStep,
	setPage,
}: {
	setStep: React.Dispatch<React.SetStateAction<number | 0 | 1 | 2>>;
	setPage: React.Dispatch<React.SetStateAction<distributorCreditPageNumbers>>;
}) => {
	const registrationSteps = [
		{
			img: ApplyCreditImg,
			title: "Apply for Credit",
			description:
				"Submit information about the credit you are applying for",
		},
		{
			img: SignUpImg,
			title: "Submit your Information",
			description: "Provide information needed to facilitate your credit",
		},
		{
			img: AcceptCreditOffer,
			title: "Accept Credit Offer",
			description: "You can then accept your credit offer",
		},
	];
	return (
		<>
			<div className="flex flex-col gap-8 px-8 py-12">
				<div className="mb-4 flex flex-col items-center">
					<h1 className="text-2xl font-bold text-[#002060] ">
						Apply For Credit
					</h1>
					<p className="font-medium text-[#626D88]">
						Follow 3 Steps to get{" "}
						<span className="font-normal underline">Credit</span>{" "}
						for you
					</p>
				</div>
				<div className="flex flex-col justify-center gap-8 md:flex-row">
					{registrationSteps.map((card, index) => (
						<div
							key={index}
							className="flex min-w-[264px] flex-1 flex-col items-center gap-2 rounded-lg bg-gray-100 px-4 py-8 backdrop:blur-sm lg:min-w-[264px]"
						>
							<div className="flex h-[112px] w-[121px] items-center justify-center">
								<img
									src={card.img}
									alt={card.title}
									className=" object-contain"
								/>
							</div>
							<h1 className="font-semibold text-[#002060]">
								{card.title}
							</h1>
							<p className="w-[60%] text-center text-[12px] text-[#626D88]">
								{" "}
								{card.description}{" "}
							</p>
						</div>
					))}
				</div>
				<div className="flex w-full justify-center">
					<button
						className="w-full rounded-lg bg-[#072154] py-2 text-white md:w-[40%]"
						onClick={() => {
							setStep(1);
							setPage(1);
						}}
					>
						Start
					</button>
				</div>
			</div>
		</>
	);
};

export default HomeScreen;
