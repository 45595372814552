import { ReactNode, useEffect, useState } from "react";
import { AuthContext } from "../hooks/auth";
import { request } from "../utils/axios-utils";

type Props = {
	children: ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
	const [user, setUser] = useState(() => {
		const storedUser = localStorage.getItem("hadi_user");
		return storedUser ? JSON.parse(storedUser) : null;
	});
	const value = {
		user,
		setUser,
	};

	useEffect(() => {
		const handleLocalStorageChange = (event: StorageEvent) => {
			if (event.key === "hadi_user") {
				setUser(event.newValue ? JSON.parse(event.newValue) : null);
			}
		};
		window.addEventListener("storage", handleLocalStorageChange);

		return () => {
			window.removeEventListener("storage", handleLocalStorageChange);
		};
	}, []);

	useEffect(() => {
		if (
			user &&
			user?.distributor &&
			user.distributor?.distributor_onboarding
		) {
			const isUserVerified =
				user.distributor?.distributor_onboarding.is_verified;
			if (isUserVerified == false || isUserVerified == null) {
				const interval = setInterval(async () => {
					const response = await request({
						url: "/auth/user/",
						method: "get",
					});
					if (response?.status === 200) {
						const UserData = response.data;
						localStorage.setItem(
							"hadi_user",
							JSON.stringify(UserData)
						);
					} else {
						return;
					}
				}, 180000);

				return () => {
					clearInterval(interval);
				};
			}
		}
	}, [user]);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
