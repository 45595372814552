import { cn } from "utils/utilities";
import React from "react";
import { ButtonProps } from "types/components/";

export const Button: React.FC<ButtonProps> = ({
	disabled,
	additionalClasses,
	hoverEffect,
	// type,
	onClick,
	children,
	...props
}) => {
	return (
		<button
			// type={type || "button"}
			className={cn(
				"flex h-14 w-full items-center justify-center rounded-lg bg-primary-800 font-semibold leading-5 duration-200 ease-in-out active:scale-95 disabled:bg-opacity-50",
				additionalClasses,
				{
					" hover:scale-105": hoverEffect,
				}
			)}
			onClick={(e) => {
				if (onClick) {
					e.preventDefault();
					onClick(e);
				}
			}}
			disabled={disabled}
			{...props}
		>
			{children}
		</button>
	);
};
