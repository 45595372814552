import { useEffect, useState } from "react";
// import Logo from "../../assets/image/Logo.svg";
// import { MdClose } from "react-icons/md";
// import { IoPlay } from "react-icons/io5";
// import ChatIcon from "../../assets/image/chats.png";
// import { LuLogOut } from "react-icons/lu";
// import SidebarItem from "../Sidebar/SidebarItem";
import { useLocation, useNavigate } from "react-router";
// import { useLogoutUser } from "../../hooks/useAuthData";
// import { BiBookContent } from "react-icons/bi";
import { useSidebar } from "../../provider/SidebarViewProvider";
// import { BsTelephone } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
// import useDimension from "../../hooks/useDimension";
import { AiOutlineMenu } from "react-icons/ai";
// import {
// 	dashBoardSidebarData,
// 	myCompanySidebarData,
// 	myAccountSidebarData,
// } from "../Sidebar/SidebarData";
// import { FormInput } from "components";
import { FaRegBell } from "react-icons/fa6";
import { useNotificationWebSocketContext } from "../../provider/NotificationProvider";
import { useAuth } from "../../hooks/auth";
// import { BsChevronDown } from "react-icons/bs";
// import { SearchNormal1 } from "iconsax-react";
import Logo from "../../assets/image/blueLogo.svg";

export const Header: React.FC = () => {
	// const location = useLocation();
	const navigate = useNavigate();
	const { user, setUser } = useAuth();
	// const [searchTerm, setSearchTerm] = useState("");
	// const [isDarkMode, setIsDarkMode] = useState(false); // Initial theme (optional)
	// const { height } = useDimension();
	const { showSidebar, setShowSidebar } = useSidebar();
	// const [showPhoneNumber, setShowPhoneNumber] = useState(false);
	const Username = user?.distributor && user?.distributor?.name;
	// const { mutate: logoutUser } = useLogoutUser();

	// const toggleTheme = () => {
	// 	setIsDarkMode(!isDarkMode);
	// };

	// const handleLogout = () => {
	// 	logoutUser();
	// };

	const notificationContext = useNotificationWebSocketContext();
	// console.log("🚀 ~ notificationContext:", notificationContext);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1024) setShowSidebar(true);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<AnimatePresence>
			<motion.div
				className={`sticky left-0 right-0 top-0 z-40 flex justify-between bg-white px-8 py-[18px] shadow-md msm:flex-col msm:gap-8 msm:px-5 `}
				initial={{ x: "-100%" }}
				animate={{ x: "0%" }}
				exit={{ x: "-100%" }}
				transition={{
					duration: 0.3,
					type: "tween",
					ease: "easeInOut",
				}}
			>
				<div className="relative flex flex-1 items-center justify-between lg:hidden">
					<figure
						className="h-8 w-32 cursor-pointer"
						onClick={() => navigate("/dashboard")}
					>
						<img src={Logo} alt="" className="h-full w-full" />
					</figure>
					<motion.span
						className="block cursor-pointer rounded-full p-2 text-[#002060] md:hidden"
						onClick={() => setShowSidebar(!showSidebar)}
						initial={{
							rotate: showSidebar ? 0 : 90,
						}}
						animate={{ rotate: showSidebar ? 90 : 0 }}
						whileHover={{
							backgroundColor: "#cbd5e1",
							transition: {
								ease: "easeInOut",
								duration: 0.2,
							},
						}}
						transition={{
							duration: 0.2,
							type: "tween",
							ease: "easeInOut",
						}}
					>
						<AiOutlineMenu />
					</motion.span>
				</div>
				<div className="flex flex-1 items-center justify-end gap-8 text-[#002060] msm:justify-between">
					<Link
						className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border border-[#C3D4E9]"
						to={"/dashboard/profile/notifications"}
					>
						<FaRegBell className="h-6 w-6 text-[#292D32]" />
						{notificationContext &&
							+notificationContext.unreadNotificationCount >
								0 && (
								<span className="absolute -right-1 -top-1 flex h-3 w-fit min-w-[12px] items-center justify-center rounded-full bg-red-700 px-0.5 text-[10px] text-white">
									{notificationContext &&
										notificationContext.unreadNotificationCount}
								</span>
							)}
					</Link>
					<Link
						to="/dashboard/profile"
						className="flex items-center gap-3"
					>
						<span className="flex h-12 w-12 items-center justify-center rounded-full bg-[#002060] capitalize text-white">
							{user?.first_name
								? user?.first_name?.split("")[0]
								: "H"}
						</span>
						<h1 className="text-base font-semibold capitalize text-[#1A202C]">
							{Username ? user?.first_name : "User"}
						</h1>
					</Link>
					<motion.span
						className="block cursor-pointer rounded-full p-2 text-[#002060] lg:hidden msm:hidden"
						onClick={() => setShowSidebar(!showSidebar)}
						initial={{
							rotate: showSidebar ? 0 : 90,
						}}
						animate={{ rotate: showSidebar ? 90 : 0 }}
						whileHover={{
							backgroundColor: "#cbd5e1",
							transition: {
								ease: "easeInOut",
								duration: 0.2,
							},
						}}
						transition={{
							duration: 0.2,
							type: "tween",
							ease: "easeInOut",
						}}
					>
						<AiOutlineMenu />
					</motion.span>
				</div>
			</motion.div>
		</AnimatePresence>
	);
};
