import {
	DistributorCreditChildrenProps,
	DistributorCreditFormData,
} from "types/components/dashboard/distributor";
import { FormInput } from "components";
import { validateTextNumber } from "utils/utilities";
import { Toast } from "utils/toast";
import { Patterns } from "services/Constants";
import {
	isSimilarName,
	isSimilarPhoneNumber,
	isValidAddress,
	isValidName,
} from "utils/validation";

interface DistributorGuarantorForm2Props
	extends DistributorCreditChildrenProps {
	page: 3;
}
function formValidation(formData: DistributorCreditFormData): boolean {
	/**
	 * g1 - guarantor1
	 * g2 - guarantor2
	 * kin - next of kin
	 */
	const { name: g1_name, phone: g1_phone } = formData.guarantor1;
	const {
		name: g2_name,
		address: g2_address,
		phone: g2_phone,
	} = formData.guarantor2;

	if (!isValidName(g2_name)) {
		Toast.info("Guarantor's name is not valid");
		return false;
	}
	if (!isSimilarName(g1_name, g2_name)) {
		Toast.info("Guarantor's 2 name cannot be same as Guarantor 1");
		return false;
	}
	if (!isValidAddress(g2_address)) {
		Toast.info("Guarantor's address is not valid");
		return false;
	}
	if (!isSimilarPhoneNumber(g1_phone, g2_phone)) {
		Toast.info("Both guarantor's cant have same phone number");
		return false;
	}
	if (!Patterns.telephone.test(g2_phone)) {
		Toast.info("Enter a valid phone number");
		return false;
	}
	return true;
}
export const DistributorGuarantorForm2 = ({
	setPage,
	formData,
	setFormData,
}: DistributorGuarantorForm2Props) => {
	return (
		<div className=" flex w-full flex-col items-center gap-3">
			<h1 className="text-2xl font-semibold text-[#002060]">
				Guarantor (II) Details
			</h1>
			<FormInput
				label="Guarantor Name"
				name="guarantor_name"
				id="guarantor_name"
				value={formData.guarantor2.name}
				placeholder="Enter Guarantor II Full Name"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e) =>
					setFormData({
						...formData,
						guarantor2: {
							...formData.guarantor2,
							name: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Guarantor Address"
				name="guarantor_address"
				id="guarantor_address"
				value={formData.guarantor2.address}
				placeholder="Enter Guarantor's II Address"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e) =>
					setFormData({
						...formData,
						guarantor2: {
							...formData.guarantor2,
							address: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Guarantor's Phone Number"
				name="guarantor_phone"
				id="guarantor_phone"
				value={formData.guarantor2.phone}
				placeholder="Enter Guarantor's II phone number"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				type="text"
				minLength={10}
				maxLength={11}
				required={true}
				autoComplete="false"
				onChange={(e) =>
					setFormData({
						...formData,
						guarantor2: {
							...formData.guarantor2,
							phone: validateTextNumber(e.target.value),
						},
					})
				}
			/>
			<div className="mx-auto flex w-[95%] flex-col items-center gap-2 pt-2">
				<button
					type="button"
					onClick={() => {
						if (!formValidation(formData)) return;
						setPage(4);
					}}
					// disabled={false} set to value of validation
					className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white"
				>
					Next
				</button>

				<button
					type="button"
					className="self-center text-sm font-semibold text-blue-900"
					onClick={() => setPage(2)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};
