import {
	DistributorCreditChildrenProps,
	DistributorCreditFormData,
} from "types/components/dashboard/distributor";
import { FormInput } from "components";
import { Toast } from "utils/toast";
import { validateTextNumber } from "utils/utilities";
import { Patterns } from "services/Constants";
import { isValidAddress, isValidName } from "utils/validation";

interface DistributorGuarantorForm1Props
	extends DistributorCreditChildrenProps {
	page: 2;
}
function formValidation(formData: DistributorCreditFormData): boolean {
	const { name, address, phone } = formData.guarantor1;
	if (!isValidName(name)) {
		Toast.info("Guarantor's name is not valid");
		return false;
	}
	if (!isValidAddress(address)) {
		Toast.info("Fill in guarantor's address");
		return false;
	}
	if (!Patterns.telephone.test(phone)) {
		Toast.info("Fill in a valid phone number");
		return false;
	}
	return true;
}
export const DistributorGuarantorForm1 = ({
	setPage,
	formData,
	setFormData,
}: DistributorGuarantorForm1Props) => {
	return (
		<div className=" flex w-full flex-col items-center gap-3">
			<h1 className="text-2xl font-semibold text-[#002060]">
				Guarantor (I) Details
			</h1>
			<FormInput
				label="Guarantor Name"
				name="guarantor_name"
				id="guarantor_name"
				value={formData.guarantor1.name}
				placeholder="Enter Guarantor's Full Name"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						guarantor1: {
							...formData.guarantor1,
							name: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Guarantor Address"
				name="guarantor_address"
				id="guarantor_address"
				value={formData.guarantor1.address}
				placeholder="Enter Guarantor's Address"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						guarantor1: {
							...formData.guarantor1,
							address: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Guarantor's Phone Number"
				name="guarantor_phone"
				id="guarantor_phone"
				value={formData.guarantor1.phone}
				placeholder="Enter Guarantor's phone number"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				type="text"
				minLength={10}
				maxLength={11}
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						guarantor1: {
							...formData.guarantor1,
							phone: validateTextNumber(e.target.value),
						},
					})
				}
			/>
			<div className="mx-auto flex w-[95%] flex-col items-center gap-2 pt-2">
				<button
					type="button"
					onClick={() => {
						if (!formValidation(formData)) {
							return;
						}
						setPage(3);
					}}
					// disabled={false} set to value of validation
					className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white"
				>
					Next
				</button>

				<button
					type="button"
					className="self-center text-sm font-semibold text-blue-900"
					onClick={() => setPage(1)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};
