import React, { useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { Overlay } from "./Overlay";
import { FormInput } from "../FormInput";
import { SubusersFormDataProps } from "types/views";
import { PermissionLevel } from "../../services/Constants";
import { Button } from "../Button";
import { toast } from "react-toastify";
import { subusersmodalprops } from "types/components/modals";
import { slideDownAnimation } from "../../services/Animations";

export const SubUserModal: React.FC<subusersmodalprops> = ({
	show,
	setShow,
	buttonAction,
	buttonContent,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [formData, setFormData] = useState<SubusersFormDataProps>({
		userName: "",
		permissionLevel: "",
		password: "",
		confirmPassword: "",
	});
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (SubFormValidation()) {
			if (buttonAction) {
				buttonAction();
			}
			setShow(false);
		}
	};

	const SubFormValidation = () => {
		if (!formData.userName) {
			toast.error("Please enter your user name");
			return false;
		}
		if (!formData.permissionLevel) {
			toast.error("Please enter your permissionLevel");
			return false;
		}
		if (!formData.password) {
			toast.error("Please enter your password");
			return false;
		}
		if (formData.password !== formData.confirmPassword) {
			toast.error("Passwords dont match");
			return false;
		}
		return true;
	};
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-fit max-w-[675px] flex-col items-center rounded-[10px] bg-white px-16 py-8"
				>
					<div className="flex w-full justify-end">
						<button
							className="text-[1.5em]"
							onClick={() => setShow(!show)}
						>
							<MdClose />
						</button>
					</div>

					<section>
						<div className="flex w-full flex-col items-center justify-center">
							<h3 className="text-[28px] font-semibold text-blue-950">
								Sub-Users
							</h3>
							<p className="w-[346px] p-2 text-center text-[14px] font-[500] text-slate-400">
								Add the details for your sub-users and have them
								onboarded
							</p>
						</div>
						<form className="mt-[16px] w-full space-y-4">
							<FormInput
								label="User Name"
								name="user_name"
								id="user_name"
								type="text"
								placeholder="Zack Efron"
								autoComplete={"false"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light  outline-none"
								required={true}
								onChange={(e) => {
									setFormData({
										...formData,
										userName: e.target.value,
									});
								}}
							/>
							<FormInput
								select={true}
								options={PermissionLevel}
								label="Permission Level"
								name="permission_level"
								id="permission_level"
								type="text"
								placeholder="Select Permission Level....."
								autoComplete={"false"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none"
								required={true}
								onChange={(permissionLevel) => {
									setFormData({
										...formData,
										permissionLevel: permissionLevel.value,
									});
								}}
							/>
							<FormInput
								label="Password"
								name="password"
								id="password"
								type="password"
								password={true}
								placeholder=" "
								autoComplete={"false"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light  outline-none"
								required={false}
								onChange={(e) => {
									setFormData({
										...formData,
										password: e.target.value,
									});
								}}
							/>
							<FormInput
								label="Confirm Password"
								name="confirm_password"
								id="confirm_password"
								type="password"
								placeholder=" "
								autoComplete={"false"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light  outline-none"
								required={false}
								onChange={(e) => {
									setFormData({
										...formData,
										confirmPassword: e.target.value,
									});
								}}
							/>
							<Button
								disabled={false}
								onClick={handleSubmit}
								additionalClasses="text-lg text-white font-semibold"
							>
								Submit
							</Button>
						</form>
					</section>
				</motion.section>
			)}
		</Overlay>
	);
};
