import { Fragment } from "react";
import { Button, Loader2 } from "../components";
import { useResendEmail } from "../hooks/useAuthData";
import { toast } from "react-toastify";
import { useLocation, useNavigate, Link } from "react-router-dom";
import blackLogo from "../assets/image/blackLogo.svg";

const ResendEmail: React.FC = () => {
	const { state } = useLocation();
	const email = state?.formData.email;
	const navigate = useNavigate();
	const onSuccess = () => {
		toast.success("Email resend successful!");
		navigate("/login");
	};
	const onError = () => {
		toast.error("Failed to resend email.");
	};

	const { isLoading, mutate: resendEmail } = useResendEmail(
		onSuccess,
		onError
	);

	const handleResendEmail = (e: any) => {
		e.preventDefault();
		const data = { email: email };
		if (!email) {
			navigate("/login");
		} else {
			resendEmail(data);
		}
	};

	const handleOpenEmail = () => {
		window.open("https://mail.google.com/", "_blank");
	};

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/login"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Already have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign in
					</span>
				</Link>
			</div>
			<div className="flex w-full flex-1 flex-col items-center justify-center rounded-lg px-4 py-8  md:px-6 md:py-11">
				<h3 className="text-center text-2xl font-semibold text-blue-950">
					First Let&apos;s Verify your Email.
				</h3>
				<p className="pt-2 text-center text-base font-[500] text-[#626D88]">
					Check <b> {email || "your email"} </b> to verify your
					account and get started
				</p>

				<div className="flex w-full items-center justify-center gap-5 pt-7">
					<Button
						onClick={(e) => {
							e.preventDefault();
							handleOpenEmail();
						}}
						disabled={false}
						additionalClasses="text-white p-7"
					>
						Open Gmail
					</Button>
					<Button
						onClick={handleResendEmail}
						disabled={false}
						additionalClasses="bg-transparent border-[1.5px]  border-[#002060] "
					>
						{isLoading ? (
							<Loader2 color="#002060" />
						) : (
							"Resend Email"
						)}
					</Button>
				</div>
				<p className="pt-7 text-center text-xs font-[500] text-gray-800">
					Need help?{" "}
					<span className="cursor-pointer text-blue-800">
						Visit support{" "}
					</span>{" "}
					or{" "}
					<span className="cursor-pointer text-blue-800">
						Contact Us
					</span>{" "}
				</p>
			</div>
			{/* </div> */}
		</Fragment>
	);
};

export default ResendEmail;
