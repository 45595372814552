import { Button } from "components/Button";
import React from "react";
import { useNavigate } from "react-router";

const SaveContinueProcess = () => {
	const navigate = useNavigate();
	return (
		<Button
			disabled={false}
			additionalClasses="w-content h-auto bg-transparent text-xl font-medium  underline-offset-4 text-error-600 underline !w-auto"
			onClick={() => navigate("/dashboard")}
		>
			Save and Continue later
		</Button>
	);
};

export default SaveContinueProcess;
