import { OnboardingProcessStageProps } from "types/components/dashboard";
import React from "react";

export const OnboardingProcessStage: React.FC<OnboardingProcessStageProps> = ({
	stage,
	title,
}) => {
	return (
		<div className="flex flex-shrink-0 items-center gap-3.5 ">
			<div className="flex h-8 w-8 max-w-fit items-center justify-center rounded-full bg-primary-700 px-3 py-1.5 text-[14px] font-medium text-white">
				{stage}
			</div>
			<p className="text-[14px] font-medium text-[#061F6A]">{title}</p>
		</div>
	);
};
