import React, { Dispatch, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import { NotificationType3 } from "../../services/Constants";
import { FaExclamation, FaCheck, FaX } from "react-icons/fa6";
import RequestIsLoading from "../RequestIsLoading";
import { NotificationType } from "../../types/components/dashboard/dashboard";
import NumberFormat from "../../utils/NumberFormat";
import { parseISO, format } from "date-fns";
import { useNotificationWebSocketContext } from "../../provider/NotificationProvider";
import NotificationTile from "./Notifications/NotificationTile";
import RecordDetails from "./Notifications/RecordDetails";

interface Props {
	dateString: string; // Prop to receive the date string
}

const FormattedDate: React.FC<Props> = ({ dateString }) => {
	try {
		const parsedDate = parseISO(dateString);
		const formattedDate = format(parsedDate, "do 'of' MMM, yyyy");
		return <div>{formattedDate}</div>;
	} catch (error) {
		console.error("Error parsing date string:", error);
		return <div>Invalid date format</div>;
	}
};

interface NotificationContainerProps {
	data: any[] | undefined;
}

const NotificationContainer: React.FC<NotificationContainerProps> = ({
	data,
}) => {
	return data.length === 0 ? (
		<div className="h-full w-full rounded-md bg-white p-6">
			<div className="flex items-center justify-between">
				<h1 className="font-semibold">Notifications</h1>
				<Link
					to="/dashboard/profile/notifications"
					className="text-sm hover:underline"
				>
					View all
				</Link>
			</div>
			<div className="mt-[37px] flex w-full flex-col items-center justify-center">
				<div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-primary-100 p-2">
					<div className="flex h-full w-full items-center justify-center rounded-full bg-[#DBF2FF] p-2">
						<span className="">
							<svg
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.5 19L15.15 14.65M17.5 9C17.5 13.4183 13.9183 17 9.5 17C5.08172 17 1.5 13.4183 1.5 9C1.5 4.58172 5.08172 1 9.5 1C13.9183 1 17.5 4.58172 17.5 9Z"
									stroke="#2A60B7"
									strokeWidth="1.66667"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</div>
				</div>
				<h1 className="mb-6 mt-4 text-xl font-semibold">
					No new notification
				</h1>
			</div>
		</div>
	) : (
		<div className="flex h-full w-full flex-col gap-y-8 rounded-md bg-white p-6">
			<div className="flex items-center justify-between">
				<h1 className="font-semibold">Notifications</h1>
				<Link
					to="/dashboard/profile/notifications"
					className="text-sm hover:underline"
				>
					View all
				</Link>
			</div>
			{data.slice(0, 5).map((notification: any) => {
				return (
					<div
						key={notification.id}
						className="flex w-full justify-between mmd:flex-col mmd:gap-3"
					>
						<div className="flex items-center gap-2">
							<span
								className={`flex h-[31px] w-[31px] items-center justify-center rounded-full ${
									notification.heading ===
									NotificationType3.CreditApplied
										? "bg-[#DBA32A]"
										: notification.heading ===
										  NotificationType3.CreditAuthorized
										? "bg-[#7FB519]"
										: notification.heading ===
										  NotificationType3.CreditCancelled
										? "bg-red-700"
										: "bg-[#F5FCD254]"
								} bg-opacity-20 p-2`}
							>
								{notification.heading ===
									NotificationType3.CreditApplied && (
									<FaExclamation
										size={12}
										className="text-[#DBA32A]"
									/>
								)}

								{notification.heading ===
									NotificationType3.CreditCancelled && (
									<FaX size={12} className="text-red-700" />
								)}

								{notification.heading ===
									NotificationType3.CreditAuthorized && (
									<FaCheck
										size={12}
										className="text-[#7FB519]"
									/>
								)}
								{/* <ArrowUp size="15" color="#7FB519" />
								<ArrowDown size="15" color="#DB2719" />
								<CloseCircle size="15" color="#FF8A65" />
								<InfoCircle size="15" color="#FF8A65" /> */}
							</span>
							<h2 className="text-base font-bold text-gray-900">
								{notification?.heading}
							</h2>
						</div>
						<div className="flex flex-col items-center justify-end gap-1 mmd:flex-row">
							<NumberFormat
								className="w-full flex-1 text-right text-[11px] font-semibold text-gray-900 mmd:text-left"
								value={notification?.content_object?.amount}
							/>
							<span className="flex-1 text-[10px] text-gray-500 mmd:text-right">
								<FormattedDate
									dateString={notification?.created}
								/>
							</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default NotificationContainer;
