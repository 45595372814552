import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { getCookie } from "../utils/cookies";
import { useQueryClient } from "react-query";
import { NotificationType } from "../types/components/dashboard/dashboard";
import { useAuth } from "../hooks/auth";
import { useGetUnreadNotifications } from "../hooks/useCustomerData";
import { Toast } from "../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URI } from "../services/Constants";

export interface NotificationWebSocketContextProps {
	wsInstance: WebSocket | null;
	unreadNotificationCount: string;
	setUnreadNotificationCount: Dispatch<SetStateAction<string>>;
}

const NotificationWebSocketContext = createContext<any>(null);

export const useNotificationWebSocketContext = () =>
	useContext(NotificationWebSocketContext);

const NotificationWebSocketProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	let wsInstance: WebSocket | null = null;
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	const [unreadNotificationCount, setUnreadNotificationCount] = useState(
		user && user.unread_notifications_count
			? user.unread_notifications_count
			: "0"
	);

	const [token, setToken] = useState(getCookie("ac-token"));

	const value: NotificationWebSocketContextProps = {
		wsInstance,
		unreadNotificationCount,
		setUnreadNotificationCount,
	};

	useGetUnreadNotifications(
		undefined,
		() => {
			Toast.error("Error getting notifications");
		},
		setUnreadNotificationCount,
		!!user && !!token && location.pathname !== "/"
	);

	const updateQueryData = (newData: NotificationType) => {
		queryClient.setQueryData(
			["unseen_notifications"],
			(oldData: { data: { data: NotificationType[] } }) => {
				if (!oldData.data.data) return oldData;
				const joinedData: NotificationType[] = [
					newData,
					...oldData.data.data,
				];
				return {
					data: {
						data: joinedData,
					},
				};
			}
		);
	};

	const createWebSocket = () => {
		if (token && wsInstance === null) {
			const domain = API_URI.split("https://")[1];
			const url = `wss://${domain}/websocket/notifications/`;

			wsInstance = new WebSocket(`${url}?auth=${token}`);

			wsInstance.onopen = (event) => {
				// console.log("WebSocket connection opened:", event);
			};

			wsInstance.onmessage = (event) => {
				// console.log("Received message:", event.data);
				const newData = JSON.parse(event.data);
				updateQueryData(newData);
				Toast.info("You just got a new notification, click here", {
					onClick: () => {
						navigate("dashboard/profile/notifications");
					},
				});
			};

			wsInstance.onclose = (event) => {
				// console.log("WebSocket connection closed:", event);
			};

			wsInstance.onerror = (error) => {
				// console.error("WebSocket error:", error);
			};
			return wsInstance;
		} else {
			// console.log(wsInstance);
			if (!user) wsInstance?.close();
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			const newValue = getCookie("ac-token");
			if (newValue !== token) {
				setToken(newValue);
				wsInstance?.close();
			}
			if (!user || newValue === null || newValue?.length === 0) {
				wsInstance?.close();
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [token, user]);

	useEffect(() => {
		const socket = createWebSocket();
		if (token === null || token?.length === 0) {
			return () => {
				if (wsInstance) socket?.close();
			};
		}
	}, [wsInstance, token]);

	return (
		<NotificationWebSocketContext.Provider value={value}>
			{children}
		</NotificationWebSocketContext.Provider>
	);
};

export default NotificationWebSocketProvider;
