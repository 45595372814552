import { NextOfKinRelationship } from "services/Constants";
import { FormInput, Loader2 } from "components";
import { DistributorCreditChildrenProps } from "types/components/dashboard/distributor";
import { validateTextNumber } from "utils/utilities";

interface DistributorKinFormProps extends DistributorCreditChildrenProps {
	page: 4;
	isLoading: boolean;
}

export const DistributorKinForm = ({
	formData,
	setPage,
	setFormData,
	isLoading,
}: DistributorKinFormProps) => {
	return (
		<div className=" flex w-full flex-col items-center gap-3">
			<h1 className="text-2xl font-semibold text-[#002060]">
				Next of Kin (I) Details
			</h1>
			<FormInput
				label="Next of Kin's Name"
				name="next_of_kin_name"
				id="next_of_kin_name"
				value={formData.next_of_kin.name}
				placeholder="Enter Next of kin's full name"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						next_of_kin: {
							...formData.next_of_kin,
							name: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Next of Kin's Address"
				name="next_of_kin_address"
				id="next_of_kin_address"
				value={formData.next_of_kin.address}
				placeholder="Enter Next of kin's address"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						next_of_kin: {
							...formData.next_of_kin,
							address: e.target.value,
						},
					})
				}
			/>
			<FormInput
				label="Next of Kin's Phone Number"
				name="next_of_kin_phone"
				id="next_of_kin_phone"
				value={formData.next_of_kin.phone}
				placeholder="Enter Next of kin's Phone number"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				type="text"
				minLength={11}
				maxLength={11}
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						next_of_kin: {
							...formData.next_of_kin,
							phone: validateTextNumber(e.target.value),
						},
					})
				}
			/>
			<div className="w-full">
				<FormInput
					label="Relationship"
					name="relationship"
					id="relationship"
					placeholder="Select relationship from drop down"
					value={formData.next_of_kin.relationship ?? ""}
					type="text"
					onChange={(selectedOption: {
						value: string;
						label: string;
					}) =>
						setFormData({
							...formData,
							next_of_kin: {
								...formData.next_of_kin,
								relationship: selectedOption.value,
							},
						})
					}
					options={NextOfKinRelationship}
					autoComplete="false"
					required={false}
					select={true}
				/>
			</div>
			<div className="mx-auto flex w-[95%] flex-col items-center gap-2 pt-2">
				<button
					type="submit"
					disabled={isLoading}
					className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white"
				>
					{isLoading ? <Loader2 /> : "Next"}
				</button>

				<button
					type="button"
					className="self-center text-sm font-semibold text-blue-900"
					onClick={() => setPage(3)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};
