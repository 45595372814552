import React, { useState } from "react";
import { getStatusColor } from "../utils/utilities";
import { StatusType } from "../types/utils";

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "./Table/dropdown-menu";
import { CaretSortIcon } from "@radix-ui/react-icons";
interface DropdownProps {
	options: StatusType[] | string[];
	onSelect: (selectedOption: StatusType) => void;
	onClear?: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onSelect, onClear }) => {
	const [selectedOption, setSelectedOption] = useState<StatusType | null>(
		null
	);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleOptionSelect = (option: StatusType) => {
		setSelectedOption(option);
		onSelect(option);
		setIsOpen(false);
	};
	const handleShowAll = () => {
		setSelectedOption(null);
		onClear?.();
		setIsOpen(false);
	};
	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="w-[100px] focus:outline-none">
				<div className="tranistion-all flex w-[100px] items-center justify-between whitespace-nowrap rounded-md border border-zinc-200 bg-white px-3 py-2 text-sm font-normal text-black shadow-sm duration-300 hover:bg-zinc-100/60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-zinc-950  ">
					Status
					<CaretSortIcon aria-hidden="true" />
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent className=" min-w-[6rem] space-y-2 p-1">
				{options?.map((option: StatusType | string) => (
					<DropdownMenuItem
						key={option as StatusType}
						className={`flex w-full cursor-pointer items-center gap-2 rounded-md bg-opacity-20 px-2 py-1 ${getStatusColor(
							option as StatusType,
							"background"
						)}`}
						onClick={() => handleOptionSelect(option as StatusType)}
					>
						<span
							className={`text-[12px] font-semibold  ${getStatusColor(
								option as StatusType,
								"text"
							)}`}
						>
							{option || "N/A"}
						</span>
					</DropdownMenuItem>
				))}{" "}
				<div
					className="flex w-full cursor-pointer items-center gap-2 rounded-md bg-gray-300 bg-opacity-20 px-2 py-1 text-gray-500"
					onClick={handleShowAll}
				>
					<span className="text-[12px] font-semibold">Show All</span>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default Dropdown;
