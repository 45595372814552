import { FormInput } from "../../../../components/FormInput";
import { ProcessUploadFile } from "../../../../components/Onboarding";
import { CreditLoanPeriod } from "services/Constants";
import { AnimatePresence, motion } from "framer-motion";
import UploadedFile from "../../../../components/UploadedFile";
import { uploadedFileContainerVariants } from "services/Animations";
import {
	DistributorCreditChildrenProps,
	DistributorCreditFormData,
} from "types/components/dashboard/distributor";
import { Toast } from "utils/toast";

interface DistributorApplyFormProps extends DistributorCreditChildrenProps {
	page: 1;
	bankData: { value: string; label: string }[];
}

function formValidation(formData: DistributorCreditFormData): boolean {
	if (formData.credit_amount === "") {
		Toast.info("Credit amount cannot be blank");
		return false;
	} else if (formData.tenure === "") {
		Toast.info("Tenure must be filled");
		return false;
	}
	// else if (!formData.invoice_file) {
	// 	Toast.info("Invoice must be uploaded");
	// 	return false;
	// }
	else if (!formData.bank_code) {
		Toast.info("Select your bank");
		return false;
	} else if (!formData.bank_statement_file) {
		Toast.info("Bank statement must be uploaded");
		return false;
	}
	return true;
}
export const DistributorApplyForm = ({
	formData,
	setPage,
	setFormData,
	setStep,
	bankData,
}: DistributorApplyFormProps) => {
	return (
		<>
			<div className="flex w-full flex-col items-center gap-3">
				<div className="flex flex-col">
					<h1 className="text-center text-2xl  font-semibold text-[#002060]">
						Apply for Credit
					</h1>
					<p className="text-gray-500">Enter Credit Details</p>
				</div>
				<div className="relative w-full">
					<FormInput
						label="Credit Amount"
						name="credit_amount"
						id="credit_amount"
						value={formData.credit_amount}
						placeholder="Enter the loan amount"
						type="text"
						required={true}
						minLength={4}
						autoComplete="off"
						additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent !px-6 py-2 text-[15px] placeholder-black placeholder-opacity-50 outline-none"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
							const formattedValue = value.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								","
							);

							if (formattedValue.startsWith("0")) {
								return;
							}

							// const creditAmount = parseInt(
							// 	e.target.value.replace(/,/g, ""),
							// 	10
							// );

							// if (creditAmount > 250000) {
							// 	return Toast.info(
							// 		"Credit Amount cannot be greater than NGN250,000"
							// 	);
							// }

							setFormData({
								...formData,
								credit_amount: formattedValue,
							});
						}}
					/>
					<span className="absolute bottom-[10%] left-2">₦</span>
				</div>
				<div className="relative w-full">
					<FormInput
						label="Loan Tenure"
						name="loan_tenure"
						id="loan_tenure"
						placeholder="Enter the loan tenure"
						value={formData.tenure ?? ""}
						type="text"
						required={true}
						additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent  py-2 text-[15px] placeholder-black placeholder-opacity-50 outline-none"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
							const formattedValue = value.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								","
							);

							if (formattedValue.startsWith("0")) {
								return;
							}

							const creditAmount = parseInt(
								e.target.value.replace(/,/g, ""),
								10
							);

							if (creditAmount > 60) {
								return Toast.info(
									"Loan tenure cannot be greater than 60days"
								);
							}
							setFormData({
								...formData,
								tenure: formattedValue,
							});
						}}
						autoComplete="false"
					/>
					<span className="absolute bottom-2 right-2">days</span>
				</div>

				{/* <div className="flex w-full flex-col">
					<h1 className="text-[18px]flex relative items-center font-semibold text-primary-700">
						Invoice Upload{" "}
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</h1>
					<p className="text-[#626D88]">Upload Your Invoice Here</p>
					<ProcessUploadFile
						formats={[
							"jpeg",
							"jpg",
							"png",
							"pdf",
							"ai",
							"doc",
							"docx",
							"ppt",
							"pptx",
						]}
						maxSize={2000000}
						setFile={setFormData}
						localStorageFileName={"invoice_file"}
					/>
					<AnimatePresence>
						{formData.invoice_file && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={formData.invoice_fileName}
										deleteFile={() => {
											setFormData({
												...formData,
												invoice_file: null,
											});
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div> */}
				<div className="w-full">
					<FormInput
						label="Bank"
						labelStyles="font-medium"
						name="bank_name"
						id="bank_name"
						select={true}
						options={bankData}
						placeholderStyleOptions={{
							color: "#333333",
							opacity: 0.7,
							fontSize: "15px",
						}}
						selectStyleOptions={{
							fontSize: "14px",
						}}
						type="select"
						placeholder="Select a bank"
						additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
						value={formData.bank_code}
						autoComplete="off"
						required={true}
						onChange={(bank) => {
							setFormData((prevData) => {
								return {
									...prevData,
									bank_code: bank?.value,
								};
							});
						}}
					/>
				</div>
				<div className="flex w-full flex-col">
					<h1 className="relative text-[18px] font-semibold text-primary-700">
						Bank Statement Upload
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</h1>
					<p className="text-[#626D88]">Upload your statement here</p>
					<ProcessUploadFile
						formats={["pdf"]}
						maxSize={2000000}
						setFile={setFormData}
						localStorageFileName={"bank_statement_file"}
					/>
					<AnimatePresence>
						{formData.bank_statement_file && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={formData.bank_statement_fileName}
										deleteFile={() => {
											setFormData({
												...formData,
												bank_statement_file: null,
											});
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				<div className="mx-auto flex  w-full flex-col gap-2 pt-2">
					<button
						type="button"
						onClick={() => {
							if (!formValidation(formData)) {
								return;
							} else {
								setPage(2);
								setStep(2); // remove this later and call in parent element
							}
						}}
						className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white disabled:bg-gray-400"
					>
						Next
					</button>
					<button
						type="button"
						className="text-[#072154]"
						onClick={() => {
							setStep(0); // remove this later and call in parent element
							setPage(0);
						}}
					>
						return back
					</button>
				</div>
			</div>
		</>
	);
};
