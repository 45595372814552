import * as React from "react";
import { format, parse } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "../utils/utilities";
import { Calendar } from "./Table/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Table/popover";

interface DatePickerWithRangeProps<TData> {
	className?: React.HTMLAttributes<HTMLDivElement>;
	dateRange: DateRange | undefined;
	setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
	data: TData[];
	dateFieldName: string;
}

export function DatePickerWithRange<TData>({
	className,
	setDateRange,
	dateRange,
	data,
	dateFieldName,
}: DatePickerWithRangeProps<TData>) {
	const [fromValue, setFromValue] = React.useState<string>("");
	const [toValue, setToValue] = React.useState<string>("");

	const earliestYear = Math.min(
		...data.map((item: any) =>
			new Date(
				item?.[dateFieldName]?.replace(/(\d+)(st|nd|rd|th)/, "$1")
			).getFullYear()
		)
	);
	const oldestYear = Math.max(
		...data.map((item: any) =>
			new Date(
				item?.[dateFieldName]?.replace(/(\d+)(st|nd|rd|th)/, "$1")
			).getFullYear()
		)
	);
	const handleFromChange: React.ChangeEventHandler<HTMLInputElement> = (
		e
	) => {
		setFromValue(e.target.value);
		const fromDate = parse(e.target.value, "LLL dd, y", new Date());
		if (!isNaN(fromDate.getTime())) {
			const newDateRange: DateRange = {
				from: fromDate,
				to: dateRange?.to,
			};
			setDateRange(newDateRange);
		}
	};

	const handleToChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setToValue(e.target.value);
		const toDate = parse(e.target.value, "LLL dd, y", new Date());
		if (!isNaN(toDate.getTime())) {
			const newDateRange: DateRange = {
				from: dateRange?.from,
				to: toDate,
			};
			setDateRange(newDateRange);
		}
	};

	const handleRangeSelect: (range: DateRange | undefined) => void = (
		range
	) => {
		if (range) {
			setFromValue(format(range.from || new Date(), "LLL dd, y"));
			setToValue(format(range.to || new Date(), "LLL dd, y"));
			setDateRange(range);
		} else {
			setFromValue("");
			setToValue("");
			setDateRange(undefined);
		}
	};

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover>
				<PopoverTrigger asChild>
					<div className="flex items-center">
						<input
							type="text"
							placeholder="From Date"
							value={fromValue}
							onChange={handleFromChange}
							className={cn(
								"w-[130px] rounded-md border p-2 text-[14px]  focus:border-blue-950 focus:outline-none focus:ring-1 focus:ring-blue-950",
								!dateRange && "text-gray-400"
							)}
						/>
						<span className="mx-1">-</span>
						<input
							type="text"
							placeholder="To Date"
							value={
								dateRange?.to
									? format(dateRange.to, "LLL dd, y")
									: ""
							}
							onChange={handleToChange}
							className={cn(
								"w-[130px] rounded-md border p-2 text-[14px] focus:border-blue-950 focus:outline-none focus:ring-1 focus:ring-blue-950",
								!dateRange && "text-gray-400"
							)}
						/>
					</div>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						selected={dateRange}
						onSelect={handleRangeSelect}
						numberOfMonths={2}
						fromYear={earliestYear}
						toYear={oldestYear}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
}
