import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, SmartCamera } from "../../components";
import { ProcessTransitionAnimation } from "../../services/Animations";
import { BiCameraHome } from "react-icons/bi";
import { OnboardingFormContext } from ".";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

export const SelfieUploadProcess = () => {
	const { onboardingFormData, setOnboardingFormData, setPage } = useContext(
		OnboardingFormContext
	);
	const [showSelfieButton, setShowSelfieButton] = useState<boolean>(false);
	const handleSelfieImageChange = (data: any) => {
		const selfieData = data;
		setOnboardingFormData({
			...onboardingFormData,
			selfiePhoto: JSON.stringify(selfieData),
		});
		// localStorage.setItem("selfiePhotoData", JSON.stringify(selfieData));
	};

	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex w-full max-w-[510px] flex-col items-center gap-4 rounded-lg bg-white px-8 py-8 shadow"
			>
				{onboardingFormData.selfiePhoto && (
					<div className="flex w-full flex-col items-center gap-1">
						<h2 className="text-xl font-semibold text-[#002060]">
							Verify your identity
						</h2>
						<p className="mb-3 text-lg font-medium text-[#64748B]">
							Your Selfie has been successfully been taken
						</p>
						{onboardingFormData.selfiePhoto && (
							<Button
								onClick={() => setPage(4)}
								disabled={false}
								additionalClasses="text-white"
							>
								Proceed
							</Button>
						)}
						<div className="pt-4">
							<SaveContinueProcess />
						</div>
					</div>
				)}
				{showSelfieButton && !onboardingFormData.selfiePhoto && (
					<>
						{" "}
						<h2 className="text-xl font-semibold text-[#002060]">
							Verify your identity
						</h2>
						<SmartCamera
							onHandleImageChanged={handleSelfieImageChange}
						/>{" "}
						{onboardingFormData.selfiePhoto && (
							<Button
								onClick={() => setPage(4)}
								disabled={false}
								additionalClasses="gap-2  bg-primary-700  items-center text-white justify-center"
							>
								Proceed
							</Button>
						)}
					</>
				)}

				{!showSelfieButton && !onboardingFormData.selfiePhoto && (
					<>
						<h2 className="text-xl font-semibold text-[#002060]">
							Verify your identity
						</h2>
						<Button
							onClick={() => setShowSelfieButton(true)}
							disabled={false}
							additionalClasses=" bg-primary-700 gap-2 items-center text-white justify-center"
						>
							<span className="text-xl">
								<BiCameraHome />
							</span>
							<span>Take a Selfie</span>
						</Button>
						<div className="flex flex-col items-center">
							<p className="font-medium text-[#64748B]">
								Take a selfie and verify your identity
							</p>
							<div className="pt-4 ">
								<SaveContinueProcess />
							</div>
						</div>
					</>
				)}
			</motion.section>
		</AnimatePresence>
	);
};

export default SelfieUploadProcess;
