import React, {
	useEffect,
	Dispatch,
	SetStateAction,
	useRef,
	Fragment,
} from "react";
import { MONO_KEY } from "../services/Constants";
import { useLinkToMono } from "../hooks/useAuthData";
import { SuccessModal } from "./modals/SuccessModal";
import { AxiosResponse } from "axios";
import RequestIsLoading from "./RequestIsLoading";
import { Button } from "./Button";

export interface MonoConnectResponse {
	code: string;
}

interface MonoConnectConfig {
	key: string;
	onSuccess: (response: MonoConnectResponse) => void;
	onClose: () => void;
	// Add other properties if available in the actual config
}

declare class Connect {
	constructor(config: MonoConnectConfig);
	setup(): void;
	open(): void;
	// Add other methods or properties if available in the actual Connect class
}

interface MonoConnectProps {
	onSuccess: () => void;
	showMonoModal?: boolean;
	setShow?: Dispatch<SetStateAction<boolean>>;
	viewMode?: string;
	buttonContent?: string;
	buttonAction?: boolean;
	auth?: string;
	isLoading?: boolean;
}

export const MonoConnectComponent: React.FC<MonoConnectProps> = ({
	onSuccess,
	showMonoModal,
	setShow,
	viewMode,
	buttonContent,
	buttonAction,
	auth,
	isLoading: loading,
}) => {
	const launchButtonRefInline = useRef(null);
	const launchButtonRefModal = useRef(null);
	const { mutateConfirmMonoAuth, isLoading } = useLinkToMono(onSuccess);

	useEffect(() => {
		const config: MonoConnectConfig = {
			key: MONO_KEY,
			onSuccess: function (response: MonoConnectResponse) {
				mutateConfirmMonoAuth({ code: response.code, auth: auth });
				if (viewMode !== "inline") {
					setShow(false);
				}
			},
			onClose: function () {
				null;
			},
		};

		const connect = new Connect(config);
		connect.setup();

		const launch = document.getElementById("launch-btn");
		if (launch) {
			launch.onclick = function (e) {
				e.preventDefault();
				connect.open();
			};
		}
	}, []);

	const handleContinue = () => {
		if (viewMode === "inline") {
			if (buttonAction && launchButtonRefInline.current) {
				launchButtonRefInline.current.click();
			}
		} else {
			if (launchButtonRefModal.current) {
				setShow(false);
				launchButtonRefModal.current.click();
			}
		}
	};

	return (
		<Fragment>
			<div className="hidden p-5">
				{viewMode === "inline" ? (
					<button id="launch-btn" ref={launchButtonRefInline}>
						Launch Inline
					</button>
				) : (
					<button id="launch-btn" ref={launchButtonRefModal}>
						Launch Modal
					</button>
				)}
			</div>
			<RequestIsLoading isLoading={isLoading || loading} />
			{viewMode === "inline" ? (
				<Button
					additionalClasses=" w-fit text-white"
					disabled={isLoading || loading}
					onClick={handleContinue}
				>
					{buttonContent}
				</Button>
			) : (
				<SuccessModal
					show={showMonoModal}
					setShow={setShow}
					title="Grant Access?"
					buttonAction={handleContinue}
					buttonContent="Continue"
					content="By clicking continue, you are giving HADI access to your bank statement on MONO"
				/>
			)}
		</Fragment>
	);
};
