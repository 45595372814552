import { CreditAcceptProcessProps } from "types/views";
import { Button } from "../../../components/Button";
import React from "react";
import logo from "../../../assets/image/blueLogo.svg";
import { getDueDate, formatAmountToNaira } from "../../../utils/utilities";
const Receipt: React.FC<CreditAcceptProcessProps> = ({
	creditData,
	stage,
	code,
	setStage,
	setIsOTPComplete,
	goBack,
}) => {
	const date = new Date(creditData?.data?.data?.created);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	const handlePrintReceipt = () => {
		window.print();
	};
	return (
		<>
			{stage === 3 && (
				<section className="mx-auto w-full max-w-[640px] p-5">
					{/* <div className="flex items-center justify-center py-5">
						<img src={logo} alt="Hadi finance logo" />
					</div> */}

					<h3 className="pt-6 text-center text-[24px] font-semibold text-blue-950">
						Transaction Receipt
					</h3>
					<div className="flex flex-col gap-2 pt-[18px]">
						<p className="pb-2 text-[16px] font-semibold text-blue-950">
							Transaction Summary
						</p>
						<hr className="border-[0.5px] border-slate-300" />
						<table className="w-full">
							<tr className="w-full">
								<td className="w-[70%]">Credit Applicant:</td>
								<td className="w-[30%]">
									{creditData?.data?.data?.retailer_name}
								</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Purchase Amount:</td>
								<td className="w-[30%]">
									{formatAmountToNaira(
										creditData?.data?.data?.content_object
											?.total_amount
									)}
								</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Credit Status:</td>
								<td className="w-[30%]">Disbursed</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Disbursed Date:</td>
								<td className="w-[30%]">
									{getDueDate(new Date())}
								</td>
							</tr>
						</table>

						<hr className="border-[0.5px] border-slate-300" />
					</div>

					<div className="flex flex-col gap-2 pt-[18px]">
						<p className="pb-2 text-[16px] font-semibold text-blue-950">
							Transaction Information
						</p>
						<hr className="border-[0.5px] border-slate-300" />
						<table className="w-full">
							<tr className="w-full">
								<td className="w-[70%]">Credit Amount:</td>
								<td className="w-[30%]">
									{formatAmountToNaira(
										creditData?.data?.data?.amount
									)}
								</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Credit Offer:</td>
								<td className="w-[30%]">
									{formatAmountToNaira(
										creditData?.data?.data?.credit_offer
									)}
								</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Repayment Amount:</td>
								<td className="w-[30%]">
									{creditData?.data?.data?.repayment_amount
										? formatAmountToNaira(
												creditData?.data?.data
													?.repayment_amount
										  )
										: "NaN"}
								</td>
							</tr>
							<tr className="w-full">
								<td className="w-[70%]">Payment Method:</td>
								<td className="w-[30%]">Cash</td>
							</tr>
						</table>

						<hr className="border-[0.5px] border-slate-300" />
					</div>

					<div className="pt-[18px]">
						<p className="text-[16px] font-[600] text-blue-950">
							Read Me!
						</p>
						<div className="mt-4 rounded bg-slate-100 px-2 py-2">
							<p className="text-[14px] text-slate-500">
								Your Credit is Due for Repayment on
								{creditData?.data?.data?.due_date
									? " " +
									  getDueDate(
											creditData?.data?.data?.due_date
									  )
									: " NaN"}
								, make sure your credit is repaid on or before
								the due date to be able to access credit.
							</p>
						</div>
						{/* <div className="pt-4">
							<p className="text-[16px] font-[600] text-blue-950">
								Make Repayments by Transfer To your Hadi Pay
								Account
							</p>
							<div className="space-y-1 pt-2">
								<p>Anthony Wagu </p>
								<p>3903737219</p>
								<p>HadiPay</p>
							</div>
						</div>
						<div className="pt-4">
							<p className="text-[16px] font-[600] text-blue-950">
								Make Repayments through your Distributor
							</p>
							<div className="space-y-1 pt-2">
								<p>Anthony Wagu </p>
								<p>3903737219</p>
								<p>HadiPay</p>
							</div>
						</div> */}

						<div className="mx-auto mt-4 max-w-[122px]">
							<Button
								disabled={false}
								onClick={() => {
									handlePrintReceipt();
								}}
								additionalClasses="text-lg text-white"
							>
								Download
							</Button>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default Receipt;
