import { ProcessUploadFile } from "components/Onboarding";
import { AnimatePresence, motion } from "framer-motion";
import { UploadedFile } from "../../UploadedFile";
import { uploadedFileContainerVariants } from "../../../services/Animations";
import { GuarantorCreditAcceptPageProps } from "types/views";

interface GuarantorAcceptForm2 extends GuarantorCreditAcceptPageProps {
	disabled: boolean;
}
const GuarantorAcceptForm2 = ({
	formData,
	setFormData,
}: GuarantorAcceptForm2) => {
	return (
		<div className="space-y-4 pt-3">
			<div className="p-4">
				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className=" text-blue-950"
					>
						Bank Statement (min of 6 months from request)
					</label>
					<p className="text-sm text-slate-600">
						Upload an image or document of your bank statement
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5000Kb
					setFile={setFormData}
					localStorageFileName={"bank_statement"}
				/>
				<AnimatePresence>
					{formData.bank_statement && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData.bank_statementName}
								deleteFile={() => {
									setFormData((prevData: any) => ({
										...prevData,
										bank_statement: null,
									}));
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<div className="p-4">
				<div className="flex flex-col gap-2">
					<label
						htmlFor="digital_signature"
						className=" text-blue-950"
					>
						Signature (not compulsory)
					</label>
					<p className="text-sm text-slate-600">
						Upload an image or document of your signature
					</p>
				</div>
				<ProcessUploadFile
					formats={["jpeg", "jpg", "png", "pdf", "doc", "docx"]}
					maxSize={5 * 1024 * 1024} // 5mb
					setFile={setFormData}
					localStorageFileName={"signature_document"}
				/>
				<AnimatePresence>
					{formData?.signature_document && (
						<motion.div
							className="mt-3 flex w-full flex-col gap-2"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<UploadedFile
								title={formData?.signature_documentName}
								deleteFile={() => {
									setFormData((prevData: any) => ({
										...prevData,
										signature_document: null,
									}));
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default GuarantorAcceptForm2;
