import React, { Dispatch, SetStateAction, useState } from "react";
import { NotificationType3 } from "services/Constants";
import { getDueDate, getTime } from "utils/utilities";
import {
	RiInformationFill,
	RiCloseCircleFill,
	RiCheckboxCircleFill,
} from "react-icons/ri";
import { NotificationType } from "types/components/dashboard/dashboard";
import RecordItem from "./RecordItem";

interface NotificationTileProps {
	notification: NotificationType;
	onClick: () => void;
	tab?: number;
	newSeenNotifications?: NotificationType[] | [] | undefined;
	setNewSeenNotifications?:
		| Dispatch<SetStateAction<NotificationType[] | []>>
		| undefined;
}

const NotificationTile: React.FC<NotificationTileProps> = ({
	notification,
	onClick,
}) => {
	const [currentRecordDetails, setCurrentRecordDetails] = useState<any>();

	return (
		<li
			className={`flex min-h-[88px] cursor-pointer flex-col justify-center gap-2 rounded-lg border-b border-slate-200 px-2.5  duration-200 ease-in-out ${
				!notification.isSeen || !notification.is_seen
					? "border-l-8 border-l-primary-600 bg-[#EEF9FF]"
					: "bg-white"
			}  ${
				!notification.isSeen || !notification.is_seen
					? " hover:bg-opacity-0"
					: ""
			}`}
			onClick={() => {
				setCurrentRecordDetails(() => {
					if (currentRecordDetails) {
						return undefined;
					} else {
						return notification;
					}
				});
				onClick();
			}}
		>
			<div
				className={`flex flex-1 items-center justify-between gap-2 pl-2.5 duration-200 ease-in-out`}
			>
				<div className="flex flex-[0.6] gap-2 py-4">
					{notification.heading ===
						NotificationType3.CreditAuthorized && (
						<RiCheckboxCircleFill
							color="#038637"
							size={24}
							className="m-1 flex-shrink-0"
						/>
					)}
					{notification.heading ===
						NotificationType3.CreditProcessing && (
						<RiInformationFill
							size={24}
							className="text-flex-shrink-0 m-1 text-2xl text-[#ffcd2a]"
						/>
					)}

					{notification.heading ===
						NotificationType3.CreditApplied && (
						<RiInformationFill
							size={24}
							className="m-1 flex-shrink-0 text-blue-950"
						/>
					)}

					{notification.heading ===
						NotificationType3.CreditCancelled && (
						<RiCloseCircleFill
							size={24}
							className="m-1 flex-shrink-0 text-red-700"
						/>
					)}

					<div className="flex flex-col gap-0.5">
						<p className="text-sm font-medium">
							{notification.heading}
						</p>
						<p className="line-clamp-1 max-w-[360px] text-xs text-slate-500">
							{notification.message}
						</p>
					</div>
				</div>
				<div className=" flex flex-[0.4] flex-col items-end">
					{(!notification.isSeen || !notification.is_seen) && (
						<p className="text-sm font-normal text-[#FF4423]">
							Unread{" "}
						</p>
					)}

					<p className=" text-xs text-[#07122F] opacity-50">
						{getDueDate(new Date(notification.created))};
						{getTime(new Date(notification.created))}
					</p>
				</div>
			</div>
			{currentRecordDetails && (
				<div className="w-max pb-4 pl-[52px] md:hidden">
					<div className="flex cursor-pointer items-center gap-2">
						Record Details
					</div>

					<div className="mt-3">
						<RecordItem
							title={"Date"}
							date={new Date(notification.created)}
						/>
						<RecordItem
							title={"Status"}
							desc={notification.heading}
						/>
					</div>
				</div>
			)}
		</li>
	);
};

export default NotificationTile;
