import { ChangeEvent, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
	Button,
	FormInput,
	UploadedFile,
	ProcessUploadFile,
} from "../../components";
import {
	ProcessTransitionAnimation,
	uploadedFileContainerVariants,
} from "../../services/Animations";
import { OnboardingFormContext } from ".";
import { Toast } from "../../utils/toast";
import { getState, getStateLga } from "utils/utilities";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

export const GovernmentIDProcess = () => {
	const { onboardingFormData, setOnboardingFormData, setPage } = useContext(
		OnboardingFormContext
	);
	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex w-full max-w-[510px] flex-col items-center gap-4 rounded-lg bg-white px-8 py-8 shadow"
			>
				<div className="flex w-full flex-col gap-4">
					<div className="flex flex-col ">
						{/* Government Id  */}
						<div className="flex flex-col text-left">
							<h2 className="text-xl font-semibold text-[#002060]">
								Government ID
							</h2>
							<p className="text-[14px] font-medium text-[#626D88]">
								Take a picture of the selected government issued
								ID to verify your identity
							</p>
						</div>
						<FormInput
							select={true}
							options={[
								{
									label: "International Passport",
									value: "international_passport",
								},
								{
									label: "Driver's License",
									value: "drivers_license",
								},
								{
									label: "Voter's Card",
									value: "voter_card",
								},
								{
									label: "National ID",
									value: "national_id",
								},
							]}
							label=""
							name="document_type"
							id="business_sector"
							type="text"
							value={
								onboardingFormData.governmentIdDetails.idType
							}
							placeholder="Select from the drop-down below"
							autoComplete="off"
							required={false}
							onChange={(selectedOption) => {
								// localStorage.setItem(
								// 	"governmentIdType",
								// 	selectedOption.value
								// );
								setOnboardingFormData({
									...onboardingFormData,
									governmentIdDetails: {
										...onboardingFormData.governmentIdDetails,
										idType: selectedOption.value,
									},
								});
							}}
						/>
					</div>

					{/* Bank verification number  */}
					<div>
						<FormInput
							label="Bank Verification Number (BVN)"
							name="bvn_no"
							id="bvn_no"
							type="text"
							placeholder="Type in your bank verification number"
							minLength={11}
							maxLength={11}
							required={true}
							autoComplete="on"
							additionalClasses="text-[#263238] rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  outline-none placeholder:text-[14px]"
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setOnboardingFormData({
									...onboardingFormData,
									bvn: e.target.value,
								});
							}}
							value={onboardingFormData.bvn ?? ""}
						/>
					</div>
					<FormInput
						label="Government ID Number"
						name="id_no"
						id="id_no"
						type="text"
						placeholder="Type in the id no contained in your uploaded document"
						minLength={5}
						maxLength={25}
						required={true}
						autoComplete="on"
						additionalClasses="text-[#263238] rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  outline-none placeholder:text-[14px]"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setOnboardingFormData({
								...onboardingFormData,
								governmentIdDetails: {
									...onboardingFormData.governmentIdDetails,
									idNo: e.target.value,
								},
							});
						}}
						value={
							onboardingFormData.governmentIdDetails.idNo ?? ""
						}
					/>
					{onboardingFormData.governmentIdDetails.idType ===
						"voter_card" && (
						<>
							<FormInput
								select={true}
								options={getState()}
								label="State on Voter's Card "
								name="document_type"
								id="business_sector"
								type="text"
								value={onboardingFormData.metadata.voter_state}
								placeholder="Select from the drop-down below"
								autoComplete="off"
								required={true}
								onChange={(selectedOption) => {
									// localStorage.setItem(
									// 	"governmentIdType",
									// 	selectedOption.value
									// );
									setOnboardingFormData({
										...onboardingFormData,
										metadata: {
											...onboardingFormData.metadata,
											voter_state: selectedOption.value,
											voter_lga: "",
										},
									});
								}}
							/>
							<FormInput
								select={true}
								options={getStateLga(
									onboardingFormData.metadata.voter_state
								)}
								label="LGA on Voter's Card "
								name="document_type"
								id="business_sector"
								type="text"
								value={onboardingFormData.metadata.voter_lga}
								placeholder="Select from the drop-down below"
								autoComplete="off"
								required={true}
								onChange={(selectedOption) => {
									// localStorage.setItem(
									// 	"governmentIdType",
									// 	selectedOption.value
									// );
									setOnboardingFormData({
										...onboardingFormData,
										metadata: {
											...onboardingFormData.metadata,
											voter_lga: selectedOption.value,
										},
									});
								}}
							/>
						</>
					)}
				</div>
				<div className="flex flex-col gap-2">
					<div className="w-full">
						<div className="flex flex-col text-left">
							<h2 className="text-lg font-semibold text-[#002060]">
								Upload the Front of your Government issued ID
							</h2>
							<p className="text-[14px] leading-5 text-[#626D88]">
								Scan or Upload the front of the PDF, JPEG or PNG
								Copy of the Government issue ID <br />
								<b>Max Size of: 2MB</b>
							</p>
						</div>
						<ProcessUploadFile
							formats={[
								"jpeg",
								"jpg",
								"png",
								"pdf",
								"ai",
								"doc",
								"docx",
								"ppt",
								"pptx",
							]}
							maxSize={2 * 1024 * 1024}
							setFile={setOnboardingFormData}
							localStorageFileName={"governmentFrontFile"}
						/>
					</div>
					<AnimatePresence>
						{onboardingFormData.governmentFrontFile && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={
											onboardingFormData.governmentFrontFileName
										}
										deleteFile={() => {
											setOnboardingFormData(
												(prevData) => ({
													...prevData,
													governmentFrontFile: null,
												})
											);
											localStorage.removeItem(
												"governmentFrontFile"
											);
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				{onboardingFormData.governmentIdDetails.idType !==
					"international_passport" &&
					onboardingFormData.governmentIdDetails.idType !== "" && (
						<div className="flex flex-col gap-2">
							<div className="w-full">
								<div className="flex flex-col text-left">
									<h2 className="text-lg font-semibold text-[#002060]">
										Upload the Back of your Government
										issued ID
									</h2>
									<p className="text-[14px] leading-5 text-[#626D88]">
										Scan or Upload the front of the PDF,
										JPEG or PNG Copy of the Government issue
										ID <br /> <b>Max Size of: 2MB</b>
									</p>
								</div>
								<ProcessUploadFile
									formats={[
										"jpeg",
										"jpg",
										"png",
										"pdf",
										"ai",
										"doc",
										"docx",
										"ppt",
										"pptx",
									]}
									maxSize={2 * 1024 * 1024}
									setFile={setOnboardingFormData}
									localStorageFileName={"governmentBackFile"}
								/>
							</div>
							<AnimatePresence>
								{onboardingFormData.governmentBackFile && (
									<motion.div
										className="flex w-full flex-col gap-4"
										variants={uploadedFileContainerVariants}
										initial="hidden"
										animate="visible"
										exit="exit"
									>
										<h4 className="w-full text-[#676767]">
											Uploaded file(s)
										</h4>
										<div className="flex flex-col gap-3">
											<UploadedFile
												title={
													onboardingFormData.governmentBackFileName
												}
												deleteFile={() => {
													setOnboardingFormData(
														(prevData) => ({
															...prevData,
															governmentBackFile:
																null,
														})
													);
													localStorage.removeItem(
														"governmentBackFile"
													);
												}}
											/>
										</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
					)}

				<div className="mx-auto flex w-full flex-col items-center gap-6">
					<Button
						disabled={false}
						hoverEffect
						onClick={() => {
							if (
								onboardingFormData.governmentFrontFile &&
								onboardingFormData.governmentIdDetails.idNo &&
								onboardingFormData.governmentIdDetails.idType &&
								onboardingFormData.bvn
							) {
								return setPage(2);
							}
							return Toast.error("Please fill all fields");
							// } else if (
							// businessAddress ||
							// 	String(businessAddress).length < 10
							// ) {
							// 	return Toast.info(
							// 		"Kindly fill in a valid address"
							// 	);
							// } else if (!fileBusinessAddress) {
							// 	return Toast.info(
							// 		"Kindly upload a document and fill in the field"
							// 	);
							// }
						}}
						additionalClasses="max-w-[445px] bg-primary-700 text-white"
					>
						Next
					</Button>
					<SaveContinueProcess />
					{/* <Button
						disabled={false}
						additionalClasses=" bg-primary-700 w-content h-auto bg-transparent text-xl font-semibold text-error-600 underline !w-auto"
						onClick={() => navigate("/dashboard")}
					>
						Save and Continue later
					</Button> */}
					{/* <button
						className=" text-sm font-semibold text-blue-900"
						onClick={() => setPage(page - 1)}
					>
						Go back
					</button> */}
				</div>
			</motion.section>
		</AnimatePresence>
	);
};

export default GovernmentIDProcess;
