import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Icon as Iconify } from "@iconify/react";
import { opacityAnimation } from "../services/Animations";
import { RequestSuccessFailProps } from "../types/components/RequestSuccessFail";

export const RequestSuccessFail: React.FC<RequestSuccessFailProps> = ({
	state,
	title,
	subtitle,
}) => {
	return (
		<AnimatePresence>
			<motion.main
				variants={opacityAnimation}
				initial="hidden"
				animate="visible"
				className="m-5 mt-[5%] flex flex-col items-center bg-white"
			>
				<div className="flex items-center justify-center">
					<figure
						className={`flex h-[170px] w-[170px] items-center justify-center rounded-full ${
							state ? "bg-[#002060]" : "bg-[#D52500]"
						}`}
					>
						<Iconify
							icon={state ? "mdi:tick" : "mdi:close"}
							color="white"
							className="h-16 w-16"
						/>
					</figure>
				</div>
				<div className="mt-[10%] flex flex-col items-center">
					<p className="text-[24px] font-[600] text-blue-950">
						{title}
					</p>
					<p className="mt-[15px] text-center text-[14px] text-slate-400">
						{subtitle}
					</p>
				</div>
			</motion.main>
		</AnimatePresence>
	);
};
