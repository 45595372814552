import { useState, useEffect, Dispatch, SetStateAction } from "react";

const getStorageValue = <T,>(key: string, defaultValue: T) => {
	const saved = sessionStorage.getItem(key);
	const initial = saved !== null ? JSON.parse(saved) : defaultValue;
	return initial as T;
};

export const UseSessionStorage = <T,>(
	key: string,
	defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
	const [value, setValue] = useState(() =>
		getStorageValue<T>(key, defaultValue)
	);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
};

const dbName = "appDB";
const storeName = "formDataStore";

const openDB = (): Promise<IDBDatabase> => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName, 1);

		request.onupgradeneeded = (event) => {
			const db = (event.target as IDBOpenDBRequest).result;
			db.createObjectStore(storeName, { keyPath: "id" });
		};

		request.onsuccess = (event) => {
			resolve((event.target as IDBOpenDBRequest).result);
		};

		request.onerror = (event) => {
			reject((event.target as IDBOpenDBRequest).error);
		};
	});
};

const saveToIndexedDB = async (key: string, data: any) => {
	const db = await openDB();
	const transaction = db.transaction(storeName, "readwrite");
	const store = transaction.objectStore(storeName);
	store.put({ id: key, data });
};

const getFromIndexedDB = async (key: string): Promise<any> => {
	const db = await openDB();
	const transaction = db.transaction(storeName, "readonly");
	const store = transaction.objectStore(storeName);
	return new Promise((resolve, reject) => {
		const request = store.get(key);

		request.onsuccess = (event) => {
			resolve((event.target as IDBRequest).result?.data);
		};

		request.onerror = (event) => {
			reject((event.target as IDBRequest).error);
		};
	});
};

export const useIndexedDBStorage = <T,>(
	key: string,
	defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
	const [value, setValue] = useState<T>(defaultValue);

	useEffect(() => {
		(async () => {
			const storedValue = await getFromIndexedDB(key);
			if (storedValue !== undefined) {
				setValue(storedValue);
			}
		})();
	}, [key]);

	useEffect(() => {
		saveToIndexedDB(key, value);
	}, [key, value]);

	return [value, setValue];
};

export const clearIndexedDB = async () => {
	const db = await openDB();
	const transaction = db.transaction(storeName, "readwrite");
	const store = transaction.objectStore(storeName);
	store.clear();
};
