type StepProcessStageProps = {
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	index: number;
	title: string;
};
export const StepProcessStage = ({
	page,
	title,
	setPage,
	index,
}: StepProcessStageProps) => {
	return (
		<div
			className="flex flex-shrink-0 cursor-pointer items-center gap-3.5"
			onClick={() => {
				if (page >= index + 1) {
					setPage(index + 1);
				}
			}}
		>
			<div className="flex h-8 w-8 max-w-fit items-center justify-center rounded-full bg-[#002060] px-3 py-1.5 font-medium text-white">
				{index + 1}
			</div>
			<p className="font-medium text-[#061F6A]">{title}</p>
		</div>
	);
};
