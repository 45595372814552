import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { Overlay } from "./Overlay";
import DeliveryOtp from "../../views/Dashboard/CreditBook/DeliveryOtp";
import { DeliveryModalProps } from "../../types/components/modals/Deliverymodalprops";
import { slideDownAnimation } from "../../services/Animations";
import { Loader2 } from "../Loaders";
import {
	confirmDeliveryOtpData,
	sendDeliveryOtpData,
} from "../../types/hooks/credit";
import SuccessImg from "../../assets/image/icons/success.svg";
import { confirmDeliveryOtp, sendDeliveryOtp } from "../../api/credit";
import { Toast } from "../../utils/toast";
// import { useQueryClient } from "react-query";

export const DeliveryModal: React.FC<DeliveryModalProps> = ({
	show,
	setShow,
	customerData,
	// buttonAction,
	buttonContent,
}) => {
	// const queryClient = useQueryClient();
	const modalRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [otpCode, setOtpCode] = useState<string>("");
	const [loadingOtp, setLoadingOtp] = useState<boolean>(false);
	const [showOtp, setShowOtp] = useState<boolean>(false);
	const [confirmDeliveryOtpError, setConfirmDeliveryOtpError] =
		useState<boolean>(false);
	const [confirmDeliveryOtpSuccess, setConfirmDeliveryOtpSuccess] =
		useState<boolean>(false);
	const [minutes, setMinutes] = useState(5);
	const [seconds, setSeconds] = useState(0);
	const [isCounting, setIsCounting] = useState<boolean>(false);
	const [resendAttempts, setResendAttempts] = useState<number>(0);
	const maxResendAttempts = 3;
	// const [resendOccurence, setResendOccurence] = useState<any>(0);
	// const [response, setResponse] = useState("");
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [successMessage, setSuccessMessage] = useState<boolean>(false);

	useEffect(() => {
		const timer = setInterval(() => {
			if (minutes === 0 && seconds === 0) {
				clearInterval(timer);
				setIsCounting(false);
			} else if (seconds === 0) {
				setSeconds(59);
				setMinutes(minutes - 1);
			} else {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [minutes, seconds]);

	const handleSendDeliveryOtp = async () => {
		const phone = customerData.customer;
		// console.log(customerData);
		if (!phone) {
			Toast.error("No customer available");
		}
		setLoading(true);
		try {
			const otpData: sendDeliveryOtpData = {
				phone_number: phone,
			};
			const response = await sendDeliveryOtp(otpData);

			if (response.status === 200) {
				setLoading(false);
				setShowOtp(true);
				setSuccessMessage(true);
				// setShow(false);
			}
		} catch (error) {
			setLoading(false);
			setShowOtp(true);
			setErrorMessage(true);
		}
	};

	const handleCloseDeliveryModal = async () => {
		setShow(false);
	};

	const handleResendDeliveryOtp = async () => {
		if (resendAttempts >= maxResendAttempts) {
			Toast.info("Contact us to get an OTP");
			return;
		}
		const phone = customerData.customer;
		if (!phone) {
			Toast.error("No Customer available");
		}
		try {
			const otpData: sendDeliveryOtpData = {
				phone_number: phone,
			};

			const response = await sendDeliveryOtp(otpData);

			if (response.status === 200) {
				setSuccessMessage(true);
				setIsCounting(true);
				setMinutes(5);
				setSeconds(0);
				setResendAttempts(resendAttempts + 1);
				// setShow(false);
			}
		} catch (error) {
			setErrorMessage(true);
		}
	};

	const handleConfirmDeliveryOtp = async (data: string) => {
		const phone = customerData.customer;
		if (!phone) {
			Toast.error("Customer data not available");
			// console.log("Customer data not available");
		}
		try {
			setLoadingOtp(true);
			const otpData: confirmDeliveryOtpData = {
				phone_number: phone,
				pin: data,
			};

			const response = await confirmDeliveryOtp(otpData);

			if (response.status === 200 || response.status === 201) {
				// console.log("Goods have been Confirmed");
				setLoadingOtp(false);
				setConfirmDeliveryOtpSuccess(true);
				setTimeout(() => {
					setShow(false);
					window.location.reload();
				}, 4000);
			}
		} catch (error) {
			setLoadingOtp(false);
			setConfirmDeliveryOtpError(true);
		}
	};

	return (
		<Overlay
			show={show}
			setShow={setShow}
			modalRef={modalRef}
			isPersistent={true}
		>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-5 flex flex-col  items-center rounded-[10px] bg-white px-5 py-6"
				>
					<div className="flex w-full justify-end">
						<button
							className="text-[1.5em]"
							onClick={() => setShow(!show)}
						>
							<MdClose />
						</button>
					</div>

					<section className="flex flex-col items-center gap-3">
						{!showOtp && (
							<div className="max-w-[400px]">
								<h3 className="text-center font-semibold text-blue-950 md:text-[20px] lg:text-[24px]">
									Confirm Product Delivery
								</h3>
								<p className="text-center">
									Are you sure you want to confirm the
									delivery of products to{" "}
									<b>
										{customerData?.retailer_name ?? "N/A"}
									</b>
								</p>
								<div className="flex justify-center gap-4 py-4">
									<button
										onClick={handleCloseDeliveryModal}
										className="flex h-8 items-center justify-center rounded-md bg-[#D52500] px-8 py-1 text-white"
									>
										No
									</button>
									<button
										className="flex h-8 items-center justify-center rounded-md border border-[#BABFC3] px-8 py-1 text-[#202223]"
										onClick={handleSendDeliveryOtp}
									>
										{loading ? (
											<Loader2 color="#072154" />
										) : (
											"Yes"
										)}
									</button>
								</div>
								<div className="pt-2 text-center">
									<p className="text-sm">
										<b>Note:</b> The Retailer would receive
										an otp which you would input to confirm
										delivery{" "}
									</p>
								</div>
							</div>
						)}
						{showOtp && !confirmDeliveryOtpSuccess && (
							<div className="">
								<div className="pb-2">
									<h3 className="mb-2 text-center font-semibold text-blue-950 md:text-[20px] lg:text-[24px]">
										Enter Delivery Code
									</h3>
									{successMessage &&
										!confirmDeliveryOtpError && (
											<p className="text-center text-[12px] text-green-600">
												Retailer otp was sent
												successfully, please enter it
												below
											</p>
										)}
									{errorMessage && (
										<p className="text-center text-[12px] text-red-500">
											Retailer otp was not sent, there was
											error
										</p>
									)}
									{confirmDeliveryOtpError && (
										<p className="text-center text-[12px] text-red-500">
											Incorrect otp, Please input a valid
											otp !!
										</p>
									)}
								</div>
								<DeliveryOtp
									handleDeliveryOtp={handleConfirmDeliveryOtp}
									setDeliveryOtp={setOtpCode}
									loading={loadingOtp}
								/>
								{buttonContent && (
									<div className="flex w-full flex-col items-center justify-center gap-2 pt-3">
										{/* <button
											className="w-full rounded-lg bg-[#002060] py-3 text-sm text-white"
											type="submit"
											onClick={handleConfirmDeliveryOtp}
										>
											{buttonContent}
										</button> */}
										<div className="flex items-center gap-3 text-[14px]">
											<p>Didn&apos;t receive otp? </p>

											{!isCounting && (
												<button
													className={`text-[12px] hover:underline-offset-2 ${
														isCounting
															? "text-gray-400"
															: "text-blue-960"
													}  `}
													disabled={isCounting}
													onClick={() => {
														handleResendDeliveryOtp();
													}}
												>
													Resend otp
												</button>
											)}

											{isCounting && (
												<p>
													{minutes < 10 ? "0" : ""}
													{minutes}:
													{seconds < 10 ? "0" : ""}
													{seconds}
												</p>
											)}
										</div>
									</div>
								)}
							</div>
						)}

						{showOtp && confirmDeliveryOtpSuccess && (
							<>
								<div className="flex max-w-sm flex-col items-center gap-y-3">
									<img src={SuccessImg} alt="success" />
									<p className="text-center text-base font-semibold text-[#072154] md:text-lg ">
										Congratulations! The delivery of goods
										to the retailer has been confirmed.
									</p>
								</div>
							</>
						)}
					</section>
				</motion.section>
			)}
		</Overlay>
	);
};
