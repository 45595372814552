import React from "react";
import { StepProcessStage } from "../StepProcessStage";
import { HiOutlineChevronDoubleRight } from "react-icons/hi2";

type StepCounterProps = {
	step: number | 0 | 1 | 2;
	setStep: React.Dispatch<React.SetStateAction<number | 0 | 1 | 2>>;
	processes: string[];
};

const StepCounter = ({ step, setStep, processes }: StepCounterProps) => {
	return (
		<div className="mt-4 flex flex-wrap justify-center gap-5 px-8 md:mt-0">
			{step !== 0 &&
				processes.map((process, index) => (
					<div
						key={index}
						className={`flex items-center gap-3 bg-opacity-5 duration-200 ${
							!(step - 1 >= index) ? "opacity-30" : ""
						} ${step === 0 ? "opacity-30" : ""}`}
					>
						<StepProcessStage
							title={process}
							page={step}
							index={index}
							setPage={setStep}
						/>

						<HiOutlineChevronDoubleRight
							className={`hidden flex-shrink-0 text-[#061F6A] md:block ${
								processes.length - 1 === index && "hidden"
							}`}
						/>
					</div>
				))}
		</div>
	);
};

export default StepCounter;
