import { AxiosResponse } from "axios";
import {
	guarantorAcceptData,
	sendDeliveryOtpData,
	cancelCreditByDistributorData,
	updateTransctionData,
} from "../types/hooks/credit";
import { CreditFormData } from "../types/views";
import { request } from "../utils/axios-utils";
import { QueryFunctionContext } from "react-query";

export const applyForCredit = (data: AxiosResponse) => {
	return request({
		method: "POST",
		url: `/credit/`,
		data,
	});
};
export const applyForDistributorCredit = (data: FormData) => {
	return request({
		method: "POST",
		url: `/credit/distributor/create/`,
		data,
	});
};

export const updateStoreDetails = (data: {
	retailer_phone_number: string;
	store_name: string;
	store_address: string;
}) => {
	return request({
		method: "POST",
		url: `/distributors/retailer_store/`,
		data,
	});
};

export const customerCancelCredit = (auth: string) => {
	return request({
		method: "GET",
		url: `/credit/customer/cancel?auth=${auth}`,
	});
};

export const customerDeclineCredit = (auth: string) => {
	return request({
		method: "GET",
		url: `/credit/customer/decline?auth=${auth}`,
	});
};

export const guarantorDeclineCredit = (auth: string) => {
	return request({
		method: "POST",
		url: `/credit/guarantor/decline?auth=${auth}`,
	});
};

export const verifyCredit = (auth: string) => {
	return request({
		method: "GET",
		url: `/credit/customer/verify?auth=${auth}`,
	});
};

export const verifyRetailerCredit = ({ queryKey }: QueryFunctionContext) => {
	const auth = queryKey[1];
	return request({
		method: "GET",
		url: `/credit/customer/verify?auth=${auth}`,
	});
};

export const initialAcceptCredit = (
	data: CreditFormData | null,
	auth: string | null
) => {
	return request({
		method: "POST",
		url: `/credit/customer/initial-accept?auth=${auth}`,
		data: data !== null ? data : {},
	});
};

// To replace initialAcceptCredit endpoint
export const retailerAuthorizeCredit = (data: {
	data: FormData;
	auth: string;
}) => {
	return request({
		method: "POST",
		url: `/credit/customer/initial-accept?auth=${data.auth}`,
		data: data.data !== null ? data.data : {},
	});
};

// To replace customerCancelCredit endpoint
export const retailerForbidCredit = (auth: string) => {
	return request({
		method: "GET",
		url: `/credit/customer/cancel?auth=${auth}`,
	});
};

export const sendOTP = (auth: string) => {
	return request({
		method: "GET",
		url: `/credit/customer/send-otp?auth=${auth}`,
	});
};

export const finalAcceptCredit = (
	data: {
		pin: string;
		pin_id: string;
	},
	auth: string | null
) => {
	return request({
		method: "POST",
		url: `/credit/customer/complete?auth=${auth}`,
		data,
	});
};

export const getAllDistributorCredit = () => {
	return request({
		method: "GET",
		url: `/credit/by-distributor/`,
	});
};

export const guarantorAccept = (
	data: guarantorAcceptData,
	auth: string | null
) => {
	return request({
		method: "POST",
		url: `/credit/guarantor?auth=${auth}`,
		data,
	});
};

export const guarantorVerify = (
	auth: string | null,
	requestType: string | null
) => {
	const path = requestType ? `/distributor` : "";
	const url = `/credit${path}/guarantor/verify?auth=${auth}`;

	return request({
		method: "GET",
		url,
	});
};
export const retailerGuarantorVerify = ({ queryKey }: QueryFunctionContext) => {
	const auth = queryKey[1];
	const url = `/credit/guarantor/verify?auth=${auth}`;
	return request({
		method: "GET",
		url,
	});
};

//distributor guarantor part to come soon

export const sendDeliveryOtp = (data: sendDeliveryOtpData) => {
	return request({
		method: "POST",
		url: `/credit/send_delivery_otp/`,
		data,
	});
};

export const confirmDeliveryOtp = (data: sendDeliveryOtpData) => {
	return request({
		method: "POST",
		url: `/credit/verify_delivery_otp/`,
		data,
	});
};

//Cancel Distributor Credit
export const cancelDistributorCredit = (
	data: cancelCreditByDistributorData
) => {
	return request({
		method: "POST",
		url: `/credit/distributor/cancel/`,
		data,
	});
};

export const distributorConfirmRetailerLoan = (data: any) => {
	const { credit_id, command, amount } = data;
	const payload: updateTransctionData = {
		amount: amount,
	};
	return request({
		method: "POST",
		url: `/credit/distributor-review/${credit_id}?command=${command}`,
		data: payload !== null ? payload : {},
	});
};

export const retailerAuthorization = (data: { data: FormData }) => {
	return request({
		method: "POST",
		url: "credit/customer/create_credit",
		data: data.data !== null ? data.data : {},
	});
};
