import { useLocation, useNavigate } from "react-router";
import Logo from "../../assets/image/blackLogo.svg";
import { useLogoutUser } from "../../hooks/useAuthData";
import {
	Button,
	FormInput,
	Loader2,
	ProcessUploadFile,
	UploadedFile,
} from "../../components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { validateTextNumber, base64ToBlob } from "../../utils/utilities";
import { useReOnBoardDistributor } from "../../hooks/useCustomerData";
import { Toast } from "../../utils/toast";

export type OnboardingForm = {
	[key: string]: any;
	businessAddress: string | null;
	businessAddressFile: string | null;
	businessAddressFileName: string;
	TINNumber: string | null;
	CACNumber: string | null;
	governmentFrontFile: string | null;
	governmentFrontFileName: string;
	governmentBackFile: any;
	governmentBackFileName: string;
	governmentIdType: string | any;
	governmentIdNo: string | any;
	BVNNumber: string | null;
};

export type OnboardingFormContextProps = {
	onboardingFormData: OnboardingForm;
	setOnboardingFormData: Dispatch<SetStateAction<OnboardingForm>>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
};

const OnboardingReview = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const onboardingData = location.state;
	// console.log(onboardingData);
	const [onboardedData, setOnboardedData] = useState<any>(onboardingData);
	const { mutate: logoutUser } = useLogoutUser();
	const handleLogout = () => {
		logoutUser();
	};

	// The data coming in should specify the particular card to show based off data sent with router
	const defaultOnboardingData: OnboardingForm = {
		businessAddress: null,
		businessAddressFile: null,
		businessAddressFileName: null,
		TINNumber: null,
		CACNumber: null,
		governmentFrontFile: null,
		governmentFrontFileName: null,
		governmentBackFile: null,
		governmentBackFileName: null,
		governmentIdType: "",
		governmentIdNo: null,
		BVNNumber: null,
	};
	const [onboardingFormData, setOnboardingFormData] =
		useState<OnboardingForm>(defaultOnboardingData);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const onboardingReviewData = new FormData();

		onboardingReviewData.append(
			"address",
			onboardingFormData.businessAddress
		);
		if (onboardingFormData.businessAddressFile) {
			onboardingReviewData.append(
				"address_document",
				base64ToBlob(onboardingFormData.businessAddressFile)
			);
		}
		onboardingReviewData.append("tin_number", onboardingFormData.TINNumber);
		onboardingReviewData.append("cac_number", onboardingFormData.CACNumber);
		onboardingReviewData.append("bvn", onboardingFormData.BVNNumber);

		if (onboardingFormData.governmentFrontFile) {
			onboardingReviewData.append(
				"government_id.id_document",
				base64ToBlob(onboardingFormData.governmentFrontFile)
			);
		}

		if (onboardingFormData.governmentBackFile) {
			onboardingReviewData.append(
				"government_id.id_document_back",
				base64ToBlob(onboardingFormData.governmentBackFile)
			);
		}
		onboardingReviewData.append(
			"government_id.id_type",
			onboardingFormData.governmentIdType
		);
		onboardingReviewData.append(
			"government_id.id_number",
			onboardingFormData.governmentIdNo
		);

		mutateReOnBoardDistributor(onboardingReviewData);
	};

	const onError = () => {
		Toast.error(
			"Your Submission wasn't successful, Please try again later"
		);
	};
	const onSuccess = () => {
		Toast.success("Details submitted successfully for reonboarding");
		setTimeout(() => {
			navigate("/dashboard");
		}, 4000);
	};

	const { mutateReOnBoardDistributor, isLoading } = useReOnBoardDistributor(
		onSuccess,
		onError
	);

	useEffect(() => {
		if (location.state === null) {
			navigate("/dashboard");
		} else {
			setOnboardedData(onboardingData);
		}
	}, [location.state, onboardingData, navigate]);

	return (
		<>
			<div className="flex items-center justify-between p-8 pb-4">
				<figure
					className="h-8 w-28 cursor-pointer"
					onClick={() => navigate("/login")}
				>
					<img src={Logo} alt="" />
				</figure>
				<button onClick={handleLogout}>Logout</button>
			</div>
			<div className="mx-auto mt-8 flex flex-col items-center justify-center gap-4 text-center">
				<h1 className="text-[24px] font-semibold text-[#000]">
					Re-Upload your Details
				</h1>
				<p className="max-w-[489px] text-[14px] text-[#87898E] ">
					Your account verification couldn&apos;t be completed due to
					discrepancies in the information provided. Please review and
					correct the details submitted. If you need assistance,
					contact our{" "}
					<span className="cursor-pointer text-[#003AFF]">
						support team
					</span>{" "}
					.
				</p>
			</div>
			<form
				action=""
				encType="multipart/form-data"
				className="mx-auto mt-8 w-full max-w-[80%] divide-y"
			>
				{/* Government Id upload  */}
				{!onboardedData?.is_government_id_verified && (
					<div className="flex flex-col justify-between gap-5 py-8 md:flex-row lg:flex-row">
						<div className="w-full ">
							<p className="text-xl font-bold text-[#1E293B]">
								Upload Government ID
							</p>
							<p className="text-[14px] text-[#94A3B8]">
								This is where you would upload your proof of
								address
							</p>
						</div>

						<div className="flex w-full flex-col items-center justify-center gap-y-4 rounded  text-center">
							<div className="flex w-full flex-col  gap-y-4">
								<FormInput
									select={true}
									options={[
										{
											label: "International Passport",
											value: "international_passport",
										},
										{
											label: "Driver's License",
											value: "drivers_license",
										},
										{
											label: "Voter's Card",
											value: "voter_card",
										},
										{
											label: "National ID",
											value: "national_id",
										},
									]}
									label="Government ID type"
									name="document_type"
									id="business_sector"
									type="text"
									value={onboardingFormData.governmentIdType}
									placeholder="Select from the drop-down below"
									autoComplete="off"
									required={false}
									onChange={(selectedOption: any) => {
										setOnboardingFormData({
											...onboardingFormData,
											governmentIdType:
												selectedOption.value,
										});
									}}
								/>
								<FormInput
									label="Government ID Number"
									name="id_no"
									id="id_no"
									type="text"
									placeholder="Type in the id no contained in your uploaded document"
									minLength={5}
									maxLength={19}
									required={true}
									autoComplete="on"
									additionalClasses="text-[#263238] h-full rounded-md border border-[#263238] bg-transparent px-5 py-3  font-light  outline-none placeholder:text-[14px]"
									onChange={(e: any) => {
										localStorage.setItem(
											"governmentIdNo",
											e.target.value
										);
										setOnboardingFormData({
											...onboardingFormData,
											governmentIdNo: e.target.value,
										});
									}}
									value={onboardingFormData.governmentIdNo}
								/>
							</div>

							<div className="flex flex-col gap-2">
								<div className="w-full">
									<div className="flex flex-col text-left">
										<h2 className=" text-base font-semibold text-[#002060]">
											Upload the Front of your Government
											issued ID
										</h2>
										<p className="text-[14px] leading-5 text-[#626D88]">
											Scan or Upload the front of the PDF,
											JPEG or PNG Copy of the Government
											issue ID <b>Max Size of: 2MB</b>
										</p>
									</div>
									<ProcessUploadFile
										formats={[
											"jpeg",
											"jpg",
											"png",
											"pdf",
											"ai",
											"doc",
											"docx",
											"ppt",
											"pptx",
										]}
										maxSize={2 * 1024 * 1024}
										setFile={setOnboardingFormData}
										localStorageFileName={
											"governmentFrontFile"
										}
									/>
								</div>
								<>
									{onboardingFormData.governmentFrontFile && (
										<div className="flex w-full flex-col gap-4">
											<h4 className="w-full text-[#676767]">
												Uploaded file(s)
											</h4>
											<div className="flex flex-col gap-3">
												<UploadedFile
													title={
														onboardingFormData.governmentFrontFileName
													}
													deleteFile={() => {
														setOnboardingFormData(
															(prevData) => ({
																...prevData,
																governmentFrontFile:
																	null,
															})
														);
													}}
												/>
											</div>
										</div>
									)}
								</>
							</div>

							{onboardingFormData.governmentIdType !==
								"international_passport" &&
								onboardingFormData.governmentIdType !== "" && (
									<div className="flex flex-col gap-2">
										<div className="w-full">
											<div className="flex flex-col text-left">
												<h2 className="text-base font-semibold text-[#002060]">
													Upload the Back of your
													Government issued ID
												</h2>
												<p className="text-[14px] leading-5 text-[#626D88]">
													Scan or Upload the front of
													the PDF, JPEG or PNG Copy of
													the Government issue ID{" "}
													<b>Max Size of: 2MB</b>
												</p>
											</div>
											<ProcessUploadFile
												formats={[
													"jpeg",
													"jpg",
													"png",
													"pdf",
													"ai",
													"doc",
													"docx",
													"ppt",
													"pptx",
												]}
												maxSize={2 * 1024 * 1024}
												setFile={setOnboardingFormData}
												localStorageFileName={
													"governmentBackFile"
												}
											/>
										</div>
										<>
											{onboardingFormData.governmentBackFile && (
												<div>
													<h4 className="w-full text-[#676767]">
														Uploaded file(s)
													</h4>
													<div className="flex flex-col gap-3">
														<UploadedFile
															title={
																onboardingFormData
																	.governmentBackFile
																	.name
															}
															deleteFile={() => {
																setOnboardingFormData(
																	(
																		prevData
																	) => ({
																		...prevData,
																		governmentBackFile:
																			null,
																	})
																);
															}}
														/>
													</div>
												</div>
											)}
										</>
									</div>
								)}
						</div>
					</div>
				)}

				{/* Address Upload  */}
				{!onboardedData?.is_address_verified && (
					<div className="flex flex-col justify-between gap-5 py-8 md:flex-row lg:flex-row">
						<div className="w-full">
							<p className="text-xl font-bold text-[#1E293B]">
								Upload Proof of Address
							</p>
							<p className="text-[14px] text-[#94A3B8]">
								This is where you would upload your proof of
								address
							</p>
						</div>
						<div className="flex w-full flex-col items-center justify-center gap-y-4 rounded  text-center">
							<div className="flex flex-col gap-4">
								<div className="flex w-full flex-col gap-1">
									<div className="flex flex-col  text-left">
										<h2 className="text-base font-semibold text-[#002060]">
											Business Address
										</h2>
										<p className="text-[14px] font-medium text-[#626D88]">
											Type in your Correct Business
											Address
										</p>
									</div>
									<FormInput
										label=""
										name="business address"
										id="business address"
										type="text"
										placeholder="Type in your accurate business address"
										minLength={5}
										maxLength={60}
										required={true}
										autoComplete="on"
										additionalClasses="inputChanger focus-none h-full w-full bg-transparent px-5 py-3.5 border-[1.5px] border-[#002060] rounded-lg text-sm outline-none placeholder:text-sm placeholder:leading-5 leading-5 text-sm"
										onChange={(e) => {
											setOnboardingFormData({
												...onboardingFormData,
												businessAddress: e.target.value,
											});
										}}
										value={
											onboardingFormData.businessAddress ??
											""
										}
									/>
								</div>
								<div className="w-full">
									<div className="flex flex-col text-left">
										<h2 className="text-base font-semibold text-[#002060]">
											Proof of Address
										</h2>
										<p className="text-[14px] leading-5 text-[#626D88]">
											Scan or Upload PDF, JPEG or PNG Copy
											of your Proof of Address which could
											be either Bank Statement, utility
											Bill or Tenancy or Proof of
											Ownership <b>Max Size of: 2MB</b>
										</p>
									</div>
									<ProcessUploadFile
										formats={[
											"jpeg",
											"jpg",
											"png",
											"pdf",
											"ai",
											"doc",
											"docx",
											"ppt",
											"pptx",
										]}
										maxSize={2 * 1024 * 1024}
										setFile={setOnboardingFormData}
										localStorageFileName={
											"businessAddressFile"
										}
									/>
								</div>
								{onboardingFormData.businessAddressFile && (
									<div className="">
										<h4 className="w-full text-[#676767]">
											Uploaded file(s)
										</h4>
										<div className="flex flex-col gap-3">
											<UploadedFile
												title={
													onboardingFormData.businessAddressFileName
												}
												deleteFile={() => {
													setOnboardingFormData(
														(prevData) => ({
															...prevData,
															businessAddressFile:
																null,
														})
													);
												}}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}

				{/* Tin Number  */}
				{!onboardedData?.is_tin_verified && (
					<div className="flex flex-col justify-between gap-5 py-8 md:flex-row lg:flex-row">
						<div className="w-full ">
							<p className="text-xl font-bold text-[#1E293B]">
								TIN Number
							</p>
							<p className="text-[14px] text-[#94A3B8]">
								This is where you would type in your TIN Number
							</p>
						</div>
						<div className="flex w-full flex-col items-start justify-center gap-2">
							<FormInput
								label=""
								name="tin number"
								id="tin number"
								type="text"
								placeholder="Type in your TIN Number"
								minLength={12}
								maxLength={12}
								required={true}
								autoComplete="off"
								additionalClasses="inputChanger focus-none h-full w-full bg-transparent px-4 py-2 border-[1.5px] border-[#002060] rounded-lg text-sm py-3.5 outline-none"
								onChange={(e) => {
									setOnboardingFormData({
										...onboardingFormData,
										TINNumber: validateTextNumber(
											e.target.value
										),
									});
								}}
								value={onboardingFormData.TINNumber ?? ""}
							/>
						</div>
					</div>
				)}

				{/* Cac Number  */}
				{!onboardedData?.is_cac_verified && (
					<div className="flex flex-col justify-between gap-5 py-8  md:flex-row lg:flex-row">
						<div className="w-full">
							<p className="text-xl font-bold text-[#1E293B]">
								CAC Number
							</p>
							<p className="text-[14px] text-[#94A3B8]">
								This is where you would type in your CAC Number
							</p>
						</div>
						<div className="flex w-full flex-col items-start justify-center gap-2">
							<div className="relative w-full">
								<FormInput
									label=""
									name="cac number"
									id="cac number"
									type="text"
									placeholder="Type in your correct Corporate Affairs
									Commission Number"
									minLength={7}
									maxLength={7}
									required={true}
									autoComplete="off"
									additionalClasses="inputChanger focus-none h-full w-full bg-transparent pl-9 pr-4  border-[1.5px] border-[#002060] rounded-lg text-sm py-3.5 outline-none"
									onChange={(e) => {
										setOnboardingFormData({
											...onboardingFormData,
											CACNumber: validateTextNumber(
												e.target.value
											),
										});
									}}
									value={onboardingFormData.CACNumber ?? ""}
								/>
								<span className="absolute bottom-[0.1rem] left-[0.1rem] flex h-11 w-8 items-center justify-center rounded-bl-lg rounded-tl-lg bg-transparent text-sm font-semibold">
									RC-
								</span>
							</div>
						</div>
					</div>
				)}
				{/* Bvn Number  */}
				{!onboardedData?.is_bvn_verified && (
					<div className="flex flex-col justify-between gap-5 py-8  md:flex-row lg:flex-row">
						<div className="w-full">
							<p className="text-xl font-bold text-[#1E293B]">
								Bvn Number
							</p>
							<p className="text-[14px] text-[#94A3B8]">
								This is where you would type in your Bvn Number
							</p>
						</div>
						<div className="flex w-full flex-col items-start justify-center gap-2">
							<FormInput
								label=""
								name="bvn number"
								id="bvn number"
								type="text"
								placeholder="Type in your correct Bank verification Number"
								minLength={11}
								maxLength={11}
								required={true}
								autoComplete="off"
								additionalClasses="inputChanger focus-none h-full w-full bg-transparent px-4 py-2 border-[1.5px] border-[#002060] rounded-lg text-sm py-3.5 outline-none"
								onChange={(e) => {
									setOnboardingFormData({
										...onboardingFormData,
										BVNNumber: validateTextNumber(
											e.target.value
										),
									});
								}}
								value={onboardingFormData.BVNNumber ?? ""}
							/>
						</div>
					</div>
				)}

				<div className="flex w-full justify-end p-12 text-gray-50">
					<Button
						disabled={isLoading}
						additionalClasses="h-[40px] rounded max-w-[147px]"
						onClick={(e) => handleSubmit(e)}
					>
						{isLoading ? <Loader2 /> : "Save Changes"}
					</Button>
				</div>
			</form>
		</>
	);
};

export default OnboardingReview;
