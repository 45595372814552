import React, { useState, useEffect } from "react";
import { BsFillVinylFill, BsChevronExpand } from "react-icons/bs";
import { TopBar } from "../../../components";
import { formatDate, getStatusColor } from "../../../utils/utilities";
import { useGetAccountTransactions } from "../../../hooks/useWalletData";
import NumberFormat from "../../../utils/NumberFormat";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import TransactionDetails from "../../../components/Dashboard/HadiPay/TransactionDetails";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import Select from "react-select";

interface SortOrder {
	column: string | null;
	order: "asc" | "desc";
}

const TransactionHistory: React.FC = () => {
	const navigate = useNavigate();
	const onError = () => {
		//
	};

	const getTransactionHistory = useGetAccountTransactions(undefined, onError);

	const [tableData, setTableData] = useState([]);
	const [showTransactionDetails, setShowTransactionDetails] = useState(false);
	const [transactionData, setTransactionData] = useState<any>({});

	const [sortOrder, setSortOrder] = useState<SortOrder>({
		column: null,
		order: "asc",
	});

	const getSortIcon = (column: string) => {
		if (sortOrder.column === column) {
			return sortOrder.order === "asc" ? (
				<FaSortUp />
			) : sortOrder.order === "desc" ? (
				<FaSortDown />
			) : (
				<BsChevronExpand />
			);
		}
		return <BsChevronExpand />;
	};

	const sortData = (column: string) => {
		const order =
			sortOrder.column === column && sortOrder.order === "asc"
				? "desc"
				: "asc";

		const sortedData =
			getTransactionHistory?.data?.data &&
			Array.isArray(getTransactionHistory.data.data.transaction_history)
				? [...getTransactionHistory.data.data.transaction_history].sort(
						(a, b) => {
							if (order === "asc") {
								return a[column].localeCompare(b[column]);
							} else {
								return b[column].localeCompare(a[column]);
							}
						}
				  )
				: [];

		setTableData(sortedData);
		setSortOrder({ column, order });
	};
	const sortStatusData = (column: string, status: any) => {
		const order = "asc";

		const sortedData =
			getTransactionHistory?.data.data &&
			Array.isArray(getTransactionHistory.data.data.transaction_history)
				? [
						...getTransactionHistory.data.data.transaction_history,
				  ].filter((data) => data.transaction_type === status)
				: [];

		setTableData(sortedData);
		setSortOrder({ column, order });
	};

	const handleExportData = async () => {
		const data =
			getTransactionHistory?.data?.data?.transaction_history ?? [];
		const headers = Object.keys(data[0]);
		const csvContent =
			headers.join(",") +
			"\n" +
			data
				.map((row: any) =>
					headers.map((header) => row[header]).join(",")
				)
				.join("\n");

		const blob = new Blob([csvContent], { type: "text/csv" });

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "transaction_history.csv");

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const handleRowClick = (data: any) => {
		setShowTransactionDetails(true);
		setTransactionData(data);
	};

	useEffect(() => {
		setTableData(
			getTransactionHistory?.data?.data?.transaction_history ?? []
		);
	}, [getTransactionHistory?.data?.data?.transaction_history]);

	return (
		<>
			<section className="h-full bg-[#F8FAFC]">
				<TopBar pageName="Hadi Pay" />
				<main className="px-4 pb-5 pt-4 sm:px-8 sm:pb-10 sm:pt-7">
					<div className="mt-4 rounded-md bg-white px-6 py-[18px] drop-shadow-md sm:mt-8">
						<div
							className="w-full pb-8 pt-2 "
							onClick={() => navigate(-1)}
						>
							<button className="flex w-fit cursor-pointer items-center gap-3 font-semibold">
								<BiArrowBack />
								<p>Back To Overview</p>
							</button>
						</div>
						<div className="flex items-center justify-between font-semibold text-[#002060]">
							<h1 className="font-semibold">
								Transaction History
							</h1>
						</div>
						<div className="mt-3 min-h-[200px] w-full overflow-x-auto">
							<div className="mb-4 flex items-center justify-between gap-6 rounded  py-0.5">
								<div className="flex items-center gap-4">
									<p className="text-[14px] font-[500] text-gray-800">
										Filters
									</p>
									<button
										onClick={() => sortData("date")}
										className={
											"inline-flex items-center gap-2 rounded border border-[#BABFC3] px-3 py-1.5 text-[14px] text-[#8D9BB8] "
										}
									>
										<span className="text-[12px]">
											Date
										</span>

										{getSortIcon("date")}
									</button>
									<button>
										<Select
											className="text-[12px]"
											placeholder="Transaction Type"
											onChange={(selectedOption) => {
												sortStatusData(
													"transaction_type",
													selectedOption.value
												);
											}}
											styles={{
												control: (base) => ({
													...base,
													height: 33,
													minHeight: 33,
													color: "#8D9BB7",
												}),
												input: (base) => ({
													...base,
													color: "#8D9BB7",
												}),
												placeholder: (base) => ({
													...base,
													color: "#8D9BB7",
												}),
											}}
											options={[
												{
													label: "Credit",
													value: "credit",
												},
												{
													label: "Debit",
													value: "debit",
												},
											]}
										/>
									</button>
									{/* <button
										onClick={() =>
											sortData("transaction_type")
										}
										className={
											"inline-flex items-center gap-2 rounded border border-[#BABFC3] px-3 py-1.5 text-[14px] text-[#8D9BB8] "
										}
									>
										<span className="text-[12px]">
											Transaction Type
										</span>
										{getSortIcon("transaction_type")}
									</button> */}
								</div>
								<button
									onClick={handleExportData}
									className="inline-flex items-center gap-2 rounded border border-[#BABFC3] bg-transparent px-3 py-1.5 text-[14px] text-[#8D9BB8]"
								>
									<BsFillVinylFill className="text-blue-950" />
									<span className="text-[14px]">Export</span>
								</button>
							</div>
							<div className="overflow-x-auto">
								<table
									className={`w-full table-auto whitespace-nowrap text-[14px] ${
										getTransactionHistory.isFetched &&
										getTransactionHistory?.data?.data
											?.length < 1 &&
										"hidden"
									}`}
								>
									<thead>
										<tr className="rounded-md border-b border-t border-b-slate-400 border-t-slate-400 font-medium text-blue-950">
											<th className="px-3 py-2 text-left">
												Date
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Transaction ID
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Retailer’s Name
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Amount
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Recipient Name
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Recipient Bank
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Transaction Type
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Status
											</th>
										</tr>
									</thead>
									<tbody className="text-center">
										{tableData.map(
											(data: any, index: number) => (
												<tr
													key={index}
													className="cursor-pointer border border-x-0 border-b-[#CBD5E1] text-slate-700   hover:bg-gray-100 focus:bg-gray-100 "
													onClick={() =>
														handleRowClick(data)
													}
												>
													<td className="flex items-center px-3 py-2 text-sm capitalize">
														{formatDate(data.date)}
													</td>
													<td className="px-3 py-2 text-left">
														{data?.transaction_id
															? data?.transaction_id?.slice(
																	0,
																	15
															  ) + "..."
															: "N/A"}
													</td>
													<td className="px-3 py-2 text-left">
														{data.retailer_name ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left">
														<NumberFormat
															value={data.amount}
														/>
													</td>
													<td className="px-3 py-2 text-left">
														{data.recipient_name ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left ">
														{data.recipient_bank ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left ">
														<p
															className={`font-medium capitalize ${getStatusColor(
																data.transaction_type ??
																	"",
																"text"
															)}`}
														>
															{data?.transaction_type ??
																"N/A"}
														</p>
													</td>
													<td>
														<div
															className={`flex items-center gap-2 rounded-md bg-opacity-20 px-2 py-1 ${getStatusColor(
																data?.status ??
																	"",
																"background"
															)}`}
														>
															<span
																className={`h-2 w-2 rounded-full ${getStatusColor(
																	data?.status ??
																		"",
																	"background"
																)}`}
															></span>
															<p
																className={`font-semibold capitalize ${getStatusColor(
																	data?.status ??
																		"",
																	"text"
																)}`}
															>
																{data?.status ??
																	"N/A"}
															</p>
														</div>
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</div>
							<p>
								{getTransactionHistory.isFetched &&
									getTransactionHistory?.data?.data.length <
										1 &&
									"No transactions have been made"}
							</p>
						</div>
					</div>
				</main>
			</section>
			<TransactionDetails
				setShow={setShowTransactionDetails}
				show={showTransactionDetails}
				transactionDetailsData={transactionData}
			/>
		</>
	);
};

export default TransactionHistory;
