import { FormInput } from "../../FormInput";
import { OccupationType } from "../../../services/Constants";
import { GuarantorCreditAcceptPageProps } from "types/views";

interface GuarantorAcceptForm1 extends GuarantorCreditAcceptPageProps {
	disabled: boolean;
}
const GuarantorAcceptForm1 = ({
	formData,
	setFormData,
	disabled,
}: GuarantorAcceptForm1) => {
	return (
		<div className="space-y-4 py-4">
			<FormInput
				label="First Name"
				name="first_name"
				id="first_name"
				labelStyles="text-[16px] font-medium"
				value={formData.first_name}
				disabled={disabled}
				placeholder="Enter First Name"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						first_name: e.target.value,
					})
				}
			/>
			<FormInput
				label="Last Name"
				name="last_name"
				id="last_name"
				labelStyles="text-[16px] font-medium"
				value={formData.last_name}
				disabled={disabled}
				placeholder="Enter Last Name"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						last_name: e.target.value,
					})
				}
			/>
			<FormInput
				label="Address"
				name="address"
				id="address"
				labelStyles="text-[16px] font-medium"
				value={formData.address}
				disabled={disabled}
				placeholder="Enter Valid Address "
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						address: e.target.value,
					})
				}
			/>
			<FormInput
				label="Relationship"
				name="relationship"
				id="relationship"
				type="text"
				placeholder="Specify Type of Relationship other than family"
				autoComplete={"true"}
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				required={true}
				value={formData.relationship}
				onChange={(e) => {
					setFormData({
						...formData,
						relationship: e.target.value,
					});
				}}
			/>

			<FormInput
				label="Date of Birth"
				name="dob"
				type="date"
				id="dob"
				labelStyles="text-[16px] font-medium"
				value={formData.dob}
				placeholder="Enter Date of birth"
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						dob: e.target.value,
					})
				}
			/>
			<FormInput
				label="Occupation Type"
				name="occupation_type"
				id="occupation_type"
				labelStyles="text-[16px] font-medium"
				select={true}
				options={OccupationType}
				value={formData.occupation_type}
				disabled={disabled}
				placeholder="Specify your relationship"
				additionalClasses="rounded-md font-light  outline-none"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(selectedOption) =>
					setFormData({
						...formData,
						occupation_type: selectedOption.value,
					})
				}
			/>
			<FormInput
				label="Work Address (Salary earners only)"
				name="work_address"
				id="work_address"
				labelStyles="text-[16px] font-medium"
				value={formData.work_address}
				disabled={disabled}
				placeholder="Type in your work address "
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						work_address: e.target.value,
					})
				}
			/>
			<FormInput
				label="Business Address (Business owners only)"
				name="business_address"
				id="business_address"
				labelStyles="text-[16px] font-medium"
				value={formData.business_address}
				disabled={disabled}
				placeholder="Type in your business address "
				additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
				minLength={2}
				type="text"
				required={true}
				autoComplete="false"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setFormData({
						...formData,
						business_address: e.target.value,
					})
				}
			/>
		</div>

		// {/* <FormInput
		// 	select={true}
		// 	selectStyleOptions={{
		// 		border: "1.4px solid #CBD5E1",
		// 		fontWeight: 300,
		// 		paddingLeft: "4px",
		// 		fontSize: "14px",
		// 	}}
		// 	options={EmploymentStatus}
		// 	label="Employment Status"
		// 	name="employment_status"
		// 	id="employment_status"
		// 	type="select"
		// 	placeholder="Select from drop-down"
		// 	placeholderStyleOptions={{
		// 		opacity: 0.5,
		// 		fontWeight: 300,
		// 		paddingLeft: "4px",
		// 		fontSize: "14px",
		// 	}}
		// 	autoComplete={"true"}
		// 	additionalClasses="text-black rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none"
		// 	required={true}
		// 	value={formData.employment_status}
		// 	onChange={(employment_status) => {
		// 		setFormData((prevData) => {
		// 			return {
		// 				...prevData,
		// 				employment_status: employment_status.value,
		// 			};
		// 		});
		// 	}}
		// /> */}
	);
};

export default GuarantorAcceptForm1;
