import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import { API_URI } from "../services/Constants";
import { Toast } from "./toast";
import { deleteAllCookies, getCookie, setCookie } from "./cookies";
import { endpointsWithoutAuthorization } from "./utilities";

// Track the number of retries
// const retryCount = 0;

// // Max number of retries allowed
// const maxRetries = 3; // Change to your desired value

// Create an instance of Axios with custom configuration
export const client: AxiosInstance = axios.create({
	baseURL: `${API_URI}`,
	withCredentials: true,
});

export const forceLogout = async () => {
	try {
		const response = await axios.post(`${API_URI}/auth/logout/`);
		if (response.status === 200) {
			localStorage.clear();
			deleteAllCookies();
			window.location.replace(`/login`);
		}
	} catch (error) {
		localStorage.clear();
		deleteAllCookies();
		window.location.replace(`/login`);
	}
};

// Function to set the access token in the Authorization header
export const setAccessToken = (accessToken: string): void => {
	if (accessToken) {
		client.defaults.headers.common[
			"Authorization"
		] = `Bearer ${accessToken}`;
	} else {
		delete client.defaults.headers.common["Authorization"];
	}
};

// Function to remove the access token from the Authorization header
export const removeAccessToken = (): void => {
	delete client.defaults.headers.common["Authorization"];
};

// Verify the access token
const verifyToken = async () => {
	const accessToken = getCookie("ac-token");
	try {
		const response = await axios.post(`${API_URI}/auth/token/verify/`, {
			token: accessToken,
		});
		if (response.status === 200) {
			return true;
		}
	} catch (error: any) {
		if (error.response && error.response.status == 401) {
			return false;
		}
		return false;
	}
};

// Function to perform the token refresh request
const refreshToken = async (): Promise<boolean> => {
	try {
		const response = await axios.post(
			`${API_URI}/auth/token/refresh/`,
			{},
			{
				withCredentials: true,
			}
		);
		if (response.status === 200) {
			const { access } = response.data;
			setCookie("ac-token", access, 0.0208333);
			return true;
		}
	} catch (error) {
		await handleRefreshError();
		setTimeout(() => {
			Toast.error("User Session expired, please login again");
		}, 2000);
	}
	return false;
};

const handleRefreshError = async () => {
	await forceLogout();
};

const checkPublicEndpoint = (config: InternalAxiosRequestConfig) => {
	const isPublicEndpoint = endpointsWithoutAuthorization.some((endpoint) =>
		config?.url?.includes(endpoint)
	);
	return isPublicEndpoint;
};

//Interceptor to check and for requests with authorization
client.interceptors.request.use(
	(config) => {
		const isPublicEndpoint = checkPublicEndpoint(config);
		if (config.data instanceof FormData) {
			// Remove the 'Content-Type' header or set it to 'multipart/form-data'
			delete config.headers["Content-Type"];
		}
		if (isPublicEndpoint) {
			delete config.headers.Authorization;
		} else {
			const accessToken = getCookie("ac-token");
			if (accessToken) {
				config.headers["Authorization"] = `Bearer ${accessToken}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

//Interceptor to check and refresh the access token
client.interceptors.response.use(
	(response: AxiosResponse) => response,
	async (error) => {
		const originalRequest = error.config;
		if (
			error.response &&
			error.response.status == 401 &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			const tokenValid = await verifyToken();
			const isPublicEndpoint = checkPublicEndpoint(originalRequest);
			if (!tokenValid) {
				const tokenRefreshed = await refreshToken();
				if (tokenRefreshed) {
					if (isPublicEndpoint) {
						const { Authorization, ...restHeaders } =
							originalRequest.headers;
						const modifiedConfig = {
							...originalRequest,
							headers: { ...restHeaders },
						};
						return client(modifiedConfig);
					} else {
						originalRequest.headers[
							"Authorization"
						] = `Bearer ${getCookie("ac-token")}`;
						return client(originalRequest);
					}
				}
				//There is meant to be a toast here saying that the user session has expired but it is handle in the refreshToken function
			} else {
				return Promise.reject(error);
			}
			return;
		}
		return Promise.reject(error);
	}
);

//Intercepter to check for network error using the code in Axios and retrying
// client.interceptors.response.use(
// 	(response: AxiosResponse) => response,
// 	async (error) => {
// 		const originalRequest = error.config;
// 		if (error.code && !originalRequest._retry) {
// 			originalRequest._retry = true;
// 			try {
// 				const response = await client(originalRequest);
// 				return response;
// 			} catch (retryError) {
// 				Toast.error("Network Error");
// 			}
// 		}
// 		return Promise.reject(error);
// 	}
// );

// Reusable request function
export const request = async ({
	...options
}: AxiosRequestConfig): Promise<AxiosResponse> => {
	const onSuccess = (response: AxiosResponse) => response;
	const onError = (error: AxiosError) => {
		if (!error.response) {
			Toast.error("Network Error");
			throw new Error("Network error");
		} else {
			throw error;
		}
	};
	try {
		const response = await client(options);
		return onSuccess(response);
	} catch (error) {
		return onError(error as AxiosError);
	}
};
