import * as React from "react";

import { cn } from "../../utils/utilities";

interface TableComponentProps {
	className?: string;
	children?: React.ReactNode;
	colSpan?: number;
	onClick?: () => void;
}

const Table = React.forwardRef<HTMLTableElement, TableComponentProps>(
	({ className, children, ...props }, ref) => (
		<div className="relative w-full overflow-auto">
			<table
				ref={ref}
				className={cn("w-full caption-bottom text-sm", className)}
				{...props}
			>
				{children}
			</table>
		</div>
	)
);
Table.displayName = "Table";

const TableHeader = React.forwardRef<
	HTMLTableSectionElement,
	TableComponentProps
>(({ className, ...props }, ref) => (
	<thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
	HTMLTableSectionElement,
	TableComponentProps
>(({ className, ...props }, ref) => (
	<tbody
		ref={ref}
		className={cn("[&_tr:last-child]:border-0", className)}
		{...props}
	/>
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
	HTMLTableSectionElement,
	TableComponentProps
>(({ className, ...props }, ref) => (
	<tfoot
		ref={ref}
		className={cn(
			"border-t border-[#F3F5F7] bg-zinc-100/50 font-medium dark:bg-zinc-800/50 [&>tr]:last:border-b-0",
			className
		)}
		{...props}
	/>
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<HTMLTableRowElement, TableComponentProps>(
	({ className, onClick, ...props }, ref) => (
		<tr
			ref={ref}
			onClick={onClick}
			className={cn(
				"border-b border-t border-[#F3F5F7] transition-colors  data-[state=selected]:bg-zinc-100 dark:data-[state=selected]:bg-zinc-800",
				className
			)}
			{...props}
		/>
	)
);
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<HTMLTableCellElement, TableComponentProps>(
	({ className, ...props }, ref) => (
		<th
			ref={ref}
			className={cn(
				"h-10 px-[15px] text-left align-middle font-medium leading-5 text-[#90A3BF] dark:text-[#90A3BF] [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
				className
			)}
			{...props}
		/>
	)
);
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<HTMLTableCellElement, TableComponentProps>(
	({ className, ...props }, ref) => (
		<td
			ref={ref}
			className={cn(
				"p-[15px] align-middle font-semibold text-[#1A202C] [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
				className
			)}
			{...props}
		/>
	)
);
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
	HTMLTableCaptionElement,
	TableComponentProps
>(({ className, ...props }, ref) => (
	<caption
		ref={ref}
		className={cn(
			"mt-4 text-sm text-zinc-500 dark:text-zinc-400",
			className
		)}
		{...props}
	/>
));
TableCaption.displayName = "TableCaption";

export {
	Table,
	TableHeader,
	TableBody,
	TableFooter,
	TableHead,
	TableRow,
	TableCell,
	TableCaption,
};
