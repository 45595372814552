import {
	useCreateBeneficiary,
	useGetAccountLookup,
	useMakeTransaction,
	useGetPoolerWalletInfo,
} from "../../../hooks/useWalletData";
import { getChangeTransactionPinOTP } from "../../../api/wallet";
import { Axios, AxiosError, AxiosResponse } from "axios";
import React, {
	Dispatch,
	SetStateAction,
	useState,
	useRef,
	useEffect,
} from "react";
import { MdOutlineClose } from "react-icons/md";
import { Button } from "../../Button";
import { FormInput } from "../../FormInput";
import RequestIsLoading from "../../RequestIsLoading";
import { Overlay } from "../../modals";
import { Toast } from "../../../utils/toast";
import { Loader2, SuccessModal } from "../..";
import { encryptPin, toTitleCase } from "../../../utils/utilities";
import { OTPCode } from "../../../types/components/OtpInput";
import { RiDownloadLine } from "react-icons/ri";
import OTPGroup, {
	getFullCode,
	validatePINCode,
} from "../../OtpForms/OTPGroup";
import { useAuth } from "../../../hooks/auth";
import NumberFormat from "../../../utils/NumberFormat";

interface BankTransferProps {
	getBanksIsLoading: boolean;
	banks:
		| {
				bank_code: string;
				bank_name: string;
		  }[]
		| undefined;
	show: boolean;
	showChangePIN: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	setShowChangePIN: Dispatch<SetStateAction<boolean>>;
	setTransactionAttempt: Dispatch<SetStateAction<number>>;
	setChangePinToken: Dispatch<SetStateAction<string>>;
	walletBalance: number;
	transactionAttempt: number;
	setChangePinStage: Dispatch<SetStateAction<number>>;
}

export interface TransferData {
	bank: string;
	bank_name: string;
	account_number: string;
	account_name: string;
	amount: number;
	narration: string;
	pin: string;
}

export interface BeneficiaryData {
	account_name: string;
	account_number: string;
	bank_code: string;
	bank_name: string;
}

const BankTransfer: React.FC<BankTransferProps> = ({
	banks,
	getBanksIsLoading,
	show,
	setShow,
	showChangePIN,
	setShowChangePIN,
	setTransactionAttempt,
	setChangePinToken,
	walletBalance,
	transactionAttempt,
	setChangePinStage,
}) => {
	const { user } = useAuth();
	const onboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;
	user?.distributor?.user_profile?.is_verified;
	const [stage, setStage] = useState(1);
	const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
	const [getChangeOTPTokenLoading, setGetChangeOTPTokenLoading] =
		useState<boolean>(false);
	const [paymentSuccessfulData, setPaymentSuccessfulData] = useState<{
		account_name: string;
		amount: string;
	}>({
		account_name: "",
		amount: "",
	});
	const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryData>({
		account_number: "",
		account_name: "",
		bank_name: "",
		bank_code: "",
	});

	const [bankData, setBankData] = useState<
		{ value: string; label: string }[]
	>([]);
	const [bankName, setBankName] = useState("");
	const [otpCode, setOtpCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});

	const modalRef = useRef(null);

	const [formData, setFormData] = useState<TransferData>({
		bank: "",
		bank_name: "",
		account_number: "",
		account_name: "",
		amount: null,
		narration: "",
		pin: "",
	});

	const [rawInput, setRawInput] = useState("");

	const onSuccess = (data: AxiosResponse) => {
		setPaymentSuccessfulData({
			...paymentSuccessfulData,
			account_name: data?.data?.data?.account_name,
		});
		setFormData((prevData) => {
			return {
				...prevData,
				account_name: data?.data?.data?.account_name,
				bank_name:
					(bankData.find(
						(singleBank) => singleBank.value === formData.bank
					)?.label as string) || "",
			};
		});
		setRawInput("");
		setBankName(
			(bankData.find((singleBank) => singleBank.value === formData.bank)
				?.label as string) || ""
		);
		if (!(stage >= 3)) setStage(2);
	};

	const onError = () => {
		//
	};

	const { refetch: refetchPoolerWalletInfo } = useGetPoolerWalletInfo(
		undefined,
		undefined,
		Boolean(onboardedUser && isVerifiedUser)
	);

	const makeTransactionOnSuccess = async () => {
		setShow(false);
		setTransactionAttempt(0);
		await refetchPoolerWalletInfo();
		setTimeout(() => {
			setIsPaymentSuccessful(true);
		}, 500);
	};

	const makeTransactionOnError = async () => {
		await refetchPoolerWalletInfo();
		// Toast.error("An error occured. Kindly try again later 🤕");
	};

	const getAccountLookUpQuery = useGetAccountLookup(
		formData.account_number,
		formData.bank,
		onSuccess,
		onError
	);

	const makeTransactionQuery = useMakeTransaction(
		makeTransactionOnSuccess,
		makeTransactionOnError
	);

	const createBeneficiaryOnSuccess = (data: AxiosResponse) => {
		Toast.success("Beneficiary has been created 🎉");
		setTimeout(() => {
			setIsPaymentSuccessful(false);
		}, 800);
	};

	const createBeneficiaryOnError = (data: AxiosError) => {
		// switch (data.response?.status) {
		// 	case 400:
		// 		Toast.error("Beneficiry already exists 🎉");
		// 		break;
		// 	default:
		// 		break;
		// 	}
		Toast.error("An error occured. Kindly try again later 🤕");
	};

	const createBeneficiaryMutation = useCreateBeneficiary(
		createBeneficiaryOnSuccess,
		createBeneficiaryOnError
	);

	useEffect(() => {
		const modifiedBankData = banks?.map((bank) => ({
			value: bank?.bank_code,
			label: bank?.bank_name,
		}));

		if (modifiedBankData) {
			setBankData(modifiedBankData);
		}
	}, [banks]);

	const onClose = () => {
		setShow(false);
		setStage(1);
		setChangePinStage(0);
		setTransactionAttempt(0);
		setFormData({
			bank: "",
			bank_name: "",
			account_number: "",
			account_name: "",
			amount: null,
			narration: "",
			pin: "",
		});
		setOtpCode({
			one: "",
			two: "",
			three: "",
			four: "",
		});
		setRawInput("");
	};

	const handleOnclose = () => {
		if (showChangePIN) {
			setShow(false);
			setChangePinStage(0);
			setTransactionAttempt(0);
			setOtpCode({
				one: "",
				two: "",
				three: "",
				four: "",
			});
		} else {
			onClose();
		}
	};

	const ChangeTransactionPin = async () => {
		setGetChangeOTPTokenLoading(true);
		try {
			const changePinData = await getChangeTransactionPinOTP();
			if (changePinData) {
				const { status, message, pin_id } = changePinData;
				if (status) {
					setChangePinToken(pin_id);
					setShow(false);
					setShowChangePIN(true);
					setGetChangeOTPTokenLoading(false);
					return;
				} else if (!status) {
					setGetChangeOTPTokenLoading(false);
					Toast.error(message || "An Error Occured");
					return;
				} else {
					setGetChangeOTPTokenLoading(false);
					return Toast.error(message || "An Error Occured");
				}
			}
			setGetChangeOTPTokenLoading(false);
		} catch (error) {
			return Toast.error("An Error Occured");
		}
	};

	useEffect(() => {
		setFormData({ ...formData, pin: encryptPin(getFullCode(otpCode)) });
	}, [otpCode]);

	return (
		<>
			<Overlay
				show={show}
				setShow={setShow}
				modalRef={modalRef}
				onClose={handleOnclose}
			>
				{show && (
					<div
						className="relative flex w-full max-w-[536px] flex-col items-center rounded-[10px] bg-white px-8 py-7"
						ref={modalRef}
					>
						<figure
							className="cursor-pointer self-end rounded-full p-2.5 duration-300 ease-in-out hover:bg-slate-300"
							onClick={handleOnclose}
						>
							<MdOutlineClose size={24} />
						</figure>
						{stage <= 2 && (
							<h2 className="text-2xl font-medium text-blue-950">
								Bank Transfer
							</h2>
						)}

						<form
							className="mt-4 flex w-full flex-col items-center space-y-4"
							onSubmit={() => {
								console.log("dd");
							}}
						>
							{stage === 1 && (
								<>
									<FormInput
										label="Bank"
										labelStyles="font-medium"
										name="bank_name"
										id="bank_name"
										select={true}
										options={bankData}
										placeholderStyleOptions={{
											color: "#CBD5E1",
										}}
										selectStyleOptions={{
											border: "1.5px solid #CBD5E1",
											fontWeight: 300,
											fontSize: "14px",
											height: "51px",
										}}
										type="select"
										disabled={
											getAccountLookUpQuery.isLoading
										}
										placeholder="Select a bank"
										additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
										value={formData.bank}
										autoComplete="off"
										required={true}
										onChange={(bank) => {
											setFormData((prevData) => {
												return {
													...prevData,
													bank: bank?.value,
												};
											});
										}}
									/>
									<FormInput
										label="Account Number"
										labelStyles="font-medium"
										name="account_number"
										id="account_number"
										type="text"
										disabled={
											getAccountLookUpQuery?.isLoading
										}
										placeholder="Beneficiary Account Number"
										additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent px-4 py-3 font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
										value={formData.account_number}
										autoComplete="off"
										required={true}
										onChange={(e) => {
											setFormData({
												...formData,
												account_number: e.target.value,
											});
										}}
									/>
								</>
							)}
							{stage === 2 && (
								<>
									<div className="w-full">
										<div className="mb-3 flex flex-col space-y-1.5">
											<div>
												<h4 className="font-semibold text-blue-950">
													Name of Account:{" "}
													<span className="font-normal capitalize">
														{getAccountLookUpQuery?.data?.data?.data?.account_name?.toLowerCase()}
													</span>
												</h4>
											</div>
											<div>
												<h4 className="font-semibold text-blue-950">
													Bank Name:{" "}
													<span className="font-normal capitalize">
														{bankName} -{" "}
														{
															getAccountLookUpQuery
																?.data?.data
																?.data
																?.account_no
														}
													</span>
												</h4>
											</div>
										</div>
										<FormInput
											label="Amount"
											labelStyles="font-medium"
											name="amount"
											id="amount"
											type="text"
											placeholder="Amount in Naira (₦)"
											additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent px-4 py-3 font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
											value={rawInput
												?.toString()
												.replace(/\D/g, "")
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													","
												)}
											autoComplete="off"
											required={true}
											onChange={(e: any) => {
												const value =
													e.target.value.replace(
														/\D/g,
														""
													);

												if (
													value.length > 1 &&
													value.startsWith("0")
												) {
													setRawInput(
														e.target.value.substring(
															1
														)
													);
													return;
												}

												if (value === "") {
													setFormData({
														...formData,
														amount: 0,
													});
													setPaymentSuccessfulData({
														...paymentSuccessfulData,
														amount: "",
													});
												} else {
													const formattedValue =
														value.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															","
														);
													const amount = parseInt(
														value,
														10
													);

													if (
														amount + 50 >
														walletBalance
													) {
														return Toast.info(
															"Insufficient funds to complete transaction"
														);
													}

													setFormData({
														...formData,
														amount: amount,
													});

													setPaymentSuccessfulData({
														...paymentSuccessfulData,
														amount: formattedValue,
													});
												}

												setRawInput(e.target.value);
											}}
										/>
										<div className="mt-1 flex justify-between space-x-1">
											<small className="text-xs font-medium text-slate-400">
												Available Balance:{" "}
												<span className="font-semibold text-blue-900">
													₦{" "}
													{walletBalance
														?.toString()
														.replace(
															/\B(?=(\d{3})+(?!\d))/g,
															","
														)}
												</span>
											</small>
											<small className="text-xs font-medium text-slate-400">
												Transfer Fee:{" "}
												<span className="font-semibold text-blue-900">
													₦ 50
												</span>
											</small>
										</div>
									</div>
									<FormInput
										label="Narration"
										labelStyles="font-medium"
										name="narration"
										id="narration"
										type="text"
										placeholder="Enter a description"
										additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent px-4 py-3 font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
										value={formData.narration}
										autoComplete="off"
										required={false}
										onChange={(e) => {
											setFormData({
												...formData,
												narration: e.target.value,
											});
										}}
									/>
									<Button
										disabled={false}
										onClick={() => {
											if (
												validateTransferForm(
													formData,
													stage
												)
											)
												setStage(3);
										}}
										additionalClasses="text-white max-w-[418px]"
									>
										<p>
											Transfer{" - "}
											{formData.amount > 0 ? (
												<NumberFormat
													value={
														formData.amount > 0
															? formData.amount +
															  50
															: 0
													}
												/>
											) : (
												0
											)}
										</p>
									</Button>
								</>
							)}
							{stage === 3 && (
								<>
									<div className="flex max-w-[324px] flex-col space-y-1.5 text-center">
										<h2 className="text-2xl font-medium text-blue-950">
											Transaction PIN
										</h2>
										<p className="text-slate-600">
											You are about to Transfer{" "}
											<span className="font-semibold text-blue-950">
												{" "}
												₦
												{formData.amount
													?.toString()
													.toLocaleLowerCase()}
											</span>{" "}
											from{" "}
											<span className="font-semibold text-blue-950">
												{" "}
												Hadipay account
											</span>{" "}
											to{" "}
											<span className="font-semibold text-blue-950">
												{toTitleCase(
													getAccountLookUpQuery.data?.data?.data?.account_name?.toLowerCase()
												)}{" "}
												({bankName}){" "}
												{
													getAccountLookUpQuery?.data
														?.data?.data?.account_no
												}
											</span>
										</p>
									</div>
									<div className="flex w-full flex-col items-center space-y-4">
										<div className="flex w-full max-w-[376px] items-center justify-between">
											<OTPGroup
												otpCode={otpCode}
												setOtpCode={setOtpCode}
												size={4}
												inputSize={70}
												additionalClasses="rounded-lg"
											/>
										</div>
									</div>
									<Button
										disabled={
											makeTransactionQuery?.isLoading
										}
										onClick={() => {
											if (validatePINCode(otpCode)) {
												makeTransactionQuery.mutateMakeTransaction(
													formData
												);
												setBeneficiaryData({
													account_number:
														formData.account_number,
													account_name: toTitleCase(
														getAccountLookUpQuery
															?.data?.data?.data
															?.account_name
													),
													bank_name:
														formData.bank_name,
													bank_code: formData.bank,
												});
												setTransactionAttempt(
													(prevState: number) =>
														prevState + 1
												);
											}
										}}
										additionalClasses="text-white max-w-[418px]"
									>
										{makeTransactionQuery?.isLoading ? (
											<Loader2 />
										) : (
											"Confirm"
										)}
									</Button>
									{!makeTransactionQuery?.isLoading &&
										transactionAttempt > 1 && (
											<Button
												disabled={false}
												additionalClasses="w-content h-auto bg-transparent text-xl font-semibold text-error-600 underline !w-auto"
												onClick={ChangeTransactionPin}
											>
												{getChangeOTPTokenLoading ? (
													<Loader2 color="#061F6A" />
												) : (
													"Change transaction pin"
												)}
											</Button>
										)}
								</>
							)}
						</form>
						<RequestIsLoading
							isLoading={
								getBanksIsLoading ||
								getAccountLookUpQuery?.isLoading
							}
						/>
					</div>
				)}
			</Overlay>
			<SuccessModal
				title={"Transaction Processing!"}
				show={isPaymentSuccessful}
				setShow={setIsPaymentSuccessful}
				content={`Your transaction of ₦${paymentSuccessfulData.amount} to ${paymentSuccessfulData.account_name} is being processed`}
			>
				<div className="mt-2.5 flex w-full flex-col items-center">
					<Button
						disabled={getAccountLookUpQuery?.isLoading}
						onClick={() => {
							createBeneficiaryMutation.mutateCreateBeneficiary(
								beneficiaryData
							);
						}}
						additionalClasses="max-w-[418px] bg-white text-slate-500 border border-slate-200 h-[54px]"
					>
						{createBeneficiaryMutation?.isLoading ? (
							<Loader2 color="#061F6A" />
						) : (
							<div className="flex items-center space-x-[1px]">
								<RiDownloadLine size={24} />
								<p className="text-lg font-semibold">
									Save as Beneficiary
								</p>
							</div>
						)}
					</Button>
				</div>
			</SuccessModal>
		</>
	);
};

const validateTransferForm: (
	formData: TransferData,
	stage: number
) => boolean = (formData, stage): boolean => {
	switch (true) {
		case stage === 1 && !formData.bank:
			Toast.error("Kindly select a bank");
			return false;
		case stage === 1 && !formData.account_number:
			Toast.error("Kindly enter a valid account number");
			return false;
		case stage === 2 && !formData.amount:
			Toast.error("Kindly enter a valid amount");
			return false;
		default:
			return true;
	}
};

export default BankTransfer;
