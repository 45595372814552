import React from "react";
import { RetailerCreditAuthorizationPageProps } from "types/views";
import { Button } from "components/Button";
import { Loader2 } from "components/Loaders";
import NumberFormat from "../../../../utils/NumberFormat";

interface AuthorizationFormProps extends RetailerCreditAuthorizationPageProps {
	creditData: any;
	forbidCredit?: () => void;
	isForbidCreditLoading: boolean;
	isAdminRequest: boolean;
}

const AuthorizationForm = ({
	setPage,
	creditData,
	forbidCredit,
	isForbidCreditLoading,
	isAdminRequest,
}: AuthorizationFormProps) => {
	return (
		<div>
			<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
				<div className="flex flex-col gap-2">
					<p>Credit Applicant: {creditData?.retailer_name} </p>
					{/* This can change to content_object.total_amount later  */}
					<p>
						Purchase Amount:{" "}
						<NumberFormat
							value={
								creditData?.content_object?.total_amount ?? 0
							}
						/>
					</p>
					<p>
						Credit Amount:{" "}
						<NumberFormat value={creditData?.amount ?? 0} />
					</p>
				</div>
			</div>

			<div className="m-5 space-y-4 text-[14px] md:text-[16px]">
				<p className="">
					Do you want proceed with your credit application?
				</p>
				<div className=" flex justify-between gap-x-12 gap-y-4 md:flex-row">
					<Button
						additionalClasses="bg-red-500 text-white w-fit "
						disabled={isForbidCreditLoading}
						onClick={() => forbidCredit()}
					>
						{isForbidCreditLoading ? <Loader2 /> : "	No, i dont"}
					</Button>
					<Button
						additionalClasses="bg-transparent border border-[#BABFC3] w-fit"
						disabled={isForbidCreditLoading || false}
						onClick={() => setPage(1)}
					>
						Yes, i do
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AuthorizationForm;
