import React, { useRef, useState, Fragment } from "react";
import { Button, Loader2, OTPInput } from "../components";
import Logo from "../assets/image/blackLogo.svg";
import blackLogo from "../assets/image/blackLogo.svg";
import { Icon as Iconify } from "@iconify/react";
import { Link } from "react-router-dom";

const Verify: React.FC = () => {
	const [username, setUsername] = useState<string | null>("");
	const [time, setTime] = useState<number>(5 * 60);
	const [otpCode, setSetOtpCode] = useState<{
		one: string;
		two: string;
		three: string;
		four: string;
		five: string;
		six: string;
	}>({
		one: "",
		two: "",
		three: "",
		four: "",
		five: "",
		six: "",
	});
	const [hasResent, setHasResent] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const minutes = Math.floor(time / 60)
		.toString()
		.padStart(2, "0");
	const seconds = (time % 60).toString().padStart(2, "0");

	const fullCode =
		otpCode.one +
		otpCode.two +
		otpCode.three +
		otpCode.four +
		otpCode.five +
		otpCode.six;

	const otpRef1 = useRef<HTMLInputElement>(null);
	const otpRef2 = useRef<HTMLInputElement>(null);
	const otpRef3 = useRef<HTMLInputElement>(null);
	const otpRef4 = useRef<HTMLInputElement>(null);
	const otpRef5 = useRef<HTMLInputElement>(null);
	const otpRef6 = useRef<HTMLInputElement>(null);

	const onSuccess = () => {
		setHasResent(true);
		setTime(300);
		setIsSuccess(true);
	};

	const onError = () => {
		setIsLoading(false);
	};

	const handleVerifyOtp = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			setIsSuccess(true);
		}, 3000);
	};

	const handleResendSMSOtp = () => {
		console.log(1);
	};

	// const handleResendWhatsappOtp = () => {
	// 	console.log(1);
	// };

	return isSuccess ? (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/register"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign up
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex h-full w-full flex-col items-center justify-center">
				<Iconify
					icon="solar:check-circle-broken"
					color="#01A912"
					className="h-28 w-full max-w-[112px]"
				/>
				<div className="flex flex-col gap-2.5">
					<h3 className="w-full text-center text-2xl font-semibold text-[#002060] md:max-w-[402px]">
						Successful!
					</h3>
					<p className="max-w-[315px] text-center text-lg font-medium text-[#626d8878]">
						Verification successful!
					</p>
				</div>
			</div>
		</Fragment>
	) : (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<p className="flex w-full items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center">
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						<Link to="/register">Sign up</Link>
					</span>
				</p>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3">
					<div className="w-full max-w-[500px]">
						<h3 className="w-full text-left text-[32px] font-[600] leading-[1] text-primary-900 lg:leading-[56px]">
							OTP Verification
						</h3>
						<p className="test-[14px] mb-[63px] font-[400] text-secondary-400">
							Please enter code sent to your phone
						</p>
						<form action="" className="flex w-full flex-col">
							<div className="item-center flex flex-wrap justify-center gap-5">
								<OTPInput
									otpRef={otpRef1}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											one: e.target.value,
										}));
										if (
											e.target.value.length === 1 &&
											otpRef2.current
										) {
											(
												otpRef2.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.one}
								/>
								<OTPInput
									otpRef={otpRef2}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											two: e.target.value,
										}));
										if (
											e.target.value.length === 1 &&
											otpRef2.current
										) {
											(
												otpRef3.current as HTMLInputElement
											)?.focus();
										}
										if (
											e.target.value.length === 0 &&
											otpRef2.current
										) {
											(
												otpRef1.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.two}
								/>
								<OTPInput
									otpRef={otpRef3}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											three: e.target.value,
										}));
										if (
											e.target.value.length === 1 &&
											otpRef4.current
										) {
											(
												otpRef4.current as HTMLInputElement
											)?.focus();
										}
										if (
											e.target.value.length === 0 &&
											otpRef3.current
										) {
											(
												otpRef2.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.three}
								/>
								<OTPInput
									otpRef={otpRef4}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											four: e.target.value,
										}));
										if (
											e.target.value.length === 1 &&
											otpRef5.current
										) {
											(
												otpRef5.current as HTMLInputElement
											)?.focus();
										}
										if (
											e.target.value.length === 0 &&
											otpRef4.current
										) {
											(
												otpRef3.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.four}
								/>
								<OTPInput
									otpRef={otpRef5}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											five: e.target.value,
										}));
										if (
											e.target.value.length === 1 &&
											otpRef6.current
										) {
											(
												otpRef6.current as HTMLInputElement
											)?.focus();
										}
										if (
											e.target.value.length === 0 &&
											otpRef5.current
										) {
											(
												otpRef4.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.five}
								/>
								<OTPInput
									otpRef={otpRef6}
									size={66}
									onChange={(e) => {
										setSetOtpCode((prevData) => ({
											...prevData,
											six: e.target.value,
										}));
										if (
											e.target.value.length === 0 &&
											otpRef6.current
										) {
											(
												otpRef5.current as HTMLInputElement
											)?.focus();
										}
									}}
									otpCode={otpCode.six}
								/>
							</div>
							{/* </div> */}

							<Button
								disabled={isLoading}
								additionalClasses={
									"text-lg text-white font-medium bg-primary-800 mt-[75px]"
								}
								onClick={handleVerifyOtp}
							>
								{isLoading ? <Loader2 /> : "Submit"}
							</Button>

							<h4
								className="mt-[8px] cursor-pointer text-center text-[16px] text-secondary-400"
								onClick={handleResendSMSOtp}
							>
								Did not get the code?{" "}
								<span className="font-bold text-primary-700">
									Resend code
								</span>
							</h4>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Verify;
