import {
	ContinueOnboarding,
	Sidebar,
	Header,
	VerificationProcessModal,
} from "../components";
import { Outlet } from "react-router";
import { OnboardingReviewModal } from "../components/modals/OnboardingReviewModal";
import { useOnboarding } from "../provider/OnboardingProvider";

const Dashboard = () => {
	const {
		showOnboardingModal,
		setShowOnboardingModal,
		showNotVerified,
		setShowNotVerified,
		showIncompleteOnboarding,
		setShowIncompleteOnboarding,
	} = useOnboarding();

	return (
		<>
			<div className="relative z-0 flex h-full w-full overflow-hidden">
				<Sidebar />
				<div className="relative flex min-h-screen max-w-full flex-1 flex-col overflow-hidden bg-[#F6F7F9] ">
					<div className="relative flex h-full w-full flex-1 flex-col overflow-auto">
						<div className="flex h-full flex-col focus-visible:outline-0">
							<div className="flex-1 overflow-hidden">
								<div className="relative h-full">
									<div className="flex h-full w-full flex-col overflow-auto">
										<Header />
										<div className="h-full w-full flex-1 flex-col items-center justify-center px-7 py-8 msm:px-5">
											<Outlet />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ContinueOnboarding
				show={showOnboardingModal}
				setShow={setShowOnboardingModal}
			/>
			<VerificationProcessModal
				show={showNotVerified}
				setShow={setShowNotVerified}
			/>
			<OnboardingReviewModal
				show={showIncompleteOnboarding}
				setShow={setShowIncompleteOnboarding}
			/>
		</>
	);
};

export default Dashboard;

// Custom hook to consume context
// export const useOnboardingModal = () => useContext(OnboardingModalContext);
