import { OTPCode } from "../../types/components";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { OTPInput } from "./OTPInput";

interface OTPGroupProps {
	otpCode: OTPCode;
	setOtpCode: Dispatch<SetStateAction<OTPCode>>;
	inputSize?: number;
	additionalClasses?: string;
	// fullCode: string;
	size: 4 | 6;
}

const OTPGroup: React.FC<OTPGroupProps> = ({
	otpCode,
	setOtpCode,
	inputSize,
	additionalClasses,
	// fullCode,
	size,
}) => {
	// fullCode =
	// 	otpCode.one +
	// 	otpCode.two +
	// 	otpCode.three +
	// 	otpCode.four +
	// 	otpCode.five +
	// 	otpCode.six;

	const otpRef1 = useRef<HTMLInputElement>(null);
	const otpRef2 = useRef<HTMLInputElement>(null);
	const otpRef3 = useRef<HTMLInputElement>(null);
	const otpRef4 = useRef<HTMLInputElement>(null);
	const otpRef5 = useRef<HTMLInputElement>(null);
	const otpRef6 = useRef<HTMLInputElement>(null);
	return (
		<>
			<OTPInput
				otpRef={otpRef1}
				onChange={(e) => {
					setOtpCode((prevData) => ({
						...prevData,
						one: e.target.value,
					}));
					if (e.target.value.length === 1 && otpRef2.current) {
						(otpRef2.current as HTMLInputElement)?.focus();
					}
				}}
				otpCode={otpCode.one}
				size={inputSize}
				additionalClasses={additionalClasses}
			/>
			<OTPInput
				otpRef={otpRef2}
				onChange={(e) => {
					setOtpCode((prevData) => ({
						...prevData,
						two: e.target.value,
					}));
					if (e.target.value.length === 1 && otpRef2.current) {
						(otpRef3.current as HTMLInputElement)?.focus();
					}
					if (e.target.value.length === 0 && otpRef2.current) {
						(otpRef1.current as HTMLInputElement)?.focus();
					}
				}}
				otpCode={otpCode.two}
				size={inputSize}
				additionalClasses={additionalClasses}
			/>
			<OTPInput
				otpRef={otpRef3}
				onChange={(e) => {
					setOtpCode((prevData) => ({
						...prevData,
						three: e.target.value,
					}));
					if (e.target.value.length === 1 && otpRef4.current) {
						(otpRef4.current as HTMLInputElement)?.focus();
					}
					if (e.target.value.length === 0 && otpRef3.current) {
						(otpRef2.current as HTMLInputElement)?.focus();
					}
				}}
				otpCode={otpCode.three}
				size={inputSize}
				additionalClasses={additionalClasses}
			/>
			<OTPInput
				otpRef={otpRef4}
				onChange={(e) => {
					setOtpCode((prevData) => ({
						...prevData,
						four: e.target.value,
					}));
					if (
						otpCode.five &&
						e.target.value.length === 1 &&
						otpRef5.current
					) {
						(otpRef5.current as HTMLInputElement)?.focus();
					}
					if (e.target.value.length === 0 && otpRef4.current) {
						(otpRef3.current as HTMLInputElement)?.focus();
					}
				}}
				otpCode={otpCode.four}
				size={inputSize}
				additionalClasses={additionalClasses}
			/>
			{size === 6 && (
				<>
					<OTPInput
						otpRef={otpRef5}
						onChange={(e) => {
							setOtpCode((prevData) => ({
								...prevData,
								five: e.target.value,
							}));
							if (
								e.target.value.length === 1 &&
								otpRef6.current
							) {
								(otpRef6.current as HTMLInputElement)?.focus();
							}
							if (
								e.target.value.length === 0 &&
								otpRef5.current
							) {
								(otpRef4.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.five}
						size={inputSize}
						additionalClasses={additionalClasses}
					/>
					<OTPInput
						otpRef={otpRef6}
						onChange={(e) => {
							setOtpCode((prevData) => ({
								...prevData,
								six: e.target.value,
							}));
							if (
								e.target.value.length === 0 &&
								otpRef6.current
							) {
								(otpRef5.current as HTMLInputElement)?.focus();
							}
						}}
						otpCode={otpCode.six}
						size={inputSize}
						additionalClasses={additionalClasses}
					/>
				</>
			)}
		</>
	);
};

export default OTPGroup;

export const validatePINCode = (otpCode: {
	[key: string]: string;
}): boolean => {
	const otpCodeKeys = Object.keys(otpCode);
	for (let i = 0; i < otpCodeKeys.length; i++) {
		if (otpCode[otpCodeKeys[i]].length === 0) return false;
	}
	return true;
};

export const getFullCode = (otpCode: { [key: string]: string }): string => {
	const otpCodeKeys = Object.keys(otpCode);
	let code = "";
	for (let i = 0; i < otpCodeKeys.length; i++) {
		code += otpCode[otpCodeKeys[i]];
	}
	return code;
};
