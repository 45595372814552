import React, { useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Button, Loader2, RequestSuccessFail } from "../../../components";
import {
	useCustomerDeclineCredit,
	useSendVerifyOTP,
	useVerifyCredit,
} from "../../../hooks/useCreditData";
import { AxiosResponse } from "axios";
import { CreditAcceptProcessProps } from "../../../types/views";
import { RequestSuccessFailProps } from "../../../types/components";
import { getRepaymentDateFromTenure } from "../../../utils/utilities";

const AcceptCredit: React.FC<CreditAcceptProcessProps> = ({
	creditData,
	stage,
	code,
	goBack,
	setStage,
	setCreditData,
	setPinID,
}) => {
	const [show, setShow] = useState(false);
	const [requestFormData, setRequestFormData] =
		useState<RequestSuccessFailProps>({
			state: false,
			title: "",
			subtitle: "",
		});

	const verifyCreditOnSuccess = (data: AxiosResponse) => {
		if (setCreditData) setCreditData(data);
	};

	const verifyCreditOnError = () => {
		setShow(true);
		setRequestFormData({
			state: false,
			title: "This link could not be verified",
			subtitle: "The credit could not be verified",
		});
	};

	const sendVerifyOTPOnSuccess = (data: AxiosResponse) => {
		setPinID(data.data.data.pinId);
		setStage(2);
	};

	const sendVerifyOTPOnError = () => {
		setShow(true);
		setRequestFormData({
			state: false,
			title: "OTP Error",
			subtitle: "The OTP code could not be sent. Kindly try again later.",
		});
	};
	const rejectCreditOnSuccess = () => {
		setShow(true);
		setRequestFormData({
			state: true,
			title: "Credit Rejected",
			subtitle: "Credit has been successfully rejected.",
		});
	};

	const rejectCreditOnError = () => {
		setShow(true);
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The credit could not be rejected.",
		});
	};

	const { mutateVerifyCredit, isLoading: verifyLoading } = useVerifyCredit(
		verifyCreditOnSuccess,
		verifyCreditOnError
	);

	const { mutateSendOTP, isLoading: sendVerifyOTPLoading } = useSendVerifyOTP(
		sendVerifyOTPOnSuccess,
		sendVerifyOTPOnError
	);

	const { mutateDeclineCredit, isLoading: declineCreditIsLoading } =
		useCustomerDeclineCredit(rejectCreditOnSuccess, rejectCreditOnError);

	useEffect(() => {
		if (verifyLoading) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [verifyLoading]);

	useEffect(() => {
		if (code) {
			mutateVerifyCredit(code);
		}
	}, [code]);

	return (
		<>
			{!show && stage === 1 ? (
				<section className="w-full max-w-[640px]">
					<div className="relative p-3 text-center text-[24px] font-semibold text-blue-950">
						<div
							className="absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer"
							onClick={goBack}
						>
							<RiArrowLeftSLine className="m-5 h-[32px] w-[32px]" />
						</div>
						Accept Credit Offer
					</div>
					<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
						<div
							style={{
								visibility: verifyLoading
									? "hidden"
									: "initial",
							}}
							className="flex flex-col gap-2"
						>
							<p>
								Credit Applicant:{" "}
								{!verifyLoading &&
									creditData?.data?.data &&
									creditData?.data?.data?.retailer_name}
							</p>
							<p>
								Credit Amount:{" "}
								{!verifyLoading &&
									creditData?.data?.data &&
									"₦" +
										(+creditData?.data?.data
											?.amount).toLocaleString()}
							</p>
							<p>
								Credit Offer:{" "}
								{!verifyLoading &&
									creditData?.data?.data &&
									"₦" +
										(+creditData?.data?.data
											?.credit_offer).toLocaleString()}
							</p>
							<p>
								Repayment Amount:{" "}
								{!verifyLoading && creditData?.data?.data && (
									<>
										{creditData?.data?.data
											?.repayment_amount
											? "₦ " +
											  creditData?.data?.data
													?.repayment_amount
											: "NaN"}
									</>
								)}
							</p>
							<p>
								Repayment Date:{" "}
								{!verifyLoading &&
									creditData?.data?.data &&
									"To be determined after delivery"}
							</p>
						</div>
						<div
							className={`absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 ${
								!verifyLoading ? "hidden" : ""
							}`}
						>
							<Loader2 color="#061F6A" />
						</div>
					</div>

					<div className="m-5 flex items-center justify-between text-[14px] font-[500]">
						<Button
							disabled={declineCreditIsLoading}
							onClick={() => {
								if (code) mutateDeclineCredit(code);
							}}
							additionalClasses="rounded-[4px] bg-[#D52500] py-2 font-normal text-white max-w-[136px] text-sm"
						>
							{declineCreditIsLoading ? <Loader2 /> : "Reject"}
						</Button>
						<Button
							disabled={sendVerifyOTPLoading}
							onClick={() => {
								if (code) mutateSendOTP(code);
							}}
							additionalClasses="rounded-[4px] border border-[#BABFC3] px-12 py-2 text-[14px] text-[#202223] max-w-[136px] bg-white"
						>
							{sendVerifyOTPLoading ? (
								<Loader2 color="#002060" />
							) : (
								"Proceed"
							)}
						</Button>
					</div>
					<div
						className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 ${
							!verifyLoading ? "hidden" : ""
						}`}
					>
						<Loader2 color="#061F6A" />
					</div>
				</section>
			) : (
				show && (
					<RequestSuccessFail
						state={requestFormData.state}
						title={requestFormData.title}
						subtitle={requestFormData.subtitle}
					/>
				)
			)}
		</>
	);
};
export default AcceptCredit;
