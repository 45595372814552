import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Loader2 } from "../../../components";
import { Button } from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentModal, SuccessModal } from "../../../components/modals";
import {
	firstWordToTitle,
	repaymentFormatDate,
	letter_profile_icon,
} from "../../../utils/utilities";
import { useGetRepaymentData } from "../../../hooks/useRepaymentData";
import { FaRegSadTear } from "react-icons/fa";
import { Toast } from "../../../utils/toast";
import { WordOrdinal } from "ordinalify";
import { useAuth } from "../../../hooks/auth";
import { useGetPoolerWalletInfo } from "../../../hooks/useWalletData";

const CreditRepaymentID: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
	const { user } = useAuth();
	const onboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;
	user?.distributor?.user_profile?.is_verified;

	const getAccountError = () => {
		Toast.error("Error fetching account details 🙂");
	};

	const getPoolerWalletQuery = useGetPoolerWalletInfo(
		undefined,
		getAccountError,
		Boolean(onboardedUser && isVerifiedUser)
	);

	const handleClick = () => {
		setShowPaymentModal(true);
	};

	const goBack = () => {
		navigate("/dashboard/credit-book/repayments");
	};

	const regex =
		/^\/dashboard\/credit-book\/repayments\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

	if (!regex.test(location.pathname)) {
		navigate("/dashboard/credit-book/repayments");
	}

	const onError = () => {
		Toast.error("Invalid credit");
		navigate("/dashboard/credit-book/repayments");
	};

	const repaymendId = location.pathname.split(
		"/dashboard/credit-book/repayments/"
	)[1];

	const {
		data: repaymentData,
		isLoading,
		isSuccess,
	} = useGetRepaymentData(undefined, onError, repaymendId);

	return (
		<div>
			<div
				className="flex cursor-pointer items-center gap-2"
				onClick={goBack}
			>
				<BiArrowBack />
				<p>Back To Repayment History</p>
			</div>
			<section className="mb-10 mt-6">
				<h1 className="text-[24px] font-[600] text-[#1E293B]">
					Credit Repayment
				</h1>

				<div className="mt-[34px] px-2.5 sm:px-5">
					<div
						className={`mx-auto my-8 flex max-h-[60px] w-full items-center justify-center sm:my-12 ${
							!isLoading ? "hidden" : ""
						}`}
					>
						<span className="scale-[2]">
							<Loader2 color="#061F6A" />
						</span>
					</div>
					{isSuccess && repaymentData?.data && (
						<>
							<hr className="border-t border-slate-300" />
							<div className="flex gap-2 p-4 msm:max-w-fit msm:flex-col">
								<div className="flex-1">
									<h1 className="text-base font-semibold text-[#1E293B]">
										Hadi Wallet
									</h1>
									<p className="max-w-[288px] text-sm text-slate-400">
										This is the account number to which all
										transactions would be made
									</p>
								</div>
								<div className="flex flex-1 flex-col gap-y-2">
									<div className="rounded-md border border-[#000] border-opacity-20 px-5 py-4">
										{/* <figure className="flex items-center gap-2">
											<figure className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-[#002060] text-[12px] font-semibold text-white">
												{getPoolerWalletQuery.isFetched &&
												getPoolerWalletQuery.data?.data
													?.bank_name !== undefined
													? letter_profile_icon(
															getPoolerWalletQuery
																.data?.data
																?.bank_name
													  )
													: "H"}
											</figure>
											<figcaption>
												<p className="font-semibold text-blue-950">
													Send Funds
												</p>
												<p className="text-sm text-gray-400">
													1232652734
												</p>
												<p className="text-sm text-blue-950">
													Hadi Finance
												</p>
											</figcaption>
										</figure>
										<div className=""></div> */}
										<div>
											<div className="flex items-center gap-2">
												<figure className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-[#002060] text-[12px] font-semibold text-white">
													{getPoolerWalletQuery.isFetched &&
													getPoolerWalletQuery.data
														?.data?.bank_name !==
														undefined
														? letter_profile_icon(
																getPoolerWalletQuery
																	.data?.data
																	?.bank_name
														  )
														: "H"}
												</figure>
												<h4 className="font-semibold text-blue-950">
													{getPoolerWalletQuery.isFetched &&
													getPoolerWalletQuery?.data
														?.data?.bank_name !==
														undefined
														? getPoolerWalletQuery
																?.data?.data
																?.bank_name
														: "N/A"}
												</h4>
											</div>
											<div className="px-8">
												<p className="text-gray-400">
													{" "}
													{getPoolerWalletQuery.isFetched &&
													getPoolerWalletQuery?.data
														?.data?.account_no !==
														undefined
														? getPoolerWalletQuery
																.data?.data
																?.account_no
														: "N/A"}
												</p>
												<h4 className="text-blue-950">
													{getPoolerWalletQuery.isFetched &&
													getPoolerWalletQuery?.data
														?.data?.display_name !==
														undefined
														? getPoolerWalletQuery
																?.data?.data
																?.display_name
														: "N/A"}
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr className="border-t border-slate-300" />
							<div>
								<hr className="mt-[32px] border-t border-slate-300" />
								<div className="p-4">
									<h3 className="text-[24px] font-[600] text-slate-800">
										{user?.distributor?.name || "N/A"}
									</h3>

									<div className="grid grid-cols-1 gap-x-2 pt-[17px] text-gray-700 sm:grid-cols-2 lg:max-w-[81.5%]">
										<div className="flex flex-col">
											<p className="flex gap-3 py-2">
												Loan Amount:
												<span>
													₦{" "}
													{(+repaymentData?.data?.data
														.amount).toLocaleString()}
												</span>
											</p>
											<p className="flex gap-3 py-2">
												Repayment Amount:
												<span>
													₦{" "}
													{(+repaymentData?.data?.data
														.repayment_amount).toLocaleString()}
												</span>
											</p>
											<p className="flex gap-3 py-2">
												Outstanding Balance:
												<span>
													₦{" "}
													{(+repaymentData?.data?.data
														.amount_due).toLocaleString()}
												</span>
											</p>
											<p className="flex gap-3 py-2">
												Status:
												<span>
													{firstWordToTitle(
														repaymentData?.data
															?.data.status,
														"_"
													)}
												</span>
											</p>
										</div>
										<div className="flex flex-col sm:justify-self-end">
											<p className="flex gap-3 py-2">
												Date Issued:
												<span>
													{repaymentFormatDate(
														repaymentData?.data
															?.data.created
													)}
												</span>
											</p>
											<p className="flex gap-3 py-2">
												Repayment Date:
												<span>
													{repaymentData?.data?.data
														.due_date
														? repaymentFormatDate(
																repaymentData
																	?.data?.data
																	.due_date
														  )
														: "N/A"}
												</span>
											</p>
										</div>
									</div>
								</div>
								<hr className="border-t border-slate-300" />
							</div>

							{repaymentData?.data?.data.repayments.length !==
								0 || !repaymentData?.data ? (
								<CreditRepaymentItem
									installments={
										repaymentData?.data?.data.repayments
									}
								/>
							) : (
								<section className="my-4 flex flex-col items-center gap-3">
									<FaRegSadTear
										size={100}
										className="text-blue-950"
									/>
									<h4 className="text-black">
										There has not been any payment for this
										credit.
									</h4>
								</section>
							)}

							<div className="mt-[32px] flex justify-end">
								<Button
									disabled={
										repaymentData?.data?.data.amount_due ===
										0
									}
									additionalClasses={
										"text-lg text-white font-medium w-full max-w-fit px-5"
									}
									onClick={handleClick}
								>
									Pay for retailer
								</Button>
							</div>
						</>
					)}
				</div>
			</section>
			<PaymentModal
				show={showPaymentModal}
				isPaymentSuccessful={isPaymentSuccessful}
				repaymentID={repaymendId}
				setShow={setShowPaymentModal}
				setIsPaymentSuccessful={setIsPaymentSuccessful}
				walletBalance={getPoolerWalletQuery?.data?.data?.balance ?? 0}
			/>
			<SuccessModal
				show={isPaymentSuccessful}
				setShow={setIsPaymentSuccessful}
				content={"Your Transaction was successful!"}
			/>
		</div>
	);
};

export default CreditRepaymentID;

interface CreditRepaymentItemProps {
	installments: { amount: number; created: Date }[];
}

const CreditRepaymentItem: React.FC<CreditRepaymentItemProps> = ({
	installments,
}) => {
	return (
		<div>
			<hr className="mt-8 border-t border-slate-300" />
			<div className="p-4">
				<h3 className="text-2xl font-semibold text-slate-800 ">
					Payment Method
				</h3>

				<p className="pt-3 font-semibold text-slate-800">
					Instalment Details
				</p>

				<div className="pt-3 text-slate-700">
					{installments.map((installment, index) => (
						<p key={index} className="flex gap-3 py-2">
							<span className="flex gap-1">
								{WordOrdinal(index + 1, {
									capitalizeFirstLetter: true,
								})}{" "}
								Instalment:
							</span>
							<span>₦ {installment.amount.toLocaleString()}</span>
						</p>
					))}
				</div>
			</div>
			<hr className="border-t border-slate-300" />
		</div>
	);
};
