import { Fragment } from "react";
import Logo from "../assets/image/Logo.svg";
import blackLogo from "../assets/image/blackLogo.svg";
import lines from "../assets/image/lines.svg";
import downline from "../assets/image/downline.svg";
import laptop from "../assets/image/laptop.png";
import avatar1 from "../assets/image/avatar/avatar.svg";
import avatar2 from "../assets/image/avatar/avatar2.svg";
import avatar3 from "../assets/image/avatar/avatar3.svg";
import bg from "../assets/image/background/landing-page-bg.png";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "components";

const LandingPage = () => {
	const navigate = useNavigate();
	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/login"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Already have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign in
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex h-full  w-full items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3">
					<h3 className="w-full text-center text-[24px] font-[600] text-[#1A4393]">
						Are you a Distributor or Retailer?
					</h3>

					{/* <div
						className="mt-[57px] flex w-full max-w-[500px] cursor-pointer items-center justify-between gap-8 rounded-md border border-[#D1EAFF] bg-blue-100 bg-opacity-30 px-3 py-3"
						onClick={() => navigate("/register")}
					></div> */}

					<Button
						additionalClasses="h-auto mt-[57px] max-w-[510px] flex w-full cursor-pointer items-center justify-between gap-8 rounded-md border border-[#D1EAFF]  hover:bg-blue-50 bg-white bg-opacity-30 px-6 py-3 group text-left"
						onClick={() => navigate("/register")}
						disabled={false}
					>
						<div className="flex flex-row items-center border px-4">
							<img
								src={avatar1}
								alt="avatar"
								className="absolute z-[2] ml-2"
							/>
							<img
								src={avatar2}
								alt="avatar"
								className="absolute z-[1] -ml-2 mr-5"
							/>
							<img
								src={avatar3}
								alt="avatar"
								className="absolute z-0 -ml-5"
							/>
						</div>
						<div className="w-full">
							<p className="text-[16px] font-[500] text-[#002060]">
								I&apos;m a Distributor
							</p>
							<p className="test-[12px] font-[500] text-[#94A3B8]">
								I need to open an account for my customers
							</p>
						</div>
						<div>
							<div className="flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-full border border-[#002060] bg-white">
								<div className="bg-red h-[20px] w-[20px] rounded-full group-hover:bg-[#002060]"></div>
							</div>
						</div>
					</Button>
					<Button
						additionalClasses="h-auto mt-[16px] max-w-[510px] flex w-full cursor-pointer items-center justify-between gap-8 rounded-md border border-[#D1EAFF]  hover:bg-blue-50 bg-white bg-opacity-30 px-6 py-3 group text-left"
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=com.hadifinance.hadifinanceapp",
								"_blank"
							)
						}
						disabled={false}
					>
						<div className="flex flex-row items-center border px-4">
							<img
								src={avatar1}
								alt="avatar"
								className="absolute z-[2] ml-2"
							/>
							<img
								src={avatar2}
								alt="avatar"
								className="absolute z-[1] -ml-2 mr-5"
							/>
							<img
								src={avatar3}
								alt="avatar"
								className="absolute z-0 -ml-5"
							/>
						</div>
						<div className="w-full">
							<p className="text-[16px] font-[500] text-[#002060]">
								I&apos;m a Retailer
							</p>
							<p className="test-[12px] font-[500] text-[#94A3B8]">
								I need to open an account for myself
							</p>
						</div>
						<div>
							<div className="flex h-[20px] w-[20px] items-center justify-center overflow-hidden rounded-full border border-[#002060] bg-white">
								<div className="bg-red h-[20px] w-[20px] rounded-full group-hover:bg-[#002060]"></div>
							</div>
						</div>
					</Button>
					{/* <div
						className="mt-[16px] flex w-full max-w-[500px] cursor-pointer items-center justify-between gap-8 rounded-md border-2 border-[#F1F5F9] bg-[#FFFFFF] bg-opacity-30 px-3 py-3"
						onClick={() => navigate("/retailer")}
					>
						<div className="flex flex-row items-center border px-4">
							<img
								src={avatar1}
								alt="avatar"
								className="absolute z-[2] -mt-3 ml-2"
							/>
							<img
								src={avatar2}
								alt="avatar"
								className="absolute z-[1] -ml-2 -mt-3 mr-5"
							/>
							<img
								src={avatar3}
								alt="avatar"
								className="absolute z-0 -ml-5 -mt-3"
							/>
						</div>
						<div className="w-full">
							<p className="text-[16px] font-[500] text-[#002060]">
								I&apos;m a Retailer
							</p>
							<p className="test-[12px] font-[500] text-[#94A3B8]">
								I need to open an account for myself
							</p>
						</div>
						<div>
							<div className="h-[20px] w-[20px] rounded-full border border-[#475569]"></div>
						</div>
					</div> */}
				</div>
			</div>
		</Fragment>
	);
};

export default LandingPage;
