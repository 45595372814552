import React, { Dispatch, SetStateAction, useRef } from "react";
import { motion } from "framer-motion";
import Overlay from "./Overlay";
import { slideDownAnimation } from "../../services/Animations";
import { Button } from "../Button";
import { Loader2 } from "../Loaders";
import SuccessImg from "../../assets/image/icons/success.svg";
import ErrorImg from "../../assets/image/icons/error.png";
import CancelImg from "../../assets/image/icons/cancel.png";
import { MdClose } from "react-icons/md";

type ConfirmCancelCreditModalProps = {
	processCancel: (data: Record<string, unknown>) => void;
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	loading: boolean;
	error: boolean;
	success: boolean;
	data: Record<string, unknown>;
};

export const ConfirmCancelCreditModal: React.FC<
	ConfirmCancelCreditModalProps
> = ({ show, setShow, processCancel, loading, data, success, error }) => {
	const modalRef = useRef<HTMLDivElement>(null);

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-6 flex w-full max-w-[532px] flex-col items-center rounded-[10px] bg-white p-4  md:m-6 md:p-6"
				>
					{success && (
						<>
							<div className="flex flex-col items-center gap-y-3">
								<img src={SuccessImg} alt="success" />
								<p>Credit was successfully cancelled</p>
							</div>
						</>
					)}

					{error && (
						<>
							<div>
								<div className="flex flex-col items-center gap-y-3">
									<img src={ErrorImg} alt="success" />
									<p>The Credit couldn&apos;t be cancelled</p>
								</div>
							</div>
						</>
					)}
					{!success && !error && (
						<div className="mt-2 flex w-full flex-col items-center gap-5">
							<div className="flex w-full justify-end">
								<button
									className="text-[2em] "
									onClick={() => setShow(!show)}
								>
									<MdClose />
								</button>
							</div>
							<img src={CancelImg} alt="error" className="" />
							<p className="text-center text-[18px]">
								Are you sure you want to cancel the credit for{" "}
								<b>{data.retailer_name as string}</b> with phone
								number {""}
								<b>{data.customer as string}</b>
							</p>
							<div className="flex gap-4">
								{/* <Button
									disabled={loading}
									onClick={() => {
										setShow(false);
									}}
									additionalClasses="rounded-[4px] bg-[#D52500] px-12 py-2 font-normal text-white max-w-[136px] text-sm"
								>
									Cancel
								</Button> */}
								<Button
									disabled={loading}
									onClick={() => {
										processCancel(data);
									}}
									additionalClasses="rounded-[4px] border border-[#BABFC3] bg-[#D52500] px-12 py-2 text-[14px] text-[#202223] max-w-[200px] text-white"
								>
									{loading ? (
										<Loader2 color="#002060" />
									) : (
										"Cancel Credit"
									)}
								</Button>
							</div>
						</div>
					)}
				</motion.section>
			)}
		</Overlay>
	);
};

export default ConfirmCancelCreditModal;
