import { GrClose } from "react-icons/gr";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Icon as Iconify } from "@iconify/react";
import { Overlay } from "..";
import { PasswordChangeSuccessfulProps } from "types/components/modals/";
import { slideDownAnimation } from "../../services/Animations";

export const ProcessCompleted: React.FC<PasswordChangeSuccessfulProps> = ({
	show,
	setShow,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex w-full max-w-[532px] flex-col items-center rounded-[10px] bg-white px-9 py-12"
				>
					<div className="cursor-pointer self-end rounded-full bg-opacity-50 p-2 duration-200 ease-in-out hover:bg-slate-100 active:scale-[0.85]">
						<GrClose
							className="h-[22px] w-[22px]"
							onClick={() => {
								setShow(!show);
							}}
						/>
					</div>
					<div className="mt-2 flex w-full flex-col items-center gap-5">
						<Iconify
							icon="solar:check-circle-broken"
							color="#01A912"
							className="h-28 w-full max-w-[112px]"
						/>
						<div className="flex flex-col gap-2.5">
							<h3 className="max-w-[402px] text-center text-2xl font-semibold text-[#002060]">
								Successful!
							</h3>
							<p className="max-w-[315px] text-center text-lg font-medium text-[#626d8878]">
								Your Onboarding has been completed successfully!
							</p>
						</div>
					</div>
				</motion.section>
			)}
		</Overlay>
	);
};
