import React from "react";

export const Loader2: React.FC<{ color?: string }> = ({ color }) => {
	return (
		<div className="lds-ring">
			<div
				style={{
					borderTopColor: color || "white",
				}}
			></div>
			<div
				style={{
					borderTopColor: color || "white",
				}}
			></div>
			<div
				style={{
					borderTopColor: color || "white",
				}}
			></div>
			<div
				style={{
					borderTopColor: color || "white",
				}}
			></div>
		</div>
	);
};
