import { ProcessUploadFileProps } from "types/components/cards";
import { supportedExtensions } from "../../services/Constants";
import React, { useEffect, useRef, useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { Toast } from "../../utils/toast";
import { updateNestedState } from "../../utils/utilities";

export const ProcessUploadFile: React.FC<ProcessUploadFileProps> = ({
	formats,
	localStorageFileName,
	maxSize,
	setFile,
}) => {
	const filePickerRef = useRef<HTMLInputElement>(null);

	const [isHovered, setIsHovered] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);

		const files = e.dataTransfer.files;
		if (!files) return;
		if (files && files.length > 0) {
			handleDroppedFiles(files);
		}
	};

	// Generalized setFile function
	const setFileToState = (
		setState: any,
		localStorageFileName: string,
		fileData: any,
		fileName: string
	) => {
		setState((prevState: any) => {
			const updatedStateWithFileData = updateNestedState(
				prevState,
				localStorageFileName,
				fileData
			);
			const updatedStateWithFileName = updateNestedState(
				updatedStateWithFileData,
				`${localStorageFileName}Name`,
				fileName
			);
			return updatedStateWithFileName;
		});
	};

	const checkFileSizeAndExtension: (file: File) => void = (file: File) => {
		if (file.size > maxSize) {
			setFile((prevFile: any) => ({
				...prevFile,
				[localStorageFileName]: null,
				[localStorageFileName + "Name"]: "",
			}));
			Toast.info(
				`This file size is greater than ${(
					maxSize /
					1024 /
					1024
				).toFixed(0)}MB`
			);
			return;
		}
		const fileName = file.name;
		const fileExtension = fileName.split(".").pop()?.toLowerCase();

		if (!supportedExtensions.includes(fileExtension || "")) {
			setFile((prevFile: any) => ({
				...prevFile,
				[localStorageFileName]: null,
				[localStorageFileName + "Name"]: "",
			}));
			Toast.info("." + fileExtension + " format is not supported");
			return;
		}
		const reader = new FileReader();
		reader.readAsDataURL(file); // Read the file as a data URL
		reader.onload = () => {
			const fileData: any = reader.result; // Get the file data as a string
			setFile((prevFile: any) => ({
				...prevFile,
				[localStorageFileName]: fileData,
				[localStorageFileName + "Name"]: file.name,
			}));
			setFileToState(setFile, localStorageFileName, fileData, file.name);
		};
		return;
	};

	const addDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;
		checkFileSizeAndExtension(file);

		// Clear the file input field's value to allow selecting the same file again
		if (filePickerRef.current) {
			filePickerRef.current.value = "";
		}
	};

	const handleDroppedFiles = (files: FileList) => {
		const droppedFile = Array.from(files).filter((file) => {
			const fileExtension = file.name.split(".").pop()?.toLowerCase();
			return supportedExtensions.includes(fileExtension || "");
		});

		if (droppedFile.length > 0) {
			checkFileSizeAndExtension(droppedFile[0]);
		} else {
			Toast.info("Invalid file format");
		}
	};

	// useEffect(() => {
	// 	const fileData = localStorage.getItem(localStorageFileName);
	// 	if (fileData !== null) {
	// 		// Parse the data URL to extract fileType and base64Data
	// 		const matches = fileData.match(/^data:(.*?);base64,(.*)$/);
	// 		if (matches && matches.length === 3) {
	// 			const fileType = matches[1];
	// 			const base64Data = matches[2];
	// 			const byteCharacters = atob(base64Data);

	// 			const fileName =
	// 				localStorage.getItem(localStorageFileName + "Name") ?? "";

	// 			// Convert base64 data to Blob
	// 			const byteArrays = [];
	// 			for (
	// 				let offset = 0;
	// 				offset < byteCharacters.length;
	// 				offset += 1024
	// 			) {
	// 				const slice = byteCharacters.slice(offset, offset + 1024);
	// 				const byteNumbers = new Array(slice.length);
	// 				for (let i = 0; i < slice.length; i++) {
	// 					byteNumbers[i] = slice.charCodeAt(i);
	// 				}
	// 				const byteArray = new Uint8Array(byteNumbers);
	// 				byteArrays.push(byteArray);
	// 			}
	// 			const blob = new Blob(byteArrays, { type: fileType });

	// 			const file = new File([blob], fileName, { type: fileType });
	// 			setFile(file);
	// 		}
	// 	}
	// }, []);

	return (
		<div
			className={`bg-clearblue mt-2 flex h-fit w-full cursor-pointer select-none flex-col items-center justify-center rounded-xl border border-dashed border-[#384EB74D] bg-[#F1F5F9] py-5 duration-200 ease-in-out ${
				isHovered ? "bg-blue-100" : ""
			} ${isDragging ? "bg-blue-200" : ""}`}
			onClick={() => filePickerRef.current?.click()}
			onDragOver={handleDragOver}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<SlCloudUpload className="min-h-[56px] min-w-[64px] text-[#002060]" />
			<input
				type="file"
				ref={filePickerRef}
				name="inputfile"
				hidden
				onChange={(e) => addDocument(e)}
				id="inputfile"
			/>
			<div className="mt-2 flex flex-col gap-1.5 text-center">
				<p className="text-[14px] font-[500] text-[#94A3B8] underline">
					Drag & drop files or{" "}
					<span className="text-[#002EA9]">Browse</span>
				</p>
				<p className="text-xs text-[#94A3B8]">
					Supported formats:{""}
					{formats.join(", ").toUpperCase()}
				</p>
			</div>
		</div>
	);
};

export default ProcessUploadFile;
