import React, { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, FormInput, UploadedFile } from "../../components";
import { ProcessUploadFile } from "../../components";
import {
	ProcessTransitionAnimation,
	uploadedFileContainerVariants,
} from "../../services/Animations";
import { Toast } from "../../utils/toast";
import { OnboardingFormContext } from ".";
import SaveContinueProcess from "components/Onboarding/SaveContinueProcess";

export const AddressUpload = () => {
	const { onboardingFormData, setOnboardingFormData, setPage } = useContext(
		OnboardingFormContext
	);
	return (
		<AnimatePresence>
			<motion.section
				variants={ProcessTransitionAnimation}
				initial="hidden"
				animate="visible"
				exit="exit"
				className="flex w-full max-w-[510px] flex-col items-center gap-6 rounded-lg bg-white px-8 py-8 shadow"
			>
				<div className="flex w-full flex-col gap-3">
					<div className="flex flex-col text-left">
						<h2 className="text-xl font-semibold text-[#002060]">
							Business Address
						</h2>
						<p className="text-[14px] font-medium text-[#626D88]">
							Type in your Correct Business Address
						</p>
					</div>
					<FormInput
						label=""
						name="business address"
						id="business address"
						type="text"
						placeholder="Type in your accurate business address"
						minLength={5}
						maxLength={60}
						required={false}
						autoComplete="on"
						labelStyles="text-center"
						additionalClasses="inputChanger focus-none h-full w-full bg-transparent px-5 py-3.5 border-[1.5px] border-[#002060] rounded-lg text-sm outline-none placeholder:text-sm placeholder:leading-5 leading-5 text-sm"
						onChange={(e) => {
							setOnboardingFormData({
								...onboardingFormData,
								businessAddress: e.target.value,
							});
							// localStorage.setItem(
							// 	"BusinessAddress",
							// 	e.target.value
							// );
						}}
						value={onboardingFormData.businessAddress ?? ""}
					/>
				</div>
				<div className="flex w-full flex-col gap-3">
					<div className="flex flex-col text-left">
						<h2 className="text-xl font-semibold text-[#002060]">
							Proof of Address
						</h2>
						<p className="text-[14px] leading-5 text-[#626D88]">
							Scan or Upload PDF, JPEG or PNG Copy of your Proof
							of Address which could be either Bank Statement,
							utility Bill or Tenancy or Proof of Ownership{" "}
							<b>Max Size of: 2MB</b>
						</p>
					</div>
					<ProcessUploadFile
						formats={[
							"jpeg",
							"jpg",
							"png",
							"pdf",
							"ai",
							"doc",
							"docx",
							"ppt",
							"pptx",
						]}
						maxSize={2 * 1024 * 1024}
						setFile={setOnboardingFormData}
						localStorageFileName={"businessAddressFile"}
					/>
				</div>
				<AnimatePresence>
					{onboardingFormData.businessAddressFile && (
						<motion.div
							className="flex w-full flex-col gap-4"
							variants={uploadedFileContainerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
						>
							<h4 className="w-full text-[#676767]">
								Uploaded file(s)
							</h4>
							<div className="flex flex-col gap-3">
								<UploadedFile
									title={
										onboardingFormData.businessAddressFileName
									}
									deleteFile={() => {
										setOnboardingFormData((prevData) => ({
											...prevData,
											businessAddressFile: null,
										}));
									}}
								/>
							</div>
						</motion.div>
					)}
				</AnimatePresence>
				<div className="mx-auto flex w-full flex-col items-center gap-6">
					<Button
						disabled={false}
						onClick={() => {
							if (
								onboardingFormData.businessAddressFile &&
								onboardingFormData.businessAddress &&
								onboardingFormData.businessAddress.length > 10
							) {
								return setPage(3);
							} else if (
								!onboardingFormData.businessAddress ||
								String(onboardingFormData.businessAddress)
									.length < 10
							) {
								return Toast.info(
									"Kindly fill in a valid address"
								);
							} else if (
								!onboardingFormData.businessAddressFile
							) {
								return Toast.info(
									"Kindly upload a document and fill in the field"
								);
							}
						}}
						additionalClasses="max-w-[445px] text-white  bg-primary-700 "
						hoverEffect
					>
						Next
					</Button>
					<SaveContinueProcess />
				</div>
			</motion.section>
		</AnimatePresence>
	);
};

export default AddressUpload;
