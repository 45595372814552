import { useEffect } from "react";
import { useLocation } from "react-router";
import { request } from "../../utils/axios-utils";
import { API_URI } from "../../services/Constants";
import Logo from "../../assets/image/blackLogo.svg";
import { toast } from "react-toastify";
import { Toast } from "../../utils/toast";

const QuickbookCallback = () => {
	const location = useLocation();
	useEffect(() => {
		const controller = new AbortController();
		const queryParams = new URLSearchParams(location.search);
		const errorParam = queryParams.get("error");
		const searchParams = location.search.substring(1);
		if (errorParam) {
			toast.error("An error occured. Try again later😢");
			localStorage.setItem("crmError", "true");
			setTimeout(() => {
				window.close();
			}, 4000);
		} else if (searchParams) {
			sendCallback(searchParams, controller);
		}
	}, [location.search]);
	const sendCallback = async (
		searchParams: string,
		controller: AbortController
	) => {
		try {
			const response = await request({
				url: `${API_URI}/accounting-crm/quickbooks/callback?${searchParams}`,
				method: "GET",
				signal: controller.signal,
			});
			await response.data;
			if (response.status === 200) {
				toast.success("Quickbooks linked successfully");
				localStorage.setItem("crmSuccess", "true");
				setTimeout(() => {
					window.close();
				}, 4000);
			}
		} catch (err) {
			Toast.error("An Error Occured");
			setTimeout(() => {
				window.close();
			}, 4000);
		}
	};
	return (
		<>
			<div className="flex w-full flex-col px-8 py-4">
				<div className="flex items-center justify-between pb-4 ">
					<figure className="h-8 w-28 cursor-pointer">
						<img src={Logo} alt="" />
					</figure>
				</div>
			</div>
		</>
	);
};

export default QuickbookCallback;
