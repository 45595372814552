import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Icon as Iconify } from "@iconify/react";
import { Button, Overlay } from "..";
import { ContinueOnboardingProps } from "types/components/modals";
import { useNavigate } from "react-router";
import { slideDownAnimation } from "../../services/Animations";
import { ImCross } from "react-icons/im";

export const ContinueOnboarding: React.FC<ContinueOnboardingProps> = ({
	show,
	setShow,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	return (
		<Overlay
			isPersistent={true}
			show={show}
			setShow={setShow}
			modalRef={modalRef}
		>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="relative m-10 flex w-full max-w-[623px] flex-col items-center rounded-[10px] bg-white px-14 py-12"
				>
					<div className="absolute left-8 top-8 flex justify-start">
						<ImCross
							className="h-8 w-8 cursor-pointer text-primary-700"
							onClick={() => setShow(false)}
						/>
					</div>
					<div className=" flex w-full flex-1 flex-col items-center gap-3 py-8">
						{/* <Iconify
							icon="material-symbols:cloud-done"
							color="#002060"
							className="h-[119px] w-[119px]"
						/> */}
						<svg
							width="149"
							height="149"
							viewBox="0 0 149 149"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27.917 41.7126C27.917 37.9275 27.917 36.0349 28.6653 34.5943C29.2959 33.3804 30.2857 32.3906 31.4996 31.76C32.9402 31.0117 34.8327 31.0117 38.6178 31.0117H66.5489C68.1591 31.0117 68.9642 31.0117 69.7235 31.1925C70.369 31.3463 70.9878 31.5958 71.5594 31.9328C72.2317 32.3292 72.8117 32.8877 73.9715 34.0045L74.6578 34.6654C75.8176 35.7823 76.3975 36.3407 77.0699 36.7371C77.6415 37.0741 78.2603 37.3237 78.9058 37.4774C79.6651 37.6582 80.4702 37.6582 82.0803 37.6582H110.267C114.052 37.6582 115.945 37.6582 117.385 38.4065C118.599 39.0371 119.589 40.0269 120.22 41.2408C120.968 42.6814 120.968 44.5739 120.968 48.3591V112.066C120.968 113.958 120.968 114.905 120.594 115.625C120.278 116.232 119.784 116.727 119.177 117.042C118.456 117.416 117.51 117.416 115.617 117.416H37.5477C34.1412 117.416 32.4379 117.416 31.1414 116.743C30.0488 116.175 29.158 115.284 28.5905 114.192C27.917 112.895 27.917 111.192 27.917 107.785V41.7126Z"
								fill="url(#paint0_linear_1610_18070)"
							/>
							<g filter="url(#filter0_b_1610_18070)">
								<path
									d="M52.5552 60.9865C53.4951 57.7535 53.965 56.1369 55.2062 55.2052C56.4474 54.2734 58.1303 54.2734 61.4962 54.2734H122.794C128.094 54.2734 130.744 54.2734 132.141 55.9945C133.537 57.7156 132.991 60.3089 131.899 65.4955L122.524 110.027C121.779 113.566 121.407 115.336 120.125 116.375C118.844 117.415 117.036 117.415 113.419 117.415H34.5645C38.0023 113.676 38.0023 109.938 41.4401 98.7219C43.9066 90.675 49.5583 71.2955 52.5552 60.9865Z"
									fill="#B9DDFF"
									fillOpacity="0.6"
								/>
								<path
									d="M52.853 61.0731C53.325 59.4495 53.672 58.2576 54.0534 57.3534C54.4317 56.4567 54.8317 55.874 55.3924 55.4532C55.953 55.0323 56.624 54.8109 57.5904 54.6979C58.5649 54.584 59.8059 54.5836 61.4962 54.5836H122.794C125.454 54.5836 127.415 54.5843 128.887 54.7955C130.351 55.0056 131.272 55.4163 131.9 56.1899C132.528 56.9635 132.74 57.9493 132.644 59.4253C132.547 60.9097 132.144 62.8289 131.596 65.4316L122.221 109.963C121.847 111.74 121.571 113.046 121.232 114.038C120.896 115.023 120.508 115.665 119.93 116.135C119.351 116.604 118.643 116.851 117.61 116.977C116.569 117.104 115.235 117.105 113.419 117.105H35.2492C36.7137 115.354 37.5106 113.535 38.2988 110.963C38.6565 109.796 39.0142 108.468 39.4313 106.919C39.5176 106.599 39.6064 106.269 39.6983 105.93C40.2351 103.945 40.8786 101.612 41.7366 98.8127C44.2039 90.7632 49.8564 71.3811 52.853 61.0731Z"
									stroke="url(#paint1_linear_1610_18070)"
									strokeWidth="0.620229"
								/>
							</g>
							<path
								d="M41.459 40.9814C41.459 39.2831 42.8358 37.9063 44.5342 37.9063H57.8272C59.5255 37.9063 60.9023 39.2831 60.9023 40.9814C60.9023 42.6798 59.5255 44.0566 57.8272 44.0566H44.5342C42.8358 44.0566 41.459 42.6798 41.459 40.9814Z"
								fill="url(#paint2_linear_1610_18070)"
								fillOpacity="0.9"
								stroke="url(#paint3_linear_1610_18070)"
								strokeWidth="0.496183"
							/>
							<defs>
								<filter
									id="filter0_b_1610_18070"
									x="21.2715"
									y="40.9805"
									width="124.997"
									height="89.7276"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feGaussianBlur
										in="BackgroundImageFix"
										stdDeviation="6.64649"
									/>
									<feComposite
										in2="SourceAlpha"
										operator="in"
										result="effect1_backgroundBlur_1610_18070"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect1_backgroundBlur_1610_18070"
										result="shape"
									/>
								</filter>
								<linearGradient
									id="paint0_linear_1610_18070"
									x1="54.7586"
									y1="49.9127"
									x2="124.969"
									y2="75.9149"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#79ADF0" />
									<stop offset="1" stopColor="#2A60B7" />
								</linearGradient>
								<linearGradient
									id="paint1_linear_1610_18070"
									x1="29.5796"
									y1="58.7836"
									x2="64.8211"
									y2="143.768"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="white" />
									<stop
										offset="0.765625"
										stopColor="white"
										stopOpacity="0"
									/>
								</linearGradient>
								<linearGradient
									id="paint2_linear_1610_18070"
									x1="39.6955"
									y1="37.2141"
									x2="66.9856"
									y2="45.2771"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="white" />
									<stop
										offset="1"
										stopColor="white"
										stopOpacity="0"
									/>
								</linearGradient>
								<linearGradient
									id="paint3_linear_1610_18070"
									x1="37.8348"
									y1="39.0748"
									x2="51.1807"
									y2="44.3047"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="white" />
									<stop
										offset="1"
										stopColor="white"
										stopOpacity="0"
									/>
								</linearGradient>
							</defs>
						</svg>

						<div className="flex flex-col items-center">
							<h2 className="text-center text-[28px] font-bold leading-[28px] text-primary-900">
								Continue to onboarding?
							</h2>
							<p className="mb-4 w-[70%] max-w-[402px] text-center text-[16px] font-normal text-[#002060]">
								Complete your onboarding and start giving your
								customers{" "}
								<span className="font-semibold text-primary-700">
									credit
								</span>
							</p>
						</div>

						<div className="flex w-full gap-x-5 msm:flex-col msm:gap-y-4">
							<Button
								onClick={() => {
									navigate("/onboarding");
								}}
								disabled={false}
								additionalClasses="text-sm flex items-center gap-x-6 text-white py-2 font-semibold bg-primary-700 h-11"
							>
								Continue to onboarding
								<span>+</span>
							</Button>
							<Button
								onClick={() => setShow(false)}
								disabled={false}
								additionalClasses="text-sm flex items-center gap-x-6 text-error-600 py-2 font-semibold bg-white border border-error-600 h-11"
							>
								Skip to dashboard
								<span>x</span>
							</Button>
						</div>
					</div>
				</motion.section>
			)}
		</Overlay>
	);
};
