import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import {
	getDistributorDashboardMetrics,
	getDistributorDashboardChartData,
} from "../api/dashboard";

export const useGetDistributorMetrics = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("distributor_metrics", getDistributorDashboardMetrics, {
		retry: 2,
		refetchInterval: 300000,
		onSuccess,
		onError,
	});
};

export const useGetDistributorDashboardChartData = (
	data: string | undefined,
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery(data, getDistributorDashboardChartData, {
		enabled: !!data,
		staleTime: 0,
		retry: 2,
		// refetchInterval: 300000,
		onSuccess,
		onError,
	});
};

// export const useGetDashboardData = (
// 	onSuccess: (data: AxiosResponse) => void = () => {
// 		return;
// 	},
// 	onError: (data: AxiosError) => void
// ) => {
// 	return useQuery("dashboardData", getDashboardData, {
// 		onSuccess,
// 		onError,
// 		retry: 4,
// 		refetchInterval: 300000,
// 	});
// };
