/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { Overlay } from "./Overlay";
import { UpdateTransactionModalProps } from "types/components/modals";
import { MdClose } from "react-icons/md";
import { slideDownAnimation } from "../../services/Animations";
import { Button } from "../Button";
import { FormInput, Loader2 } from "..";
import { Toast } from "../../utils/toast";
import { toast } from "react-toastify";

export const UpdateTransactionModal: React.FC<UpdateTransactionModalProps> = ({
	show,
	setShow,
	loanData,
	handleLoanUpdateAndApproval,
	isLoading,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className="m-10 flex h-fit w-[80%] max-w-[675px] flex-1 flex-col items-center rounded-[10px] bg-white px-4 py-4 md:w-fit md:px-8"
				>
					<UpdateTransaction
						show={show}
						setShow={setShow}
						loanData={loanData}
						handleLoanUpdateAndApproval={
							handleLoanUpdateAndApproval
						}
						isLoading={isLoading}
					/>
				</motion.section>
			)}
		</Overlay>
	);
};

const UpdateTransaction = ({
	show,
	setShow,
	loanData,
	handleLoanUpdateAndApproval,
	isLoading,
}: UpdateTransactionModalProps) => {
	const [formData, setFormData] = useState({
		loan_amount:
			loanData.credit_offer.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0,
		amount: loanData.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0,
		date_issued: loanData.created ?? "",
		repayment_date: loanData.due_date ?? "",
	});

	const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const tempValue = value.replace(/[^\d.]/g, "");
		const [whole, decimal] = tempValue.split(".");
		const wholeFormatted = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		const limitedDecimal = decimal ? `.${decimal.slice(0, 2)}` : "";
		const formattedValue = `${wholeFormatted}${limitedDecimal}`;

		if (formattedValue.startsWith("0")) {
			return;
		}
		const amount = parseFloat(
			formData.amount
				.replace(/,/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, "")
		);
		const offerAmount = parseFloat(formattedValue.replace(/,/g, ""));

		if (offerAmount > amount) {
			return toast.info(
				"Offer amount cannot be higher than credit amount"
			);
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: formattedValue,
		}));
	};

	const handleUpdateAndApproveLoan: () => void = () => {
		if (
			parseFloat(formData.loan_amount.replace(/,/g, "")) >
			parseFloat(loanData.credit_offer.replace(/,/g, ""))
		) {
			Toast.error(
				"Updated loan amount cannot be higher than current credit offer"
			);
			return;
		}
		handleLoanUpdateAndApproval(formData.loan_amount.replace(/,/g, ""));
	};
	return (
		<div className="flex w-full flex-1 flex-col items-center justify-center py-2 pb-6">
			<div className="flex w-full justify-between">
				<h2 className="text-2xl font-bold text-[#001746]">
					{loanData?.retailer_name}
				</h2>
				<button className="text-[1.5em]" onClick={() => setShow(!show)}>
					<MdClose />
				</button>
			</div>
			<div className="flex w-full justify-start">
				<div className="mt-4 flex items-center gap-2 rounded bg-[#F1C40F]  bg-opacity-20 px-2 py-1">
					<span className="h-2 w-2 rounded-full bg-[#F1C40F]"></span>
					<p className="text-[12px] font-semibold text-[#F1C40F]">
						Loan Due
					</p>
				</div>
			</div>

			<form action="" className="mt-4 flex w-full flex-col gap-6">
				<div className="relative w-full">
					<FormInput
						label="Amount"
						name="amount"
						id="amount"
						type="text"
						placeholder="50,0000"
						additionalClasses=" h-10 px-8 py-2 focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
						autoComplete="off"
						required={true}
						value={formData.amount}
						onChange={handleFromChange}
						disabled
					/>
					<span className="absolute bottom-2 left-4">₦</span>
				</div>
				<div className="relative w-full">
					<FormInput
						label="Loan Offer"
						name="loan_amount"
						id="loan_amount"
						type="text"
						placeholder="50,0000"
						additionalClasses="h-10 px-8 py-2 focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
						autoComplete="off"
						required={true}
						value={formData.loan_amount}
						onChange={handleFromChange}
					/>
					<span className="absolute bottom-2 left-4">₦</span>
					<span className="absolute bottom-2 right-4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
						>
							<path
								d="M3.75 15.0369H4.8105L11.796 8.05143L10.7355 6.99093L3.75 13.9764V15.0369ZM15.75 16.5369H2.25V13.3547L12.3263 3.27843C12.4669 3.13782 12.6576 3.05884 12.8565 3.05884C13.0554 3.05884 13.2461 3.13782 13.3868 3.27843L15.5085 5.40018C15.6491 5.54082 15.7281 5.73155 15.7281 5.93043C15.7281 6.1293 15.6491 6.32003 15.5085 6.46068L6.93225 15.0369H15.75V16.5369ZM11.796 5.93043L12.8565 6.99093L13.917 5.93043L12.8565 4.86993L11.796 5.93043Z"
								fill="#002060"
							/>
						</svg>
					</span>
				</div>

				{/* <FormInput
					label="Date Issued"
					name="date_issued"
					id="date_issued"
					type="date"
					placeholder="8th March 2023"
					additionalClasses="focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
					autoComplete="off"
					required={true}
					value={formData.date_issued}
					onChange={handleFromChange}
					disabled
				/>

				<FormInput
					label="Repayment Date"
					name="repayment_date"
					id="repayment_date"
					type="date"
					placeholder="8th March 2023"
					additionalClasses="focus-none rounded-md border border-[#263238] bg-transparent px-4 py-2 font-light  text-[#263238] placeholder-[#263238] outline-none placeholder:text-[15px]"
					autoComplete="off"
					required={true}
					value={formData.repayment_date}
					onChange={handleFromChange}
					disabled
				/> */}
				<div className="align-center flex w-full justify-center">
					<div className=" flex w-[70%] gap-8">
						<Button
							disabled={isLoading ? true : false}
							additionalClasses="w-fit text-white font-[600] cusor-pointer"
							onClick={handleUpdateAndApproveLoan}
						>
							{isLoading ? <Loader2 /> : "Approve"}{" "}
						</Button>
						<Button
							disabled={false}
							onClick={() => {
								setShow(false);
							}}
							additionalClasses="bg-transparent border rounded-md w-fit "
						>
							Cancel
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};
