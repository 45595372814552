import React from "react";
import { Loader2 } from "./Loaders/Loader2";

interface RequestIsLoadingProps {
	isFullpage?: boolean;
	isLoading: boolean;
}

const RequestIsLoading: React.FC<RequestIsLoadingProps> = ({
	isFullpage,
	isLoading,
}) => {
	return (
		<div
			className={`${
				isFullpage ? "fixed" : "absolute"
			} inset-0 flex items-center justify-center bg-white bg-opacity-50 ${
				!isLoading ? "hidden" : ""
			}`}
		>
			<Loader2 color="#061F6A" />
		</div>
	);
};

export default RequestIsLoading;
