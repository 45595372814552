import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/image/blackLogo.svg";
import AcceptCredit from "./AcceptCredit";
import Verify from "./Verify";
import Receipt from "./Receipt";
import { useQueryParams } from "../../../utils/utilities";

const CreditAccept: React.FC = () => {
	const navigate = useNavigate();
	const query = useQueryParams();

	const [stage, setStage] = useState<number>(1);
	const [code, setCode] = useState<string | null>(null);
	const [isOTPComplete, setIsOTPComplete] = useState<boolean>(false);
	const [creditData, setCreditData] = useState<boolean>(false);
	const [pinID, setPinID] = useState("");

	const goBack = () => {
		if (stage > 1) {
			setStage((prevStage) => prevStage - 1);
		} else {
			navigate(-1);
		}
	};
	useEffect(() => {
		const codeParam = query.get("auth");

		if (codeParam) {
			setCode(codeParam);
		}
	}, [query]);

	return (
		<main className="mx-auto flex flex-col items-center pb-8 pt-5">
			<div className="flex items-center justify-center p-3">
				<figure className="h-8 w-28">
					<img src={Logo} alt="hadi logo" />
				</figure>
			</div>
			<AcceptCredit
				code={code}
				isOTPComplete={isOTPComplete}
				stage={stage}
				creditData={creditData}
				setStage={setStage}
				goBack={goBack}
				setCreditData={setCreditData}
				setPinID={setPinID}
				pinID={pinID}
			/>
			<Verify
				code={code}
				isOTPComplete={isOTPComplete}
				stage={stage}
				creditData={creditData}
				pinID={pinID}
				goBack={goBack}
				setStage={setStage}
				setIsOTPComplete={setIsOTPComplete}
				setPinID={setPinID}
			/>
			<Receipt
				code={code}
				creditData={creditData}
				stage={stage}
				goBack={goBack}
				setCreditData={setCreditData}
				setStage={setStage}
				isOTPComplete={isOTPComplete}
				pinID={pinID}
				setPinID={setPinID}
			/>
		</main>
	);
};

export default CreditAccept;
