import React from "react";
import { Button, FormikInput } from "../../components";
import { Gender } from "../../services/Constants";

const Form1: React.FC = () => {
	return (
		<div className="grid grid-cols-2 items-start gap-x-4 gap-y-9 md:grid-cols-2">
			<FormikInput
				label="Phone Number"
				name="phoneNumber"
				id="phoneNumber"
				floatingLabel={true}
				type="text"
				minLength={11}
				maxLength={11}
				placeholder="Enter your phone number"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="col-span-2"
				required={true}
			/>
			<FormikInput
				label="First Name"
				name="firstName"
				id="firstName"
				floatingLabel={true}
				type="text"
				placeholder="Enter your first name"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				required={true}
			/>
			<FormikInput
				label="Last Name"
				name="lastName"
				id="lastName"
				floatingLabel={true}
				type="text"
				placeholder="Enter your last name"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				required={true}
			/>
			<FormikInput
				options={Gender}
				label="Gender"
				name="gender"
				id="gender"
				floatingLabel={true}
				selectValueKey="value"
				type="select"
				placeholder="Gender"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				required={true}
			/>
			<FormikInput
				label="Email"
				name="email"
				id="email"
				floatingLabel={true}
				type="email"
				placeholder="janedoe@gmail.com"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				required={true}
			/>

			<FormikInput
				label="Personal Address"
				name="address"
				id="address"
				floatingLabel={true}
				type="text"
				placeholder="Enter your home address"
				autoComplete={"false"}
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="col-span-2"
				required={true}
			/>
			<FormikInput
				label={
					<>
						I accept the{" "}
						<a
							href="https://www.hadifinance.com/terms-and-conditions"
							rel="noreferrer"
							target="_blank"
							className="text-primary-700"
						>
							Terms & Conditions
						</a>
					</>
				}
				name="terms"
				id="terms"
				type="checkbox"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="-mt-6 col-span-2"
				required={true}
			/>
			<Button
				disabled={false}
				type="submit"
				additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px] col-span-2"
			>
				Save and Continue
			</Button>
		</div>
	);
};

export default Form1;
