import { useState, Fragment } from "react";
import { Button, FormInput, Loader2, FormikInput } from "../components";
import Logo from "../assets/image/blackLogo.svg";
import blackLogo from "../assets/image/blackLogo.svg";
import { Icon as Iconify } from "@iconify/react";
import { Toast } from "../utils/toast";
import { logDev } from "../utils/utilities";
import { usePasswordReset } from "../hooks/useAuthData";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordResetData } from "@/types/hooks";

const ForgotPassword = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formData] = useState<{
		email: string;
	}>({
		email: "",
	});

	const onError = (error: any) => {
		Toast.error(error?.response?.data?.message ?? "Something went wrong");
		setIsLoading(false);
	};

	const onSuccess = (): void => {
		Toast.success("Kindly check your email for a reset link");
		setIsLoading(false);
		navigate("/login");
	};

	const { mutate: passwordReset } = usePasswordReset(onSuccess, onError);

	const handleForgotPassword = (payLoad: PasswordResetData) => {
		passwordReset(payLoad);
		setIsLoading(true);
	};

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/register"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign up
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3">
					<div className="min-h-[450px] w-full max-w-[500px]">
						<h3 className="w-full text-left text-[32px] font-[600] leading-[1] text-primary-900 lg:leading-[56px]">
							Forgot Password?
						</h3>
						<p className="test-[14px] font-[400] text-secondary-400">
							Don&apos;t worry this usually happens.
						</p>
						<p className="test-[14px] mb-[63px] font-[400] text-secondary-400">
							Please enter the email address linked to your
							account
						</p>
						<Formik
							initialValues={formData}
							validationSchema={Yup.object({
								email: Yup.string()
									.required("Email is required")
									.email("Invalid email address"),
							})}
							onSubmit={handleForgotPassword}
						>
							{({ values }) => (
								<Form className="flex w-full flex-col">
									<FormikInput
										label="Email Address"
										floatingLabel={true}
										name="email"
										id="email"
										type="email"
										placeholder="Enter your email address"
										additionalClasses="border-[#263238] placeholder-[#263238]"
										disabled={isLoading}
										required={true}
										autoComplete="off"
									/>
									<Button
										disabled={isLoading}
										additionalClasses={
											"text-lg text-white font-medium bg-primary-800 mt-[37px] lg:mt-[63px]"
										}
										type="submit"
									>
										{isLoading ? (
											<Loader2 />
										) : (
											"Send reset link"
										)}
									</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ForgotPassword;
