import { request } from "../utils/axios-utils";
export const getAllRepayments = () => {
	return request({
		method: "GET",
		url: `/credit/repayments/`,
	});
};

export const getRepayment = ({ queryKey }: any) => {
	const id: string = queryKey[1];
	return request({
		method: "GET",
		url: `/credit/repayments/${id}/`,
	});
};

export const payInstallment = (data: { amount: string }, id: string) => {
	return request({
		method: "POST",
		url: `/credit/repayments/${id}/`,
		data,
	});
};
