import { useSidebar } from "../../provider/SidebarViewProvider";
import { useAuth } from "../../hooks/auth";
import { BsBellFill, BsChevronDown } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { Sidebar } from "../Sidebar";
import { motion } from "framer-motion";
import { useNotificationWebSocketContext } from "../../provider/NotificationWebSocketProvider";
import { Link } from "react-router-dom";

type PageProps = {
	pageName: string;
	pageLead?: string;
};

export const TopBar = ({ pageName, pageLead }: PageProps) => {
	const { user } = useAuth();
	const { showSidebar, setShowSidebar } = useSidebar();
	const nameInitials = user?.first_name.split("")[0];
	const notificationContext = useNotificationWebSocketContext();

	return (
		<div className="flex items-center justify-between px-4 py-4 md:px-8">
			<div className="flex flex-col">
				<h1 className="text-xl font-semibold text-[#101828] md:text-base">
					{pageName}
				</h1>
				{pageLead && (
					<p className="text-sm font-normal text-[#667085]">
						{pageLead}
					</p>
				)}
			</div>
			{/* <div className="flex items-center gap-4 text-[#002060]">
					<Link
						className="relative cursor-pointer"
						to={"/dashboard/profile/notifications"}
					>
						<BsBellFill />
						{notificationContext &&
							+notificationContext.unreadNotificationCount >
								0 && (
								<span className="absolute -right-1 -top-1 flex h-3 w-fit min-w-[12px] items-center justify-center rounded-full bg-red-700 px-0.5 text-[10px] text-white">
									{notificationContext &&
										notificationContext.unreadNotificationCount}
								</span>
							)}
					</Link>
					<Link
						to="/dashboard/profile"
						className="flex items-center gap-1"
					>
						<span className="flex h-8 w-8 items-center justify-center rounded-full bg-[#002060] text-white">
							{nameInitials}
						</span>
					</Link>
					<motion.span
						className="block cursor-pointer rounded-full p-2 text-[#002060] lg:hidden"
						onClick={() => setShowSidebar(!showSidebar)}
						initial={{
							rotate: showSidebar ? 0 : 90,
							backgroundColor: "#fff",
						}}
						animate={{ rotate: showSidebar ? 90 : 0 }}
						whileHover={{
							backgroundColor: "#cbd5e1",
							transition: {
								ease: "easeInOut",
								duration: 0.2,
							},
						}}
						transition={{
							duration: 0.2,
							type: "tween",
							ease: "easeInOut",
						}}
					>
						<AiOutlineMenu />
					</motion.span>
				</div> */}
		</div>
	);
};
