import { useEffect, useState } from "react";
import Logo from "../../../assets/image/blackLogo.svg";
import AuthorizationForm from "components/CreditAuth/Retailer/RetailerForms/AuthorizationForm";
import { RetailerCreditAuthorizationFormProps } from "types/views";
import RetailerDetailsForm1 from "components/CreditAuth/Retailer/RetailerForms/RetailerDetailsForm1";
import RetailerDetailsForm2 from "components/CreditAuth/Retailer/RetailerForms/RetailerDetailsForm2";
import RetailerDetailsForm3 from "components/CreditAuth/Retailer/RetailerForms/RetailerDetailsForm3";
import GuarantorForm from "components/CreditAuth/Retailer/RetailerForms/GuarantorForm";
import LinkMonoForm from "components/CreditAuth/Retailer/RetailerForms/LinkMonoForm";
import {
	useRetailerAuthorizeCredit,
	useRetailerForbidCredit,
	useRetailerVerifyCredit,
} from "../../../hooks/useCreditData";
import { Patterns } from "../../../services/Constants";
import { isValidName } from "utils/validation";
import { RequestSuccessFailProps } from "types/components";
import { AxiosResponse } from "axios";
import { Loader2, RequestSuccessFail } from "components";
import { Toast } from "utils/toast";
import { useNavigate } from "react-router";
import { base64ToBlob } from "../../../utils/utilities";

const RetailerCreditAuthorization = () => {
	const navigate = useNavigate();
	const initialData = {
		name: "",
		first_name: "",
		last_name: "",
		email: "",
		// phone: "",
		dob: "",
		house_address: "",
		gender: "",
		marital_status: "",
		residential_state: "",
		residential_address: "",
		business_name: "",
		store_address: "",
		lga: "",
		bvn: "",
		BVN: "",
		shop_address: "",
		nearest_landmark: "",
		nationality: "",
		mother_maidens_name: "",
		occupation: "",
		tenure: "",
		id_document: null,
		id_documentName: "",
		id_no: "",
		id_image: null,
		id_imageName: "",
		id_number: "",
		id_type: "",
		address_document: null,
		address_documentName: "",
		phone_number: "",
		guarantor: {
			first_name: "",
			last_name: "",
			phone: "",
			address: "",
			relationship: "",
			other_relationship: "",
			occupation_type: "",
			signature: null,
			signatureName: "",
		},
		invoice: {
			invoice: null,
			invoiceName: "",
			total_amount: "",
		},
		bank_name: "",
		account_number: "",
		credit_amount: "",
		purchase_amount: "",
		signature: null,
		signatureName: "",
	};
	const [showProcess, setShowProcess] = useState(false);
	const [code, setCode] = useState(null);
	const [isAdminRequest, setIsAdminRequest] = useState(false);
	const [creditData, setCreditData] = useState(null);
	const [previousGuarantor, setPreviousGuarantor] = useState(false);
	// const [previousRetailer, setPreviousRetailer] = useState(false);
	// const query = useQueryParams();
	const [formData, setFormData] =
		useState<RetailerCreditAuthorizationFormProps>(initialData);
	const [requestFormData, setRequestFormData] =
		useState<RequestSuccessFailProps>({
			state: false,
			title: "",
			subtitle: "",
		});

	const [page, setPage] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);

	const queryParams = new URLSearchParams(location.search);
	const codeParam = queryParams.get("auth");

	/** Function to set display on credit submission success */
	const retailerAuthorizationOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Successful!",
			subtitle: "Congratulations, your credit is being processed!",
		});
		setShowProcess(true);
	};
	/** Function to set display on credit submission error */
	const retailerAuthorizationOnError = (error: any) => {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			(error.response.data.error as any[]).forEach(
				(errorObj: any, index: number) => {
					setTimeout(() => {
						Object.values(errorObj).forEach((messages: any) => {
							messages.forEach((message: string) => {
								Toast.error(message);
							});
						});
					}, index * 4000);
				}
			);
		} else if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			Toast.error(error.response.data.message);
		} else {
			setRequestFormData({
				state: false,
				title: "This link has expired",
				subtitle: "The credit could not be authorized.",
			});
		}
	};

	const retailerForbidCreditOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Credit Rejected",
			subtitle: "Credit has been successfully rejected.",
		});
		setShowProcess(true);
	};
	/** Function to set display on credit submission error */
	const retailerForbidCreditOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The credit could not be rejected.",
		});
		setShowProcess(true);
	};

	/** Function to set display on success */
	const verifyCreditOnSuccess = (data: AxiosResponse) => {
		const queryParams = new URLSearchParams(location.search);
		const adminParam = queryParams.get("admin");

		if (adminParam) {
			setIsAdminRequest(Boolean(adminParam));
		}

		setShowProcess(false);
		setCreditData(data?.data.data);
		// set guarantor data and retailer data

		const verifyData = data?.data?.data;

		if (verifyData) {
			setFormData({
				...formData,
				first_name:
					verifyData?.retailer?.full_name?.split(" ")[0] ?? "",
				last_name: verifyData?.retailer?.full_name?.split(" ")[1] ?? "",
				name: verifyData?.retailer?.name ?? "",
				email: verifyData?.retailer?.email ?? "",
				dob: verifyData?.date_of_birth
					? new Date(verifyData?.date_of_birth)
							.toISOString()
							.split("T")[0]
					: "",
				house_address: verifyData?.retailer?.residential_address ?? "",
				gender: verifyData?.retailer?.gender ?? "",
				marital_status: verifyData?.retailer?.marital_status ?? "",
				residential_state:
					verifyData?.retailer?.residential_state ?? "",
				lga: verifyData?.retailer?.LGA ?? "",
				bvn: verifyData?.retailer?.BVN ?? "",
				shop_address: verifyData?.retailer?.shop_address ?? "",
				nearest_landmark: verifyData?.retailer?.nearest_landmark ?? "",
				nationality: verifyData?.retailer?.nationality ?? "",
				mother_maidens_name:
					verifyData?.retailer?.mother_maiden_name ?? "",
				occupation: verifyData?.retailer?.occupation ?? "",
				tenure: verifyData?.tenure ?? "",
				id_document: "",
				id_no: "",
				bank_name: "",
				account_number: "",
				credit_amount: "",
				purchase_amount: "",
				phone_number: verifyData?.retailer?.phone_number ?? "",
			});
			if (verifyData?.guarantor) {
				setFormData((prevState: any) => ({
					...prevState,
					guarantor: {
						...prevState.guarantor,
						first_name:
							verifyData?.guarantor?.name.split(" ")[0] ?? "",
						last_name:
							verifyData?.guarantor?.name.split(" ")[1] ?? "",
						phone: verifyData?.guarantor?.contact_phone ?? "",
						address: verifyData?.guarantor?.address ?? "",
						relationship: verifyData?.guarantor?.relationship ?? "",
						occupation_type:
							verifyData?.guarantor?.occupation_type ?? "",
					},
				}));
			}
		}
		if (verifyData?.guarantor) {
			setPreviousGuarantor(true);
		} else {
			setPreviousGuarantor(false);
		}
		return;
	};
	/** Function to set display on error */
	const verifyCreditOnError = () => {
		setShowProcess(true);
		setRequestFormData({
			state: false,
			title: "This link could not be verified",
			subtitle: "The credit doesn't exist",
		});
	};

	const validateGuarantorForm = (
		formData: RetailerCreditAuthorizationFormProps
	) => {
		if (!isValidName(formData.guarantor.first_name)) {
			Toast.info("Enter valid guarantor's first name");
			return false;
		} else if (!formData.guarantor.last_name) {
			Toast.info("Enter guarantor's last name");
			return false;
		} else if (!Patterns.telephone.test(formData.guarantor.phone)) {
			Toast.info("Enter a valid Phone Number");
			return false;
		} else if (!formData.guarantor.address) {
			Toast.info("Enter a valid guarantor address");
			return false;
		} else if (!formData.guarantor.relationship) {
			Toast.info("Select your relationship with guarantor");
			return false;
		} else if (!formData.guarantor.occupation_type) {
			Toast.info("Select your occupation");
			return false;
		} else if (formData.guarantor.relationship == "other") {
			if (!formData.guarantor.other_relationship) {
				Toast.info("Type in your relationship with guarantor");
				return false;
			}
		}
		return true;
	};

	const handleSubmit = () => {
		const raFormData = new FormData();
		// Retailer details
		raFormData.append("retailer_details.name", creditData?.retailer_name);
		raFormData.append("retailer_details.id_number", formData.id_no);
		if (formData.id_document) {
			raFormData.append(
				"retailer_details.id_image",
				base64ToBlob(formData.id_document)
			);
		}
		raFormData.append("retailer_details.BVN", formData.bvn);
		raFormData.append("retailer_details.email", formData.email);
		raFormData.append("retailer_details.name", formData.name);

		raFormData.append("retailer_details.date_of_birth", formData.dob);
		raFormData.append("retailer_details.gender", formData.gender);
		raFormData.append(
			"retailer_details.marital_status",
			formData.marital_status
		);
		raFormData.append(
			"retailer_details.residential_address",
			formData.house_address
		);
		raFormData.append(
			"retailer_details.shop_address",
			formData.shop_address
		);
		raFormData.append(
			"retailer_details.nearest_landmark",
			formData.nearest_landmark
		);
		raFormData.append("retailer_details.nationality", formData.nationality);
		raFormData.append(
			"retailer_details.mother_maiden_name",
			formData.mother_maidens_name
		);

		raFormData.append(
			"retailer_details.residential_state",
			formData.residential_state
		);
		raFormData.append("retailer_details.LGA", formData.lga);
		raFormData.append("retailer_details.bank_name", formData.bank_name);
		raFormData.append(
			"retailer_details.account_number",
			formData.account_number
		);

		//Guarantor details
		raFormData.append(
			"user_guarantor.occupation_type",
			formData.guarantor.occupation_type
		);
		raFormData.append(
			"user_guarantor.name",
			formData.guarantor.first_name + " " + formData.guarantor.last_name
		);
		raFormData.append("user_guarantor.address", formData.guarantor.address);
		raFormData.append(
			"user_guarantor.contact_phone",
			formData.guarantor.phone
		);
		raFormData.append(
			"user_guarantor.relationship",
			formData.guarantor.relationship === "other"
				? formData.guarantor.other_relationship
				: formData.guarantor.relationship
		);

		//Retailer credit amount
		raFormData.append(
			"credit_amount",
			formData.credit_amount.replace(/,/g, "")
		);
		raFormData.append(
			"purchase_amount",
			formData.purchase_amount.replace(/,/g, "")
		);
		raFormData.append(
			"actual_name",
			formData.first_name + " " + formData.last_name
		);
		raFormData.append("bank", formData.bank_name);
		raFormData.append("account_number", formData.account_number);
		if (code && raFormData) {
			mutateRetailerAuthorizeCredit({ data: raFormData, auth: code });
		} else {
			Toast.error("An Error Occurred, Try again later");
		}
	};
	/** Verify if credit is still valid */
	const { refetch, isLoading: verifyLoading } = useRetailerVerifyCredit(
		code,
		verifyCreditOnSuccess,
		verifyCreditOnError
	);

	const retailerForbidCredit = () => {
		if (code) {
			return mutateRetailerForbidCredit(code);
		}
		//Alternatively i am meant to trigger a page refresh
		// Toast.error("An Error Occured with credit cancellatioin");
	};

	/** Request to authorize credit */
	const { mutateRetailerAuthorizeCredit, isLoading: authorizeCreditLoading } =
		useRetailerAuthorizeCredit(
			retailerAuthorizationOnSuccess,
			retailerAuthorizationOnError
		);

	/** Request to cancel credit */
	const { mutateRetailerForbidCredit, isLoading: isForbidCreditLoading } =
		useRetailerForbidCredit(
			retailerForbidCreditOnSuccess,
			retailerForbidCreditOnError
		);

	useEffect(() => {
		// const queryParams = new URLSearchParams(location.search);
		// const codeParam = queryParams.get("auth");

		if (codeParam) {
			setCode(codeParam);
		} else {
			// navigate("/");
			return;
		}
	}, [navigate, codeParam]);

	useEffect(() => {
		if (code) {
			refetch();
		}
	}, [code, refetch]);

	return (
		<div className="mx-auto flex min-h-[100vh] w-full flex-col items-center pb-8 pt-5">
			<div className="w-full max-w-[640px]">
				<div className="flex items-center justify-center p-3">
					<figure className="h-8 w-28">
						<img src={Logo} alt="hadi logo" />
					</figure>
				</div>
				{verifyLoading && (
					<div
						className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 `}
					>
						<Loader2 color="#061F6A" />
					</div>
				)}
				{!showProcess ? (
					!verifyLoading ? (
						<form encType="multipart/form-data">
							<header className="relative">
								{page === 4 && (
									<h3 className="mx-auto px-5 py-5 text-center text-[24px] font-semibold text-blue-950">
										Guarantor’s Form
									</h3>
								)}
								<h3 className="mx-auto px-5 py-3 text-center text-[24px] font-semibold text-blue-950">
									Authorise Credit Application
								</h3>
							</header>
							{page === 0 ? (
								<AuthorizationForm
									page={page}
									setPage={setPage}
									creditData={creditData}
									forbidCredit={retailerForbidCredit}
									isForbidCreditLoading={
										isForbidCreditLoading
									}
									isAdminRequest={isAdminRequest}
								/>
							) : null}
							{page === 1 ? (
								<RetailerDetailsForm1
									page={page}
									setPage={setPage}
									formData={formData}
									setFormData={setFormData}
								/>
							) : null}
							{page === 2 ? (
								<RetailerDetailsForm2
									page={page}
									setPage={setPage}
									formData={formData}
									setFormData={setFormData}
									// isAdminRequest={isAdminRequest}
								/>
							) : null}
							{page === 3 ? (
								<RetailerDetailsForm3
									page={page}
									setPage={setPage}
									formData={formData}
									setFormData={setFormData}
									isAdminRequest={isAdminRequest}
									validateGuarantorForm={
										validateGuarantorForm
									}
									setPreviousGuarantor={setPreviousGuarantor}
								/>
							) : null}
							{page === 4 ? (
								<GuarantorForm
									page={page}
									setPage={setPage}
									formData={formData}
									setFormData={setFormData}
									isLoading={authorizeCreditLoading}
									previousGuarantor={previousGuarantor}
									setPreviousGuarantor={setPreviousGuarantor}
									validateGuarantorForm={
										validateGuarantorForm
									}
								/>
							) : null}
							{page === 5 ? (
								<LinkMonoForm
									page={page}
									creditData={creditData}
									setPage={setPage}
									formData={formData}
									setFormData={setFormData}
									submit={handleSubmit}
									isLoading={authorizeCreditLoading}
									previousGuarantor={previousGuarantor}
									auth={code}
									isAdminRequest={isAdminRequest}
									validateGuarantorForm={
										validateGuarantorForm
									}
									previousPage={4}
									retailer_name={creditData?.retailer_name}
									purchase_amount={
										isAdminRequest
											? formData?.purchase_amount
											: creditData?.content_object
													?.total_amount
									}
									formCredit_amount={formData?.credit_amount}
									credit_amount={creditData?.amount}
									creditDataAmount={
										creditData?.content_object?.total_amount
									}
								/>
							) : null}
						</form>
					) : null
				) : (
					<RequestSuccessFail
						state={requestFormData.state}
						title={requestFormData.title}
						subtitle={requestFormData.subtitle}
					/>
				)}
				{/* 
				{!verifyLoading && !isVerifySuccess ? (
					<div className="justify-center ">
						<RequestSuccessFail
							state={requestFormData.state}
							title={requestFormData.title}
							subtitle={requestFormData.subtitle}
						/>
					</div>
				) : null} */}
			</div>
		</div>
	);
};

export default RetailerCreditAuthorization;
