import { request } from "../utils/axios-utils";
import { Toast } from "../utils/toast";

export const checkCrmAvailability = async () => {
	try {
		const response = await request({
			method: "GET",
			url: "/accounting-crm/quickbooks/token/check/",
		});
		return response.data;
	} catch (error) {
		Toast.error("error checking crm availability");
		return null;
	}
};
