import { FormInput } from "../../../components/FormInput";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SuccessModal } from "../../../components/modals/SuccessModal";
import { useLocation, useNavigate } from "react-router";
import {
	Loader2,
	ProcessUploadFile,
	TopBar,
	UploadedFile,
} from "../../../components";
import { AnimatePresence, motion } from "framer-motion";
import { uploadedFileContainerVariants } from "services/Animations";
import { toast } from "react-toastify";
import { useApplyForCredit } from "hooks/useCreditData";
import { useGetAllBanks } from "hooks/useWalletData";
import { RetailerCreditLoanPeriod } from "services/Constants";
import { Toast } from "utils/toast";
import { base64ToBlob } from "utils/utilities";

type submitCreditFormType = {
	submit: () => void;
	formData: {
		// crm: string;
		statement_fileName: any;
		statement_file: any;
		invoice_fileName: any;
		invoice_file: any;
		retailer_name: string;
		tenure: string;
		credit_amount: string;
		purchase_amount: string;
		statement: string;
		bank_code: string;
		bank: string;
	};
	bankData: { value: string; label: string }[];
	setFormData: Dispatch<
		SetStateAction<{
			// crm: string;
			statement_fileName: any;
			statement_file: any;
			invoice_fileName: any;
			invoice_file: any;
			retailer_name: string;
			tenure: string;
			credit_amount: string;
			purchase_amount: string;
			statement: string;
			bank_code: string;
			bank: string;
		}>
	>;
	loading: boolean;
	setLoading: Dispatch<SetStateAction<boolean>>;
};

const ApplyForCredit: React.FC = () => {
	const { state } = useLocation();
	const customerName = state?.formData?.phoneNumber;
	const navigate = useNavigate();
	const [show, setShow] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const initialState = {
		statement_file: null,
		statement_fileName: "",
		invoice_file: null,
		invoice_fileName: "",
		retailer_name: customerName,
		tenure: "",
		credit_amount: "",
		purchase_amount: "",
		statement: "",
		bank_code: "",
		bank: "",
	};

	const getAllBanksQuery = useGetAllBanks(undefined, undefined);
	const [bankData, setBankData] = useState<
		{ value: string; label: string }[]
	>([]);
	useEffect(() => {
		const modifiedBankData = getAllBanksQuery?.data?.data?.data?.map(
			(bank) => ({
				value: bank?.bank_code,
				label: bank?.bank_name,
			})
		);

		if (modifiedBankData) {
			setBankData(modifiedBankData);
		}
	}, [getAllBanksQuery?.data?.data?.data]);
	const [formData, setFormData] = useState(initialState);

	const onSuccess = (data: any) => {
		localStorage.removeItem("invoiceFile");
		setLoading(false);
		setShow(true);
		setTimeout(() => {
			navigate("/dashboard/credit-book");
		}, 4000);
	};
	const onError = (error: any) => {
		localStorage.removeItem("invoiceFile");
		localStorage.removeItem("invoiceFileName");
		setLoading(false);
		Toast.error(error.response.data.message);
	};

	const { mutateApplyForCredit } = useApplyForCredit(onSuccess, onError);
	const submitCreditApplication = () => {
		if (!customerName) {
			toast.error("There was an error. Please try again later");
			return setTimeout(() => {
				navigate("/dashboard/credit-application");
			}, 2000);
		}
		const newFormData: any = new FormData();
		newFormData.append("amount", formData.credit_amount.replace(/,/g, ""));
		newFormData.append("customer", customerName);
		newFormData.append("bank_statement.bank_code", formData.bank_code);

		if (formData.statement_file) {
			newFormData.append(
				"bank_statement.statement",
				base64ToBlob(formData.statement_file)
			);
		}

		// if (formData.invoice_file) {
		// 	newFormData.append(
		// 		"invoice.invoice",
		// 		base64ToBlob(formData.invoice_file)
		// 	);
		// }

		newFormData.append("tenure", formData.tenure);
		newFormData.append(
			"invoice.total_amount",
			formData.purchase_amount.replace(/,/g, "")
		);
		mutateApplyForCredit(newFormData);
	};
	return (
		<div className="mx-auto ">
			<TopBar pageName="Credit Applications" />
			<div className="mt-12 px-8 md:mt-0">
				<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-4 shadow-sm md:w-[500px] md:p-8">
					<CreditForm
						formData={formData}
						setFormData={setFormData}
						submit={submitCreditApplication}
						loading={loading}
						setLoading={setLoading}
						bankData={bankData}
					/>
				</div>
			</div>
			<SuccessModal
				content="Inform Retailer to Authorise Credit Application or else credit won't be processed"
				show={show}
				setShow={setShow}
			/>
		</div>
	);
};

export default ApplyForCredit;

const CreditForm = ({
	submit,
	formData,
	setFormData,
	loading,
	setLoading,
	bankData,
}: submitCreditFormType) => {
	const navigate = useNavigate();

	const validateCreditForm: (formData) => boolean = (formData): boolean => {
		switch (true) {
			case !formData.purchase_amount:
				Toast.error("Kindly provide purchase amount");
				return false;
			case !formData.credit_amount:
				Toast.error("Kindly provide credit amount");
				return false;
			case !formData.bank_code:
				Toast.error("Kindly select a bank");
				return false;
			case !formData.statement_file:
				Toast.error("Please upload bank statement");
				return false;
			// case !formData.invoice_file:
			// 	Toast.error("Please upload invoice");
			// 	return false;
			default:
				return true;
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (validateCreditForm(formData)) {
			setLoading(true);
			submit();
		}
	};

	return (
		<>
			<form
				encType="multipart/form-data"
				className=" flex w-full flex-col items-center gap-3 "
				onSubmit={(e) => handleSubmit(e)}
			>
				<div className="flex flex-col">
					<h1 className="text-center text-2xl  font-semibold text-[#002060]">
						Apply for Credit
					</h1>
					<p className="text-center text-gray-500">
						Enter Credit Details
					</p>
				</div>
				<div className="relative w-full">
					<FormInput
						label="Purchase Amount"
						name="purchase_amount"
						id="purchase_amount"
						value={formData.purchase_amount}
						placeholder="Enter the amount of goods your customer is to purchase"
						type="text"
						required={true}
						minLength={4}
						autoComplete="off"
						additionalClasses="h-10 rounded-md border border-[#001746] bg-transparent !px-6 py-2 text-[15px] placeholder-black placeholder-opacity-50  outline-none "
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
							const formattedValue = value.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								","
							);

							if (formattedValue.startsWith("0")) {
								return;
							}

							setFormData({
								...formData,
								purchase_amount: formattedValue,
							});
						}}
					/>
					<span className="absolute bottom-[10%] left-2">₦</span>
				</div>
				<div className="relative w-full">
					<FormInput
						label="Credit Amount"
						name="credit_amount"
						id="credit_amount"
						value={formData.credit_amount}
						placeholder="Enter The Amount to be borrowed"
						type="text"
						required={true}
						minLength={4}
						autoComplete="off"
						additionalClasses="relative h-10 rounded-md border border-[#001746] bg-transparent !px-6 py-2 text-[15px] placeholder-black placeholder-opacity-50  outline-none "
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
							const formattedValue = value.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								","
							);
							if (formattedValue.startsWith("0")) {
								return;
							}
							const purchaseAmount = parseInt(
								formData.purchase_amount.replace(/,/g, ""),
								10
							);
							const creditAmount = parseInt(
								e.target.value.replace(/,/g, ""),
								10
							);

							if (creditAmount > 250000) {
								return toast.info(
									"Credit Amount cannot be greater than NGN250,000"
								);
							}
							if (creditAmount > purchaseAmount) {
								return toast.info(
									"Purchase amount must be greater than credit amount"
								);
							}

							setFormData({
								...formData,
								credit_amount: formattedValue,
							});
						}}
					/>
					<span className="absolute bottom-[10%] left-2">₦</span>
				</div>
				{/* we would remove the loan tenure from here on next iteration because only retailer can update it */}
				<div className="w-full">
					<FormInput
						label="Loan Tenure"
						name="tenure"
						id="tenure"
						placeholder="Select from drop down"
						type="text"
						value={formData.tenure}
						onChange={(selectedOption) =>
							setFormData({
								...formData,
								tenure: selectedOption.value,
							})
						}
						options={RetailerCreditLoanPeriod}
						placeholderStyleOptions={{
							color: "#333333",
							opacity: 0.7,
							fontSize: "15px",
						}}
						autoComplete="false"
						required={false}
						select={true}
					/>
				</div>

				<div className="w-full">
					<FormInput
						label="Bank"
						labelStyles="font-medium"
						name="bank_name"
						id="bank_name"
						select={true}
						options={bankData}
						placeholderStyleOptions={{
							color: "#333333",
							opacity: 0.7,
							fontSize: "15px",
						}}
						selectStyleOptions={{
							fontSize: "14px",
						}}
						type="select"
						placeholder="Select a bank"
						additionalClasses="text-[#263238] rounded-md border-[1.7px] border-slate-300 bg-transparent font-light placeholder-slate-300 outline-none placeholder:text-[15px]"
						value={formData.bank_code}
						autoComplete="off"
						required={true}
						onChange={(bank) => {
							setFormData((prevData) => {
								return {
									...prevData,
									bank_code: bank?.value,
								};
							});
						}}
					/>
				</div>

				<div className="flex w-full flex-col">
					<h1 className="relative text-[18px] font-semibold text-primary-700">
						Bank Statement Upload
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</h1>
					<p className="text-[#626D88]">Upload your statement here</p>
					<ProcessUploadFile
						formats={["pdf"]}
						maxSize={2 * 1024 * 1024}
						setFile={setFormData}
						localStorageFileName={"statement_file"}
					/>
					<AnimatePresence>
						{formData.statement_file && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={formData.statement_fileName}
										deleteFile={() => {
											setFormData({
												...formData,
												statement_file: null,
											});
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
				{/* <div className="flex w-full flex-col">
					<h1 className="relative text-[18px] font-semibold text-primary-700">
						Invoice Upload
						<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
							*
						</span>
					</h1>
					<p className="text-[#626D88]">Upload Your Invoice Here</p>
					<ProcessUploadFile
						formats={[
							"jpeg",
							"jpg",
							"png",
							"pdf",
							"ai",
							"doc",
							"docx",
							"ppt",
							"pptx",
						]}
						maxSize={2 * 1024 * 1024}
						setFile={setFormData}
						localStorageFileName={"invoice_file"}
					/>
					<AnimatePresence>
						{formData.invoice_file && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={formData.invoice_fileName}
										deleteFile={() => {
											setFormData({
												...formData,
												invoice_file: null,
											});
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div> */}
				<div className="mx-auto flex  w-full flex-col gap-2 pt-2">
					<button
						type="submit"
						disabled={loading}
						className="flex h-10 w-full items-center justify-center rounded-md bg-[#072154] py-2 font-semibold text-white disabled:bg-gray-400"
					>
						{loading ? <Loader2 color="#fff" /> : "Submit"}
					</button>
					<button
						className="text-[#072154]"
						onClick={() => navigate(-1)}
					>
						return back
					</button>
				</div>
			</form>
		</>
	);
};
