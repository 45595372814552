import {
	Home,
	DocumentText1,
	EmptyWalletChange,
	ShopAdd,
	StatusUp,
	ShoppingCart,
	MoneyRecive,
	People,
	Setting,
	InfoCircle,
} from "iconsax-react";

export const dashBoardSidebarData = [
	{
		id: 1,
		title: "Dashboard",
		icon: <Home size="22" variant="Outline" />,
		activeIcon: <Home size="22" variant="Bold" />,
		path: "/dashboard",
		className: "",
		submenus: [],
	},
	{
		id: 2,
		title: "Hadi Credit",
		icon: <DocumentText1 size="22" variant="Outline" />,
		activeIcon: <DocumentText1 size="22" variant="Bold" />,
		path: "",
		className: "",
		submenus: [
			{
				id: 1,
				title: "Credit Book",
				path: "/dashboard/credit-book",
			},
			{
				id: 2,
				title: "Credit Repayment",
				path: "/dashboard/credit-book/repayments",
			},
		],
	},
	{
		id: 3,
		title: "Hadi Pay",
		icon: <EmptyWalletChange size="22" variant="Outline" />,
		activeIcon: <EmptyWalletChange size="22" variant="Bold" />,
		path: "/dashboard/hadi-pay",
		className: "",
		submenus: [],
	},
	{
		id: 4,
		title: "Credit Application",
		icon: <ShopAdd size="22" variant="Outline" />,
		activeIcon: <ShopAdd size="22" variant="Bold" />,
		path: "",
		className: "",
		submenus: [
			{
				id: 1,
				title: "Credit for retailers",
				path: "/dashboard/credit-application",
			},
			{
				id: 2,
				title: "Credit for me",
				path: "/dashboard/credit-application/distributor",
			},
		],
	},
];

export const myCompanySidebarData = [
	{
		id: 1,
		title: "Sales Analytics",
		icon: <StatusUp size="22" variant="Outline" />,
		activeIcon: <StatusUp size="22" variant="Bold" />,
		path: "dashboard/sales/analytics",
		className: "",
		submenus: [],
	},
	{
		id: 2,
		title: "Inventory",
		icon: <ShoppingCart size="22" variant="Outline" />,
		activeIcon: <ShoppingCart size="22" variant="Bold" />,
		path: "dashboard/sales/inventory",
		className: "",
		submenus: [],
	},
	{
		id: 3,
		title: "Order",
		icon: <MoneyRecive size="22" variant="Outline" />,
		activeIcon: <MoneyRecive size="22" variant="Bold" />,
		path: "dashboard/sales/order",
		className: "",
		submenus: [],
	},
	{
		id: 4,
		title: "Customer",
		icon: <People size="22" variant="Outline" />,
		activeIcon: <People size="22" variant="Bold" />,
		path: "/dashboard/customers",
		className: "",
		submenus: [],
	},
];

export const myAccountSidebarData = [
	{
		id: 1,
		title: "Profile",
		icon: <DocumentText1 size="22" variant="Outline" />,
		activeIcon: <DocumentText1 size="22" variant="Bold" />,
		path: "/dashboard/profile",
		className: "",
		submenus: [],
	},
	{
		id: 2,
		title: "Settings",
		icon: <Setting size="22" variant="Outline" />,
		activeIcon: <Setting size="22" variant="Bold" />,
		path: "/dashboard/settings",
		className: "",
		submenus: [],
	},
	{
		id: 3,
		title: "Help & Center",
		icon: <InfoCircle size="22" variant="Outline" />,
		activeIcon: <InfoCircle size="22" variant="Bold" />,
		path: "/dashboard/help-center",
		className: "",
		submenus: [],
	},
];
