import { Button } from "../../../components";
import React from "react";
import { HiPlus } from "react-icons/hi2";
import ErrorImg from "../../../assets/image/404.svg";

const NoDataUser: React.FC<{ stage1: (e: any) => void }> = ({ stage1 }) => {
	const handleClick = (e: any) => {
		e.preventDefault();
		// setShow(true);
		stage1(e);
	};
	return (
		<div className="flex h-[500px] flex-col items-center justify-center">
			<img src={ErrorImg} alt="404 image" />

			<div className="flex w-[406px] flex-col gap-8">
				<p className="pt-4 text-center text-[24px] font-semibold leading-[28px] text-gray-400">
					Oops! Seems you don&apos;t have any Sub-Users Created Yet!
				</p>

				<Button
					disabled={false}
					onClick={handleClick}
					additionalClasses="flex items-center gap-[12px] text-lg text-white"
				>
					<span className="flex h-[24px] w-[24px] items-center justify-center rounded bg-[#475569] text-[white]">
						<HiPlus />
					</span>
					Create Sub-Users
				</Button>
			</div>
		</div>
	);
};

export default NoDataUser;
