import { OTPInputProps } from "types/components";
import React, { useState, useRef } from "react";

export const DeliveryOTPInput: React.FC<OTPInputProps> = ({
	otpRef,
	onChange,
}) => {
	const inputCount = 4;
	const [inputValues, setInputValues] = useState<string[]>(
		Array(inputCount).fill("")
	);
	const inputRefs = useRef<HTMLInputElement[]>([]);

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const keyValue = e.key;

		if (e.ctrlKey || e.metaKey) {
			return;
		}

		if (!/^\d$/.test(keyValue) && keyValue !== "Backspace") {
			e.preventDefault();
		} else if (keyValue === "Backspace") {
			const focusedIndex = inputRefs.current.findIndex(
				(inputRef) => inputRef === document.activeElement
			);

			if (focusedIndex >= 0 && inputRefs.current[focusedIndex]) {
				const updatedInputValues = [...inputValues];
				updatedInputValues[focusedIndex] = "";
				setInputValues(updatedInputValues);
				inputRefs.current[focusedIndex]?.focus();
			}
		}
	};

	const handleInputChange = (index: number, value: string) => {
		const updatedInputValues = [...inputValues];
		updatedInputValues[index] = value;
		setInputValues(updatedInputValues);
		onChange(createSyntheticEvent(updatedInputValues.join("")));

		if (index < inputCount - 1 && inputRefs.current[index + 1]) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData("text");
		const pastedNumbers = pastedText
			.replace(/\D/g, "")
			.slice(0, inputCount);

		const newInputValues = Array(inputCount).fill("");
		for (let i = 0; i < pastedNumbers.length; i++) {
			newInputValues[i] = pastedNumbers[i];
		}

		setInputValues(newInputValues);
		onChange(createSyntheticEvent(newInputValues.join("")));
	};

	const createSyntheticEvent = (value: string) =>
		({
			target: { value },
		} as React.ChangeEvent<HTMLInputElement>);

	return (
		<div className="flex gap-6">
			{inputValues.map((value, index) => (
				<input
					key={index}
					ref={(ref) =>
						(inputRefs.current[index] = ref as HTMLInputElement)
					}
					className="text-darkblue-300 flex h-[60px] w-full max-w-[60px] items-center rounded-lg border border-[#AAB2C3] text-center text-3xl text-[#002060] caret-white selection:bg-transparent md:h-[60px] lg:h-[60px]"
					value={value}
					maxLength={1}
					onChange={(e) => handleInputChange(index, e.target.value)}
					onFocus={(e) => e.target.select()}
					onClick={() => otpRef?.current?.select()}
					inputMode="numeric"
					onKeyDown={handleKeyPress}
					onPaste={handlePaste}
				/>
			))}
		</div>
	);
};
