import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, Loader2, OTPInput, Overlay } from "../..";
import { MdOutlineClose } from "react-icons/md";
import { OTPCode } from "../../../types/components";
import OTPGroup, { validatePINCode } from "../../OtpForms/OTPGroup";
import { useCreatePIN } from "../../../hooks/useWalletData";
import { Toast } from "../../../utils/toast";
import { isEqual } from "lodash";
import { useAuth } from "../../../hooks/auth";
import { encryptPin } from "../../../utils/utilities";

interface SetNewPINProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}

const SetNewPIN: React.FC<SetNewPINProps> = ({ show, setShow }) => {
	const modalRef = useRef(null);
	const { user, setUser } = useAuth();
	const [firstPINCode, setFirstPINCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});
	const [confirmPINCode, setConfirmPINCode] = useState<OTPCode>({
		one: "",
		two: "",
		three: "",
		four: "",
	});
	const [stage, setStage] = useState(1);

	const fullCode =
		confirmPINCode.one +
		confirmPINCode.two +
		confirmPINCode.three +
		confirmPINCode.four;

	const onSuccess = () => {
		setShow(false);
		setUser({
			...user,
			transfer_pin: true,
		});
		localStorage.setItem(
			"hadi_user",
			JSON.stringify({ ...user, transfer_pin: true })
		);
		Toast.success("PIN has been created successfully 🎉");
	};

	const onError = () => {
		Toast.error("An error occured. Kindly try again later 🤕");
	};

	const createPINMutation = useCreatePIN(onSuccess, onError);
	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef} isPersistent>
			{show && (
				<section
					className="relative flex w-full max-w-[536px] flex-col items-center rounded-[10px] bg-white px-8 py-7"
					ref={modalRef}
				>
					<figure
						className="cursor-pointer self-end rounded-full p-2.5 duration-300 ease-in-out hover:bg-slate-300"
						onClick={() => setShow(false)}
					>
						<MdOutlineClose size={24} />
					</figure>
					<div className="flex max-w-[324px] flex-col space-y-1.5 text-center">
						<h2 className="text-2xl font-medium text-blue-950">
							{stage === 1 ? "Set-Up Pin" : " Confirm Pin"}
						</h2>
						<p className="font-medium text-slate-600">
							In order to make transactions set up your pin to
							continue this process.
						</p>
					</div>
					<div className="mt-4 flex w-full max-w-[376px] justify-between">
						{stage === 1 ? (
							<OTPGroup
								otpCode={firstPINCode}
								setOtpCode={setFirstPINCode}
								size={4}
								inputSize={70}
								additionalClasses="rounded-lg"
							/>
						) : (
							<OTPGroup
								otpCode={confirmPINCode}
								setOtpCode={setConfirmPINCode}
								size={4}
								inputSize={70}
								additionalClasses="rounded-lg"
							/>
						)}
					</div>

					<Button
						disabled={createPINMutation.isLoading}
						onClick={() => {
							if (stage === 1 && validatePINCode(firstPINCode)) {
								setStage(2);
							} else if (
								stage === 1 &&
								!validatePINCode(firstPINCode)
							) {
								Toast.info(
									"Kindly input in your preferred PIN code"
								);
							}
							if (
								stage === 2 &&
								!isEqual(firstPINCode, confirmPINCode)
							) {
								Toast.info("PIN codes do not match");
								return;
							}
							if (
								stage === 2 &&
								validatePINCode(confirmPINCode) &&
								isEqual(firstPINCode, confirmPINCode)
							) {
								createPINMutation.mutateCreatePIN({
									pin: encryptPin(fullCode),
								});
							} else if (
								stage === 2 &&
								!validatePINCode(confirmPINCode)
							) {
								Toast.info("PIN codes do not match");
							}
						}}
						additionalClasses="text-white max-w-[418px] mt-4 h-[53px]"
					>
						{stage === 1
							? "Next"
							: stage === 2 && !createPINMutation.isLoading
							? "Confirm"
							: stage === 2 &&
							  createPINMutation.isLoading && <Loader2 />}
					</Button>
				</section>
			)}
		</Overlay>
	);
};

export default SetNewPIN;
