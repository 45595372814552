// import { BsArrowUp, BsArrowDown, BsDash } from "react-icons/bs";
import { ArrowUp, ArrowDown, Minus } from "iconsax-react";

export const GetTrendIcon = (value: number, textType: string) => {
	if (value > 0) {
		if (textType === "text") {
			return (
				<p className="text-[11px] font-bold text-[#90A3BF]">
					<span className="text-[#7FB519]">{value ?? 0}%</span>{" "}
					increase compared to last week
				</p>
			);
		} else if (textType === "color") {
			return "text-[#7FB519]";
		} else if (textType === "icon") {
			return <ArrowUp color="#7FB519" className="text-[#7FB519]" />;
		}
	} else if (value < 0) {
		if (textType === "text") {
			return (
				<p className="text-[11px] font-bold text-[#90A3BF]">
					<span className="text-[#FF4423]">
						{Math.abs(value ?? 0)}%
					</span>{" "}
					decrease compared to last week
				</p>
			);
		} else if (textType === "color") {
			return "text-[#FF4423]";
		} else if (textType === "icon") {
			return <ArrowDown color="#FF4423" className="text-[#FF4423]" />;
		}
	} else {
		if (textType === "text") {
			return (
				<p className="text-[11px] font-bold text-[#90A3BF]">
					<span className="text-[#F1C40F]">{Math.abs(value)}%</span>{" "}
					increae compared to last week
				</p>
			);
		} else if (textType === "color") {
			return "text-[#F1C40F]";
		} else if (textType === "icon") {
			return <Minus color="#F1C40F" className="text-[#F1C40F]" />;
		}
	}
};

export const getInitials = (username: string | undefined): string => {
	if (!username) return "N/A";

	const nameParts = username.split(" ").filter((part) => part.length > 0);

	return nameParts.length > 0 ? nameParts[0][0].toUpperCase() : "N/A";
};
