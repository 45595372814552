import { TopBar } from "../Topbar";

type CreditApplicationWrapperProps = {
	children: React.ReactNode;
	name: string;
};
const CreditApplicationWrapper = ({
	children,
	name,
}: CreditApplicationWrapperProps) => {
	return (
		<div>
			<TopBar pageName={name} />
			{children}
		</div>
	);
};

export default CreditApplicationWrapper;
