import React from "react";
import {
	BarChart,
	Bar,
	Rectangle,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

interface ChartDataIProps {
	chartData: any[];
	isLoading: boolean;
	chartFilterYear: number;
}

type DataItem = {
	month: string;
	year: number;
	count: number;
};

type FilledDataItem = DataItem;

const months: string[] = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

const CustomTooltip = ({ active, payload }: any) => {
	if (active && payload && payload.length > 0) {
		return (
			<div className=" rounded-md bg-black px-3 py-2 text-white shadow">
				{payload.map((pld: any) => (
					<div
						key={pld.name}
						className="flex flex-col items-center gap-2"
					>
						<p className="text-[10px] font-semibold">
							Highest Number
						</p>
						<p className="text-[14px] font-normal">{pld.value}</p>
					</div>
				))}
			</div>
		);
	}
	return null;
};

export const DashboardChart: React.FC<ChartDataIProps> = ({
	chartData,
	isLoading,
	chartFilterYear,
}) => {
	const processMonthlyData = (data: DataItem[]): FilledDataItem[] => {
		if (data.length !== 0) {
			const year = chartFilterYear;
			const dataMap: Map<string, DataItem> = new Map(
				data.map((item) => [item.month, item])
			);

			const filledData: FilledDataItem[] = months.map(
				(month) => dataMap.get(month) || { month, year, count: 0 }
			);

			return filledData.sort(
				(a, b) => months.indexOf(a.month) - months.indexOf(b.month)
			);
		} else {
			return [];
		}
	};

	return (
		<div className="h-full min-h-[338px] w-full">
			<ResponsiveContainer height={300}>
				<BarChart
					width={500}
					height={338}
					data={processMonthlyData(chartData ?? []) ?? []}
					barSize={12}
					margin={{
						top: 0,
						right: 0,
						left: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid vertical={false} strokeDasharray="0 0" />
					<XAxis
						dataKey="month"
						axisLine={false}
						tickLine={false}
						tickSize={12}
					/>
					<YAxis axisLine={false} tickLine={false} tickSize={6} />
					<Tooltip cursor={false} content={<CustomTooltip />} />
					<Bar
						dataKey="count"
						fill="#3E6EC2"
						radius={[30, 30, 0, 0]}
						activeBar={
							<Rectangle fill="#1C1E23" stroke="#1C1E23" />
						}
						barSize={12}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};
