import React from "react";
import { NotificationType3 } from "services/Constants";
import { repaymentFormatDate } from "utils/utilities";
import {
	RiCalendar2Fill,
	RiInformationFill,
	RiCloseCircleFill,
	RiCheckboxCircleFill,
} from "react-icons/ri";

interface RecordItemProps {
	title: string;
	message?: string;
	icon?: NotificationType3;
	desc?: string;
	date?: Date;
}

const RecordItem: React.FC<RecordItemProps> = ({
	title,
	message,
	icon,
	desc,
	date,
}) => {
	const NotificationDate = (date: Date) => {
		return (
			repaymentFormatDate(date) +
			" " +
			date.getHours() +
			":" +
			date.getMinutes() +
			(date.getHours() > 12 ? "pm" : "am")
		);
	};

	return (
		<div className="flex w-full flex-col">
			<div className="flex items-center gap-2.5">
				{date && (
					<RiCalendar2Fill
						size={18}
						className="mt-2 text-slate-700"
					/>
				)}

				{desc === NotificationType3.CreditApplied && (
					<RiInformationFill
						size={18}
						className="mt-2 text-blue-950"
					/>
				)}

				{desc === NotificationType3.CreditCancelled && (
					<RiCloseCircleFill
						size={18}
						className="mt-2 text-red-700"
					/>
				)}

				{desc === NotificationType3.CreditAuthorized && (
					<RiCheckboxCircleFill
						color="#038637"
						size={18}
						className="mt-2 text-red-700"
					/>
				)}
				<p className="flex items-center pt-2 text-sm text-slate-600">
					{date ? NotificationDate(date) : desc}
				</p>
			</div>
			<div className="mt-8">
				<p className="text-[13px] font-normal text-[#596780]">
					{message ?? "No message content available"}
				</p>
			</div>
		</div>
	);
};

export default RecordItem;
