import { AxiosResponse } from "axios";
import {
	BeneficiaryData,
	TransferData,
} from "../components/Dashboard/HadiPay/BankTransfer";
import { request } from "../utils/axios-utils";
import { getCookie } from "../utils/cookies";
import { encryptPin } from "../utils/utilities";
import { Toast } from "../utils/toast";

export const getAllBanks = () => {
	return request({
		url: `/wallets/banks/`,
	});
};

export const getAccountLookup = ({ queryKey }: any) => {
	const account_number = queryKey[1];
	const bank_code = queryKey[2];
	return request({
		url: `/wallets/bank-account-lookup/${account_number}/${bank_code}/`,
	});
};

export const makeTransaction = async (
	data: TransferData
): Promise<AxiosResponse> => {
	const response = await request({
		method: "POST",
		url: `/hadipay/inter_transfer/`,
		data,
	});

	return response;
};

export const getAccountDetails = () => {
	return request({
		url: `/wallets/account-details/`,
	});
};

export const getPoolerWalletDetails = () => {
	return request({
		url: `/hadipay/pooler_wallet/`,
	});
};

export const getAccountBeneficiaries = () => {
	return request({
		url: `/wallets/all-distributors-beneficiaries/`,
	});
};

export const createBeneficiary = (data: BeneficiaryData) => {
	return request({
		method: "POST",
		url: `/wallets/create-beneficiary/`,
		data,
	});
};

export const deleteBeneficiary = (account_number: string) => {
	return request({
		method: "DELETE",
		url: `/wallets/delete-beneficiary/${account_number}/`,
	});
};

export const getAccountTransactionHistory = () => {
	return request({
		url: `/hadipay/inter_transfer/history/`,
	});
};

export const getAccountTransactions = () => {
	return request({
		url: `/hadipay/transactions/`,
	});
};

export const createPIN = (data: { pin: string }) => {
	return request({
		method: "POST",
		url: `/distributors/create_pin/`,
		data,
	});
};

export const updatePIN = (data: {
	new_pin: string;
	otp_pin_id: string;
	otp_pin: string;
}) => {
	return request({
		method: "POST",
		url: `/distributors/change_pin/`,
		data,
	});
};

export const verifyOtp = (data: { pin: string }) => {
	return request({
		method: "POST",
		url: `/distributors/create_pin/`,
		data,
	});
};

export const getChangeTransactionPinOTP = async () => {
	try {
		const response = await request({
			method: "GET",
			url: "/distributors/change_pin/",
		});
		return response.data;
	} catch (error) {
		Toast.error("Error sending otp");
		return null;
	}
};
