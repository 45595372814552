import { useMutation } from "react-query";
import { FormInput, Loader2 } from "../../../components";
import { handleCopyUrlLink } from "../../../utils/utilities";
import React, { FormEvent, useState } from "react";
import { BsCopy } from "react-icons/bs";
import { request } from "../../../utils/axios-utils";
import { API_URI } from "../../../services/Constants";
import { Toast } from "../../..//utils/toast";
import { useAuth } from "../../../hooks/auth";

const HelpCenter = () => {
	const { user } = useAuth();
	const [copiedEmail, setCopiedEmail] = useState();
	const [copiedPhone, setCopiedPhone] = useState();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const submitContactForm = useMutation(
		["contact-form"],
		(data: {
			name: string;
			phone_number: string;
			subject: string;
			message: string;
			email: string;
		}) =>
			request({
				method: "POST",
				url: `${API_URI}/admin/contact_us/`,
				data,
			}),
		{
			onError: () => {
				Toast.error(
					"Something went wrong during submission. Please try again later."
				);
			},
			onSuccess: () => {
				Toast.success("Your message was sent successfully 😊");
				setFormData({ name: "", email: "", message: "" });
			},
		}
	);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = {
			name: formData.name,
			email: formData.email,
			message: formData.message,
			subject: "Message From Support Center - Credix",
			phone_number: user?.username ?? "User from Credix",
		};

		submitContactForm.mutate(data);
	};
	return (
		<div className="flex flex-col gap-y-12">
			<div>
				<h1 className="text-lg font-semibold text-[#101828]">
					Help Center
				</h1>
				<p className="text-sm text-[#667085]">
					Need to speak with us? we are here to support you!
				</p>
			</div>

			<div className="flex gap-x-12">
				<form
					onSubmit={(e: FormEvent<HTMLFormElement>) =>
						handleSubmit(e)
					}
					className="flex w-full max-w-[577px] flex-col gap-y-8 rounded-lg bg-white py-8 pl-12 pr-24"
				>
					<FormInput
						label="Your Name"
						labelStyles="!text-black "
						name="name"
						id="name"
						type="text"
						placeholder="Enter your full name"
						minLength={5}
						maxLength={19}
						required={true}
						autoComplete="on"
						additionalClasses="!text-black h-full rounded-md border border-[#263238] bg-transparent px-5 py-3  font-light  outline-none placeholder:text-[14px]"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData({ ...formData, name: e.target.value });
						}}
						value={formData.name}
					/>
					<FormInput
						label="email"
						labelStyles="!text-black "
						name="email"
						id="email"
						type="email"
						placeholder="Enter your email address"
						pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$"
						minLength={5}
						maxLength={19}
						required={true}
						autoComplete="on"
						additionalClasses="!text-black h-full rounded-md border border-[#263238] bg-transparent px-5 py-3  font-light  outline-none placeholder:text-[14px]"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData({ ...formData, email: e.target.value });
						}}
						value={formData.email}
					/>
					<FormInput
						label="Write your message"
						labelStyles="!text-black "
						name="message"
						id="message"
						type="text"
						textarea={true}
						pattern=".{15,}"
						placeholder="Enter your message"
						rows={5}
						required={true}
						autoComplete="on"
						additionalClasses="h-full rounded-md border  bg-transparent py-3 border-[#90A3BF] font-light  outline-none placeholder:text-[14px]"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData({
								...formData,
								message: e.target.value,
							});
						}}
						value={formData.message}
					/>

					<button
						className="flex h-10 w-fit items-center rounded-md bg-primary-600 px-5 py-2.5 text-sm text-white"
						type="submit"
					>
						{submitContactForm.isLoading ? (
							<Loader2 color="#fff" />
						) : (
							"Send Message"
						)}
					</button>
				</form>
				{/* Copy Section   */}
				<div className="flex flex-col gap-y-16 self-center">
					<div className="flex flex-col gap-y-2">
						<h1 className="text-lg font-semibold text-primary-600">
							Send us an email
						</h1>

						<span className="flex items-center gap-x-2 font-medium">
							<a
								href="mailto:support@hadifinance.com"
								className="text-primary-900 underline"
							>
								support@hadifinance.com
							</a>
							<button
								onClick={() =>
									handleCopyUrlLink(
										setCopiedEmail,
										"support@hadifinance.com"
									)
								}
							>
								<BsCopy />
							</button>

							<p className="text-xs">
								{copiedEmail === true ? "Copied" : ""}
							</p>
						</span>
					</div>
					<div className="flex flex-col gap-y-2">
						<h1 className="text-lg font-semibold text-primary-600">
							Call us
						</h1>
						<span className="flex items-center gap-x-2 font-medium">
							<a
								href="tel:+2342013309129"
								className="text-primary-900 underline"
							>
								+2342013309129
							</a>
							<button
								onClick={() =>
									handleCopyUrlLink(
										setCopiedPhone,
										"+2342013309129"
									)
								}
							>
								<BsCopy />
							</button>
							<p className="text-xs">
								{copiedPhone === true ? "Copied" : ""}
							</p>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HelpCenter;
