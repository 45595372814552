import React, { useMemo } from "react";
import { Toast } from "./toast";
import { useLocation } from "react-router-dom";
import { ColorType, StatusType } from "../types/utils";
import lgaList from "./data/lgaData";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function encodeValue(val: unknown) {
	if (typeof val === "string") {
		return val;
	}
	return JSON.stringify(val);
}

export function decodeValue(val: unknown) {
	if (typeof val === "string") {
		try {
			return JSON.parse(val);
		} catch (_) {
			/* empty */
		}
	}
	return val;
}

export const logDev = (...args: any) => {
	// if (process.env.NODE_ENV === "development") {
	// 	const error = new Error();
	// 	const stack = error.stack || "";
	// 	console.log(stack);
	// 	// const caller = stack.split("\n")[2].split("at ")[1].trim();
	// 	// console.log(`${caller}\n`, ...args);
	// }
	return;
	// console.log("hi");
};

export const onErrorToast = (error: any) => {
	if (error.response.status >= 500) {
		Toast.error("Please try again later 🤕");
		return error;
	}

	//Phone Number is verified
	if (error.response.data.message == "Phone number is verified") {
		Toast.success(error.response.data.message);
		setTimeout(() => {
			window.location.href = "/login";
		}, 3000);
		return;
	}

	if (error.response.data.message) {
		Toast.error(error.response.data.message);
		return error;
	}

	if (error.response.data) {
		const firstObject: any = Object.values(error.response.data.error)[0];
		const firstErrorField = Object.keys(firstObject)[0];
		const firstErrorMessage = firstObject[firstErrorField][0];
		// console.log(firstErrorMessage);
		Toast.error(firstErrorMessage);
	}
};

export const onSuccessThrowError = (data: any) => {
	if (
		data.response &&
		data.response.status >= 400 &&
		data.response.status < 500
	) {
		const error = Object.assign(new Error(), { data });
		error.stack = "";
		throw error;
	}
};

export const getDateSuffix = (day: number) => {
	const suffixes = ["th", "st", "nd", "rd"];
	const relevantDigits = day < 30 ? day % 20 : day % 30;
	const suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
	return suffix;
};

export const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.toLocaleString("default", { month: "short" });
	const year = date.getFullYear();
	const formattedDate = `${day}${getDateSuffix(day)} ${month} ${year}`;

	return formattedDate;
};

export const useQueryParams = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
};

export const validateEmail = (email: string) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

export const validateTextNumber = (value: string) => {
	const numericValue = value.replace(/\D/g, "");
	return numericValue;
};

export const removeWhiteSpaces = (value: string) => {
	const sanitizedValue = value.replace(/\s/g, "");
	return sanitizedValue;
};

export const toTitleCase = (string?: string): string => {
	if (!string) {
		return "";
	}

	return string
		.toLowerCase()
		.split(/\s+/)
		.slice(0, 2)
		.map((part) => (part ? part[0].toUpperCase() + part.slice(1) : ""))
		.join(" ");
};

export const getRepaymentDateFromTenure = (
	created: Date,
	tenure: number
): string => {
	const createdDate = new Date(created);
	createdDate.setDate(createdDate.getDate() + tenure);

	const year = createdDate.getFullYear().toString().slice(-2);
	const month = (createdDate.getMonth() + 1).toString().padStart(2, "0");
	const day = createdDate.getDate().toString().padStart(2, "0");

	return `${day}-${month}-${year}`;
};

export const getDueDate = (created: Date): string => {
	const createdDate = new Date(created);
	createdDate.setDate(createdDate.getDate());

	const year = createdDate.getFullYear().toString().slice(-2);
	const month = (createdDate.getMonth() + 1).toString().padStart(2, "0");
	const day = createdDate.getDate().toString().padStart(2, "0");

	return `${day}-${month}-${year}`;
};

export const getTime = (date: Date): string => {
	const newdate = new Date(date);
	return (
		newdate.getHours() +
		":" +
		newdate.getMinutes() +
		(newdate.getHours() > 12 ? "pm" : "am")
	);
};

export const repaymentFormatDate = (dateString: Date): string => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = date.toLocaleDateString(undefined, { month: "long" });
	const year = date.getFullYear();

	let daySuffix = "th";

	if (day === 1 || day === 21 || day === 31) {
		daySuffix = "st";
	} else if (day === 2 || day === 22) {
		daySuffix = "nd";
	} else if (day === 3 || day === 23) {
		daySuffix = "rd";
	}

	return `${day}${daySuffix} ${month} ${year}`;
};

export const getStatusColor = (status: StatusType, type: ColorType) => {
	const newStatus = status?.toLowerCase();

	// Define color mappings for background and text
	const backgroundColors: { [key: string]: string } = {
		applied: "bg-[#3498db]",
		authorized: "bg-[#47EBE0]",
		processing: "bg-[#F1C40F]",
		approved: "bg-[#27AE60]",
		rejected: "bg-[#C7C7C7]",
		accepted: "bg-[#8E44AD]",
		declined: "bg-[#95A5A6]",
		delivered: "bg-[#BA68C8]",
		disbursed: "bg-[#7CAC58]",
		repaid: "bg-[#062B56]",
		payment_due: "bg-[#FFB294]",
		pending_payment: "bg-[#6122E6]",
		defaulted: "bg-[#C02B2B]",
		cancelled: "bg-[#C02B2B]",
		debit: "bg-[#BA1A1A]",
		credit: "bg-[#00A47E]",
	};

	const textColors: { [key: string]: string } = {
		applied: "text-[#3498db]",
		authorized: "text-[#47EBE0]",
		processing: "text-[#F1C40F]",
		approved: "text-[#27AE60]",
		rejected: "text-[#C7C7C7]",
		accepted: "text-[#8E44AD]",
		declined: "text-[#95A5A6]",
		delivered: "text-[#BA68C8]",
		disbursed: "text-[#7CAC58]",
		repaid: "text-[#062B56]",
		payment_due: "text-[#FFB294]",
		pending_payment: "text-[#6122E6]",
		defaulted: "text-[#C02B2B]",
		cancelled: "text-[#C02B2B]",
		debit: "text-[#BA1A1A]",
		credit: "text-[#00A47E]",
	};

	if (type === "background") {
		return backgroundColors[newStatus.toLowerCase()] || "bg-[#3498db]";
	} else {
		return textColors[newStatus.toLowerCase()] || "text-[#3498db]";
	}
};

export const firstWordToTitle: (str: string, separator?: string) => string = (
	str,
	separator = ""
): string => {
	return (
		str.split("")[0].toUpperCase() +
		str
			.slice(1)
			.split(separator || " ")
			.join(" ")
			.toLowerCase()
	);
};

export const letter_profile_icon = (title: string) => {
	return title
		?.split(" ")
		.slice(0, 2)
		.map((part) => part?.split("")[0].toUpperCase())
		.join("");
};

export const starred_account_number = (account_number: string) => {
	const account_number_split = account_number.split("");

	return (
		account_number_split.splice(0, 3).join("") +
		"*****" +
		account_number_split
			.splice(
				account_number_split.length - 3,
				account_number_split.length - 1
			)
			.join("")
	);
};

export const encryptPin = (pin: string): string => {
	const combinedPin = pin + process.env.REACT_APP_PIN_SALT;
	const encodedPin = btoa(combinedPin);
	return encodedPin;
};

export const endpointsWithoutAuthorization = [
	"/credit/create_offer",
	"/credit/customer/cancel",
	"/credit/customer/complete",
	"/credit/customer/initial-accept",
	"/credit/customer/reject",
	"/credit/customer/send-otp",
	"/credit/customer/verify",
	"/credit/guarantor",
	"/credit/guarantor/decline",
	"/credit/guarantor/verify",
	"/dashboard/distributor/",
	"/auth/login/",
	"/auth/logout/",
	"/auth/register/",
	"/auth/password-reset/verify-token",
	"/auth/password-reset/",
	"/auth/auth/resend-email/",
	"/auth/password-reset-confirm/",
	"/auth/account-confirm-email/",
	"/accounting-crm/quickbooks/callback/",
	"/third-party/mono/",
];

export const formatAmountToNaira = (amount: string | number): string => {
	const formattedNumber = parseFloat(amount as string).toLocaleString(
		undefined,
		{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
	);
	return `₦${formattedNumber}`;
};

export const getState = () => {
	const states = Object.keys(lgaList).map((key) => ({
		value: key,
		label: key,
	}));
	return states;
};

export const getStateLga = (state: string) => {
	const options: any[] = [];
	if (state) {
		lgaList[state]?.map((data) => {
			options.push({ value: data, label: data });
		});
		return options;
	} else {
		return [{ value: "", label: "" }];
	}
};

export const getMaxDate = (maxDate: number) => {
	const currentDate = new Date();

	const tempDate = new Date(
		currentDate.getFullYear() - (maxDate ?? 18),
		currentDate.getMonth(),
		currentDate.getDate()
	);

	return tempDate;
};

export const exportDataAsCSV = (data: any[], fileName: string) => {
	const headers = Object.keys(data[0]);
	const csvContent =
		headers.join(",") +
		"\n" +
		data
			.map((row: any) => headers.map((header) => row[header]).join(","))
			.join("\n");

	const blob = new Blob([csvContent], { type: "text/csv" });

	const url = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);

	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
};

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const base64ToBlob = (base64Data: string) => {
	const [metadata, data] = base64Data.split(",");
	const byteString = atob(data);
	const mimeString = metadata.split(":")[1].split(";")[0];
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
};

export const updateNestedState = (prevState: any, path: string, value: any) => {
	const keys = path.split(".");
	const lastKey = keys.pop();
	const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));

	const tempState = deepClone(prevState);
	let temp = tempState;

	keys.forEach((key) => {
		if (!temp[key]) {
			temp[key] = {};
		}
		temp = temp[key];
	});

	temp[lastKey] = value;

	return tempState;
};

export const handleCopyUrlLink = async (setCopied, text) => {
	setCopied(true);
	await navigator.clipboard
		.writeText(text)
		.then(() => {
			setCopied(true);
		})
		.catch(() => setCopied(undefined));

	setTimeout(() => {
		setCopied(false);
	}, 1500);
};
