import { AxiosResponse } from "axios";
import { client, request } from "../utils/axios-utils";
import { API_URI } from "../services/Constants";
import {
	loginUserData,
	changePasswordData,
	LogoutUserData,
	PasswordResetData,
	passwordResetConfirmData,
	passwordResetVerifyTokenData,
	accountConfirmData,
	ResendEmailData,
	MonoAuthData,
	UserSearchData,
	FieldAgentData,
} from "../types/hooks";
import { registrationFormData } from "../types/views";

export const loginUser = (data: loginUserData) => {
	return request({ url: "/auth/login/", method: "post", data });
};

export const changePassword = (data: changePasswordData) => {
	return request({ url: "/auth/password/change/", method: "post", data });
};

export const logoutUser = (): Promise<AxiosResponse<LogoutUserData>> => {
	return request({ url: "/auth/logout/", method: "post" });
};

export const passwordReset = (
	data: PasswordResetData
): Promise<AxiosResponse> => {
	return request({
		url: "/auth/password-reset/",
		method: "post",
		data,
	});
};

export const registerDistributor = (data: registrationFormData) => {
	return request({
		method: "POST",
		url: `${API_URI}/auth/register/`,
		data,
	});
};

export const linkQuickbooks = () => {
	return request({
		method: "GET",
		url: `${API_URI}/accounting-crm/quickbooks/auth/`,
	});
};

export const linkZohoCRM = () => {
	return request({
		method: "GET",
		url: `${API_URI}/accounting-crm/zoho/auth/`,
	});
};

export const revokeZohoCRMAccess = () => {
	return request({
		method: "GET",
		url: `${API_URI}/accounting-crm/zoho/token/revoke/`,
	});
};

export const accountConfirm = (
	data: accountConfirmData
): Promise<AxiosResponse> => {
	return request({
		url: `${API_URI}/auth/account-confirm-email/${data.key}/`,
		method: "post",
		data,
	});
};

export const passwordResetConfirm = (
	data: passwordResetConfirmData
): Promise<AxiosResponse> => {
	return request({
		url: `/auth/password-reset-confirm/${data.uid}/${data.token}/`,
		method: "post",
		data,
	});
};

export const passwordResetVerifyToken = (
	data: passwordResetVerifyTokenData
): Promise<AxiosResponse> => {
	return request({
		url: `/auth/password-reset/verify-token/${data.uid}/${data.token}/`,
		method: "post",
		data,
	});
};

export const resendEmail = (data: ResendEmailData): Promise<AxiosResponse> => {
	return request({
		url: "auth/resend-email/",
		method: "post",
		data,
	});
};

// export const linkMono = async (data: MonoAuthData): Promise<AxiosResponse> => {
// 	const response = await request({
// 		method: "POST",
// 		url: `${API_URI}/third-party/mono/`,
// 		data,
// 	});

// 	return response;
// };

export const linkMono = async (data: MonoAuthData): Promise<AxiosResponse> => {
	const { code, auth } = data;
	const payload: MonoAuthData = {
		code: code ?? "",
	};
	const response = await request({
		method: "POST",
		url: `${API_URI}/third-party/mono/${auth ? "?token=" + auth : ""}`,
		data: payload,
	});

	return response;
};

export const onboardRetailer = (data: { data: FormData }) => {
	return request({
		method: "POST",
		url: "auth/retailer/create/",
		data: data.data !== null ? data.data : {},
	});
};

export const retailerSearch = async (data: { data: UserSearchData }) => {
	const response = await request({
		method: "POST",
		url: "/auth/retailer/search/",
		data: data.data !== null ? data.data : {},
	});

	return response;
};

export const retailerGurantorSearch = (data: { data: UserSearchData }) => {
	return request({
		method: "POST",
		url: "/auth/retailer/search-guarantor/",
		data: data.data !== null ? data.data : {},
	});
};

export const fieldAgent = (data: { data: FieldAgentData }) => {
	return request({
		method: "POST",
		url: "/auth/field_agent/",
		data: data.data !== null ? data.data : {},
	});
};
// export const getCrmLink = () => {
// 	return request({
// 		method: "GET",
// 		url: `/accounting-crm/quickbooks/auth`,
// 	});
// };
