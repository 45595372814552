import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/image/Logo.svg";
import avatar4 from "../assets/image/avatar/avatar4.png";

interface DashboardLayoutProps {
	children: ReactNode;
}

const AuthLayout: FC<DashboardLayoutProps> = ({ children }) => {
	return (
		<div className="relative flex h-screen w-full flex-row overflow-hidden">
			<div className="relative hidden w-full lg:flex lg:w-[46%]">
				<div className="sticky inset-0 top-0 min-h-screen w-full bg-authBackground bg-cover bg-center">
					<div className="opacity-81 absolute inset-0 bg-[#07122FCF]"></div>
					<div className="absolute inset-0 flex flex-col items-center justify-start px-[28px] py-[65px] xl:px-[59px]">
						<div className="w-full">
							<Link to="/" className="flex items-center">
								<figure className="h-8 w-28">
									<img src={Logo} alt="Hadi logo" />
								</figure>
							</Link>
						</div>

						<div className="relative mx-auto mt-[50px] flex h-full w-full flex-4 items-center justify-center px-[25px] xl:px-[50px]">
							<div className="flex w-full flex-col justify-between">
								<div>
									<h3 className="w-full text-left text-[2.6rem] font-[400] text-white">
										Unlock the Power <br /> of{" "}
										<span className="font-[700]">
											Inventory Financing
										</span>
									</h3>
									<p className="mt-4 text-left text-[16px] text-[#BAE5FF] xl:text-[18px]">
										Boost sales, get settled upfront, and{" "}
										<br /> earn more seamlessly – all with
										Hadi Finance!
									</p>
								</div>

								<div className="items-left mt-[10%] flex w-full flex-col justify-between gap-8 rounded-md bg-white bg-opacity-10 px-[31px] py-[24px] xl:mt-[20%]">
									<h3 className=" text-[14px] font-[300] text-white">
										“Hadi Finance service has been easy
										access for retailers and wholesalers
										with their swift response and easy cash
										disbursement to it&apos;s distributors“
									</h3>
									<div className="flex w-full flex-row items-center">
										<img
											src={avatar4}
											alt="avatar"
											className="h-[49px] w-[49px] rounded-full"
										/>
										<div className="ml-[18px] w-full">
											<p className="text-[12px] font-[500] text-white">
												BLINQIT
											</p>
											<p className="test-[12px] font-[300] text-white">
												Abuja, Nigeria
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex w-[100%] flex-col overflow-y-auto px-[28px] py-[65px] lg:w-[54%] lg:px-[59px]">
				{children}
			</div>
		</div>
	);
};

export default AuthLayout;
