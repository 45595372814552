import { formatDate } from "../../../utils/utilities";
import NoTableData from "../../../components/Dashboard/NoTableData";
import { useNavigate } from "react-router";
import { Button, Loader2 } from "../../../components";
import { useGetAllRepaymentsData } from "../../../hooks/useRepaymentData";
import { AxiosError, AxiosResponse } from "axios";

const Repayment = () => {
	const navigate = useNavigate();

	const onSuccess = (data: AxiosResponse) => {
		//
	};

	const onError = (error: AxiosError) => {
		//
	};

	const {
		data: repayments,
		isLoading,
		isError,
		isSuccess,
	} = useGetAllRepaymentsData(onSuccess, onError);

	return (
		<div>
			<div className={`flex h-full ${!isLoading ? "hidden" : ""}`}>
				<div className="mx-auto mt-12 h-full scale-[2]">
					<Loader2 color="#061F6A" />
				</div>
			</div>

			{isError && (
				<div className=" flex h-full ">
					<div className="mx-auto mt-12 h-full">
						<h1>Cannot retrieve data</h1>
					</div>
				</div>
			)}
			{isSuccess &&
				repayments &&
				repayments?.data?.data?.length !== 0 && (
					<div className=" h-full min-h-[600px] w-full overflow-x-scroll">
						<table className=" w-full min-w-[600px] table-auto  text-[13px] lg:text-[16px] ">
							<thead>
								<tr className="w-full rounded-md bg-slate-700 font-medium text-gray-50">
									<th className="px-3 py-2 text-left ">
										Date Created
									</th>

									<th className="px-3 py-2 text-left">
										Retailer Name
									</th>
									<th className="px-3 py-2 text-left">
										Retailer Number
									</th>
									{/* <th className="px-3 py-2 text-left">Status</th> */}
									<th className="px-3 py-2 text-left">
										Loan Amount
									</th>
									<th className="px-3 py-2 text-left">
										Action
									</th>
								</tr>
							</thead>

							<tbody>
								{repayments?.data?.data.map(
									(repayment: any, index: number) => {
										return (
											<tr
												key={`${index}`}
												className="cursor-pointer"
											>
												<td className="px-4 py-2.5">
													<p>
														{formatDate(
															repayment?.created
														) ?? "N/A"}
													</p>
												</td>
												<td className="px-4 py-2.5">
													{repayment?.retailer_name ??
														"N/A"}
												</td>
												<td className="px-4 py-2.5">
													{repayment?.customer ??
														"N/A"}
												</td>
												{/* <td
												className={`px-3 py-2 text-center capitalize`}
											>
												<div
													className={`flex items-center gap-2 rounded-md bg-opacity-20 px-2 py-1 ${getStatusColor(
														repayment?.status,
														"background"
													)}`}
												>
													<span
														className={`h-2 w-2 rounded-full ${getStatusColor(
															repayment?.status,
															"background"
														)}`}
													></span>
													<p
														className={`font-semibold ${getStatusColor(
															repayment?.status,
															"text"
														)}`}
													>
														{repayment?.status ?? "N/A"}
													</p>
												</div>
											</td> */}
												<td className="px-4 py-2.5">
													{repayment?.credit_offer ? (
														<>
															₦{" "}
															{Number(
																repayment.credit_offer.split(
																	"."
																)[0]
															).toLocaleString()}
														</>
													) : (
														"N/A"
													)}
												</td>
												<td className="relative px-4 py-2">
													<Button
														onClick={() => {
															navigate(
																repayment.id
															);
														}}
														additionalClasses="rounded-[4px] border border-[#BABFC3] px-4 py-2 bg-white max-w-fit"
														disabled={false}
													>
														Repayments
													</Button>
												</td>
											</tr>
										);
									}
								)}
							</tbody>
						</table>
					</div>
				)}
			{isSuccess && repayments && repayments?.data.data.length === 0 && (
				<NoTableData
					textInformation="Oops! Seems there is not any credit due for repayment"
					buttonContent="Apply For Credit"
					buttonAction={() =>
						navigate("/dashboard/credit-application")
					}
				/>
			)}
		</div>
	);
};

export default Repayment;
