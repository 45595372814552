import { useEffect, useState, Fragment } from "react";
import { Button, FormInput, FormikInput, Loader2 } from "../components";
import Logo from "../assets/image/Logo.svg";
import blackLogo from "../assets/image/blackLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUser } from "../hooks/useAuthData";
import { useAuth } from "../hooks/auth";
import { loginUserData } from "types/hooks";
import { Toast } from "utils/toast";
import { AxiosError } from "axios";
import { validateEmail } from "utils/utilities";
import { HiCheck } from "react-icons/hi";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const Login = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [checked, setChecked] = useState<boolean>(false);
	const [formData, setFormData] = useState<loginUserData>({
		email: "",
		password: "",
	});

	const handleResendEmail = (formData: loginUserData) => {
		navigate("/resend-email", { state: { formData } });
	};

	const onError = (
		error: AxiosError<{ message: string }>,
		payload: loginUserData
	) => {
		if (error.response) {
			const { message } = error.response.data;
			Toast.error(message);
			if (message === "Email is not verified") {
				handleResendEmail(payload);
			}
		}
	};

	const { mutate: loginUser, isLoading } = useLoginUser(onError);

	const handleClick = async (payload: loginUserData) => {
		// setFormData(payload);
		setFormData((prevFormData) => ({
			...prevFormData,
			email: payload.email,
			password: payload.password,
		}));
		loginUser(payload);
	};

	useEffect(() => {
		if (user) {
			navigate("/dashboard");
		}
	}, [user, navigate]);

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<Link to="/" className="flex w-auto items-center lg:hidden">
					<figure className="h-8 w-28">
						<img src={blackLogo} alt="Hadi logo" />
					</figure>
				</Link>
				<Link
					to="/register"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign up
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3 py-8">
					<div className="w-full max-w-[500px]">
						<h3 className="w-full text-left text-[32px] font-[600] leading-[1] text-primary-900 lg:leading-[56px]">
							Sign in to your account
						</h3>
						<p className="test-[14px] mb-[63px] font-[400] text-secondary-400">
							Please enter your details below
						</p>
						<Formik
							initialValues={formData}
							validationSchema={Yup.object({
								email: Yup.string()
									.required("Email is required")
									.email("Invalid email address"),
								password: Yup.string()
									.required("Password is required")
									.min(
										6,
										"Password must be at least 6 characters"
									),
							})}
							onSubmit={handleClick}
						>
							{({ values }) => (
								<Form className="flex h-full w-full flex-col">
									<div className="flex w-full flex-col gap-y-9">
										<FormikInput
											label="Email"
											name="email"
											floatingLabel={true}
											id="email"
											type="email"
											placeholder="Enter Email"
											disabled={isLoading}
											additionalClasses="border-[#263238] placeholder-[#263238]"
											autoComplete="off"
											required={true}
										/>
										<div>
											<FormikInput
												label="Password"
												name="password"
												floatingLabel={true}
												id="password"
												type="password"
												placeholder="Enter User Password"
												disabled={isLoading}
												additionalClasses="border-[#263238] placeholder-[#263238]"
												password={true}
												autoComplete="off"
												required={true}
											/>

											<div
												className="mt-2 flex items-center gap-2"
												onClick={() =>
													setChecked(!checked)
												}
											>
												<input
													type="checkbox"
													checked={checked}
													onChange={() =>
														setChecked(!checked)
													}
													name="remember"
													id="checked-checkbox"
													className="peer relative h-4 w-4 shrink-0 cursor-pointer appearance-none rounded-[1px] border-[0.5px] border-primary-900 bg-transparent text-white checked:border-primary-800 checked:bg-primary-800"
												/>
												<label
													htmlFor="remmeber"
													className="font-[500] text-secondary-400"
												>
													Remember me
												</label>
												<HiCheck className="pointer-events-none absolute mx-auto hidden h-3.5 w-4 text-white peer-checked:block" />
											</div>
										</div>
									</div>
									<Button
										disabled={isLoading}
										additionalClasses={
											"text-lg text-white font-medium bg-primary-800 mt-[37px]"
										}
										type="submit"
									>
										{isLoading ? <Loader2 /> : "Sign in"}
									</Button>

									<Link
										to={"/forgot-password"}
										className="mt-[8px] text-center text-sm text-[#001746] underline"
									>
										Forgot Password?
									</Link>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Login;
