import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Button, Overlay } from "..";
import { ContinueOnboardingProps } from "types/components/modals";
import { slideDownAnimation } from "../../services/Animations";
import { FiRefreshCcw } from "react-icons/fi";
import { useLogoutUser } from "../../hooks/useAuthData";
import { ImCross } from "react-icons/im";

export const VerificationProcessModal: React.FC<ContinueOnboardingProps> = ({
	show,
	setShow,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const { mutate: logoutUser } = useLogoutUser();

	return (
		<Overlay
			isPersistent={true}
			show={show}
			setShow={setShow}
			modalRef={modalRef}
		>
			{show && (
				<motion.section
					variants={slideDownAnimation}
					initial="hidden"
					animate="visible"
					exit="exit"
					ref={modalRef}
					className=" relative m-10 flex w-fit flex-col items-center rounded-[10px] bg-white p-12"
				>
					<div className="absolute left-8 top-8 flex w-full justify-start">
						<ImCross
							className="h-8 w-8 cursor-pointer text-primary-700"
							onClick={() => setShow(false)}
						/>
					</div>
					<div className=" flex w-full flex-col items-center gap-4 py-8">
						<div className="text-center text-[5em] text-[#00CC9C] ">
							<FiRefreshCcw size="1em" />
						</div>
						<div className="flex flex-col items-center">
							<h1 className="text-[1.7em] font-semibold text-[#002060]">
								Processing ...
							</h1>
							<p className=" max-w-[402px] text-center text-lg  text-gray-400">
								Your Verification is in progress, it will be
								complete soon
							</p>
						</div>
						<div className="flex w-full gap-x-5 msm:flex-col msm:gap-y-4">
							<Button
								onClick={() => {
									logoutUser();
								}}
								disabled={false}
								additionalClasses="text-base text-white py-5 font-semibold max-w-[402px]"
							>
								Log Out
							</Button>
							<Button
								onClick={() => setShow(false)}
								disabled={false}
								additionalClasses="text-sm flex items-center gap-x-6 text-error-600 py-2 font-semibold bg-white border border-error-600 h-11"
							>
								Skip to dashboard
								<span>x</span>
							</Button>
						</div>
					</div>
				</motion.section>
			)}
		</Overlay>
	);
};
