/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";
import {
	DeliveryModal,
	ConfirmCancelCreditModal,
} from "../../../components/modals";
import { useQuery } from "react-query";
import { getAllDistributorCredit } from "../../../api/credit";
import { formatDate, getStatusColor } from "../../../utils/utilities";
import NoTableData from "../../../components/Dashboard/NoTableData";
import { useNavigate } from "react-router";
// import { RiShareBoxLine } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import { getCookie } from "../../../utils/cookies";
import { useCancelDistributorCredit } from "../../../hooks/useCreditData";
import { Loader2 } from "../../../components/Loaders/Loader2";

const Transactions = () => {
	const navigate = useNavigate();
	// const selection = ["Date", "In/out", "Method", "Paid"];
	const [distributorCancelError, setDistributorCancelError] =
		useState<boolean>(false);
	const [distributorCancelSuccess, setDistributorCancelSuccess] =
		useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [showCancelCreditModal, setShowCancelCreditModal] = useState(false);
	const [selectedCredit, setSelectedCredit] = useState<
		Record<string, unknown>
	>({});
	const [selectedCustomer, setSelectedCustomer] = useState<any>();
	const [showResendOtp, setShowResendOtp] = useState(false);

	const {
		data: transactions,
		isLoading,
		isError,
		isSuccess,
	} = useQuery("distributor-credit", getAllDistributorCredit, {
		retry: 3,
		refetchInterval: 300000,
	});

	const handleCancelCreditError = () => {
		setDistributorCancelError(true);
		setTimeout(() => {
			setDistributorCancelError(false);
			setShowCancelCreditModal(false);
		}, 5000);
	};

	const handleCancelCreditSuccess = () => {
		setDistributorCancelSuccess(true);
		setTimeout(() => {
			setDistributorCancelSuccess(false);
			setShowCancelCreditModal(false);
		}, 3500);
	};

	const handleDelivery = (data: any) => {
		setSelectedCustomer(data);
		setShowModal(true);
	};

	const actionModalRef = useRef<HTMLDivElement>(null);
	const [showActionModalIndex, setShowActionModalIndex] = useState<
		number | null | string
	>(null);

	const handleModalClose = () => {
		setShowModal(false);
	};
	const handleShowCancelCreditModal = (data: Record<string, unknown>) => {
		setSelectedCredit(data);
		setShowCancelCreditModal(true);
	};

	const handleCancelCredit = (data: Record<string, any>) => {
		const { customer, created } = data;
		const formdata = {
			customer: customer,
			created: created,
		};
		mutateCancelDistributorCredit(formdata);
	};

	const handleShowActionModal = (index: any) => {
		if (showActionModalIndex === index) {
			setShowActionModalIndex(null); // Close the modal if it's already open
		} else {
			setShowActionModalIndex(index); // Open the modal for the clicked tr element
		}
		// setShowActionModal(!showActionModal)
	};

	const {
		mutateCancelDistributorCredit,
		isLoading: distributorCancelLoading,
	} = useCancelDistributorCredit(
		handleCancelCreditSuccess,
		handleCancelCreditError
	);
	return (
		<div>
			{/* <div className="flex items-center justify-between px-4 pb-4">
				<div className="hidden gap-4 md:flex">
					<p className="text-[14px] font-[500] text-[#1E293B]">
						Filters
					</p>
					<div className=" flex flex-wrap gap-4 text-[12px]">
						{selection.map((data, index) => (
							<button
								className=" flex items-center gap-2  rounded border border-gray-400 px-2 py-[0.5px] text-gray-400"
								key={index}
							>
								{data}
								<BsChevronDown />
							</button>
						))}
					</div>
				</div>
				<div className="hidden text-[14px] md:block">
					<button className="flex items-center gap-2 rounded  bg-[#061F6A] px-3 py-[5px] text-gray-50">
						<RiShareBoxLine />
						Export
					</button>
				</div>
			</div> */}
			{isLoading && (
				<div className={`flex h-full`}>
					<div className="mx-auto mt-12 h-full scale-[2]">
						<Loader2 color="#061F6A" />
					</div>
				</div>
			)}
			{isError && (
				<div className=" flex h-full ">
					<div className="mx-auto mt-12 h-full">
						<h1>Cannot retrieve data</h1>
					</div>
				</div>
			)}
			{isSuccess &&
				transactions &&
				transactions?.data.data.length !== 0 && (
					<div className=" h-full min-h-[600px] w-full overflow-x-scroll">
						<table className=" w-full min-w-[600px] table-auto  text-[13px] lg:text-[16px] ">
							<thead>
								<tr className="w-full rounded-md bg-slate-700 font-medium text-gray-50">
									<th className="px-3 py-2 text-left ">
										Date Created
									</th>

									<th className="px-3 py-2 text-left">
										Retailer Name
									</th>
									<th className="px-3 py-2 text-left">
										Retailer Number
									</th>
									<th className="px-3 py-2 text-left">
										Status
									</th>
									<th className="px-3 py-2 text-left">
										Amount
									</th>
									<th className="px-3 py-2 text-left">
										Loan Approved
									</th>
									<th className="px-3 py-2 text-left">
										Action
									</th>
								</tr>
							</thead>

							<tbody>
								{transactions?.data.data.map(
									(data: any, index: number) => {
										const isCancelableStatus =
											[
												"applied",
												"authorized",
												"processing",
												"approved",
												"accepted",
											].includes(data?.status) || false;
										return (
											<tr
												key={`${index}-${data.customer}`}
											>
												<td className="px-4">
													<p>
														{formatDate(
															data?.created
														) ?? "N/A"}
													</p>
												</td>
												<td className="px-4">
													{data?.retailer_name ??
														"N/A"}
												</td>
												<td className="px-4">
													{data?.customer ?? "N/A"}
												</td>
												<td
													className={`px-3 py-2 text-center capitalize`}
												>
													<div
														className={`flex items-center gap-2 rounded-md bg-opacity-20 px-2 py-1 ${getStatusColor(
															data?.status,
															"background"
														)}`}
													>
														<span
															className={`h-2 w-2 rounded-full ${getStatusColor(
																data?.status,
																"background"
															)}`}
														></span>
														<p
															className={`font-semibold ${getStatusColor(
																data?.status,
																"text"
															)}`}
														>
															{data?.status ??
																"N/A"}
														</p>
													</div>
												</td>
												<td className="px-4">
													₦{" "}
													{data?.amount
														? Number(
																data.amount.split(
																	"."
																)[0]
														  ).toLocaleString()
														: "N/A"}
												</td>
												<td className="px-4">
													{data?.credit_offer ? (
														<>
															₦{" "}
															{Number(
																data.credit_offer.split(
																	"."
																)[0]
															).toLocaleString()}
														</>
													) : (
														"N/A"
													)}
												</td>
												<td className="relative flex cursor-pointer items-center justify-center px-4 py-4">
													<button
														onClick={() =>
															handleShowActionModal(
																`${index}-${data.customer}`
															)
														}
													>
														<BsThreeDots />
													</button>
													{showActionModalIndex ===
														`${index}-${data.customer}` && (
														<div
															className={`absolute right-2 top-10 z-10 flex w-[130px] flex-col gap-2 rounded-md border bg-white p-2 shadow-md ${
																showActionModalIndex
																	? "flex"
																	: "hidden"
															}`}
															ref={actionModalRef}
														>
															{data?.status ===
																"distributor review" && (
																<button
																	className="w-full rounded-md bg-[#002060] px-2 py-2 text-[14px] font-semibold  text-white"
																	onClick={() =>
																		navigate(
																			`transactions/${data?.id}`
																		)
																	}
																>
																	Approve Loan
																</button>
															)}
															{getCookie(
																`${data.customer}-deliveryOtp`
															) !== "true" && (
																<button
																	disabled={
																		data?.status !==
																		"accepted"
																	}
																	className="w-full rounded-md border border-[#BABFC3] px-2 py-2  text-[14px] font-semibold text-[#475569] shadow-sm disabled:text-gray-400  "
																	onClick={() =>
																		handleDelivery(
																			data
																		)
																	}
																>
																	Send Otp
																</button>
															)}

															{getCookie(
																`${data.customer}-deliveryOtp`
															) == "true" && (
																<button
																	disabled={
																		data?.status !==
																		"accepted"
																	}
																	className="w-full rounded-md border border-[#BABFC3] px-2 py-2 text-[14px] font-semibold text-[#475569] shadow-sm"
																	onClick={() => {
																		handleDelivery(
																			data
																		);
																		// setShowModal(true);
																		// handleCountdown();
																	}}
																>
																	Enter Otp
																</button>
															)}
															{isCancelableStatus && (
																<button
																	className="w-full rounded-md bg-red-500 px-4 py-2 text-[14px] font-semibold  text-white"
																	onClick={() =>
																		handleShowCancelCreditModal(
																			data
																		)
																	}
																>
																	Cancel
																</button>
															)}
														</div>
													)}
												</td>
											</tr>
										);
									}
								)}
							</tbody>
						</table>
					</div>
				)}
			{isSuccess &&
				transactions &&
				transactions?.data.data.length === 0 && (
					<NoTableData
						textInformation="Oops! Seems you haven't applied for credit yet"
						buttonContent="Apply For Credit"
						buttonAction={() =>
							navigate("/dashboard/credit-application")
						}
					/>
				)}

			<DeliveryModal
				show={showModal}
				showResendOtp={showResendOtp}
				setShowResendOtp={setShowResendOtp}
				customerData={selectedCustomer}
				setShow={setShowModal}
				content={"Get OTP from retailer and confirm delivery"}
				buttonAction={handleModalClose}
				buttonContent="Confirm"
			/>

			<ConfirmCancelCreditModal
				show={showCancelCreditModal}
				setShow={setShowCancelCreditModal}
				loading={distributorCancelLoading}
				processCancel={handleCancelCredit}
				success={distributorCancelSuccess}
				error={distributorCancelError}
				data={selectedCredit}
			/>
		</div>
	);
};

export default Transactions;
