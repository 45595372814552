/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useEffect,
	useState,
} from "react";
import { HiOutlineChevronDoubleRight } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import { useOnBoardDistributor } from "../../hooks/useCustomerData";
import {
	SuccessModal,
	ErrorCrmModal,
	OnboardingProcessStage,
} from "../../components";
import { Toast } from "../../utils/toast";
import Logo from "../../assets/image/blueLogo.svg";
import { useLogoutUser } from "../../hooks/useAuthData";
import { useAuth } from "../../hooks/auth";
import GovernmentIDProcess from "./GovernmentIDProcess";
import SelfieUploadProcess from "./SelfieUploadProcess";
import Overview from "./Overview";
import AddressUpload from "./AddressUpload";
import VerifyOrganization from "./VerifyOrganization";
import LinkERP from "./LinkERP";
import FinalOverview from "./FinalOverview";
import ManufacturerProcess from "./ManufacturerProcess";
import {
	// UseSessionStorage,
	clearIndexedDB,
	useIndexedDBStorage,
} from "../../utils/UseSessionStorage";
import { AxiosError, AxiosResponse } from "axios";
import { useGetManufacturers } from "../../hooks/useCustomerData";
import { base64ToBlob } from "../../utils/utilities";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";

export type OnboardingForm = {
	businessAddress: string | null;
	bvn: string | null;
	businessAddressFileName: string | null;
	businessAddressFile: any;
	TINNumber: string | null;
	CACNumber: string | null;
	cacDocumentFile: any;
	cacDocumentFileName: string | null;
	manufacturers: any[];
	governmentFrontFileName: string | null;
	governmentBackFileName: string | null;
	governmentFrontFile: any;
	governmentBackFile: any;
	governmentIdDetails: {
		idType: string | any;
		idNo: string | any;
	};
	selfiePhoto: any;
	metadata: {
		voter_state: string | null;
		voter_lga: string | null;
	};
};

export type OnboardingFormContextProps = {
	manufacturersList: any[];
	onboardingFormData: OnboardingForm;
	setOnboardingFormData: Dispatch<SetStateAction<OnboardingForm>>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
};

const STORAGE_KEY = "onboardingFormData";

const defaultOnboardingData: OnboardingForm = {
	bvn: "",
	businessAddress: "",
	businessAddressFileName: "",
	businessAddressFile: null,
	TINNumber: "",
	CACNumber: "",
	cacDocumentFile: null,
	cacDocumentFileName: "",
	manufacturers: [],
	governmentFrontFileName: "",
	governmentBackFileName: "",
	governmentFrontFile: null,
	governmentBackFile: null,
	governmentIdDetails: {
		idType: "",
		idNo: "",
	},
	metadata: {
		voter_state: "",
		voter_lga: "",
	},
	selfiePhoto: null,
};

export const OnboardingFormContext = createContext<OnboardingFormContextProps>({
	manufacturersList: [],
	onboardingFormData: defaultOnboardingData,
	setOnboardingFormData: () => {
		defaultOnboardingData;
	},
	page: 0,
	setPage: () => {},
});

const Onboarding: React.FC = () => {
	const { user, setUser } = useAuth();
	const distroOnboardingData = user?.distributor?.distributor_onboarding;
	const navigate = useNavigate();
	const { pages } = useParams();

	const [page, setPage] = useState<number>(parseInt(pages || "0", 10));
	const [onboardingFormData, setOnboardingFormData] =
		useIndexedDBStorage<OnboardingForm>(STORAGE_KEY, defaultOnboardingData);
	const [crmLinkSuccess, setCrmLinkSuccess] = useState<boolean>(false);
	const [show, setShow] = useState(false);
	const [showCrmSuccess, setShowCrmSuccess] = useState(false);
	const [showCrmError, setShowCrmError] = useState(false);
	const [manufacturersList, setManufacturersList] = useState([
		{ name: "", description: "" },
	]);

	const { mutate: logoutUser } = useLogoutUser();
	const handleLogout = () => {
		logoutUser();
	};
	const onError = () => {
		Toast.error("An error occurred. Please try again later");
	};
	const onSuccess = async (data: any) => {
		const UserData = data.data.data;
		setShow(true);
		await clearIndexedDB();
		setTimeout(() => {
			setUser(UserData);
			sessionStorage.removeItem("onboardingFormData");
			localStorage.setItem("hadi_user", JSON.stringify(UserData));
			navigate("/dashboard");
		}, 5500);
	};

	const { mutateOnBoardDistributor, isLoading } = useOnBoardDistributor(
		onSuccess,
		onError
	);
	const handleSubmit = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		const formData: any = new FormData();

		formData.append("address", onboardingFormData.businessAddress);

		if (onboardingFormData.businessAddressFile) {
			formData.append(
				"address_document",
				base64ToBlob(onboardingFormData.businessAddressFile)
			);
		}
		if (onboardingFormData.cacDocumentFile) {
			formData.append(
				"cac_document",
				base64ToBlob(onboardingFormData.cacDocumentFile)
			);
		}
		formData.append("tin_number", onboardingFormData.TINNumber);
		formData.append("cac_number", onboardingFormData.CACNumber);
		formData.append(
			"government_id.id_type",
			onboardingFormData.governmentIdDetails.idType
		);
		formData.append(
			"government_id.id_number",
			onboardingFormData.governmentIdDetails.idNo
		);
		if (onboardingFormData.governmentFrontFile) {
			const response = await fetch(
				onboardingFormData.governmentFrontFile
			);
			const blob = await response.blob();
			const file = new File(
				[blob],
				onboardingFormData.governmentFrontFileName
			);
			formData.append("government_id.id_document", file);
		}
		if (onboardingFormData.governmentFrontFile) {
			const response = await fetch(
				onboardingFormData.governmentFrontFile
			);
			const blob = await response.blob();
			const file = new File(
				[blob],
				onboardingFormData.governmentFrontFileName
			);
			formData.append("government_id.id_document_back", file);
		}
		formData.append(
			"smile_data",
			JSON.stringify(onboardingFormData.selfiePhoto) || ""
		);
		formData.append("bvn", onboardingFormData.bvn);
		formData.append(
			"metadata.voter_state",
			onboardingFormData.metadata.voter_state
		);
		formData.append(
			"metadata.voter_lga",
			onboardingFormData.metadata.voter_lga
		);

		onboardingFormData.manufacturers.forEach((value: any) => {
			formData.append("manufacturers[]", value.value);
		});

		mutateOnBoardDistributor(formData);
	};

	const getManufacturersOnSuccess = (data: AxiosResponse) => {
		setManufacturersList(data?.data?.results);
	};

	const getManufacturersOnError = (error: AxiosError) => {
		console.log(error);
	};
	useGetManufacturers(getManufacturersOnSuccess, getManufacturersOnError);

	const processes = [
		"Government ID",
		"Proof Of Address",
		"Take a Selfie",
		"TIN & CAC Number",
		"Manufacturers",
		"Link ERP",
	];

	useEffect(() => {
		// Navigate user back to login if onboarded
		if (!user) {
			navigate("/login");
		} else if (!user?.distributor) {
			navigate("/login");
		} else if (user?.distributor.is_onboarded) {
			navigate("/dashboard");
		}
	}, [user, navigate]);

	useEffect(() => {
		if (pages) {
			const validPages = [0, 1, 2, 3, 4, 5, 6, 7];
			const pageNumber = parseInt(pages, 10);
			if (!validPages.includes(pageNumber)) {
				navigate("/*");
			}
		}
	}, [navigate, pages]);

	useEffect(() => {
		if (distroOnboardingData) {
			setOnboardingFormData((prevData: any) => ({
				...prevData,
				bvn: distroOnboardingData?.bvn || "",
				businessAddress: distroOnboardingData?.businessAddress || "",
				businessAddressFileName:
					distroOnboardingData?.businessAddressFileName || "",
				businessAddressFile:
					distroOnboardingData?.businessAddressFile || null,
				TINNumber: distroOnboardingData?.TINNumber || "",
				CACNumber: distroOnboardingData?.CACNumber || "",
				manufacturers: distroOnboardingData?.manufacturers || [],
				governmentFrontFileName:
					distroOnboardingData?.governmentFrontFileName || "",
				governmentBackFileName:
					distroOnboardingData?.governmentBackFileName || "",
				governmentFrontFile:
					distroOnboardingData?.governmentFrontFile || null,
				governmentBackFile:
					distroOnboardingData?.governmentBackFile || null,
				governmentIdDetails:
					distroOnboardingData?.governmentIdDetails || {
						idType: "",
						idNo: "",
					},
				metadata: distroOnboardingData?.metadata || {
					voter_state: "",
					voter_lga: "",
				},
				selfiePhoto: distroOnboardingData?.selfiePhoto || null,
			}));
		}
	}, [distroOnboardingData]);

	return (
		<OnboardingFormContext.Provider
			value={{
				onboardingFormData,
				setOnboardingFormData,
				page,
				setPage,
				manufacturersList,
			}}
		>
			<div className="flex min-h-[100svh] w-full flex-col px-8 py-4">
				<div className="flex items-center justify-between pb-4 ">
					<figure
						className="h-8 w-32 cursor-pointer"
						onClick={() => navigate("/login")}
					>
						<img src={Logo} alt="" className="h-full w-full" />
					</figure>
					<button onClick={handleLogout}>Logout</button>
				</div>
				{page !== 0 && page !== 7 && (
					<div className="flex flex-wrap justify-center gap-5">
						{processes.map((process, index) => (
							<div
								key={index}
								className={`flex items-center gap-x-1 bg-opacity-5 duration-200 ${
									!(page >= index + 1) ? "opacity-30" : ""
								} ${page === 0 ? "opacity-30" : ""}`}
							>
								<OnboardingProcessStage
									title={process}
									stage={index + 1}
								/>

								<HiOutlineChevronDoubleRight
									className={`hidden flex-shrink-0 text-[#061F6A] md:flex${
										processes.length - 1 === index &&
										"hidden"
									}`}
								/>
							</div>
						))}
					</div>
				)}
				{page !== 0 && page !== 7 && (
					<div className="mx-auto flex w-full max-w-full  items-center gap-2 self-start pt-10 sm:max-w-[70%] ">
						<MdKeyboardDoubleArrowLeft className="text-xl text-primary-700" />
						<button
							onClick={() => setPage(page - 1)}
							className="font-medium text-primary-700 underline-offset-2 hover:underline"
						>
							Go Back
						</button>
					</div>
				)}

				<form
					action=""
					encType="multipart/form-data"
					// className="relative mt-5 w-full flex-1"
					className="relative my-auto mb-5 mt-6 flex flex-1 flex-col items-center justify-center py-4"
				>
					{page === 0 && <Overview />}
					{page === 1 && <GovernmentIDProcess />}
					{page === 2 && <AddressUpload />}
					{page === 3 && <SelfieUploadProcess />}
					{page === 4 && <VerifyOrganization />}
					{page === 5 && <ManufacturerProcess />}
					{page === 6 && (
						<LinkERP
							setCrmLinkSuccess={setCrmLinkSuccess}
							showCrmSuccess={showCrmSuccess}
							setShowCrmSuccess={setShowCrmSuccess}
							showCrmError={showCrmError}
							setShowCrmError={setShowCrmError}
							isLoading={isLoading}
						/>
					)}
					{page === 7 && (
						<FinalOverview
							crmLinkSuccess={crmLinkSuccess}
							isLoading={isLoading}
							handleSubmit={handleSubmit}
						/>
					)}
				</form>
			</div>
			<SuccessModal
				content="Your documents are being processed and your onboarding would be completed within 24hrs"
				show={show}
				setShow={setShow}
			/>
			<SuccessModal
				content="Congratulations, you have successfully linked your ERP"
				show={showCrmSuccess}
				setShow={setShowCrmSuccess}
				buttonContent="Continue"
				buttonAction={() => {
					setShowCrmSuccess(false);
					setPage(6);
				}}
			/>
			<ErrorCrmModal
				content="Your CRM Linking Failed "
				show={showCrmError}
				setShow={setShowCrmError}
				buttonContent="Try Again"
				buttonAction={() => {
					setShowCrmError(false);
					localStorage.removeItem("crmError");
				}}
			/>
		</OnboardingFormContext.Provider>
	);
};

export default Onboarding;
