import React from "react";
import { Button } from "../../Button";
import {
	Document,
	Page,
	StyleSheet,
	View,
	Text,
	Image,
	Font,
	pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { StatusType } from "../../../types/utils";
import NumberFormat from "../../../utils/NumberFormat";
import Logo from "../../../assets/image/hadiPngLogo.png";
import { formatDate, getStatusColor } from "../../../utils/utilities";

interface TransactionDataInterface {
	date: string;
	amount: number;
	transaction_id: string;
	status: StatusType;
	recipient_name: string | null;
	recipient_bank: string | null;
	retailer_name: string | null;
	transaction_type: string;
	narration: string;
}

interface PdfDataPairProps {
	label: string;
	value: string | number;
	status?: StatusType;
	color?: string;
}

interface TransactionRecieptProps {
	transactionData: TransactionDataInterface;
}

// Register font
Font.register({
	family: "Quicksand",
	format: "truetype",
	src: "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap",
});

// Define styles
const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	printableContent: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		maxWidth: "536px",
		backgroundColor: "#fff",
		padding: "0 6px",
	},
	flex: {
		display: "flex",
	},
	flexRow: {
		flexDirection: "row",
	},
	halfWidth: {
		width: "50%",
	},
	itemsCenter: {
		alignItems: "center",
	},
	justifyBetween: {
		justifyContent: "space-between",
	},
	py5: {
		paddingTop: "20px",
		paddingBottom: "20px",
	},
	image: {
		height: "16px",
		width: "auto",
	},
	content: {
		marginTop: "12px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	heading: {
		textAlign: "center",
		fontSize: "19px",
		fontWeight: 600,
		color: "#061F6A",
		marginBottom: "16px",
	},
	summary: {
		marginTop: "32px",
		width: "100%",
		maxWidth: "400px",
	},
	sectionHeading: {
		fontSize: "12px",
		fontWeight: 600,
		color: "#061F6A",
		borderBottom: "1px solid #CBD5E1",
		paddingBottom: "12px",
		marginBottom: "12px",
	},
	label: {
		fontSize: "12px",
		fontWeight: 500,
		color: "#4B5563",
		textTransform: "capitalize",
	},
	labelBold: {
		fontSize: "12px",
		fontWeight: 700,
		color: "#4B5563",
		textTransform: "capitalize",
	},
	value: {
		fontSize: "14px",
		color: "#555",
	},
	readMe: {
		fontSize: "14px",
		fontWeight: "bold",
		color: "#0659FB",
		marginBottom: "12px",
	},
	readMeText: {
		fontSize: "12px",
		color: "#555",
	},
	dataPairContainer: {
		marginBottom: "16px",
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		borderBottom: "1px solid #ccc",
		paddingBottom: "32px",
	},
});

const PdfDataPair: React.FC<PdfDataPairProps> = ({ label, value, color }) => {
	const statusColor = (status: string) => {
		const str = status;
		const regex = /#([A-Fa-f0-9]{6})/;
		const match = str.match(regex);

		if (match && match[1]) {
			const hexCode = "#" + match[1];
			return hexCode;
		} else {
			return "";
		}
	};

	// const extractAmountFromNumberFormat = (numberFormatComponent: any) => {
	// 	const { children } = numberFormatComponent.props;
	// 	let amount = "";

	// 	// Check if children is an array and iterate over each child
	// 	if (Array.isArray(children)) {
	// 		children.forEach((child) => {
	// 			// Check if the child is a string and extract the amount
	// 			if (typeof child === "string") {
	// 				amount += child;
	// 			}
	// 		});
	// 	} else if (typeof children === "string") {
	// 		// If children is a string, directly assign it to amount
	// 		amount = children;
	// 	}

	// 	// Remove any HTML tags from the extracted amount
	// 	return amount.replace(/<\/?[^>]+(>|$)/g, "");
	// };

	const extractAmountFromNumberFormat = (numberFormatComponent: any) => {
		const { children } = numberFormatComponent.props;
		let amount = "";

		// Check if children is an array and iterate over each child
		if (Array.isArray(children)) {
			children.forEach((child) => {
				// Check if the child is a string and extract the amount
				if (typeof child === "string") {
					amount += child;
				} else if (
					typeof child === "object" &&
					child.props &&
					child.props.value
				) {
					// If the child is an object with a value prop, extract the value
					amount += child.props.value;
				}
			});
		} else if (typeof children === "string") {
			// If children is a string, directly assign it to amount
			amount = children;
		} else if (
			typeof children === "object" &&
			children.props &&
			children.props.value
		) {
			// If children is an object with a value prop, extract the value
			amount = children.props.value;
		}

		// Remove any HTML tags from the extracted amount
		return amount.replace(/<\/?[^>]+(>|$)/g, "");
	};

	return (
		<View style={[styles.flex, styles.flexRow]}>
			<View style={styles.halfWidth}>
				<Text style={styles.label}>{label}:</Text>
			</View>
			<View style={styles.halfWidth}>
				{label === "Status:" ? (
					<Text
						style={[
							styles.label,
							{ color: statusColor(color) ?? "inherit" },
						]}
					>
						{value ?? "N/A"}
					</Text>
				) : label === "Amount" ? (
					<Text
						debug
						style={[
							styles.label,
							{ color: statusColor(color) ?? "inherit" },
						]}
					>
						{/* {extractAmountFromNumberFormat( */}
						<NumberFormat value={value} />
						{/* // ) ?? "N/A"} */}
					</Text>
				) : label === "Transaction ID" ? (
					<Text style={[styles.labelBold]}>{value ?? "N/A"}</Text>
				) : (
					<Text style={styles.label}>{value ?? "N/A"}</Text>
				)}
			</View>
		</View>
	);
};

const TransactionReciept: React.FC<TransactionRecieptProps> = ({
	transactionData,
}) => (
	<Document>
		<Page size="A4" style={styles.body}>
			<View style={styles.printableContent}>
				<View
					style={[
						styles.flex,
						styles.itemsCenter,
						styles.justifyBetween,
						styles.py5,
					]}
				>
					<Image style={styles.image} src={Logo} />
				</View>
				<View style={styles.content}>
					<Text style={styles.heading}>Transaction Reciept</Text>
					<View style={styles.summary}>
						<Text style={styles.sectionHeading}>
							Transaction Summary
						</Text>

						<View style={styles.dataPairContainer}>
							<PdfDataPair
								label="Date:"
								value={formatDate(transactionData?.date)}
							/>
							<PdfDataPair
								label="Transaction ID"
								value={transactionData?.transaction_id}
							/>
							<PdfDataPair
								label="Retailer’s Name"
								value={transactionData?.retailer_name}
							/>
							<PdfDataPair
								label="Amount:"
								value={transactionData?.amount}
							/>
							<PdfDataPair
								label="Recipient Name"
								value={transactionData?.recipient_name}
							/>

							<PdfDataPair
								label="Recipient Bank"
								value={transactionData?.recipient_bank}
							/>
							<PdfDataPair
								label="Transaction Type"
								value={transactionData?.transaction_type}
							/>
							<PdfDataPair
								label="Status:"
								value={transactionData?.status}
								color={getStatusColor(
									transactionData?.status ?? "",
									"text"
								)}
							/>
							<PdfDataPair
								label="Narration"
								value={transactionData?.narration}
							/>
						</View>
					</View>
				</View>
			</View>
		</Page>
	</Document>
);

const delay = (t: any) => new Promise((resolve) => setTimeout(resolve, t));

const getProps = async (data: any) => {
	await delay(1000);
	return {
		transactionData: data,
	};
};

export const LazyDownloadPDFButton = ({ data }: any) => (
	<Button
		onClick={async () => {
			const props = await getProps(data);
			const doc = <TransactionReciept {...props} />;
			const asPdf = pdf([] as any);
			asPdf.updateContainer(doc);
			const blob = await asPdf.toBlob();
			saveAs(blob, "transaction_reciept.pdf");
		}}
		disabled={false}
		additionalClasses="mb-6 mt-3 block !w-auto rounded-lg border border-slate-200 bg-[#061F6A] px-4 py-2 text-lg font-semibold text-white duration-200 ease-in-out active:scale-95"
	>
		Download
	</Button>
);
