import { QueryFunctionContext } from "react-query";
import { request } from "../utils/axios-utils";

function isEmptyOrContainsOnlyFalsyValues(arr: any) {
	if (!Array.isArray(arr) || arr.length === 0) {
		return true;
	}
	return arr.every((item) => !item.trim());
}

export const getDistributorDashboardChartData = ({
	queryKey,
}: QueryFunctionContext) => {
	return request({
		method: "GET",
		url: `/dashboard/retailer/monthly-statistics/${
			!isEmptyOrContainsOnlyFalsyValues(queryKey)
				? "?year=" + queryKey
				: ""
		}`,
	});
};

export const getDistributorDashboardMetrics = () => {
	return request({
		method: "GET",
		url: `/dashboard/distributor/credit-dashboard/`,
	});
};
