import { useNavigate } from "react-router";
import Logo from "../assets/image/blackLogo.svg";
import ErrorImg from "../assets/image/Error404.svg";
import { useAuth } from "../hooks/auth";
const NoMatch = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	return (
		<div className="flex h-screen w-full flex-col bg-[#F8FAFC] px-4 md:px-12">
			<div className="flex items-center justify-between py-4 pb-4">
				<figure className="h-8 w-28">
					<img src={Logo} alt="" />
				</figure>
			</div>
			<div className="flex max-w-[600px] flex-col items-center gap-y-6 self-center rounded-lg border border-[#EAF5FF] bg-[#EAF5FF] bg-opacity-20 p-12">
				<figure className="w-full md:w-[400px]">
					<img src={ErrorImg} alt="" />
				</figure>
				<h1 className="text-2xl font-semibold text-gray-400">
					Oops! This Page cannot be found
				</h1>
				<button
					className="w-fit rounded-md border border-blue-800 px-4 py-2 text-lg text-blue-800"
					onClick={() => navigate(user ? "/dashboard" : "/")}
				>
					Go to Home Page
				</button>
			</div>
		</div>
	);
};

export default NoMatch;
