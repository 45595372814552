import { Toast } from "../../../utils/toast";
import { FormInput } from "../../../components/FormInput";
import {
	useLinkToQuickbooksCRM,
	useLinkToZohoCRM,
} from "../../../hooks/useAuthData";
import { useState } from "react";
import { checkCrmAvailability } from "../../../api/onboarding";
import { AxiosResponse } from "axios";
import { ERPData } from "../../../services/Constants";
import { Loader2 } from "../../../components/Loaders/Loader2";

const ERPLink = () => {
	const [crmLinkSuccess, setCrmLinkSuccess] = useState<boolean>(false);
	const [crmChoice, setCrmChoice] = useState("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isRevokeLoading, setIsRevokeLoading] = useState<boolean>(false);

	const onSuccess = (response: AxiosResponse) => {
		const CRMLink = response?.data.data.auth_url;
		window.open(CRMLink, "_blank");
	};

	const onError = () => {
		Toast.error("An error occurred, please try again later");
	};

	const { refetch: LinkToQuickbooksCrm, isLoading: isCrmLinkLoading } =
		useLinkToQuickbooksCRM(onSuccess, onError);
	const { refetch: LinkToZohoCrm } = useLinkToZohoCRM(onSuccess, onError);

	const processCrmChoice = async () => {
		if (crmChoice === "quick_book") {
			return await LinkToQuickbooksCrm();
		} else if (crmChoice === "zoho") {
			return await LinkToZohoCrm();
		} else {
			return Toast.error("Please select your CRM tool");
		}
	};

	const LinktoCRM = async () => {
		if (crmChoice === "") {
			return Toast.error("Please select your CRM tool");
		}
		setIsLoading(true);
		try {
			const crmCheckedData = await checkCrmAvailability();
			setIsLoading(false);
			if (crmCheckedData) {
				const { status } = crmCheckedData;
				if (status === 200) {
					setCrmLinkSuccess(true);
					Toast.success("CRM Linked already");
					return;
				} else if (status === 404) {
					processCrmChoice();
					return;
				} else {
					return Toast.error(
						"An error occurred, please try again later"
					);
				}
			}
		} catch (error) {
			return Toast.error("An Error Occurred in Linking CRM");
		}
	};

	const RevokeCRMAccess = () => {
		setIsRevokeLoading(true);
		setTimeout(() => {
			setIsRevokeLoading(false);
			Toast.success("This feature is not available yet");
		}, 2000);
	};

	return (
		<>
			<div className="mt-10 flex w-full flex-col gap-y-6 divide-y-2 px-8 ">
				<div>
					<div className="flex flex-col">
						<h2 className="text-xl font-semibold text-[#002060]">
							Link to ERP
						</h2>
						<p className="text-[16px] font-medium text-[#626D88]">
							Connect your ERP to access your customer details
						</p>
					</div>
					<div className="align-center mt-4 flex w-2/4 flex-nowrap gap-4 py-[12px] mxs:gap-4 mmd:flex-wrap">
						<div className="flex w-full">
							<FormInput
								label=""
								labelStyles="hidden"
								name="erp_link"
								id="erp_link"
								placeholder="Quick Books"
								value={crmChoice}
								placeholderStyleOptions={{
									opacity: 0.5,
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								type="text"
								onChange={(selectedOption) => {
									setCrmChoice(selectedOption.value);
								}}
								options={ERPData}
								autoComplete="false"
								required={false}
								select={true}
								selectStyleOptions={{
									border: "1.4px solid #CBD5E1",
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
									textAlign: "left",
									backgroundColor: "white",
								}}
							/>
						</div>
						<button
							disabled={
								isCrmLinkLoading || isLoading || crmLinkSuccess
							}
							onClick={LinktoCRM}
							className="flex h-10 w-fit items-center justify-center whitespace-nowrap rounded-md  bg-blue-950 px-8 py-4 text-white disabled:bg-opacity-60"
						>
							{isLoading ? (
								<Loader2 color="#fff" />
							) : (
								"Link to ERP"
							)}
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-y-6 pt-6">
					<div className="flex flex-col">
						<h2 className="text-xl font-semibold text-[#002060]">
							Disconnect ERP
						</h2>
						<p className="text-[16px] font-medium text-[#626D88]">
							Revoke your ERP access from HADI
						</p>
					</div>
					<div>
						<button
							disabled={true}
							onClick={RevokeCRMAccess}
							className="flex h-10 w-fit items-center justify-center whitespace-nowrap rounded-md  bg-red-600 px-4 py-4 text-white disabled:bg-opacity-60"
						>
							{isRevokeLoading ? (
								<Loader2 color="#fff" />
							) : (
								"Revoke access"
							)}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ERPLink;
{
	/* <div className="align-center flex flex-col justify-center rounded-md border border-gray-300 bg-[#F1F5F980] px-[20px] py-[30px] text-center xs:w-[60%] mmd:w-[90%]">
					<h1 className="text-[24px] font-semibold text-[#002060]">
						Link to ERP
					</h1>
					<p className="text-[16px] font-medium text-[#626D88]">
						Add your CRM to access customers Details
					</p>
					<div className="align-center mt-[20px] flex w-full flex-nowrap gap-4 px-[10px] py-[12px] mxs:gap-4 mmd:flex-wrap">
						<div className="flex w-full">
							<FormInput
								label=""
								labelStyles="hidden"
								name="erp_link"
								id="erp_link"
								placeholder="Quick Books"
								placeholderStyleOptions={{
									opacity: 0.5,
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								type="text"
								onChange={() => {
									null;
								}}
								options={ERPData}
								autoComplete="false"
								required={false}
								select={true}
								selectStyleOptions={{
									border: "1.4px solid #CBD5E1",
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
									textAlign: "left",
									backgroundColor: "white",
								}}
							/>
						</div>
						<button
							disabled={
								isCrmLinkLoading ||
								crmCheckLoading ||
								crmLinkSuccess
							}
							onClick={LinktoCRM}
							className="flex h-10 w-fit items-center justify-center whitespace-nowrap rounded-md  bg-blue-950 px-8 py-4 text-white disabled:bg-opacity-60"
						>
							{isLoading ? (
								<Loader2 color="#fff" />
							) : (
								"Link to ERP"
							)}
						</button>
					</div>
				</div> */
}
