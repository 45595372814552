import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { CreditFormData } from "../types/views";
import {
	getAllRepayments,
	getRepayment,
	payInstallment,
} from "../api/repayment";

export const useGetAllRepaymentsData = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (error: AxiosError) => void
) => {
	return useQuery("repayments", getAllRepayments, {
		enabled: true,
		onSuccess,
		onError,
	});
};

export const useGetRepaymentData = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void,
	id: string
) => {
	return useQuery<AxiosResponse<any>, AxiosError<any>, any>(
		["repayment", id],
		getRepayment,
		{
			onSuccess,
			onError,
		}
	);
};

export const useInstallmentPayment = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void = () => {
		return;
	},
	id: string
) => {
	const queryClient = useQueryClient();
	const { mutate, ...rest } = useMutation<
		AxiosResponse<any, any>,
		AxiosError<any, any>,
		any,
		unknown
	>((formData) => payInstallment(formData, id), {
		onError,
		onMutate: (formData: CreditFormData | null) => {
			return formData;
		},
		onSuccess: (data: AxiosResponse) => {
			queryClient.invalidateQueries("repayment");
			onSuccess(data);
		},
	});

	return { mutatePayInstallment: mutate, ...rest };
};
