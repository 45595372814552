import React, { useEffect, useState } from "react";
import Logo from "../assets/image/blackLogo.svg";
import { Icon as Iconify } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccountConfirm } from "../hooks/useAuthData";
const VerifyEmail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [verifySuccess, setVerifySuccess] = useState(false);
	const [verifyError, setVerifyError] = useState(false);

	const onSuccess = () => {
		setVerifySuccess(true);
		setTimeout(() => {
			navigate("/login");
		}, 4000);
	};
	const onError = () => {
		setVerifyError(true);
		setTimeout(() => {
			navigate("/login");
		}, 4000);
	};

	const { mutate: accountConfirmData } = useAccountConfirm(
		onSuccess,
		onError
	);

	useEffect(() => {
		const { pathname } = location;
		const token = pathname.split("/")[2];
		if (!token) {
			navigate("/login");
		}
		accountConfirmData({ key: token });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<>
			{verifySuccess && (
				<section className="flex h-screen flex-col">
					<div className="">
						<div className="px-12 py-3">
							<figure className="h-8 w-28">
								<img src={Logo} alt="hadi logo" />
							</figure>
						</div>

						<div className="flex h-[500px] flex-col items-center justify-center">
							<figure className="flex h-[170px] w-[170px] items-center justify-center rounded-full bg-[#002060]">
								<Iconify
									icon="fluent:checkmark-32-filled"
									color="white"
									className="h-14 w-14"
								/>
							</figure>
							<p className="mt-[20px] text-[24px] font-[600] text-blue-950">
								Email Verification
							</p>
							<p className="mt-[15px] text-center text-[20px] text-slate-400">
								Your email was verified successfully. <br /> you
								would be redirected to login.
							</p>
						</div>
					</div>
				</section>
			)}

			{verifyError && (
				<section className="flex h-screen flex-col">
					<div className="">
						<div className="px-12 py-3">
							<figure className="h-8 w-28">
								<img src={Logo} alt="hadi logo" />
							</figure>
						</div>

						<div className="flex h-[500px] flex-col items-center justify-center">
							<figure className="flex h-[170px] w-[170px] items-center justify-center rounded-full bg-[red]">
								<Iconify
									icon="iconoir:cancel"
									color="white"
									className="h-16 w-16"
								/>
							</figure>
							<p className="mt-[20px] text-[24px] font-[600] text-blue-950">
								Email Verification
							</p>
							<p className="mt-[15px] text-center text-[20px] text-slate-400">
								Your email token has expired.
							</p>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default VerifyEmail;
