import { useState } from "react";
import { Toast } from "utils/toast";
import { Patterns } from "services/Constants";
import { useFieldAgent } from "hooks/useAuthData";
import { AxiosError } from "axios";

export const useFetchCodes = () => {
	const [formData, setFormData] = useState({ phone_number: "" });
	const [showData, setShowData] = useState(false);
	const [activationCode, setActivationCode] = useState("");
	const [referralCode, setReferralCode] = useState("");

	const formValidation = () => {
		if (!Patterns.telephone.test(formData.phone_number)) {
			Toast.info("Input your phone number");
			return false;
		}
		return true;
	};

	const OnSuccess = (data) => {
		setShowData(true);
		setActivationCode(data?.data?.pin);
		setReferralCode(data?.data?.referral_code);
	};

	const OnError = (error: AxiosError) => {
		Toast.error(error.message || "An error occurred, please try again");
	};

	const { mutatefieldAgent, isLoading } = useFieldAgent(OnSuccess, OnError);

	const handleGetCodes = () => {
		if (!formValidation()) {
			return;
		}
		const fieldAgentData = { phone_number: formData.phone_number };
		mutatefieldAgent({ data: fieldAgentData });
	};

	return {
		formData,
		setFormData,
		showData,
		activationCode,
		referralCode,
		handleGetCodes,
		isLoading,
	};
};
