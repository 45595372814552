import Dashboard from "../views/Dashboard";
import React from "react";
import { Route } from "react-router-dom";
import ComingSoonImage from "../assets/image/dashboard/coming-soon.svg";

const ComingSoon = () => {
	return (
		<>
			<section className="flex h-full w-full flex-1 items-center justify-center">
				<div className="flex w-full max-w-[516px] flex-col items-center gap-8">
					<figure className="flex w-full flex-col items-center md:max-w-[50%]">
						<img
							src={ComingSoonImage}
							height={238}
							width={192}
							alt={"Coming Soon"}
						/>
					</figure>
					<div className="flex w-full max-w-[470px] flex-col items-center gap-2.5 text-center">
						<div className="flex flex-col gap-2.5">
							<h3 className="text-sm font-medium text-slate-700 sm:text-lg">
								We&apos;re still
							</h3>
							<h2 className="text-xl font-bold text-blue-950 sm:text-3xl">
								Cooking on our Platform
							</h2>
							<h3 className="text-sm sm:text-lg">
								This feature is coming soon
							</h3>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ComingSoon;
