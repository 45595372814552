import { Loader2 } from "../../components/Loaders";

interface WrapperProps {
	className: string;
	fetching: boolean;
	working: boolean;
	children: React.ReactNode;
}
const Wrapper = ({
	className,
	fetching,
	working = false,
	children,
}: WrapperProps) => {
	return (
		<div className={className}>
			{fetching ? (
				<div className="flex items-center justify-center">
					<Loader2 color="#002060" />
				</div>
			) : (
				<>{children}</>
				// <Spin spinning={working} indicator={antIcon}>
				// 	{children}
				// </Spin>
			)}
		</div>
	);
};

export default Wrapper;
