import { toast, ToastOptions } from "react-toastify";

const toastConfig: ToastOptions = {
	position: "top-right",
	autoClose: 3000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
};

export const Toast = {
	success: (message: string, params?: { onClick?: () => void }) => {
		toast.dismiss();
		return toast.success(message, {
			...toastConfig,
			theme: "colored",
			onClick: params?.onClick,
		});
	},
	info: (message: string, params?: { onClick?: () => void }) => {
		toast.dismiss();
		return toast.info(message, {
			...toastConfig,
			onClick: params?.onClick,
		});
	},
	warning: (message: string, params?: { onClick: () => void }) => {
		toast.dismiss();
		return toast.warning(message, {
			...toastConfig,
			onClick: params?.onClick,
		});
	},
	error: (message: string, params?: { onClick: () => void }) => {
		toast.dismiss();
		return toast.error(message, {
			...toastConfig,
			theme: "colored",
			onClick: params?.onClick,
		});
	},
};
