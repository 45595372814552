import { ColumnDef } from "@tanstack/react-table";
import { StatusType } from "../../../types/utils";
import { formatDate, getStatusColor } from "../../../utils/utilities";
import NumberFormat from "../../../utils/NumberFormat";
import { DataTableColumnHeader } from "../../Table/DateColumnHeader";

export type DashboardCreditHistory = {
	id: string;
	amount: string;
	created: string;
	status: StatusType;
	due_date: null;
	retailer_name: string;
	repayment: string;
};

export const RecentCreditTableColumn: ColumnDef<DashboardCreditHistory>[] = [
	{
		accessorKey: "created",
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Date" />
		),
		cell: ({ row }) => {
			return (
				<p className="px-2">
					{formatDate(row.original.created) ?? "N/A"}
				</p>
			);
		},
	},
	{
		accessorKey: "retailer_name",
		accessorFn: (row) => row.retailer_name,
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Retailer Name" />
		),
		cell: ({ row }) => {
			return (
				<p className="px-2">
					{row.original.retailer_name
						? row.original.retailer_name
						: "N/A"}
				</p>
			);
		},
	},
	{
		accessorKey: "amount",
		header: "Amount",
		cell: ({ row }) => {
			return <NumberFormat value={row.original.amount} /> ?? "N/A";
		},
	},
	{
		accessorKey: "repayment",
		header: "Repayment",
		cell: ({ row }) => {
			return row.original.repayment ? (
				<NumberFormat value={row.original.repayment} />
			) : (
				"N/A"
			);
		},
	},
	{
		accessorKey: "due_date",
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Repayment date" />
		),
		cell: ({ row }) => {
			return (
				<p className="px-2">
					{row.original.due_date
						? formatDate(row.original.due_date)
						: "N/A"}
				</p>
			);
		},
	},
	{
		accessorKey: "status",
		header: "Status",
		cell: ({ row }) => {
			return row.original.status ? (
				<div className={`flex items-center gap-2`}>
					<div
						className={`flex h-3.5 w-3.5 items-center justify-center rounded-full bg-opacity-50 p-1 ${getStatusColor(
							row.original?.status,
							"background"
						)}`}
					>
						<span
							className={`h-1 w-1 rounded-full ${getStatusColor(
								row.original?.status,
								"background"
							)}`}
						></span>
					</div>
					<p className={`font-semibold capitalize`}>
						{row.original?.status}
					</p>
				</div>
			) : (
				<p className="font-semibold">N/A</p>
			);
		},
	},
];
