import React, { Dispatch, SetStateAction, useRef } from "react";
import { Overlay } from "../..";
import { MdOutlineClose } from "react-icons/md";
import { letter_profile_icon, toTitleCase } from "../../../utils/utilities";
import { RiDeleteBinFill } from "react-icons/ri";
import { BeneficiaryData } from "./BankTransfer";
import { useDeleteBeneficiary } from "../../../hooks/useWalletData";
import RequestIsLoading from "../../RequestIsLoading";
import { Toast } from "../../../utils/toast";

interface AllSavedRecipientsProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	data: BeneficiaryData[];
}

const AllSavedRecipients: React.FC<AllSavedRecipientsProps> = ({
	data,
	show,
	setShow,
}) => {
	const modalRef = useRef(null);

	const onSuccess = () => {
		Toast.success("Beneficiary has been deleted successfully 🎉");
		setShow(false);
	};
	const onError = () => {
		Toast.error("An error occured. Kindly try again later 🤕");
	};

	const deleteBeneficiaryMutation = useDeleteBeneficiary(onSuccess, onError);

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef}>
			{show && (
				<section
					className="relative flex w-full max-w-[488px] flex-col items-center rounded-[10px] bg-white px-8 py-7"
					ref={modalRef}
				>
					<figure
						className="cursor-pointer self-end rounded-full p-2.5 duration-300 ease-in-out hover:bg-slate-300"
						onClick={() => setShow(false)}
					>
						<MdOutlineClose size={24} />
					</figure>
					<div className="flex flex-col space-y-4 text-center">
						<h2 className="text-2xl font-medium text-blue-950">
							Saved Recipients
						</h2>
						<p className="font-medium text-[#87898E]">
							Remove and make quick transfers from the number of
							persons you have saved during transactions
						</p>
					</div>
					<ul className="mt-4 max-h-[340px] w-full overflow-auto">
						{data.map((beneficiary, i) => (
							<AllSavedRecipientsItem
								key={i}
								beneficiaryData={beneficiary}
								onDelete={() => {
									deleteBeneficiaryMutation.mutate(
										beneficiary.account_number
									);
								}}
							/>
						))}
					</ul>
					<RequestIsLoading
						isLoading={deleteBeneficiaryMutation.isLoading}
					/>
				</section>
			)}
		</Overlay>
	);
};

export default AllSavedRecipients;

interface AllSavedRecipientsItemProps {
	beneficiaryData: BeneficiaryData;
	onDelete: () => void;
}

const AllSavedRecipientsItem: React.FC<AllSavedRecipientsItemProps> = ({
	beneficiaryData,
	onDelete,
}) => {
	return (
		<li className="flex w-full items-center space-x-2 border-b-[2px] border-b-slate-100 px-2 py-2 duration-300 ease-in-out last:border-0 hover:bg-slate-100">
			<figure className="flex h-7 w-7 select-none items-center justify-center rounded-full bg-[#86AEFF] bg-opacity-20 text-sm font-medium">
				{letter_profile_icon(beneficiaryData.account_name)}
			</figure>
			<div className="flex-1">
				<h5 className="text-blue-950">
					{toTitleCase(beneficiaryData.account_name)}
				</h5>
				<p className="flex items-center space-x-2 text-sm">
					<span>{beneficiaryData.bank_name}</span>
					<span>•</span>
					<span>{beneficiaryData.account_number}</span>
				</p>
			</div>
			<RiDeleteBinFill
				color="#BA1A1A"
				size={24}
				className="cursor-pointer"
				onClick={onDelete}
			/>
		</li>
	);
};
