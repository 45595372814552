import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../assets/image/blackLogo.svg";
import { RequestSuccessFailProps } from "../../types/components";
import {
	Button,
	FormInput,
	Loader2,
	ProcessUploadFile,
	RequestSuccessFail,
	UploadedFile,
} from "../../components";
import {
	toTitleCase,
	useQueryParams,
	base64ToBlob,
} from "../../utils/utilities";
import { Toast } from "../../utils/toast";
import {
	useGuarantorAccept,
	useGuarantorDeclineCredit,
	useGuarantorVerify,
} from "../../hooks/useCreditData";
import { uploadedFileContainerVariants } from "../../services/Animations";
import { guarantorAcceptData } from "../../types/hooks/credit";
import { EmploymentStatus, SalaryRange } from "../../services/Constants";
import { AxiosError } from "axios";

const guarantorFormVerification: (
	formData: guarantorAcceptData,
	acceptTerms: boolean
) => boolean = (formData, acceptTerms) => {
	if (!formData.employment_status) {
		Toast.error("Kindly select your employment status");
		return false;
	}
	if (!formData.place_of_work) {
		Toast.error("Kindly enter your place of work");
		return false;
	}
	if (!formData.salary_range) {
		Toast.error("Kindly select your salary range");
		return false;
	}
	// if (!formData.signature) {
	// 	Toast.error("Kindly upload your signature photo");
	// 	return false;
	// }
	if (!acceptTerms) {
		Toast.error("Kindly accept the terms and conditions to proceed");
		return false;
	}
	return true;
};

const GuarantorAccept: React.FC = () => {
	const [show, setShow] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [code, setCode] = useState<string | null>(null);

	const [formData, setFormData] = useState<{
		employment_status: string;
		place_of_work: string;
		salary_range: string;
		signature: string | null;
		signatureName: string;
	}>({
		employment_status: "",
		place_of_work: "",
		salary_range: "",
		signature: "",
		signatureName: "",
	});

	const [requestFormData, setRequestFormData] =
		useState<RequestSuccessFailProps>({
			state: false,
			title: "",
			subtitle: "",
		});

	const query = useQueryParams();

	const declineCreditOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Credit Declined",
			subtitle: "Credit has been successfully declined.",
		});
		setShow(true);
	};

	const declineCreditOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The credit could not be declined.",
		});
		setShow(true);
	};

	const guarantorAcceptOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Successful!",
			subtitle:
				"Congratulations, your guarantor authorisation has been accepted!",
		});
		setShow(true);
		localStorage.removeItem("signaturePhoto");
		localStorage.removeItem("signaturePhotoName");
	};

	const guarantorAcceptOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The guarantor authorisation could not be accepted!",
		});
		setShow(true);
	};

	const verifyGuarantorOnError = (error: AxiosError) => {
		switch (error?.response?.status) {
			case 404:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;

			default:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;
		}

		setShow(true);
	};

	const {
		mutateGuarantorVerify,
		isLoading: verifyLoading,
		data: guarantorData,
	} = useGuarantorVerify(undefined, verifyGuarantorOnError);

	const { mutateGuarantorDeclineCredit, isLoading: declineIsLoading } =
		useGuarantorDeclineCredit(declineCreditOnSuccess, declineCreditOnError);

	const { mutateGuarantorAccept, isLoading: acceptLoading } =
		useGuarantorAccept(
			guarantorAcceptOnSuccess,
			guarantorAcceptOnError,
			code
		);

	const handleGuarantorAccept = () => {
		if (
			guarantorFormVerification(formData, acceptTerms) &&
			formData.signature
		) {
			const newFormData: FormData = new FormData();
			newFormData.append("place_of_work", formData.place_of_work);
			newFormData.append("salary_range", formData.salary_range);
			newFormData.append("employment_status", formData.employment_status);
			newFormData.append("signature", base64ToBlob(formData.signature));
			mutateGuarantorAccept(newFormData);
		}
	};

	useEffect(() => {
		const codeParam = query.get("auth");
		const requestTypeParam = query.get("distributor");

		if (codeParam) {
			setCode(codeParam);
			mutateGuarantorVerify([codeParam, requestTypeParam]);
		}
	}, [query]);

	useEffect(() => {
		if (verifyLoading) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [verifyLoading]);

	return (
		<main className="mx-auto flex flex-col items-center pb-8 pt-5">
			<div className="flex items-center justify-center p-3">
				<figure className="h-8 w-28">
					<img src={Logo} alt="hadi logo" />
				</figure>
			</div>
			{!show ? (
				<section className="flex w-full max-w-[640px] flex-col gap-[18px] px-5">
					<div>
						<header className="relative">
							<h3 className="mx-auto px-5 text-center text-2xl font-semibold text-blue-950">
								Guarantor Accept
							</h3>
						</header>
						<div className="relative mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
							<div
								style={{
									visibility: verifyLoading
										? "hidden"
										: "initial",
								}}
								className="flex flex-col gap-2"
							>
								<p>
									Full Name:{" "}
									{!verifyLoading &&
										guarantorData?.data?.data &&
										guarantorData?.data?.data?.name}
								</p>
								<p>
									Phone Number:{" "}
									{!verifyLoading &&
										guarantorData?.data?.data &&
										guarantorData?.data?.data
											?.contact_phone}
								</p>
								<p>
									Address:{" "}
									{!verifyLoading &&
										guarantorData?.data?.data &&
										guarantorData?.data?.data?.address}
								</p>
								<p>
									Relationship:{" "}
									{!verifyLoading &&
										guarantorData?.data?.data &&
										toTitleCase(
											guarantorData?.data?.data
												?.relationship
										)}
								</p>
								<p>
									Repayment Date: To be determined after
									delivery
								</p>
							</div>
							<div
								className={`absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 ${
									!verifyLoading ? "hidden" : ""
								}`}
							>
								<Loader2 color="#061F6A" />
							</div>
						</div>
					</div>
					<form
						className="rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter"
						encType="multipart/form-data"
					>
						<h4 className="text-[24px] font-semibold text-blue-950">
							Guarantor
						</h4>
						<p className="w-full text-[14px] text-slate-600">
							Please enter correct guarantor details before
							authorising the credit application
						</p>

						<form className="space-y-4 pt-3">
							<FormInput
								select={true}
								selectStyleOptions={{
									border: "1.4px solid #CBD5E1",
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								options={EmploymentStatus}
								label="Employment Status"
								name="employment_status"
								id="employment_status"
								type="select"
								placeholder="Select from drop-down"
								placeholderStyleOptions={{
									opacity: 0.5,
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								autoComplete={"true"}
								additionalClasses="text-black rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none"
								required={true}
								value={formData.employment_status}
								onChange={(employment_status) => {
									setFormData((prevData) => {
										return {
											...prevData,
											employment_status:
												employment_status.value,
										};
									});
								}}
							/>
							<FormInput
								label="Place Of Work"
								name="place_of_work"
								id="place_of_work"
								type="text"
								placeholder="Enter Name Of Employer"
								placeholderStyleOptions={{ opacity: 0.5 }}
								autoComplete={"true"}
								additionalClasses="text-black rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none text-sm sm:text-base"
								required={true}
								value={formData.place_of_work}
								onChange={(e) => {
									setFormData((prevData) => {
										return {
											...prevData,
											place_of_work: e.target.value,
										};
									});
								}}
							/>
							<FormInput
								select={true}
								selectStyleOptions={{
									border: "1.4px solid #CBD5E1",
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								options={SalaryRange}
								label="Salary Range"
								name="salary_range"
								id="salary_range"
								type="select"
								placeholder="Select from drop-down"
								placeholderStyleOptions={{
									opacity: 0.5,
									fontWeight: 300,
									paddingLeft: "4px",
									fontSize: "14px",
								}}
								autoComplete={"true"}
								additionalClasses="text-[#263238] rounded-md border border-slate-300 bg-transparent px-4 py-2 font-light outline-none"
								required={true}
								value={formData.salary_range}
								onChange={(salary_range) => {
									setFormData((prevData) => {
										return {
											...prevData,
											salary_range: salary_range.value,
										};
									});
								}}
							/>
							<div>
								<div className="flex flex-col gap-2">
									<label
										htmlFor="digital_signature"
										className="font-semibold text-blue-950"
									>
										Digital Signature
									</label>
									<p className="text-sm text-slate-600">
										Upload an image or document of your full
										legal name, Signature and date
									</p>
								</div>
								<ProcessUploadFile
									formats={[
										"jpeg",
										"jpg",
										"png",
										"pdf",
										"doc",
										"docx",
									]}
									maxSize={2 * 1024 * 1024} // 2000Kb
									setFile={setFormData}
									localStorageFileName={"signature"}
								/>
								<AnimatePresence>
									{formData.signature && (
										<motion.div
											className="mt-3 flex w-full flex-col gap-2"
											variants={
												uploadedFileContainerVariants
											}
											initial="hidden"
											animate="visible"
											exit="exit"
										>
											<h4 className="w-full text-[#676767]">
												Uploaded file(s)
											</h4>
											<UploadedFile
												title={formData.signatureName}
												deleteFile={() => {
													setFormData(
														(prevData: any) => ({
															...prevData,
															signature: null,
														})
													);
												}}
											/>
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</form>
					</form>
					<div
						className="my-2 flex gap-2 text-[14px] text-slate-600"
						onClick={() => {
							setAcceptTerms(!acceptTerms);
						}}
					>
						<input
							type="checkbox"
							className="h-4 w-4 flex-shrink-0 cursor-pointer border"
							checked={acceptTerms}
							onChange={() => setAcceptTerms(!acceptTerms)}
						/>
						<label
							htmlFor="checkBoxGuarantor"
							className="cursor-pointer text-sm leading-5"
						>
							I hereby confirm my acceptance of the{" "}
							<span className="text-blue-800">
								Terms and Conditions
							</span>{" "}
							outlined in the agreement. I am fully aware of the
							responsibilities and commitments involved, and I am
							eager to proceed as agreed. Kindly consider this as
							my official acceptance. Thank you!
						</label>
					</div>
					<div className="flex items-center justify-between text-[14px]">
						<Button
							disabled={declineIsLoading}
							onClick={() => {
								if (code) mutateGuarantorDeclineCredit(code);
							}}
							additionalClasses="rounded-[4px] bg-[#D52500] py-2 font-normal text-white max-w-[136px] text-sm"
						>
							{declineIsLoading ? <Loader2 /> : "Decline"}
						</Button>
						<Button
							disabled={acceptLoading}
							onClick={() => {
								if (
									guarantorFormVerification(
										formData,
										acceptTerms
									)
								) {
									handleGuarantorAccept();
								}
							}}
							additionalClasses="rounded-[4px] border border-[#BABFC3] px-12 py-2 text-[14px] text-[#202223] max-w-[136px] bg-white"
						>
							{acceptLoading ? (
								<Loader2 color="#061F6A" />
							) : (
								"Accept"
							)}
						</Button>
					</div>
					<div
						className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 ${
							!verifyLoading ? "hidden" : ""
						}`}
					>
						<Loader2 color="#061F6A" />
					</div>
				</section>
			) : (
				<RequestSuccessFail
					state={requestFormData.state}
					title={requestFormData.title}
					subtitle={requestFormData.subtitle}
				/>
			)}
		</main>
	);
};
export default GuarantorAccept;
