import React, { useEffect, useState } from "react";
// import { TopBar } from "../../../components";
import {
	useGetAllNotifications,
	useGetReadNotifications,
	useGetUnreadNotifications,
} from "../../../hooks/useCustomerData";
import { Toast } from "../../../utils/toast";
import NotificationsContent from "components/Dashboard/Notifications/NotificationsContent";

const Notifications: React.FC = () => {
	const [tab, setTab] = useState(0);
	const [unSeenNotificationData, setUnSeenNotificationData] = useState<any[]>(
		[]
	);
	const [seenNotificationData, setSeenNotificationData] = useState<any[]>([]);
	const [allNotificationData, setAllNotificationData] = useState<any[]>([]);

	const {
		data: unReadData,
		isLoading: isUnReadLoading,
		isSuccess: isUnReadSuccess,
		// isError,
	} = useGetUnreadNotifications(undefined, () => {
		Toast.error("Error getting notifications");
	});

	const {
		data: seenData,
		// isLoading: isSeenLoading,
		isSuccess: isSeenSuccess,
		// isError: isSeenError,
	} = useGetReadNotifications(undefined, () => {
		Toast.error("Error getting notifications");
	});

	const {
		data: allData,
		// isLoading: isAllLoading,
		isSuccess: isAllSuccess,
		// isError: isAllError,
	} = useGetAllNotifications(undefined, () => {
		Toast.error("Error getting notifications");
	});

	useEffect(() => {
		if (isSeenSuccess && seenData) {
			setSeenNotificationData(seenData?.data?.data);
		}
	}, [isSeenSuccess, seenData]);

	useEffect(() => {
		if (isAllSuccess && allData) {
			setAllNotificationData(allData?.data?.data);
		}
	}, [isAllSuccess, allData]);

	// const handleChangeTab = (tabName: string) => {
	// 	switch (tabName) {
	// 		case "unread":
	// 			setTab(0);
	// 			break;
	// 		case "read":
	// 			setTab(1);
	// 			break;
	// 		case "all":
	// 			setTab(2);
	// 			break;
	// 		default:
	// 			setTab(0);
	// 	}
	// };
	useEffect(() => {
		if (isUnReadSuccess && unReadData) {
			setUnSeenNotificationData(unReadData?.data?.data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUnReadSuccess]);

	return (
		<main className="flex h-full overflow-y-hidden">
			{/* <TopBar pageName="Profile Screen" /> */}
			{/* <div className="mt-2 box-content max-w-[932px] cursor-pointer border-b border-b-slate-200 px-5 md:ml-4 md:px-7 mmd:mx-4">
				<div className="flex gap-4">
					<button
						className={`p-2 ${
							tab === 0 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("unread")}
					>
						Unread
					</button>

					<button
						className={`p-2 ${
							tab === 1 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("read")}
					>
						Read
					</button>
					<button
						className={`p-2 ${
							tab === 2 ? "border-b  border-b-blue-950" : ""
						}`}
						onClick={() => handleChangeTab("all")}
					>
						All
					</button>
				</div>
			</div> */}

			{/* {tab === 0 && ( */}
			<NotificationsContent
				notificationData={unSeenNotificationData}
				notificationLoading={isUnReadLoading}
				nullMessage={"No unread notifications"}
				tab={tab}
				setNewSeenNotifications={setUnSeenNotificationData}
			/>
			{/* )} */}

			{/* {tab === 1 && (
				<NotificationsContent
					notificationData={seenNotificationData}
					notificationLoading={isSeenLoading}
					nullMessage={"All notifications have been read"}
					tab={tab}
				/>
			)}

			{tab === 2 && (
				<NotificationsContent
					notificationData={allNotificationData}
					notificationLoading={isAllLoading}
					nullMessage={"No notification has been made"}
					tab={tab}
				/>
			)} */}
		</main>
	);
};

export default Notifications;
