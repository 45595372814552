import { useState, useEffect } from "react";
import { MonoConnectComponent } from "../../../../components/MonoConnectComponent";
import CreditApplicationWrapper from "../../../../components/Dashboard/CreditApplications/CreditApplicationWrapper";
import StepCounter from "components/Dashboard/CreditApplications/StepCounter";
import HomeScreen from "components/Dashboard/CreditApplications/HomeScreen";
import { useGetAllBanks } from "hooks/useWalletData";
import {
	DistributorCreditFormData,
	distributorCreditPageNumbers,
} from "types/components/dashboard/distributor";
import {
	DistributorApplyForm,
	DistributorGuarantorForm1,
	DistributorGuarantorForm2,
	DistributorKinForm,
	SuccessModal,
} from "components";
import { useApplyForDistributorCredit } from "hooks/useCreditData";
import { AxiosError } from "axios";
import { Toast } from "utils/toast";
import { Patterns } from "services/Constants";
import {
	isSimilarName,
	isSimilarPhoneNumber,
	isValidAddress,
	isValidName,
} from "utils/validation";
import { useNavigate } from "react-router";
import { distributorDefaultCreditFormData } from "utils/data/initialFormsData";
import { ErrorResponse } from "types/general";
import { base64ToBlob } from "utils/utilities";

const DistributorCreditApplication = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState<DistributorCreditFormData>(
		distributorDefaultCreditFormData
	);
	const processes = ["Onboarding", "Apply For Credit", "Confirm Details"];
	const [step, setStep] = useState<number | 0 | 1 | 2>(0);
	const [page, setPage] = useState<distributorCreditPageNumbers>(0);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showMonoModal, setShowMonoModal] = useState<boolean>(false);
	const getAllBanksQuery = useGetAllBanks(undefined, undefined);
	const [bankData, setBankData] = useState<
		{ value: string; label: string }[]
	>([]);
	useEffect(() => {
		const modifiedBankData = getAllBanksQuery?.data?.data?.data?.map(
			(bank) => ({
				value: bank?.bank_code,
				label: bank?.bank_name,
			})
		);

		if (modifiedBankData) {
			setBankData(modifiedBankData);
		}
	}, [getAllBanksQuery?.data?.data?.data]);

	const onSuccess = () => {
		setShowSuccessModal(true);
	};
	const onError = (error: AxiosError) => {
		const { data } = error.response;
		if (data && typeof data === "object" && "message" in data) {
			const errorMessage = (data as ErrorResponse).message;
			Toast.error(errorMessage);
		} else {
			Toast.error("An error occurred in form submission");
		}
	};
	const onMonoSuccess = () => {
		//Submit the form after mono success
		if (formData) {
			const distributorForm = new FormData();
			distributorForm.append(
				"credit.amount",
				formData.credit_amount.replace(/,/g, "")
			);
			// if (formData.invoice_file) {
			// 	distributorForm.append(
			// 		"credit.invoice.invoice",
			// 		base64ToBlob(formData.invoice_file)
			// 	);
			// }
			if (formData.bank_statement_file) {
				distributorForm.append(
					"credit.bank_statement.statement",
					base64ToBlob(formData.bank_statement_file)
				);
			}
			distributorForm.append(
				"credit.bank_statement.bank_code",
				formData.bank_code
			);
			distributorForm.append(
				"credit.invoice.total_amount",
				formData.credit_amount.replace(/,/g, "")
			);
			distributorForm.append("credit.tenure", formData.tenure);
			distributorForm.append("guarantor1.name", formData.guarantor1.name);
			distributorForm.append(
				"guarantor1.address",
				formData.guarantor1.address
			);
			distributorForm.append(
				"guarantor1.contact_phone",
				formData.guarantor1.phone
			);
			distributorForm.append("guarantor2.name", formData.guarantor2.name);
			distributorForm.append(
				"guarantor2.address",
				formData.guarantor2.address
			);
			distributorForm.append(
				"guarantor2.contact_phone",
				formData.guarantor2.phone
			);
			distributorForm.append(
				"next_of_kin.name",
				formData.next_of_kin.name
			);
			distributorForm.append(
				"next_of_kin.address",
				formData.next_of_kin.name
			);
			distributorForm.append(
				"next_of_kin.contact_phone",
				formData.next_of_kin.phone
			);
			distributorForm.append(
				"next_of_kin.relationship",
				formData.next_of_kin.relationship
			);
			mutateApplyForDistributorCredit(distributorForm);
		} else {
			Toast.error(
				"Form details are missing or there is an error with your form"
			);
		}
	};

	const handleFormSubmit = (e: React.FormEvent<HTMLElement>) => {
		e.preventDefault();
		if (finalFormValidation(formData)) {
			setShowMonoModal(true);
		} else {
			return;
		}
	};
	const { mutateApplyForDistributorCredit, isLoading } =
		useApplyForDistributorCredit(onSuccess, onError);

	function finalFormValidation(formData: DistributorCreditFormData): boolean {
		/**
		 * g1 - guarantor1
		 * g2 - guarantor2
		 * kin - next of kin
		 */
		const { name: g1_name, phone: g1_phone } = formData.guarantor1;
		const { name: g2_name, phone: g2_phone } = formData.guarantor2;
		const {
			name: kin_name,
			address: kin_address,
			phone: kin_phone,
			relationship: kin_relationship,
		} = formData.next_of_kin;

		if (!isValidName(kin_name)) {
			Toast.info("Next of kin's name is not valid");
			return false;
		}
		if (!isValidAddress(kin_address)) {
			Toast.info("Next of kin's address is not valid");
			return false;
		}
		if (!isSimilarName(g1_name, g2_name, kin_name)) {
			Toast.info("Next of Kin's name cannot be same as guarantors");
			return false;
		}
		if (!Patterns.telephone.test(kin_phone)) {
			Toast.info("Next of Kin's phone number is not valid");
			return false;
		}
		if (!isSimilarPhoneNumber(g1_phone, g2_phone, kin_phone)) {
			Toast.info(
				"Next of Kin cannot have same phone number as guarantors"
			);
			return false;
		}
		if (!kin_relationship) {
			Toast.info("Select relationship to kin");
			return false;
		}
		return true;
	}
	return (
		<>
			<CreditApplicationWrapper name="Credit for me">
				<>
					<StepCounter
						step={step}
						setStep={setStep}
						processes={processes}
					/>

					<div className="my-8">
						<form
							encType="multipart/form-data"
							className=" flex w-full flex-col items-center gap-3 "
							onSubmit={(e) => handleFormSubmit(e)}
						>
							{step === 0 && page === 0 ? (
								<HomeScreen
									setStep={setStep}
									setPage={setPage}
								/>
							) : null}
							{page !== 0 && (
								<div className="mx-auto flex w-full flex-col items-center rounded-[15px] border bg-[#F1F5F9] bg-opacity-50 p-8 py-6 shadow-sm md:w-[500px]">
									{page === 1 ? (
										<DistributorApplyForm
											page={1}
											setPage={setPage}
											setStep={setStep}
											formData={formData}
											setFormData={setFormData}
											bankData={bankData}
										/>
									) : null}
									{page === 2 ? (
										<DistributorGuarantorForm1
											page={2}
											setPage={setPage}
											formData={formData}
											setFormData={setFormData}
										/>
									) : null}
									{page === 3 ? (
										<DistributorGuarantorForm2
											page={3}
											setPage={setPage}
											formData={formData}
											setFormData={setFormData}
										/>
									) : null}
									{page === 4 ? (
										<DistributorKinForm
											page={4}
											setPage={setPage}
											formData={formData}
											setFormData={setFormData}
											isLoading={isLoading}
										/>
									) : null}
								</div>
							)}
						</form>
					</div>
				</>
			</CreditApplicationWrapper>
			<SuccessModal
				show={showSuccessModal}
				setShow={setShowSuccessModal}
				buttonAction={() => {
					setShowSuccessModal(false);
					navigate("/dashboard");
				}}
				buttonContent="Continue"
				content="You have successfully submitted your details for credit application. You will be redirected to our third-party partner to continue this process"
			/>
			<MonoConnectComponent
				onSuccess={onMonoSuccess}
				showMonoModal={showMonoModal}
				setShow={setShowMonoModal}
				viewMode={"modal"}
			/>
		</>
	);
};

export default DistributorCreditApplication;
