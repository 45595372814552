import React, { useEffect, useState } from "react";
import { Button, Loader2, FormikInput } from "../../components";
import { BusinessSectorType } from "../../types/hooks";

const Form2: React.FC<{
	handlePrevious: () => void;
	isLoading: boolean;
	businessSectors: BusinessSectorType[];
}> = ({ handlePrevious, isLoading, businessSectors }) => {
	const [businessSectorsOptions, setBusinessSectorsOptions] = useState<any>(
		[]
	);
	useEffect(() => {
		if (businessSectors) {
			const options = businessSectors.map((businessSector) => {
				return {
					value: businessSector.name,
					label: businessSector.name,
				};
			});
			setBusinessSectorsOptions(options);
		}
	}, [businessSectors]);
	return (
		<div className="grid grid-cols-2 items-start gap-x-4 gap-y-9 md:grid-cols-2">
			<FormikInput
				label="Business Name"
				name="businessName"
				id="businessName"
				floatingLabel={true}
				type="text"
				placeholder="Enter your organization's name"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				autoComplete="off"
				required={true}
			/>
			<FormikInput
				label="Business Phone Number"
				name="businessPhoneNumber"
				id="businessPhoneNumber"
				floatingLabel={true}
				type="text"
				minLength={10}
				maxLength={11}
				placeholder="Enter business phone number"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				autoComplete="off"
				containerStyle="msm:col-span-2"
				required={true}
			/>
			<FormikInput
				label="Business Email Address"
				name="businessEmailAddress"
				id="businessEmailAddress"
				floatingLabel={true}
				type="text"
				placeholder="Enter business email address"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="col-span-2"
				autoComplete="off"
				required={false}
			/>
			<FormikInput
				options={businessSectorsOptions}
				label="Business Sector"
				name="businessSector"
				id="businessSector"
				floatingLabel={true}
				type="select"
				placeholder="Select a business sector"
				containerStyle="col-span-2"
				autoComplete="off"
				required={false}
			/>
			<FormikInput
				label="Password"
				name="password"
				id="password"
				floatingLabel={true}
				type="password"
				password={true}
				placeholder="8+ Strong characters"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				autoComplete="off"
				required={true}
			/>
			<FormikInput
				label="Confirm Password"
				name="confirmPassword"
				id="confirmPassword"
				floatingLabel={true}
				type="password"
				placeholder="Confirm your password"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="msm:col-span-2"
				autoComplete="off"
				required={true}
			/>
			<FormikInput
				label={
					<>
						I accept the{" "}
						<a
							href="https://www.hadifinance.com/terms-and-conditions"
							rel="noreferrer"
							target="_blank"
							className="text-primary-700"
						>
							Terms & Conditions
						</a>
					</>
				}
				name="terms"
				id="terms"
				type="checkbox"
				additionalClasses="border-[#263238] placeholder-[#263238]"
				containerStyle="-mt-6 col-span-2"
				required={true}
			/>

			<div className="col-span-2 flex flex-col items-center gap-1">
				<Button
					disabled={false}
					type="submit"
					additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px]"
				>
					{isLoading ? <Loader2 /> : "Sign Up"}
				</Button>
				<button
					type="button"
					className="text-primary mx-auto mt-4 text-sm font-semibold text-[#002060]"
					onClick={handlePrevious}
				>
					Go back
				</button>
			</div>
		</div>
	);
};

export default Form2;
