import React from "react";
import ErrorImg from "../../assets/image/404.svg";

type TableDataProps = {
	textInformation: string;
	buttonAction?: () => void;
	buttonContent?: string;
};

const NoTableData = ({
	textInformation,
	buttonAction,
	buttonContent,
}: TableDataProps) => {
	return (
		<div className="flex h-[500px] flex-col items-center justify-center px-4 ">
			<img src={ErrorImg} alt="404 image" />

			<div className="flex w-full flex-col gap-8 md:w-[406px]">
				<p className="pt-4 text-center text-base font-semibold leading-[28px] text-gray-400 md:text-[24px]">
					{textInformation}
				</p>

				{buttonContent && (
					<button
						disabled={false}
						onClick={buttonAction}
						className="w-fit self-center rounded-md bg-[#072154] px-6 py-2 font-semibold text-white"
					>
						{buttonContent}
					</button>
				)}
			</div>
		</div>
	);
};

export default NoTableData;
