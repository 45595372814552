import React, { SetStateAction } from "react";
import { Button, FormInput } from "components";
import {
	RetailerCreditAuthorizationFormProps,
	RetailerCreditAuthorizationPageProps,
} from "types/views";
import {
	Gender,
	MaritalStatus,
	Patterns,
	States,
	Nationalities,
} from "services/Constants";
import { isValidAddress, isValidName, isValidBVN } from "utils/validation";
import { Toast } from "utils/toast";
import { getStateLga, getMaxDate } from "utils/utilities";

interface RetailerDetailsForm1Props
	extends RetailerCreditAuthorizationPageProps {
	formData: RetailerCreditAuthorizationFormProps;
	setFormData: React.Dispatch<
		SetStateAction<RetailerCreditAuthorizationFormProps>
	>;
	// isAdminRequest: boolean;
}

const RetailerDetailsForm1 = ({
	formData,
	setFormData,
	setPage,
}: // isAdminRequest,
RetailerDetailsForm1Props) => {
	const validateRetailerForm1 = (
		formData: RetailerCreditAuthorizationFormProps
	) => {
		//missing dob
		if (!isValidName(formData.first_name)) {
			Toast.info("Enter Correct First Name");
			return false;
		} else if (!isValidName(formData.last_name)) {
			Toast.info("Enter Correct Last Name");
			return false;
		} else if (!isValidAddress(formData.house_address)) {
			Toast.info("Enter Correct home address");
			return false;
		} else if (!formData.business_name) {
			Toast.info("Enter your Business name");
			return false;
		} else if (!isValidAddress(formData.store_address)) {
			Toast.info("Enter a valid shop address");
			return false;
		} else if (!Patterns.telephone.test(formData.phone_number)) {
			Toast.info("Enter Correct phone number");
			return false;
		} else if (!formData.dob) {
			Toast.info("Select Correct Date of birth");
			return false;
		} else if (!formData.marital_status) {
			Toast.info("Select your maritial Status");
			return false;
		} else if (!formData.nationality) {
			Toast.info("Enter your nationality");
			return false;
		} else if (!isValidBVN(formData.bvn)) {
			Toast.info("Enter your correct BVN");
			return false;
		}
		return true;
	};

	return (
		<div className="relative m-5 mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
			<div className="bg-[#FFDAD6] p-2 ">
				<p className="text-[12px] md:text-[15px]">
					Please enter correct personal details before authorising the
					credit application. You must be a working adult above the
					age of 18 yrs.{" "}
				</p>
			</div>
			<div className="space-y-4 py-4">
				{/* Calendar Ommitted  */}
				<FormInput
					label="First Name"
					name="first_name"
					id="first_name"
					labelStyles="text-[16px] font-medium"
					value={formData.first_name}
					placeholder="Enter First Name"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							first_name: e.target.value,
						})
					}
				/>
				<FormInput
					label="Last Name"
					name="last_name"
					id="last_name"
					labelStyles="text-[16px] font-medium"
					value={formData.last_name}
					placeholder="Enter Last Name"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							last_name: e.target.value,
						})
					}
				/>
				<FormInput
					label="Gender"
					name="gender"
					id="gender"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={Gender}
					value={formData.gender}
					placeholder="Select Gender"
					additionalClasses="rounded-md font-light  outline-none"
					minLength={2}
					type="text"
					required={false}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							gender: selectedOption.value,
						})
					}
				/>
				<FormInput
					label="Email Address"
					name="email"
					id="email"
					labelStyles="text-[16px] font-medium"
					value={formData.email}
					placeholder="Enter Email Address"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={false}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							email: e.target.value,
						})
					}
				/>
				<FormInput
					label="Residential Address"
					name="residential_address"
					id="residential_address"
					labelStyles="text-[16px] font-medium"
					value={formData.house_address}
					placeholder="Enter Valid Address "
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							house_address: e.target.value,
						})
					}
				/>
				<FormInput
					label="Business Name"
					name="business_name"
					id="business_name"
					labelStyles="text-[16px] font-medium"
					value={formData.business_name}
					placeholder="Enter your business name"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							business_name: e.target.value,
						})
					}
				/>
				<FormInput
					label="Store Address"
					name="store_address"
					id="store_address"
					labelStyles="text-[16px] font-medium"
					value={formData.store_address}
					placeholder="Enter your store address"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							store_address: e.target.value,
						})
					}
				/>
				<FormInput
					label="Phone Number"
					name="phone_number"
					id="phone_number"
					labelStyles="text-[16px] font-medium"
					value={formData.phone_number}
					placeholder="Enter Phone Number"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={10}
					maxLength={11}
					type="tel"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
						setFormData({
							...formData,
							phone_number: value,
						});
					}}
				/>
				<FormInput
					label="Date of Birth"
					name="dob"
					type="date"
					id="dob"
					labelStyles="text-[16px] font-medium"
					value={formData.dob}
					placeholder="Enter Date of birth"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					required={true}
					autoComplete="false"
					max={getMaxDate(18).toISOString().split("T")[0]}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setFormData({
							...formData,
							dob: e.target.value,
						})
					}
				/>
				<FormInput
					label="Marital Status"
					name="marital_status"
					id="marital_status"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={MaritalStatus}
					value={formData.marital_status}
					placeholder="Select Maritial Status"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							marital_status: selectedOption.value,
						})
					}
				/>
				<FormInput
					label="Nationality"
					name="nationality"
					id="nationality"
					labelStyles="text-[16px] font-medium"
					select={true}
					options={Nationalities}
					value={formData.nationality}
					placeholder="Select Nationality"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={2}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(selectedOption) =>
						setFormData({
							...formData,
							nationality: selectedOption.value,
						})
					}
				/>
				<FormInput
					label="BVN"
					name="BVN"
					id="BVN"
					labelStyles="text-[16px] font-medium"
					value={formData.bvn}
					placeholder="Enter Correct BVN"
					additionalClasses="rounded-md font-light placeholder-grey-400 outline-none placeholder:text-[15px]"
					minLength={11}
					maxLength={11}
					type="text"
					required={true}
					autoComplete="false"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						const value = e.target.value.replace(/\D/g, "");
						setFormData({
							...formData,
							bvn: value,
						});
					}}
				/>
			</div>
			<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
				<Button
					additionalClasses=" w-fit text-white"
					disabled={false}
					onClick={() => {
						if (!validateRetailerForm1(formData)) {
							return;
						}
						setPage(2);
					}}
				>
					Next
				</Button>
				<button
					className="text-sm font-semibold text-blue-900"
					onClick={() => setPage(0)}
				>
					Go Back
				</button>
			</div>
		</div>
	);
};

export default RetailerDetailsForm1;
